import { makePostMediaFromFiles } from '@lobox/utils';

type Ratio = '0:0' | '1:1' | '4:5' | '16:9';
type Size = { width: number; height: number };

export const initialState: {
  readyToUploadFiles: {
    file: Blob;
    position: { x: number; y: number };
    ratio: Ratio;
    zoom: number;
    size: Size;
    backgroundColor: string;
  }[];
  cropperData: {
    ratio: Ratio;
    wrapperSide: number;
  } & Size;
  mediaEdited: boolean;
  postSubmitted: boolean;
  uploadSubmitted: boolean;
  uploading: boolean;
  [key: string]: any;
} = {
  selectedUsers: null,
  selectedLocation: null,
  selectedHighlight: null,
  highlightsList: {},
  highlightForm: { isValid: false, values: {} },
  files: [],
  richTextValue: '',
  uploading: false,
  readyToUploadFiles: [],
  cropperData: {
    width: 0,
    height: 0,
    wrapperSide: 0,
    ratio: '0:0',
  },
  mediaEdited: false,
  postSubmitted: false,
  uploadSubmitted: false,
};

type ActionType =
  | { type: 'INIT'; payload: any }
  | { type: 'SET_HIGHLIGHT'; payload: any }
  | { type: 'SET_CHECK_IN'; payload: any }
  | { type: 'SET_USERS'; payload: any }
  | { type: 'SET_FILES'; payload: any }
  | { type: 'SET_READY_TO_UPLOAD_FILES'; payload: { files: [] } }
  | {
      type: 'SET_CROPPER_DATA';
      payload: {
        cropperData: {
          width: 0;
          height: 0;
          wrapperSide: 0;
          ratio: '1:1';
        };
      };
    }
  | { type: 'ADD_FILE'; payload: any }
  | { type: 'DELETE_FILE'; payload: any }
  | { type: 'DELETE_FILE_BY_INDEX'; payload: { index: number } }
  | { type: 'SET_UPLOADING'; payload: { uploading: false } }
  | { type: 'START_UPLOADING'; payload: any }
  | { type: 'SET_UPLOAD_ERROR'; payload: any }
  | { type: 'SET_FILE_UPLOADED'; payload: any }
  | { type: 'SET_MEDIA_EDITED'; payload: { mediaEdited: boolean } }
  | { type: 'SET_POST_SUBMITTED'; payload: { submitted: boolean } }
  | { type: 'SET_UPLOAD_SUBMITTED'; payload: { uploadSubmitted: boolean } }
  | { type: 'CLEAR_FILES'; payload: any };

type StateType = typeof initialState;

export function createPostReducer(
  state: StateType,
  action: ActionType
): StateType {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        ...action.payload,
        postSubmitted: false,
        uploadSubmitted: false,
      };
    case 'SET_HIGHLIGHT':
      return {
        ...state,
        selectedHighlight: action.payload,
      };
    case 'SET_CHECK_IN':
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case 'SET_USERS':
      return {
        ...state,
        selectedUsers: action.payload,
      };
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload,
      };
    case 'SET_CROPPER_DATA': {
      return {
        ...state,
        cropperData: action.payload.cropperData,
      };
    }
    case 'SET_READY_TO_UPLOAD_FILES': {
      return {
        ...state,
        readyToUploadFiles: action.payload.files,
      };
    }
    case 'ADD_FILE': {
      const a = makePostMediaFromFiles(action.payload);
      const files = [...state.files, ...a] as any;
      return {
        ...state,
        files,
      };
    }
    case 'DELETE_FILE':
      return {
        ...state,
        files: state.files.filter((x: any) => x.id !== action.payload.id),
      };
    case 'DELETE_FILE_BY_INDEX':
      return {
        ...state,
        files: state.files.filter(
          (x: any, ii: number) => ii !== action.payload.index
        ),
      };
    case 'SET_UPLOADING': {
      return {
        ...state,
        uploading: action.payload.uploading,
      };
    }
    case 'START_UPLOADING': {
      const files = state.files.map((x: any) => {
        if (x.id === action.payload.id) {
          x.isUploading = true;
          x.isError = false;
        }
        return x;
      }) as any;
      return {
        ...state,
        files,
      };
    }
    case 'SET_UPLOAD_ERROR': {
      const files = state.files.map((x: any) => {
        if (x.id === action.payload.id) {
          x.isUploading = false;
          x.isError = true;
        }
        return x;
      }) as any;
      return {
        ...state,
        files,
      };
    }
    case 'SET_FILE_UPLOADED': {
      const { file, data } = action.payload;
      const files = state.files.map((x: any) => {
        if (x.id === file.id) {
          x.isUploading = false;
          x.isUploaded = true;
          x.res = data;
        }
        return x;
      }) as any;
      return {
        ...state,
        files,
      };
    }
    case 'SET_MEDIA_EDITED': {
      return {
        ...state,
        mediaEdited: action.payload.mediaEdited,
      };
    }
    case 'SET_POST_SUBMITTED': {
      return {
        ...state,
        postSubmitted: action.payload.submitted,
      };
    }
    case 'SET_UPLOAD_SUBMITTED': {
      return {
        ...state,
        uploadSubmitted: action.payload.uploadSubmitted,
      };
    }
    case 'CLEAR_FILES':
      return {
        ...state,
        files: [],
        readyToUploadFiles: [],
      };
    default: {
      throw new Error(`Unsupported action type at APP Reducer`);
    }
  }
}
