import { QueryKeys, useAuthState, useReactQuery } from '@lobox/utils';
import { getUnseenMessageCount } from '@shared/components/Organism/Message/utils/api/archiver';

type GetUnseenMessageResponseType = number | undefined;

const useGetUnseenMessageCount = ():
  | GetUnseenMessageResponseType
  | undefined => {
  const isLoggedIn = useAuthState('isLoggedIn');

  const { data } = useReactQuery<{ count: number }>({
    action: {
      apiFunc: getUnseenMessageCount,
      key: [QueryKeys.myPagesNotifications],
    },
    config: {
      enabled: isLoggedIn,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      // refetchIntervalInBackground: true,
      refetchInterval: 1000 * 60,
    },
  });

  return data?.count;
};

export default useGetUnseenMessageCount;
