import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const HelpLogin: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#393939" />
      <g clipPath="url(#a)">
        <path
          fill="#5384EE"
          d="M45.276 19.724c-6.27-6.299-16.494-6.299-22.764 0a16.023 16.023 0 0 0-3.926 16.348A5307.162 5307.162 0 0 0 .44 54.218c-.294.264-.44.645-.44 1.026v8.29c0 .411.146.762.44 1.026.263.293.615.44 1.025.44h8.291c.38 0 .762-.147 1.025-.44.762-.732 1.319-1.318 2.08-2.05.323-.323.47-.762.41-1.202-.087-.82-.175-1.435-.263-2.226 1.113-.117 1.963-.205 3.105-.293a1.495 1.495 0 0 0 1.319-1.318c.087-1.114.175-1.993.293-3.106.79.117 1.406.176 2.226.264.82.117 1.611-.557 1.611-1.436V50.45h2.696c.38 0 .761-.098 1.025-.39 1.348-1.348 2.297-2.269 3.645-3.645a16.022 16.022 0 0 0 16.348-3.926c6.299-6.27 6.299-16.495 0-22.764Zm-4.131 10.342a4.368 4.368 0 0 1-6.21 0 4.368 4.368 0 0 1 0-6.211 4.367 4.367 0 0 1 6.21 0 4.368 4.368 0 0 1 0 6.21Z"
        />
        <path
          fill="#4269BE"
          d="M45.278 42.488a16.022 16.022 0 0 1-16.348 3.926c-1.348 1.377-2.297 2.297-3.645 3.645-.264.293-.644.39-1.025.39h-2.696v2.744c0 .88-.79 1.553-1.611 1.436-.82-.088-1.436-.147-2.226-.264a111.19 111.19 0 0 0-.293 3.106 1.495 1.495 0 0 1-1.319 1.318c-1.142.088-1.992.176-3.105.293.088.791.175 1.406.264 2.227.058.439-.088.878-.41 1.2-.762.733-1.319 1.319-2.08 2.051-.264.293-.645.44-1.026.44H1.467a1.33 1.33 0 0 1-1.026-.44l34.495-34.494a4.368 4.368 0 0 0 6.211 0 4.368 4.368 0 0 0 0-6.211l4.13-4.131c6.3 6.27 6.3 16.494 0 22.764Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 15h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <g clipPath="url(#a)">
        <path
          fill="#5384EE"
          d="M45.276 19.724c-6.27-6.299-16.494-6.299-22.764 0a16.023 16.023 0 0 0-3.926 16.348A5307.162 5307.162 0 0 0 .44 54.218c-.294.264-.44.645-.44 1.026v8.29c0 .411.146.762.44 1.026.263.293.615.44 1.025.44h8.291c.38 0 .762-.147 1.025-.44.762-.732 1.319-1.318 2.08-2.05.323-.323.47-.762.41-1.202-.087-.82-.175-1.435-.263-2.226 1.113-.117 1.963-.205 3.105-.293a1.495 1.495 0 0 0 1.319-1.318c.087-1.114.175-1.993.293-3.106.79.117 1.406.176 2.226.264.82.117 1.611-.557 1.611-1.436V50.45h2.696c.38 0 .761-.098 1.025-.39 1.348-1.348 2.297-2.269 3.645-3.645a16.022 16.022 0 0 0 16.348-3.926c6.299-6.27 6.299-16.495 0-22.764Zm-4.131 10.342a4.368 4.368 0 0 1-6.21 0 4.368 4.368 0 0 1 0-6.211 4.367 4.367 0 0 1 6.21 0 4.368 4.368 0 0 1 0 6.21Z"
        />
        <path
          fill="#4269BE"
          d="M45.278 42.488a16.022 16.022 0 0 1-16.348 3.926c-1.348 1.377-2.297 2.297-3.645 3.645-.264.293-.644.39-1.025.39h-2.696v2.744c0 .88-.79 1.553-1.611 1.436-.82-.088-1.436-.147-2.226-.264a111.19 111.19 0 0 0-.293 3.106 1.495 1.495 0 0 1-1.319 1.318c-1.142.088-1.992.176-3.105.293.088.791.175 1.406.264 2.227.058.439-.088.878-.41 1.2-.762.733-1.319 1.319-2.08 2.051-.264.293-.645.44-1.026.44H1.467a1.33 1.33 0 0 1-1.026-.44l34.495-34.494a4.368 4.368 0 0 0 6.211 0 4.368 4.368 0 0 0 0-6.211l4.13-4.131c6.3 6.27 6.3 16.494 0 22.764Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 15h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HelpLogin;
