import classes from './List.content.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import AdvanceCard from '@shared/components/molecules/AdvanceCard/AdvanceCard.component';
import ProgressItem from '@lobox/uikit/ProgressItem';
import BaseButton from '@lobox/uikit/Button/BaseButton';

interface SelectForAutoFillListItemProps {
  data: any[];
  onItemClicked: (item: any) => void;
  cp?: 'list' | 'progressBar';
  addAnotherButton?: React.ReactElement;
}
const SelectForAutoFillListItem: React.FC<SelectForAutoFillListItemProps> = ({
  data,
  onItemClicked,
  cp,
  addAnotherButton,
}) => {
  if (cp === 'progressBar') {
    return (
      <Flex className={cnj(classes.cardContent)}>
        {data?.map((item, index) => (
          <BaseButton key={item.id} onClick={() => onItemClicked(item)}>
            <ProgressItem
              image={item.image}
              title={item.name?.label}
              progressValue={item.progress}
              progressSteps={7}
              tooltipText={item.level?.label}
              styles={index !== 0 ? { root: classes.progressItem } : undefined}
            />
          </BaseButton>
        ))}
      </Flex>
    );
  }
  return (
    <Flex className={cnj(classes.cardContent)}>
      {data?.map((item) => (
        <BaseButton
          onClick={() =>
            onItemClicked({
              ...item,
              description: null,
            })
          }
        >
          <AdvanceCard
            classNames={{
              root: classes.advancedCardRoot,
              thirdTextWrapperClassName: classes.thirdtextwrapper,
            }}
            key={item.id}
            secondTextProps={{ mt: 3, height: 18 }}
            thirdTextProps={{ font: '400', size: 14, mt: 0, height: 16 }}
            firstTextProps={{
              isTruncated: true,
              isWordWrap: true,
              lineNumber: 1,
            }}
            data={item.advancedCardData}
            avatarProps={{ size: 'slg', isCompany: true }}
            imageDim={68}
          />
        </BaseButton>
      ))}
      {addAnotherButton}
    </Flex>
  );
};

export default SelectForAutoFillListItem;
