import React from 'react';
import Cropper from 'react-avatar-editor';
import type { AvatarEditorProps } from 'react-avatar-editor';

const SimpleImageCropper = (
  props: AvatarEditorProps,
  ref: React.LegacyRef<Cropper>
) => <Cropper ref={ref} {...props} />;

export default React.forwardRef(SimpleImageCropper);
