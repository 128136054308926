import classes from '../Highlights.component.module.scss';

import React, { useEffect } from 'react';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import Form from '@shared/components/Organism/CreatePost/components/HighlightForm';
import cnj from '@lobox/uikit/utils/cnj';
import Button from '@lobox/uikit/Button';
import { useTranslation } from '@lobox/utils';

interface Props<T> {
  handlePrev: (item: T) => void;
  handleSubmit: () => void;
  handleClose: () => void;
  form: any;
  disabled: boolean;
  resetForm: () => void;
}

const StepFour = ({
  handlePrev,
  form,
  disabled,
  handleSubmit,
  handleClose,
  resetForm,
}: Props<any>) => {
  const { t } = useTranslation();

  useEffect(() => {
    resetForm();
  }, []);

  return (
    <>
      <ModalHeaderSimple
        backButtonProps={{
          onClick: handlePrev,
        }}
        closeButtonProps={{
          className: classes.closeBtn,
        }}
        title={t('add_highlight')}
        hideBack={false}
        visibleHeaderDivider
      />

      <ModalBody className={classes.highlightBody}>
        <Form formObj={form} />
      </ModalBody>

      <ModalFooter className={cnj(classes.modalFooter, classes.buttonsWrapper)}>
        <Button
          className={classes.cancelBtn}
          schema="ghost"
          onClick={handleClose}
          label={t('discard')}
        />
        <Button
          onClick={handleSubmit}
          disabled={disabled}
          schema="primary-blue"
          label={t('done')}
          className={classes.doneBtn}
        />
      </ModalFooter>
    </>
  );
};

export default StepFour;
