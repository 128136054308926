import classes from './ShareEntityViaEmail.footer.module.scss';

import React, { useState } from 'react';
import { ModalFooter } from '@shared/components/molecules/Modal';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import InputTags from '@lobox/uikit/InputTags';
import useToast from '@lobox/uikit/Toast/useToast';
import { useTranslation } from '@lobox/utils';
import type { FC } from 'react';
import type { CreateAttachmentPostProps } from '@shared/components/Organism/CreatePost/components/Attachment/Attachment.component';

type ShareEntityViaEmailFooterProps = {
  entityType: CreateAttachmentPostProps['entityType'];
  classNames?: {
    wrapper?: string;
  };
  entityData: any;
};

const ShareEntityViaEmailFooter: FC<ShareEntityViaEmailFooterProps> = (
  props
) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [emails, setEmails] = useState<string[]>([]);

  const onSubmit = () => {
    console.log(props);

    toast({
      icon: 'ban',
      type: 'error',
      message: "This API isn't ready yet.",
    });
  };

  return (
    <>
      <Flex className={classes.root}>
        <Flex className={classes.inputContainer}>
          <InputTags
            onChange={setEmails}
            label={t('add_email')}
            errorText={t('enter_valid_email')}
          />
        </Flex>
      </Flex>
      <ModalFooter className={classes.footer}>
        <Button
          label={t('send')}
          onClick={onSubmit}
          schema="primary-blue"
          className={classes.button}
          disabled={emails.length === 0}
        />
      </ModalFooter>
    </>
  );
};

export default ShareEntityViaEmailFooter;
