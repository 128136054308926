import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const HelpPeople: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#393939" />
      <g clipPath="url(#a)">
        <path
          fill="#072252"
          d="M38.2 42.029v.595c0 .73.117 1.456.348 2.148l1.349 4.048h2.547v-9.338h-1.698a2.547 2.547 0 0 0-2.547 2.547Z"
        />
        <path
          fill="#485363"
          d="m49.824 43.792-1.437 5.028-6.79-5.942c-.939 0-1.699-.76-1.699-1.698a2.547 2.547 0 0 1 2.547-2.547h5.942c.938 0 1.698.76 1.698 1.698v1.596a6.79 6.79 0 0 1-.261 1.865Z"
        />
        <path fill="#FED2A4" d="M46.687 51.367h-5.093v3.757h5.093v-3.757Z" />
        <path
          fill="#FED2A4"
          d="M41.598 52.926a6.748 6.748 0 0 0 5.093 0v-1.56h-5.093v1.56Z"
        />
        <path
          fill="#5384EE"
          d="m50.783 54.596-4.095-1.17-2.547 1.69-2.546-1.69-4.096 1.17a2.547 2.547 0 0 0-1.847 2.449v6.206c0 .47.38.85.85.85h15.28c.468 0 .848-.38.848-.85v-6.206a2.547 2.547 0 0 0-1.847-2.449Z"
        />
        <path
          fill="#4269BE"
          d="M44.99 64.1h-1.697l.424-8.976h.85l.424 8.976Z"
        />
        <path
          fill="#FFE5C2"
          d="M44.144 52.216a5.093 5.093 0 0 1-5.093-5.094v-.994c0-.45.179-.882.497-1.2l1.54-1.54a1.678 1.678 0 0 1 1.232-.5c2.713.087 5.115.668 6.428 1.95.322.314.49.753.49 1.203v1.081a5.093 5.093 0 0 1-5.094 5.094Z"
        />
        <path
          fill="#FED2A4"
          d="M41.597 46.338c0-.979.823-1.757 1.8-1.695 1.714.11 4.257.396 5.817 1.146a1.64 1.64 0 0 0-.466-.95c-1.313-1.283-3.715-1.865-6.428-1.95a1.678 1.678 0 0 0-1.233.499l-1.539 1.539c-.318.318-.497.75-.497 1.2v.995a5.092 5.092 0 0 0 3.682 4.891 5.052 5.052 0 0 1-1.135-3.193v-2.482Z"
        />
        <path
          fill="#4269BE"
          d="M49.234 59.574c0-.675.269-1.323.746-1.8l2.185-2.186c.292.418.465.92.465 1.457v6.206c0 .47-.38.85-.849.85h-2.547v-4.527Z"
        />
        <path
          fill="#EEBD53"
          d="M44.566 56.822h-.849a.424.424 0 0 1-.424-.425v-1.273h1.698v1.273c0 .235-.19.425-.425.425Z"
        />
        <path
          fill="#4269BE"
          d="m44.142 55.116-1.44 1.099a.51.51 0 0 1-.755-.16l-1.29-2.357.519-.793a.34.34 0 0 1 .515-.063l2.45 2.274Z"
        />
        <path
          fill="#4269BE"
          d="m44.14 55.116 1.44 1.1a.51.51 0 0 0 .755-.161l1.291-2.357-.52-.793a.34.34 0 0 0-.514-.062l-2.451 2.273Z"
        />
        <path
          fill="#072252"
          d="M15.195 51.861c-.726-1.922-1.3-5.226-1.425-6.946-.237-3.274-2.704-5.943-6.039-5.943-3.334 0-5.801 2.669-6.038 5.943-.124 1.72-.7 5.024-1.425 6.946a.846.846 0 0 0 .442 1.08c.777.352 2.435 1.043 4.434 1.311h5.175c1.99-.269 3.659-.96 4.434-1.31a.846.846 0 0 0 .442-1.08Z"
        />
        <path
          fill="#485363"
          d="M14.752 52.941a.845.845 0 0 0 .442-1.08c-.726-1.922-1.301-5.226-1.425-6.946-.237-3.274-2.704-5.943-6.039-5.943h-.025c-2.559.011-3.463 3.5-1.26 4.803.132.078.217.116.217.116l1.93 10.362h1.726c1.99-.27 3.659-.96 4.434-1.312Z"
        />
        <path
          fill="#FED2A4"
          d="m13.872 55.957-2.747-1.374a1.698 1.698 0 0 1-.938-1.518v-2.547H5.093v2.547c0 .643-.363 1.23-.938 1.518l-2.747 1.374A2.546 2.546 0 0 0 0 58.234v5.017c0 .469.38.849.849.849H14.43c.47 0 .85-.38.85-.849v-5.016c0-.965-.546-1.847-1.409-2.278Z"
        />
        <path
          fill="#D29B6E"
          d="M7.64 53.913a6.71 6.71 0 0 0 2.592-.523c-.021-.108-.046-.214-.046-.325v-2.547H5.093v2.547c0 .112-.025.219-.046.326a6.743 6.743 0 0 0 2.592.522Z"
        />
        <path
          fill="#5384EE"
          d="m13.872 55.957-1.957-.979a5.086 5.086 0 0 1-8.55 0l-1.957.979A2.547 2.547 0 0 0 0 58.234v5.017c0 .469.38.849.849.849H14.43c.47 0 .85-.38.85-.849v-5.016c0-.965-.546-1.847-1.409-2.278Z"
        />
        <path
          fill="#FFE5C2"
          d="M7.64 52.215a5.094 5.094 0 0 1-5.05-4.434.856.856 0 0 1 .453-.882c.391-.197.959-.53 1.503-1.015.605-.54.96-1.11 1.161-1.528a.84.84 0 0 1 .957-.465c2.99.762 5.024 2.317 5.762 2.952.199.17.313.422.293.682a5.094 5.094 0 0 1-5.078 4.69Z"
        />
        <path
          fill="#FED2A4"
          d="M12.426 46.843c-.738-.635-2.773-2.19-5.762-2.952a.84.84 0 0 0-.957.465 4.86 4.86 0 0 1-.776 1.132v.003a4.557 4.557 0 0 1-.385.393A7.084 7.084 0 0 1 3.043 46.9a.856.856 0 0 0-.453.883 5.101 5.101 0 0 0 4.278 4.376c-1.016-.692-1.774-1.643-1.774-3.338v-1.21c.193-.143.387-.285.582-.459A6.758 6.758 0 0 0 6.89 45.72c2.287.713 3.837 1.902 4.425 2.407.16.141.56.51 1.027.947a5.05 5.05 0 0 0 .376-1.547.82.82 0 0 0-.292-.683Z"
        />
        <path
          fill="#4269BE"
          d="M.517 56.704A2.541 2.541 0 0 0 0 58.234v5.017c0 .469.38.849.849.849h2.547v-4.277c0-.516-.235-1.004-.638-1.326l-2.24-1.793Z"
        />
        <path
          fill="#5384EE"
          d="m38.51 54.798-7.103-2.583-5.093 1.698-5.093-1.697-7.103 2.582a3.396 3.396 0 0 0-2.235 3.191v5.262c0 .469.38.85.849.85h27.164c.47 0 .85-.381.85-.85V57.99a3.396 3.396 0 0 0-2.236-3.192Z"
        />
        <path
          fill="#4269BE"
          d="m25.426 55.611-.809 8.489h3.396l-.809-8.489h-1.778Z"
        />
        <path
          fill="#EEBD53"
          d="M27.209 56.777h-1.783a.594.594 0 0 1-.594-.594v-2.27h2.97v2.27a.594.594 0 0 1-.593.594Z"
        />
        <path
          fill="#4269BE"
          d="M39.832 55.676c.573.615.914 1.434.914 2.313v5.262c0 .469-.38.849-.85.849h-4.244v-2.838c0-.9.358-1.764.995-2.401l3.185-3.185Z"
        />
        <path
          fill="#072252"
          d="M35.652 34.709v-7.111c0-.938-.76-1.698-1.697-1.698H23.768a6.791 6.791 0 0 0-6.791 6.791v2.018c0 .912.147 1.819.435 2.684l.283.848c.086.26.13.532.13.806v.435h16.978v-.435c0-.274.044-.546.131-.806l.283-.848a8.487 8.487 0 0 0 .435-2.684Z"
        />
        <path
          fill="#485363"
          d="M21.223 29.348a3.449 3.449 0 0 0 3.448 3.449h.478l.23 6.685h9.426v-.436c0-.273.044-.545.13-.805l.283-.848a8.486 8.486 0 0 0 .436-2.684v-7.111c0-.938-.76-1.698-1.698-1.698h-9.285a3.449 3.449 0 0 0-3.448 3.448Z"
        />
        <path fill="#FED2A4" d="M31.41 46.274H21.223v7.64H31.41v-7.64Z" />
        <path
          fill="#FED2A4"
          d="M21.223 49.144a10.124 10.124 0 0 0 10.186 0v-2.87H21.223v2.87Z"
        />
        <path
          fill="#4269BE"
          d="m26.315 53.913-2.708 2.708a.849.849 0 0 1-1.276-.087l-2.808-3.7.735-1.547a.849.849 0 0 1 1.212-.358l4.845 2.984ZM26.316 53.913l2.708 2.708a.849.849 0 0 0 1.276-.087l2.808-3.7-.735-1.547a.849.849 0 0 0-1.212-.358l-4.845 2.984Z"
        />
        <path
          fill="#FFE5C2"
          d="M26.317 48.82a8.49 8.49 0 0 1-8.489-8.489v-.995c0-.45.179-.882.497-1.2l.704-.703c.318-.319.497-.75.497-1.2v-2.036c0-.393.264-.734.646-.825 2.008-.474 8.256-1.597 12.507 1.53.276.203.43.536.43.88v.45c0 .45.178.882.496 1.2l.704.704c.318.318.497.75.497 1.2v.995c0 4.688-3.8 8.489-8.489 8.489Z"
        />
        <path
          fill="#FED2A4"
          d="M32.68 34.902c-2.99-2.2-6.963-2.295-9.758-1.994-1.18.127-2.154.324-2.75.464a.842.842 0 0 0-.646.825v2.035c0 .45-.179.882-.497 1.2l-.703.704c-.319.319-.498.75-.498 1.2v.995c0 4.34 3.26 7.914 7.463 8.421a8.454 8.454 0 0 1-2.37-5.874v-6.205c0-.86.64-1.58 1.494-1.686 2.037-.256 5.768-.41 8.693 1.245v-.45c0-.343-.153-.677-.428-.88Z"
        />
        <path
          fill="#4269BE"
          d="M12.797 55.676a3.392 3.392 0 0 0-.914 2.313v5.262c0 .469.38.849.849.849h4.244v-2.838c0-.9-.358-1.764-.994-2.401l-3.185-3.185Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 25h52.632v40H0z" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <g clipPath="url(#a)">
        <path
          fill="#072252"
          d="M38.2 42.029v.595c0 .73.117 1.456.348 2.148l1.349 4.048h2.547v-9.338h-1.698a2.547 2.547 0 0 0-2.547 2.547Z"
        />
        <path
          fill="#485363"
          d="m49.824 43.792-1.437 5.028-6.79-5.942c-.939 0-1.699-.76-1.699-1.698a2.547 2.547 0 0 1 2.547-2.547h5.942c.938 0 1.698.76 1.698 1.698v1.596a6.79 6.79 0 0 1-.261 1.865Z"
        />
        <path fill="#FED2A4" d="M46.687 51.367h-5.093v3.757h5.093v-3.757Z" />
        <path
          fill="#FED2A4"
          d="M41.598 52.926a6.748 6.748 0 0 0 5.093 0v-1.56h-5.093v1.56Z"
        />
        <path
          fill="#5384EE"
          d="m50.783 54.596-4.095-1.17-2.547 1.69-2.546-1.69-4.096 1.17a2.547 2.547 0 0 0-1.847 2.449v6.206c0 .47.38.85.85.85h15.28c.468 0 .848-.38.848-.85v-6.206a2.547 2.547 0 0 0-1.847-2.449Z"
        />
        <path
          fill="#4269BE"
          d="M44.99 64.1h-1.697l.424-8.976h.85l.424 8.976Z"
        />
        <path
          fill="#FFE5C2"
          d="M44.144 52.216a5.093 5.093 0 0 1-5.093-5.094v-.994c0-.45.179-.882.497-1.2l1.54-1.54a1.678 1.678 0 0 1 1.232-.5c2.713.087 5.115.668 6.428 1.95.322.314.49.753.49 1.203v1.081a5.093 5.093 0 0 1-5.094 5.094Z"
        />
        <path
          fill="#FED2A4"
          d="M41.597 46.338c0-.979.823-1.757 1.8-1.695 1.714.11 4.257.396 5.817 1.146a1.64 1.64 0 0 0-.466-.95c-1.313-1.283-3.715-1.865-6.428-1.95a1.678 1.678 0 0 0-1.233.499l-1.539 1.539c-.318.318-.497.75-.497 1.2v.995a5.092 5.092 0 0 0 3.682 4.891 5.052 5.052 0 0 1-1.135-3.193v-2.482Z"
        />
        <path
          fill="#4269BE"
          d="M49.234 59.574c0-.675.269-1.323.746-1.8l2.185-2.186c.292.418.465.92.465 1.457v6.206c0 .47-.38.85-.849.85h-2.547v-4.527Z"
        />
        <path
          fill="#EEBD53"
          d="M44.566 56.822h-.849a.424.424 0 0 1-.424-.425v-1.273h1.698v1.273c0 .235-.19.425-.425.425Z"
        />
        <path
          fill="#4269BE"
          d="m44.142 55.116-1.44 1.099a.51.51 0 0 1-.755-.16l-1.29-2.357.519-.793a.34.34 0 0 1 .515-.063l2.45 2.274Z"
        />
        <path
          fill="#4269BE"
          d="m44.14 55.116 1.44 1.1a.51.51 0 0 0 .755-.161l1.291-2.357-.52-.793a.34.34 0 0 0-.514-.062l-2.451 2.273Z"
        />
        <path
          fill="#072252"
          d="M15.195 51.861c-.726-1.922-1.3-5.226-1.425-6.946-.237-3.274-2.704-5.943-6.039-5.943-3.334 0-5.801 2.669-6.038 5.943-.124 1.72-.7 5.024-1.425 6.946a.846.846 0 0 0 .442 1.08c.777.352 2.435 1.043 4.434 1.311h5.175c1.99-.269 3.659-.96 4.434-1.31a.846.846 0 0 0 .442-1.08Z"
        />
        <path
          fill="#485363"
          d="M14.752 52.941a.845.845 0 0 0 .442-1.08c-.726-1.922-1.301-5.226-1.425-6.946-.237-3.274-2.704-5.943-6.039-5.943h-.025c-2.559.011-3.463 3.5-1.26 4.803.132.078.217.116.217.116l1.93 10.362h1.726c1.99-.27 3.659-.96 4.434-1.312Z"
        />
        <path
          fill="#FED2A4"
          d="m13.872 55.957-2.747-1.374a1.698 1.698 0 0 1-.938-1.518v-2.547H5.093v2.547c0 .643-.363 1.23-.938 1.518l-2.747 1.374A2.546 2.546 0 0 0 0 58.234v5.017c0 .469.38.849.849.849H14.43c.47 0 .85-.38.85-.849v-5.016c0-.965-.546-1.847-1.409-2.278Z"
        />
        <path
          fill="#D29B6E"
          d="M7.64 53.913a6.71 6.71 0 0 0 2.592-.523c-.021-.108-.046-.214-.046-.325v-2.547H5.093v2.547c0 .112-.025.219-.046.326a6.743 6.743 0 0 0 2.592.522Z"
        />
        <path
          fill="#5384EE"
          d="m13.872 55.957-1.957-.979a5.086 5.086 0 0 1-8.55 0l-1.957.979A2.547 2.547 0 0 0 0 58.234v5.017c0 .469.38.849.849.849H14.43c.47 0 .85-.38.85-.849v-5.016c0-.965-.546-1.847-1.409-2.278Z"
        />
        <path
          fill="#FFE5C2"
          d="M7.64 52.215a5.094 5.094 0 0 1-5.05-4.434.856.856 0 0 1 .453-.882c.391-.197.959-.53 1.503-1.015.605-.54.96-1.11 1.161-1.528a.84.84 0 0 1 .957-.465c2.99.762 5.024 2.317 5.762 2.952.199.17.313.422.293.682a5.094 5.094 0 0 1-5.078 4.69Z"
        />
        <path
          fill="#FED2A4"
          d="M12.426 46.843c-.738-.635-2.773-2.19-5.762-2.952a.84.84 0 0 0-.957.465 4.86 4.86 0 0 1-.776 1.132v.003a4.557 4.557 0 0 1-.385.393A7.084 7.084 0 0 1 3.043 46.9a.856.856 0 0 0-.453.883 5.101 5.101 0 0 0 4.278 4.376c-1.016-.692-1.774-1.643-1.774-3.338v-1.21c.193-.143.387-.285.582-.459A6.758 6.758 0 0 0 6.89 45.72c2.287.713 3.837 1.902 4.425 2.407.16.141.56.51 1.027.947a5.05 5.05 0 0 0 .376-1.547.82.82 0 0 0-.292-.683Z"
        />
        <path
          fill="#4269BE"
          d="M.517 56.704A2.541 2.541 0 0 0 0 58.234v5.017c0 .469.38.849.849.849h2.547v-4.277c0-.516-.235-1.004-.638-1.326l-2.24-1.793Z"
        />
        <path
          fill="#5384EE"
          d="m38.51 54.798-7.103-2.583-5.093 1.698-5.093-1.697-7.103 2.582a3.396 3.396 0 0 0-2.235 3.191v5.262c0 .469.38.85.849.85h27.164c.47 0 .85-.381.85-.85V57.99a3.396 3.396 0 0 0-2.236-3.192Z"
        />
        <path
          fill="#4269BE"
          d="m25.426 55.611-.809 8.489h3.396l-.809-8.489h-1.778Z"
        />
        <path
          fill="#EEBD53"
          d="M27.209 56.777h-1.783a.594.594 0 0 1-.594-.594v-2.27h2.97v2.27a.594.594 0 0 1-.593.594Z"
        />
        <path
          fill="#4269BE"
          d="M39.832 55.676c.573.615.914 1.434.914 2.313v5.262c0 .469-.38.849-.85.849h-4.244v-2.838c0-.9.358-1.764.995-2.401l3.185-3.185Z"
        />
        <path
          fill="#072252"
          d="M35.652 34.709v-7.111c0-.938-.76-1.698-1.697-1.698H23.768a6.791 6.791 0 0 0-6.791 6.791v2.018c0 .912.147 1.819.435 2.684l.283.848c.086.26.13.532.13.806v.435h16.978v-.435c0-.274.044-.546.131-.806l.283-.848a8.487 8.487 0 0 0 .435-2.684Z"
        />
        <path
          fill="#485363"
          d="M21.223 29.348a3.449 3.449 0 0 0 3.448 3.449h.478l.23 6.685h9.426v-.436c0-.273.044-.545.13-.805l.283-.848a8.486 8.486 0 0 0 .436-2.684v-7.111c0-.938-.76-1.698-1.698-1.698h-9.285a3.449 3.449 0 0 0-3.448 3.448Z"
        />
        <path fill="#FED2A4" d="M31.41 46.274H21.223v7.64H31.41v-7.64Z" />
        <path
          fill="#FED2A4"
          d="M21.223 49.144a10.124 10.124 0 0 0 10.186 0v-2.87H21.223v2.87Z"
        />
        <path
          fill="#4269BE"
          d="m26.315 53.913-2.708 2.708a.849.849 0 0 1-1.276-.087l-2.808-3.7.735-1.547a.849.849 0 0 1 1.212-.358l4.845 2.984ZM26.316 53.913l2.708 2.708a.849.849 0 0 0 1.276-.087l2.808-3.7-.735-1.547a.849.849 0 0 0-1.212-.358l-4.845 2.984Z"
        />
        <path
          fill="#FFE5C2"
          d="M26.317 48.82a8.49 8.49 0 0 1-8.489-8.489v-.995c0-.45.179-.882.497-1.2l.704-.703c.318-.319.497-.75.497-1.2v-2.036c0-.393.264-.734.646-.825 2.008-.474 8.256-1.597 12.507 1.53.276.203.43.536.43.88v.45c0 .45.178.882.496 1.2l.704.704c.318.318.497.75.497 1.2v.995c0 4.688-3.8 8.489-8.489 8.489Z"
        />
        <path
          fill="#FED2A4"
          d="M32.68 34.902c-2.99-2.2-6.963-2.295-9.758-1.994-1.18.127-2.154.324-2.75.464a.842.842 0 0 0-.646.825v2.035c0 .45-.179.882-.497 1.2l-.703.704c-.319.319-.498.75-.498 1.2v.995c0 4.34 3.26 7.914 7.463 8.421a8.454 8.454 0 0 1-2.37-5.874v-6.205c0-.86.64-1.58 1.494-1.686 2.037-.256 5.768-.41 8.693 1.245v-.45c0-.343-.153-.677-.428-.88Z"
        />
        <path
          fill="#4269BE"
          d="M12.797 55.676a3.392 3.392 0 0 0-.914 2.313v5.262c0 .469.38.849.849.849h4.244v-2.838c0-.9-.358-1.764-.994-2.401l-3.185-3.185Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 25h52.632v40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HelpPeople;
