import React from 'react';
import IconButton from '@lobox/uikit/Button/IconButton';

type Props = {
  isPlaying: boolean;
  isLoading?: boolean;
  onClick?: (evt: React.MouseEvent) => void;
};

export const PlayButton = ({
  isPlaying,
  isLoading = false,
  onClick,
}: Props): JSX.Element => (
  <IconButton
    name={isPlaying ? 'pause' : 'play'}
    disabled={isLoading}
    size="xl"
    colorSchema="transparent-white"
    onClick={onClick}
  />
);

export default React.memo(PlayButton);
