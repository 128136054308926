import classes from './index.module.scss';

import React from 'react';
import AppLogo from '@lobox/uikit/AppLogo';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Link from '@lobox/uikit/Link';
import Media from '@lobox/uikit/Media';
import {
  CREATE_POST_BOX,
  QueryKeys,
  event,
  routeNames,
  useAuthState,
  useGlobalDispatch,
  useGlobalState,
} from '@lobox/utils';
import urls from '@shared/constants/urls';
import { useHistory, useLocation } from '@lobox/utils';
import { TOP_BAR_ID, TOP_BAR_ITEM } from '@shared/constants/enums';
import eventKeys from '@shared/constants/event-keys';
import Logo from '@shared/svg/LogoIcon';
import { useGlobalSearchUtilities } from '@shared/components/molecules/GlobalSearchInput';
import type { FC } from 'react';
import useGetAppObject from '../../../../hooks/useGetAppObject';
import TopBarItems from '../partials/TopBarItems';
import NotLoggedInActions from '../partials/NotLoggedInActions';
import { useQueryClient } from '@tanstack/react-query';
interface TopBarProps {
  toggleLeftPanel?: () => void;
}

const AppLayoutHeader: FC<TopBarProps> = (props) => {
  const queryClient = useQueryClient();
  const { toggleLeftPanel } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const globalDispatch = useGlobalDispatch();
  const isLoggedIn = useAuthState('isLoggedIn');
  const isOpenProfilePanel = useGlobalState('isOpenProfilePanel');
  const { getAppObjectPropValue, isBusinessApp } = useGetAppObject();
  const { isSearchPageOnMobile } = useGlobalSearchUtilities();
  const croppedImageUrl = getAppObjectPropValue({
    userKey: 'croppedImageUrl',
    pageKey: 'croppedImageUrl',
  });
  const isUnAuthAvailability =
    !isLoggedIn && pathname?.includes(routeNames.schedulesAvailability);

  const toggleProfilePanel = () => {
    globalDispatch({
      type: 'SET_SHOW_PROFILE_PANEL',
      payload: !isOpenProfilePanel,
    });
  };

  const closeProfilePanel = () => {
    globalDispatch({
      type: 'SET_SHOW_PROFILE_PANEL',
      payload: false,
    });
  };

  const handleLogoClicked = () => {
    closeProfilePanel();
    if (pathname === routeNames.home) {
      queryClient.resetQueries([QueryKeys.homeFeedList]);
      document
        .getElementById(CREATE_POST_BOX)
        ?.scrollIntoView({ block: 'start' });
    }
  };

  const handleMessagesClicked = () => {
    history.push(routeNames.messages);
    if (isOpenProfilePanel) {
      toggleProfilePanel();
    }
  };

  const isSearchAllPage = pathname?.includes(routeNames.searchAll);

  return (
    <>
      {!isUnAuthAvailability && (
        <Flex id={TOP_BAR_ID} className={classes.topBarRoot}>
          {isLoggedIn && (
            <Flex className={cnj(TOP_BAR_ITEM, classes.avatar)}>
              <Avatar
                defaultTag
                onClick={toggleProfilePanel}
                isCompany={isBusinessApp}
                className={classes.avatarButton}
                size="xxs"
                imgSrc={croppedImageUrl}
              />
            </Flex>
          )}
          {isLoggedIn && (
            <TopBarItems
              isSearchPageOnMobile={isSearchPageOnMobile}
              isSearchAllPage={isSearchAllPage}
              handleMessagesClicked={handleMessagesClicked}
            />
          )}
          {!isLoggedIn && <NotLoggedInActions />}
          {isLoggedIn && !isSearchPageOnMobile && (
            <Link
              onClick={handleLogoClicked}
              className={cnj(classes.logoWrapper)}
              to={routeNames.home}
            >
              <AppLogo isBusinessApp={isBusinessApp} />
            </Link>
          )}
          {isLoggedIn && (
            <Media greaterThan="tablet">
              <Flex className={classes.leftPanelHamburger}>
                <IconButton
                  type="far"
                  name="bars-light"
                  onClick={toggleLeftPanel}
                  size="md20"
                  colorSchema="secondary2"
                />
              </Flex>
            </Media>
          )}
          {!isLoggedIn && (
            <Flex
              as="a"
              href={isBusinessApp ? urls.business : urls.base}
              className={classes.notLoggedInLogoWrapper}
            >
              <Logo visiblelobox />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default AppLayoutHeader;
