import classes from './index.module.scss';

import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import {
  event,
  mainRoutes,
  routeNames,
  useGlobalDispatch,
  useGlobalState,
} from '@lobox/utils';
import { useHistory, useLocation } from '@lobox/utils';
import CreateEntityModal from '@shared/components/Organism/CreateEntityModal/CreateEntityModal.component';
import eventKeys from '@shared/constants/event-keys';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import BottomBarItem from './partials/BottomBarItem';

const BottomBar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = useLocation();
  const appDispatch = useGlobalDispatch();
  const isGlobalEntityModal = useGlobalState('isGlobalEntityModal');
  const isOpenProfilePanel = useGlobalState('isOpenProfilePanel');
  const isScheduleRoute = location?.pathname?.startsWith(mainRoutes.schedules);

  const { getAppObjectPropValue } = useGetAppObject();

  const toggleShowProfilePanel = () => {
    appDispatch({
      type: 'SET_SHOW_PROFILE_PANEL',
      payload: !isOpenProfilePanel,
    });
  };

  const toggleGlobalEntityModal = () => {
    appDispatch({ type: 'TOGGLE_GLOBAL_ENTITY_MODAL' });
  };
  const scrollToTopFeedList = () => {
    event.trigger(eventKeys.scrollToTopFeedList);
  };

  const AVATAR = {
    key: 'avatar',
    name: 'avatar',
    visibleIndicator: false,
    className: classes.bottomBarRootClassName,
    image: (
      <Avatar
        imgSrc={getAppObjectPropValue({
          userKey: 'croppedImageUrl',
          pageKey: 'croppedImageUrl',
        })}
        isCompany={isBusinessApp}
        onClick={toggleShowProfilePanel}
        size="xxs"
        bordered
        avatarInnerClassName={isOpenProfilePanel && classes.activeAvatar}
      />
    ),
    onClick: scrollToTopFeedList,
  };

  const schedulesList = [
    {
      key: 'schedulesCalendar',
      rootRoute: routeNames.schedulesCalendar,
      name: 'schedulesCalendar',
      icon: 'calender-line',
      link: routeNames.schedulesCalendarDay,
      onClick: scrollToTopFeedList,
    },
    {
      key: 'schedulesMeetings',
      rootRoute: [
        routeNames.schedulesMeetings,
        routeNames.schedulesEvents,
        routeNames.schedulesReminders,
        routeNames.schedulesTasks,
      ],
      name: 'schedulesMeetings',
      icon: 'calender-star',
      link: routeNames.schedulesMeetings,
      onClick: scrollToTopFeedList,
    },
    {
      key: 'createSchedule',
      rootRoute: routeNames.createSchedule,
      link: routeNames.createSchedule,
      name: 'createSchedule',
      icon: 'circle-plus',
    },
    {
      key: 'availability',
      rootRoute: routeNames.schedulesAvailability,
      link: routeNames.schedulesAvailability,
      name: 'availability',
      icon: 'calendar-timer',
      onClick: scrollToTopFeedList,
    },
    AVATAR,
  ];

  const landList = [
    {
      key: 'home',
      rootRoute: mainRoutes.home,
      name: 'Home',
      icon: 'house',
      link: routeNames.home,
      onClick: scrollToTopFeedList,
    },
    {
      key: 'people',
      rootRoute: mainRoutes.people,
      name: 'People',
      icon: 'circle-user',
      link: routeNames.peopleDiscover,
      onClick: () => {
        scrollToTopFeedList();
        appDispatch({
          type: 'PARENT_PATHNAME',
          payload: location.pathname,
        });
      },
    },
    {
      key: 'create',
      rootRoute: mainRoutes.create,
      name: 'Create',
      icon: 'circle-plus',
    },
    {
      key: 'jobs',
      rootRoute: mainRoutes.jobs,
      name: 'Jobs',
      icon: 'briefcase-blank',
      link: isBusinessApp ? routeNames.openJobs : routeNames.discoverJobs,
      onClick: () => {
        scrollToTopFeedList();
        appDispatch({
          type: 'PARENT_PATHNAME',
          payload: location.pathname,
        });
      },
    },
    AVATAR,
  ];
  const list = isScheduleRoute ? schedulesList : landList;

  const handlePressLand =
    ({ key, link, onClick }: any) =>
    () => {
      if (key !== 'avatar') {
        appDispatch({
          type: 'SET_SHOW_PROFILE_PANEL',
          payload: false,
        });
      }

      setTimeout(() => {
        if (key === 'create') {
          toggleGlobalEntityModal();
        } else if (link) {
          history.push({ pathname: link });
          if (isGlobalEntityModal) toggleGlobalEntityModal();
          if (onClick) {
            onClick();
          }
        }
      }, 0);
    };

  const isActiveRoute = (route: string): boolean => {
    if (route === mainRoutes.home) {
      return pathname === mainRoutes.home;
    }
    return (typeof route === 'string' ? [route] : route)?.some(
      (theRoute: string) => pathname?.startsWith(theRoute)
    );
  };

  return (
    <Flex
      className={cnj(
        classes.bottomPanelRoot,
        isGlobalEntityModal && classes.isGlobalEntityMod
      )}
    >
      {list.map(
        ({
          key,
          icon,
          link,
          image,
          rootRoute,
          visibleIndicator,
          onClick,
          className,
        }: any) => {
          const isActive = isActiveRoute(rootRoute || link);

          return (
            <BottomBarItem
              key={key}
              onClick={handlePressLand({ key, link, onClick })}
              active={isActive}
              visibleIndicator={visibleIndicator}
              className={className}
              icon={
                image || (
                  <IconButton
                    noHover
                    name={isActive ? icon : `${icon}-light`}
                    size="md24"
                    type={isActive ? 'fas' : 'far'}
                    colorSchema={
                      isActive ? 'tertiary-transparent' : 'secondary2'
                    }
                  />
                )
              }
            />
          );
        }
      )}
      {isGlobalEntityModal && <CreateEntityModal />}
    </Flex>
  );
};

export default BottomBar;
