import * as React from 'react';

function HalfArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="6"
      viewBox="0 0 24 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9108 6.00008H1.0909C0.805618 5.99443 0.534104 5.88665 0.334529 5.69985C0.134953 5.51304 0.0231934 5.26207 0.0231934 5.00071C0.0231934 4.73934 0.134953 4.48837 0.334529 4.30157C0.534104 4.11477 0.805618 4.00699 1.0909 4.00133H20.2817L17.7723 1.7131C17.6705 1.61994 17.5898 1.50933 17.5347 1.38759C17.4796 1.26586 17.4513 1.13538 17.4513 1.00361C17.4512 0.871833 17.4795 0.741345 17.5346 0.619594C17.5896 0.497843 17.6703 0.387212 17.772 0.294018C17.8737 0.200825 17.9945 0.126894 18.1274 0.0764458C18.2603 0.0259981 18.4027 2.17621e-05 18.5466 1.36678e-08C18.6905 -2.17348e-05 18.8329 0.0259115 18.9658 0.076319C19.0988 0.126727 19.2195 0.200621 19.3213 0.293784L23.6856 4.29129C23.8361 4.43224 23.938 4.61061 23.9787 4.8042C24.0194 4.99779 23.9971 5.19808 23.9146 5.38015C23.832 5.56223 23.6929 5.71806 23.5144 5.82828C23.3359 5.9385 23.126 5.99824 22.9108 6.00008Z"
        fill="#8493A8"
      />
    </svg>
  );
}

export default HalfArrowIcon;
