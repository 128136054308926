import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';

export const useResizeObserver = (
  callback: () => any,
  node?: HTMLElement
): void => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const _callback = debounce(callbackRef.current, 50);
    const observer = new ResizeObserver(_callback);
    observer.observe(node || document.body);
    return () => observer.disconnect();
  }, [node]);
  useEffect(() => {
    callbackRef.current();
  }, []);
};
