import React from 'react';
import {
  useGlobalState,
  useGlobalDispatch,
  useTranslation,
} from '@lobox/utils';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Tooltip from '@lobox/uikit/Tooltip';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import useHasPermission from '@shared/hooks/useHasPermission';
import { TOP_BAR_ITEM } from '@shared/constants/enums';
import classes from './Create.module.scss';

const Create: React.FC = () => {
  const { t } = useTranslation();
  const appDispatch = useGlobalDispatch();
  const canSeePlusAction = useHasPermission([SCOPES.canSeePlusAction]);
  const isGlobalEntityModal = useGlobalState('isGlobalEntityModal');

  const toggleModal = () => {
    appDispatch({ type: 'TOGGLE_GLOBAL_ENTITY_MODAL' });
  };

  return canSeePlusAction ? (
    <Flex className={cnj(classes.icon, TOP_BAR_ITEM)}>
      <Tooltip
        disabled={isGlobalEntityModal}
        trigger={
          <IconButton
            onClick={toggleModal}
            size="md20"
            type={isGlobalEntityModal ? 'fas' : 'far'}
            name={isGlobalEntityModal ? 'circle-plus' : 'circle-plus-light'}
            colorSchema={isGlobalEntityModal ? 'tertiary' : 'secondary2'}
          />
        }
      >
        {t('create')}
      </Tooltip>
    </Flex>
  ) : (
    <Flex className={cnj(classes.icon, TOP_BAR_ITEM)}>
      <Tooltip trigger={<IconButton size="lg" name="plus" />}>
        {t('create')}
      </Tooltip>
    </Flex>
  );
};

export default React.memo(Create);
