export const initialState = {
  jobsListView: 'table',
} as any;

type ActionType =
  | {
      type: 'SET_SCROLL_REF';
      payload?: any;
    }
  | {
      type: 'SET_FILTER_WRAPPER_REF';
      payload?: any;
    }
  | { type: 'SET_IS_JOB_APPLICATION_FORM_OPEN'; payload: boolean }
  | { type: 'SET_IS_POPPER_OPEN'; payload: boolean }
  | { type: 'SET_IS_JOB_FORM_OPEN'; payload: boolean }

type StateType = typeof initialState;

export function jobsReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'SET_SCROLL_REF':
      return {
        ...state,
        scrollRef: action.payload,
      };
    case 'SET_FILTER_WRAPPER_REF':
      return {
        ...state,
        filtersWrapperRef: action.payload,
      };
    case 'SET_IS_POPPER_OPEN':
      return {
        ...state,
        isPopperOpen: action.payload,
      };
    case 'SET_IS_JOB_APPLICATION_FORM_OPEN':
      return {
        ...state,
        isJobApplicationFormOpen: action.payload,
      };
    case 'SET_IS_JOB_FORM_OPEN':
      return {
        ...state,
        isJobCreationFormOpen: action.payload,
      };

    default: {
      throw new Error(`Unsupported action type at Jobs Reducer`);
    }
  }
}
