import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Icon from '@lobox/uikit/Icon';
import useTheme from '@lobox/uikit/utils/useTheme';
import classes from './RatioActionButton.component.module.scss';

export interface Props
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'type' | 'className'> {
  isActive?: boolean;
  iconName?: string;
  buttonClassName?: string;
  iconClassName?: string;
}

function RatioActionButton({
  isActive = false,
  iconName,
  children,
  buttonClassName,
  iconClassName,
  ...rest
}: Props): JSX.Element {
  const { isDark } = useTheme();

  return (
    <button
      type="button"
      className={cnj(classes.button, buttonClassName, {
        [classes.activeButton]: isActive,
      })}
      {...rest}
    >
      {iconName && (
        <Icon
          type="far"
          name={iconName}
          size={15}
          color={(isActive && 'white') || (isDark ? 'disabledGray' : 'brand')}
          className={cnj(classes.icon, iconClassName)}
        />
      )}
      {children}
    </button>
  );
}

export default RatioActionButton;
