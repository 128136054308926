import classes from './Subcategory.content.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Card from './SubCategory.card';

export interface SubCategoryContentProps {
  onItemClicked: (item: any) => void;
  data: any[];
}

const SubCategoryContent = ({
  data = [],
  onItemClicked,
}: SubCategoryContentProps): JSX.Element => {
  return (
    <Flex className={classes.wrapper}>
      {data?.map((item) => (
        <Flex key={item.id} onClick={() => onItemClicked(item)}>
          <Card
            {...item}
            className={classes.cardItem}
            contentClassName={classes.cardContent}
            direction="row-reverse"
            titleProps={{ isTruncated: false, isWordWrap: true }}
            subtitleProps={{ isTruncated: false, isWordWrap: true }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default SubCategoryContent;
