import classes from './GlobalSearchInput.resultItem.module.scss';

import React, { useCallback, useMemo } from 'react';
import { pickBy } from 'lodash';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import HeroIcon from '@lobox/uikit/HeroIcon';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import { preventClickHandler, useTranslation } from '@lobox/utils';
import type HeroIconProps from '@lobox/uikit/HeroIconProps';
import type { SearchResultItem } from '@shared/components/Organism/types';
import type { ApiType } from '@shared/hooks/useGlobalSearchUtilities';
import DividerVertical from '@shared/uikit/Divider/DividerVertical';

const BoldItems: SearchResultItem['type'][] = ['PERSON', 'PAGE', 'HASHTAG'];

export interface ResultItemProps {
  className?: string;
  deleteAction?: (...args: any[]) => any;
  onClick?: (...args: any[]) => void;
  renderTitle?: React.ReactNode;
  resent?: boolean;
  item: SearchResultItem;
  itemType: ApiType;
  isLocation?: boolean;
  boldDisabled?: boolean;
}

const defaultHeroIconProps = {
  size: 32,
  iconSize: 16,
  iconType: 'far',
  iconName: 'search',
  color: 'themedGray',
};

const ResultItem = ({
  className,
  deleteAction,
  onClick,
  renderTitle,
  item,
  itemType,
  isLocation,
  boldDisabled,
}: ResultItemProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    label: title,
    subtitle,
    imgUrl: imgSrc,
    type,
    isSpecific,
    secondaryTitle,
  } = item;
  const clickHandler = useCallback(() => onClick(item), [onClick, item]);

  const isJobSpecific = type === 'JOB' && isSpecific;

  const heroIconProps: Record<ApiType, Partial<HeroIconProps>> = useMemo(
    () => ({
      items: {
        iconName: isJobSpecific
          ? 'briefcase-blank-light'
          : type === 'HASHTAG'
            ? 'hashtag'
            : type === 'LOCATION' || isLocation
              ? 'map-marker-alt'
              : undefined,
        color:
          type === 'HASHTAG'
            ? 'brand'
            : isJobSpecific
              ? 'disabledGray_graphene'
              : undefined,
        iconSize:
          type === 'HASHTAG'
            ? 14
            : type === 'JOB' && !isJobSpecific
              ? undefined
              : type === 'LOCATION' || isLocation
                ? 16
                : 24,
        variant: isJobSpecific ? 'square' : 'circle',
      },
      recents: {
        iconSize: 18,
        iconType: 'fas',
        iconName: 'history',
      },
      suggestions: {},
      all: {
        color: 'brandWhite',
      },
      location: {},
      locationRecents: {},
      locationSuggestions: {
        iconName: 'map-marker-alt',
        iconSize: 16,
      },
    }),
    [type]
  );
  const props = {
    ...defaultHeroIconProps,
    ...pickBy(heroIconProps[itemType], (v) => v !== undefined),
  };

  const titleBoldProps = {
    size: 16,
    font: 700,
    color: 'smoke_coal',
  };
  const isBold = useMemo(
    () => BoldItems.includes(type) && !boldDisabled,
    [type, boldDisabled]
  );

  const isAvatar = itemType === 'items' && ['PAGE', 'PERSON'].includes(type);

  return (
    <BaseButton
      onClick={clickHandler}
      className={cnj(classes.resultItemRoot, className)}
    >
      <Flex className={cnj(classes.avatarWrapper)}>
        {isAvatar ? (
          <Avatar size="xxs" imgSrc={imgSrc} isCompany={type === 'PAGE'} />
        ) : (
          <HeroIcon {...(props as HeroIconProps)} />
        )}
      </Flex>
      <Flex className={classes.titleWrapper}>
        <Flex flexDir="row">
          {renderTitle || (
            <Typography
              lineHeight={21}
              isWordWrap
              isTruncated
              lineNumber={1}
              size={15}
              font="400"
              color="smoke_coal"
              {...(isBold && titleBoldProps)}
            >
              {title}
            </Typography>
          )}
          {!!secondaryTitle && (
            <DividerVertical className={classes.verticalDivider} />
          )}
          {secondaryTitle && (
            <Typography size={12} color="disabledGrayDark_gray">
              {t(secondaryTitle || '')}
            </Typography>
          )}
        </Flex>
        {subtitle && (
          <Typography
            font="400"
            size={12}
            height={14}
            color="secondaryDisabledText"
            isWordWrap
            isTruncated
            lineNumber={1}
          >
            {subtitle}
          </Typography>
        )}
      </Flex>
      {deleteAction && (
        <IconButton
          onClick={(e) => {
            preventClickHandler(e);
            deleteAction?.();
          }}
          name="times"
          size="md18"
          colorSchema="transparent"
          className={classes.deleteAction}
        />
      )}
    </BaseButton>
  );
};

export default ResultItem;
