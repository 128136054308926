import React, { Fragment } from 'react';
import Flex from '@lobox/uikit/Flex';
import { PermissionsGate } from '@shared/components/molecules';
import type { ScopeType } from '@shared/types/permission';
import SettingsItem from '../../molecules/SidebarItem';

interface SettingsContentProps {
  onItemClickHandler: (args: any) => void;
  items: any;
}

const SettingsContent: React.FC<SettingsContentProps> = ({
  onItemClickHandler,
  items,
}) => (
  <Flex>
    {items.map((item: any) => {
      const { title, subTitle, icon, rightAction, scopes } = item;
      const onClick = () => onItemClickHandler(item);
      const Wrapper = scopes ? PermissionsGate : Fragment;
      const wrapperProps = scopes ? { scopes: scopes as ScopeType[] } : {};

      return (
        <Wrapper key={title} {...wrapperProps}>
          <SettingsItem
            onClick={onClick}
            title={title}
            subTitle={subTitle}
            icon={icon}
            action={rightAction}
          />
        </Wrapper>
      );
    })}
  </Flex>
);

export default SettingsContent;
