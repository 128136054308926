import classes from './VolumeButton.module.scss';

import React, { useImperativeHandle } from 'react';
import IconButton from '@lobox/uikit/Button/IconButton';
import Flex from '@lobox/uikit/Flex';
import SimpleSlider from '@lobox/uikit/SimpleSlider';
import cnj from '@lobox/uikit/utils/cnj';
import isBoolean from 'lodash/isBoolean';

type Props = {
  videoRef: React.RefObject<HTMLVideoElement | undefined>;
  containerClassName?: string;
  showOnClick?: boolean;
  disabled?: boolean;
  onVolumeChange?: (
    videoRef: React.RefObject<HTMLVideoElement | undefined>
  ) => void;
};

const onVolumeChangePlaceholder = (
  videoRef: React.RefObject<HTMLVideoElement | undefined>
) => null;
const sanitizeMute = (
  videoRef: React.RefObject<HTMLVideoElement | undefined>
) => (isBoolean(videoRef.current?.muted) ? videoRef.current?.muted : true);

export function FancyVideoPlayer(
  {
    videoRef,
    containerClassName,
    showOnClick = false,
    disabled = false,
    onVolumeChange = onVolumeChangePlaceholder,
  }: Props,
  ref: any
): JSX.Element {
  const [showVolume, setShowVolume] = React.useState(false);
  const [volume, setVolume] = React.useState(videoRef.current?.volume);
  const [isMuted, setIsMuted] = React.useState<boolean>(sanitizeMute(videoRef));

  const handleChangeSlider = React.useCallback(
    (value: number) => {
      if (!videoRef.current) return;

      videoRef.current.volume = value;
      videoRef.current.muted = value === 0;
      setIsMuted(() => sanitizeMute(videoRef));
      setVolume(() => videoRef.current?.volume || 0);
    },
    [videoRef]
  );

  const volumeChangeHandler = React.useCallback(() => {
    onVolumeChange(videoRef);
  }, [videoRef, onVolumeChange]);

  React.useEffect(() => {
    const ref = videoRef.current;

    ref?.addEventListener('volumechange', volumeChangeHandler);

    return () => {
      ref?.removeEventListener('volumechange', volumeChangeHandler);
    };
  }, [onVolumeChange, videoRef]);

  const handleMouseEnter = () => {
    if (disabled) {
      return;
    }
    setShowVolume(() => true);
  };

  const handleMouseLeave = () => {
    setShowVolume(() => false);
  };

  const handleClickOnVolume = () => {
    if (!videoRef.current) return;

    videoRef.current.muted = !videoRef.current?.muted;
    setIsMuted(sanitizeMute(videoRef));
    if (showOnClick && !disabled) {
      setShowVolume(() => !sanitizeMute(videoRef));
    }
  };
  useImperativeHandle(ref, () => ({
    handleChange: (val: number) => {
      handleChangeSlider(val);
    },
  }));

  return (
    <Flex
      flexDir="row"
      className={cnj(classes.container, containerClassName)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex
        className={cnj('volume-slider', classes.sliderWrapper)}
        style={{
          width: showVolume ? undefined : 0,
        }}
      >
        <SimpleSlider
          value={isMuted ? 0 : volume}
          min={0}
          max={1}
          step={0.1}
          onChange={handleChangeSlider}
          className={classes.slider}
          thumbClassName={classes.thumbClassName}
          trackClassName="trackClassName"
        />
      </Flex>
      <IconButton
        name={isMuted ? 'volume-mute' : 'volume'}
        disabled={disabled}
        size="xl"
        colorSchema="transparent-white"
        onClick={handleClickOnVolume}
      />
    </Flex>
  );
}

export default React.forwardRef<any, Props>(FancyVideoPlayer);
