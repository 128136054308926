import { useCreateContext } from '@lobox/utils';
import { createPostReducer, initialState } from './createPost.reducer';

const [state, dispatch, provider] = useCreateContext(
  createPostReducer,
  initialState
);
export const useCreatePostState = state;
export const useCreatePostDispatch = dispatch;
export const CreatePostProvider = provider;
