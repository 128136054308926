import classes from './GLobalSearchInput.searchResult.layout.module.scss';

import React, { useRef } from 'react';
import Carousel from '@lobox/uikit/Carousel';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import { useTranslation } from '@lobox/utils';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';
import SearchInCarouselItem from './SearchInCarouselItem';
import GlobalSearchInputSearchResult from './GlobalSearchInput.searchResult';
import type { Props } from './GlobalSearchInput.searchResult';
import ResultItem from './GlobalSearchInput.resultItem';

const GlobalSearchInputSearchResultLayout: React.FC<Props> = (props) => {
  const {
    inputValue,
    className,
    onSelectHandler,
    isLocation,
    handleClickOutside,
    show,
  } = props;
  const { isMoreThanTablet } = useMedia();
  const {
    searchInCarouselItems,
    searchForText,
    moduleSpecificSearchResultPage,
  } = useGlobalSearchUtilities();
  const moduleSpecificSearchResultPageIsJobs =
    moduleSpecificSearchResultPage?.module === 'jobs';
  const wrapperRef = useRef();
  const { isTabletAndLess } = useMedia();
  const { t } = useTranslation();

  const isSmallCarouselItems =
    !isMoreThanTablet || moduleSpecificSearchResultPageIsJobs;

  const onTouchStart = React.useCallback((evt: TouchEvent) => {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }, []);

  React.useEffect(() => {
    if (isTabletAndLess) {
      wrapperRef?.current?.addEventListener('touchstart', onTouchStart);
    } else {
      wrapperRef?.current?.removeEventListener('touchstart', onTouchStart);
    }

    return () => {
      wrapperRef?.current?.removeEventListener('touchstart', onTouchStart);
    };
  }, [onTouchStart]);

  return (
    <Flex ref={wrapperRef} className={cnj(classes.wrapper, className)}>
      <GlobalSearchInputSearchResult {...props} />
      {!!inputValue && !isLocation && (
        <ResultItem
          onClick={() => onSelectHandler({ label: inputValue }, 'all')}
          item={{ label: inputValue }}
          itemType="all"
          renderTitle={
            <Flex className={classes.textWrapper}>
              <Typography
                size={16}
                height={20}
                color="brand"
                isTruncated
                mr={4}
              >
                {t('search_for')}
              </Typography>
              <Typography
                font="700"
                size={16}
                height={20}
                color="brand"
                isTruncated
              >
                {inputValue}
              </Typography>
            </Flex>
          }
        />
      )}
      <Flex
        className={cnj(
          classes.stickyItems,
          isLocation && classes.stickyItemsLocation
        )}
      >
        {!isLocation && (
          <>
            <Divider className={classes.divider} />
            <Flex className={classes.carouselWrapper}>
              <Typography
                font="400"
                size={16}
                height={18}
                color="colorIconForth2"
                className={classes.searchInTitle}
              >
                {t('search_in')}
              </Typography>
              <Carousel
                visibleHead={false}
                moveWalkDistance={208}
                areaClassName={cnj(classes.areaClassName)}
                key={String(show)}
                className={classes.carouselItem}
              >
                {searchInCarouselItems
                  .filter((item) => item?.type !== 'locations')
                  .map((item) => (
                    <SearchInCarouselItem
                      key={item?.type + item?.label}
                      {...item}
                      variant={isSmallCarouselItems ? 'SMALL' : undefined}
                      onClick={() => {
                        searchForText(inputValue, item?.type);
                        handleClickOutside();
                      }}
                    />
                  ))}
              </Carousel>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default GlobalSearchInputSearchResultLayout;
