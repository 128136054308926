import useTheme from '@lobox/uikit/utils/useTheme';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { useEffect, useState } from 'react';
import { useResizeObserver } from '@/shared/hooks/useResizeObserver';
import { useLocation } from '@lobox/utils';
import { MAIN_CENTER_WRAPPER_ID } from '@lobox/utils';

const useSearchInputMargin = () => {
  const { theme } = useTheme();
  const { pathname } = useLocation();

  const [state, setState] = useState<{
    marginLeft: number;
    marginRight: number;
    paddingLeftTabs: number;
    paddingRightTabs: number;
    isLoaded: boolean;
  }>({
    marginLeft: 0,
    marginRight: 0,
    paddingLeftTabs: 0,
    paddingRightTabs: 0,
    isLoaded: false,
  });

  function handleChange() {
    let marginLeft = 0;
    let marginRight = 0;
    const contentWidth = theme.variables.contentMaxWidth;
    const logoWidth = isBusinessApp ? 114 : 64;
    if (typeof window !== 'undefined') {
      const width = document.getElementById(
        MAIN_CENTER_WRAPPER_ID
      )?.offsetWidth;
      const whiteSpace = (width - contentWidth) / 2;

      marginLeft =
        whiteSpace -
        logoWidth -
        theme.variables.headerItemsGap +
        theme.variables.largeGutter;
      marginRight = whiteSpace - 286;
    }

    // optimize for wide screen
    marginLeft = marginLeft > 0 ? marginLeft : 0;
    marginRight = marginRight > 0 ? marginRight : 0;

    const minRightMargin = theme?.variables?.headerItemsGap;
    marginRight = Math.max(marginRight, minRightMargin);
    const paddingLeftTabs =
      marginLeft + logoWidth + theme?.variables?.headerItemsGap;

    const paddingRightTabs =
      marginRight +
      (theme?.variables?.logoMaxHeight + theme?.variables?.headerItemsGap) * 3 -
      20;

    setState({
      marginLeft,
      marginRight,
      paddingLeftTabs,
      paddingRightTabs,
      isLoaded: true,
    });
  }

  useResizeObserver(handleChange);

  useEffect(() => {
    handleChange();
  }, [isBusinessApp, theme, pathname]);

  return {
    ...state,
  };
};

export default useSearchInputMargin;
