import classes from './Main.component.module.scss';

import React, { useRef, useState } from 'react';
import { useLocation } from '@lobox/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Carousel } from 'react-responsive-carousel';
import {
  isEmptyObjectValues,
  QueryKeys,
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
  withFormikContext,
} from '@lobox/utils';
import cnj from '@lobox/uikit/utils/cnj';
import EasyCropper from '@lobox/uikit/EasyCropper';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Image from '@lobox/uikit/Image';
import RichText from '@lobox/uikit/RichText';
import SimpleImageCropper from '@lobox/uikit/SimpleImageCropper';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import FancyVideoPlayer from '@shared/components/Organism/FancyVideoPlayer';
import dynamic from 'next/dynamic';
import { useExtractLinks } from '@shared/hooks/useExtractLinks';
import type { WithFormikProps } from '@lobox/utils';
import isEqual from 'lodash/isEqual';
import {
  useCreatePostDispatch,
  useCreatePostState,
} from '../../context/createPost.provider';
import UserHeader from './Main.header';
import ActionBottom from './Main.footer';
import Attachment from '../../components/Attachment';
import MapView from '../../components/Map';
import MainHighlight from '@shared/components/Organism/CreatePost/contents/Main/Main.highlight';
import EmojiPickerButton from '@shared/uikit/EmojiPickerButton';

const LinkPreview = dynamic(
  () => import('../../../FeedCard/Components/LinkPreview')
);

type Props = WithFormikProps<{
  attachment: any;
  hasHeader: boolean;
  className?: string;
}>;

const POST_TEXT_MAX_LEN = 63206;
const handleCropChangePlaceholder = (): null => null;
const CreatePostModal = ({
  formikRefs,
  formikProps,
  attachment,
  hasHeader = true,
  className,
}: Props): JSX.Element => {
  const richTextRef = useRef(null);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { openConfirmDialog } = useOpenConfirm();
  const { businessPage } = useGetAppObject();
  const { selectedMedia } = useGlobalState('createPostModal');
  const isAttachmentPost = !!attachment;
  const { dirty, isValid } = formikProps;
  const appDispatch = useGlobalDispatch();
  const createPostDispatch = useCreatePostDispatch();
  const queryClient = useQueryClient();
  const files = useCreatePostState('files');
  const uploading = useCreatePostState('uploading');
  const readyToUploadFiles = useCreatePostState('readyToUploadFiles');
  const cropperData = useCreatePostState('cropperData');
  const isEditMode = useCreatePostState('isEditMode');
  const postBody = useCreatePostState('postBody');
  const selectedLocation = useCreatePostState('selectedLocation');
  const selectedUsers = useCreatePostState('selectedUsers');
  const selectedHighlight = useCreatePostState('selectedHighlight');
  const updatePostId = useCreatePostState('updatePostId');
  const hasServerFiles = isEditMode && Boolean(files.length);
  const hasMedia = Boolean(readyToUploadFiles.length);

  React.useEffect(() => {
    if (selectedMedia) {
      createPostDispatch({ type: 'SET_FILES', payload: selectedMedia });
    }
  }, []);

  React.useEffect(() => {
    if (richTextRef.current) {
      richTextRef.current?.focus();
    }
  }, [files]);

  const [richTextInput, setRichText] = useState(() => {
    if (postBody) return postBody;
  });

  React.useEffect(() => {
    if (postBody) setRichText(postBody);
  }, [postBody]); // eslint-disable-line

  const [isVisibleMapView, toggleMapView] = useState(true);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const isOpenMedia = files?.length;

  const closeModal = React.useCallback(() => {
    appDispatch({
      type: 'RESET_CREATE_POST_MODAL',
    });
    appDispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: { isOpen: false },
    });
  }, [appDispatch]);

  const checkPostIsValid = React.useCallback(
    (sender?: string): boolean => {
      if (attachment) {
        return true;
      }
      if (selectedHighlight) {
        if (sender === 'closeAction' && dirty) {
          return true;
        }
        return (
          dirty && isValid && isEmptyObjectValues(formikRefs?.current?.status)
        );
      }
      if (uploading) return false;

      return !!(
        (richTextInput &&
          richTextInput.length &&
          richTextInput.length <= POST_TEXT_MAX_LEN &&
          richTextInput.replace(/<p>|<br>|<\/p>|\s/g, '')) ||
        files.length ||
        selectedLocation
      );
    },
    [
      attachment,
      selectedHighlight,
      selectedLocation,
      dirty,
      isValid,
      uploading,
      richTextInput,
      files.length,
    ]
  );

  const handleCloseClicked = React.useCallback(() => {
    const isPostInValidToClose = checkPostIsValid('closeAction');
    if (isPostInValidToClose) {
      openConfirmDialog({
        title: t('confirm_title'),
        message: t('confirm_desc'),
        confirmButtonText: t('confirm_ok'),
        cancelButtonText: t('confirm_cancel'),
        isAjaxCall: false,
        isReverse: true,
        cancelCallback: () => closeModal(),
      });
    } else {
      closeModal();
    }
  }, [checkPostIsValid, closeModal]);

  const handleBackClicked = React.useCallback(() => {
    const isPostInValidToClose = checkPostIsValid('closeAction');
    if (selectedHighlight) {
      if (isPostInValidToClose) {
        openConfirmDialog({
          title: t('confirm_title'),
          isReverse: true,
          message: t('confirm_desc'),
          confirmButtonText: t('confirm_ok'),
          cancelButtonText: t('confirm_cancel'),
          isAjaxCall: false,
          cancelCallback: () => {
            formikRefs?.current?.resetForm?.();
            appDispatch({
              type: 'RESET_CREATE_POST_MODAL',
            });
          },
        });
      } else {
        appDispatch({
          type: 'RESET_CREATE_POST_MODAL',
        });
      }
    } else {
      handleCloseClicked();
    }
  }, [
    selectedHighlight,
    selectedLocation,
    checkPostIsValid,
    handleCloseClicked,
  ]);

  const handleOpenCheckin = React.useCallback(() => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        currentTab: 'checkin',
      },
    });
  }, []);

  const handleHighlightClick = React.useCallback(() => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        selectedHighlight,
        isOpenModal: true,
        currentTab: 'highlight',
        isPrevStepMainTab: true,
      },
    });
  }, []);

  const handleRemoveLocation = React.useCallback(() => {
    toggleMapView(false);
  }, []);

  // tag persons
  const handleTagPerson = React.useCallback(() => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: true,
        currentTab: 'users',
      },
    });
  }, []);

  const { links, hasLinkToPreview, checkText } = useExtractLinks();

  const handleRichTextChanged = React.useCallback(
    (result) => {
      setRichText(result);
      checkText(result);
    },
    [checkText]
  );

  const handleClickOnSubmit = () => {
    appDispatch({
      type: 'SET_UPLOADING_POST_FILES',
      payload: false,
    });
    queryClient.setQueryData(
      [QueryKeys.readyToPostFeed, 'READY_TO_POST_FEED'],
      {
        postIt: true,
        isEditMode,
        updatePostId,
        values: formikRefs?.current?.values || [],
        richTextInput,
        isVisibleMapView,
        selectedHighlight,
        selectedLocation,
        selectedUsers,
        attachment,
        pathname,
        readyToUploadFiles,
        files,
      }
    );

    closeModal();
  };

  const ArrowNext = React.useCallback(
    (clickHandler: () => void, hasNext: boolean) =>
      hasNext ? (
        <IconButton
          onClick={() => clickHandler()}
          type="far"
          name="chevron-right"
          size="lg"
          colorSchema="primary-light"
          className={cnj('control-arrow', 'control-next', classes.sliderArrow)}
        />
      ) : (
        <></>
      ),
    [classes.sliderArrow]
  );

  const ArrowPrev = React.useCallback(
    (clickHandler: () => void, hasPrev: boolean) =>
      hasPrev ? (
        <IconButton
          onClick={() => clickHandler()}
          type="far"
          name="chevron-left"
          size="lg"
          colorSchema="primary-light"
          className={cnj('control-arrow', 'control-prev', classes.sliderArrow)}
        />
      ) : (
        <></>
      ),
    [classes.sliderArrow]
  );

  const handleClickOnEdit = () => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: true,
        currentTab: 'media',
      },
    });
  };

  return (
    <>
      {hasHeader && (
        <ModalHeaderSimple
          visibleHeaderDivider
          closeButtonProps={{
            onClick: handleCloseClicked,
            className: classes.closeBtn,
          }}
          title={t('create_post_title')}
          // hideBack={true}
          backButtonProps={{
            onClick: handleBackClicked,
          }}
        />
      )}
      <ModalBody className={cnj(classes.body, className)}>
        <Flex className={classes.mainContainer}>
          <UserHeader
            atLocation={selectedLocation?.title}
            onLocationClicked={handleOpenCheckin}
            withPersons={selectedUsers}
          />
          <Flex className={classes.dynamicElementsContainer}>
            <Flex
              className={cnj(
                classes.responsivePadding,
                classes.richTextContainer
              )}
            >
              <RichText
                disableNewLineWithEnter={false}
                defaultValue={postBody}
                showEmoji={false}
                placeholderFontSize={24}
                variant="simple"
                ref={richTextRef}
                isFocus
                businessPageId={businessPage?.id}
                label={t('post_richtext_ph')}
                onChange={handleRichTextChanged}
                changeWithDebounce
                defaultHashtag={
                  !isEditMode ? pathname.split('/')[3] : undefined
                }
                className={cnj(classes.richText)}
                disabledEmoji
              />
            </Flex>
            <Flex className={classes.emojiButtonWrapper}>
              <EmojiPickerButton
                onEmojiSelect={richTextRef?.current?.onChooseEmoji}
                placement="top-end"
                emojiProps={{ size: 'md20' }}
              />
            </Flex>
            {!!selectedHighlight && (
              <MainHighlight
                post={{
                  highlight: selectedHighlight?.form?.highlightTransform?.(
                    formikRefs?.current?.values
                  ),
                }}
              />
            )}
            {hasLinkToPreview &&
              links.map((link, index) => (
                <LinkPreview key={link + index} url={link} />
              ))}
            {isAttachmentPost ? (
              <Flex
                className={cnj(
                  classes.contentElement,
                  classes.sharedPostContainer
                )}
              >
                <Attachment
                  entityType={attachment.type}
                  entityData={attachment.data}
                  classNames={{ attachmentWrapper: classes.noEvents }}
                />
              </Flex>
            ) : null}
            {(hasServerFiles || hasMedia) && (
              <Flex className={classes.sliderWrapper}>
                {!isEditMode && (
                  <IconButton
                    onClick={handleClickOnEdit}
                    type="far"
                    name="pen"
                    colorSchema="primary-light"
                    className={classes.editButton}
                  />
                )}
                {hasMedia && (
                  <Carousel
                    swipeable
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    renderArrowNext={ArrowNext}
                    renderArrowPrev={ArrowPrev}
                    className={classes.carousel}
                  >
                    {readyToUploadFiles.map((file, ii: number) => (
                      <Flex
                        key={ii}
                        className={classes.carouselItemWrapper}
                        style={{
                          backgroundColor: `${file?.backgroundColor}c8`,
                          minHeight: cropperData.height,
                        }}
                      >
                        {file?.original?.type === 'image' && (
                          <>
                            {['0:0', '4:5'].includes(file.ratio) && (
                              <SimpleImageCropper
                                image={file.original.url}
                                width={cropperData.width}
                                height={cropperData.height}
                                className={classes.backgroundCropper}
                                scale={file.zoom}
                                position={file.position}
                                border={0}
                              />
                            )}
                            <SimpleImageCropper
                              image={file.original.url}
                              width={cropperData.width}
                              height={cropperData.height}
                              className={classes.defaultCursor}
                              scale={file.zoom}
                              border={0}
                              position={file.position}
                            />
                          </>
                        )}
                        {file?.original?.type === 'video' && (
                          <Flex
                            style={{
                              width: '100%',
                              position:
                                cropperData?.ratio === '16:9'
                                  ? 'relative'
                                  : undefined,
                              top:
                                (cropperData?.wrapperSide -
                                  cropperData?.height) /
                                -2,
                              height:
                                cropperData?.ratio === '16:9'
                                  ? cropperData?.height
                                  : undefined,
                            }}
                          >
                            <Flex
                              style={{
                                width: '100%',
                                position:
                                  cropperData?.ratio === '16:9'
                                    ? 'absolute'
                                    : undefined,
                                height:
                                  cropperData?.ratio === '16:9'
                                    ? cropperData?.wrapperSide
                                    : undefined,
                              }}
                            >
                              <EasyCropper
                                video={file.url}
                                showGrid={false}
                                zoomWithScroll={false}
                                zoom={file.zoom}
                                crop={file.position}
                                aspect={cropperData.width / cropperData.height}
                                cropSize={{
                                  width: cropperData.width,
                                  height: cropperData.height,
                                }}
                                classes={{
                                  cropAreaClassName: cnj(
                                    classes.videoCropArea,
                                    classes.defaultCursor
                                  ),
                                  mediaClassName: classes.videoMedia,
                                }}
                                onCropChange={handleCropChangePlaceholder}
                                setVideoRef={(
                                  ref: React.MutableRefObject<HTMLVideoElement>
                                ) => {
                                  ref.current.loop = true;
                                  ref.current.playsInline = true;
                                  ref.current.play();
                                }}
                              />
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                    ))}
                  </Carousel>
                )}
                {hasServerFiles && (
                  <Carousel
                    swipeable
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    renderArrowNext={ArrowNext}
                    renderArrowPrev={ArrowPrev}
                    onChange={setCarouselIndex}
                  >
                    {files.map((file, ii: number) => (
                      <React.Fragment key={ii}>
                        {file?.type === 'image' && (
                          <Image resolution="original" src={file.url} alt="" />
                        )}
                        {file?.type === 'video' && (
                          <FancyVideoPlayer
                            src={file.url}
                            screenshotSrc={file.secondaryUrl}
                            playInViewport={false}
                            showOnScreenIcon={false}
                            action={carouselIndex === ii ? 'play' : 'pause'}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Carousel>
                )}
              </Flex>
            )}
            {!isAttachmentPost &&
              isVisibleMapView &&
              selectedLocation &&
              !selectedHighlight &&
              !isOpenMedia && (
                <Flex className={cnj(classes.contentElement, classes.mapView)}>
                  <MapView
                    position={selectedLocation?.position}
                    handleRemove={handleRemoveLocation}
                    onEditClicked={handleOpenCheckin}
                  />
                </Flex>
              )}
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter className={classes.modalFooter}>
        <ActionBottom
          hasRichText={richTextInput?.length > POST_TEXT_MAX_LEN}
          richTextLength={richTextInput?.length.toLocaleString()}
          enableButton={checkPostIsValid()}
          onMapClicked={handleOpenCheckin}
          onUserMentionClicked={handleTagPerson}
          onSubmitClick={handleClickOnSubmit}
          onHighlightClick={handleHighlightClick}
          isAttachmentPost={!hasHeader}
          isActivePhoto={hasMedia}
          isActiveUser={!!selectedUsers}
          isActiveMap={!!selectedLocation}
          isActiveHighlight={!!selectedHighlight}
        />
      </ModalFooter>
    </>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  isEqual(prevProps, nextProps);

export default withFormikContext({
  refs: ['values', 'status', 'resetForm'],
  props: ['dirty', 'isValid'],
})(React.memo(CreatePostModal, areEqual));
