import classes from './index.module.scss';

import React, { forwardRef } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import { getSharedEntityUrl } from '@shared/utils/getSharedEntityUrl';
import type { CreateAttachmentPostProps } from '@shared/components/Organism/CreatePost/components/Attachment/Attachment.component';
import type { HTMLProps, LegacyRef } from 'react';

type Props = {
  entityType?: CreateAttachmentPostProps['entityType'];
  entityData?: any;
};
export const CopylinkInput = forwardRef(
  (
    props: HTMLProps<HTMLInputElement> & Props,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const { className, entityData, ...rest } = props;

    return (
      <input
        value={getSharedEntityUrl(props?.entityType, entityData)}
        spellCheck={false}
        ref={ref}
        className={cnj(classes.link, className)}
        {...rest}
      />
    );
  }
);
