import classes from './FancyVideoPlayer.module.scss';

import React from 'react';
import toNumber from 'lodash/toNumber';
import max from 'lodash/max';
import min from 'lodash/min';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import cnj from '@lobox/uikit/utils/cnj';
import EasyCropper from '@lobox/uikit/EasyCropper';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Skeleton from '@lobox/uikit/Skeleton';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  getVideoImage,
  removeQueryString,
  useResizeWindow,
  useScrollMove,
} from '@lobox/utils';

import PlayButton from './PlayButton';
import VolumeButton from './VolumeButton';
import TimeSlider from './TimeSlider';
import Timer from './Timer';
import makeImageSrc from '@shared/utils/makeImageSrc';
import { LinearLoading } from '@shared/uikit/LinearLoading';
import { calculateMediaSizeBasedOnBoxAndRatio } from '@shared/utils/mediaSize';
import { MEDIA_BOX_SIZE } from '@shared/utils/consts';

const maxWaitingMS = 60 * 1000;
const intervalStepMS = 500;
const onPositionChangePlaceholder = (): null => null;

type Props = {
  src: string;
  screenshotSrc?: string;
  action?: 'play' | 'pause';
  showControls?: boolean;
  backgroundImage?: string;
  generateBackgroundImage?: boolean;
  fullScreen?: boolean;
  preventZooming?: boolean;
  playInViewport?: boolean;
  pauseOutOfViewPort?: boolean;
  showOnScreenIcon?: boolean;
  showVolumeForSmallScreen?: boolean;
  containerClassName?: string;
  initialVolume?: number;
  referenceRef?: React.MutableRefObject<HTMLVideoElement>;
  loadingSize?: number;
  forceHideAllControls?: boolean;
  fullHeight?: boolean;
  showLoading?: boolean;
  setVideoRef?: (ref: React.MutableRefObject<HTMLVideoElement>) => void;
  onClickOnVideo?: (evt: React.MouseEvent) => void;
  onClickOnPlay?: (isPlaying: boolean) => void;
  onVolumeChange?: (
    videoRef: React.RefObject<HTMLVideoElement | undefined>
  ) => void;
};

const setVideoRefPlaceholder = (): null => null;
const onClickOnVideoPlaceholder = (): null => null;
const onClickOnPlayPlaceholder = (): null => null;
const onVolumeChangePlaceholder = (
  ref: React.RefObject<HTMLVideoElement | undefined>
) => null;

export function FancyVideoPlayer({
  src,
  screenshotSrc,
  action,
  showControls = false,
  backgroundImage = '',
  generateBackgroundImage = false,
  fullScreen = false,
  preventZooming = false,
  playInViewport = true,
  pauseOutOfViewPort = true,
  showOnScreenIcon = true,
  showVolumeForSmallScreen = true,
  initialVolume = 0.25,
  containerClassName,
  referenceRef,
  forceHideAllControls,
  fullHeight,
  showLoading = false,
  setVideoRef = setVideoRefPlaceholder,
  onClickOnVideo = onClickOnVideoPlaceholder,
  onClickOnPlay = onClickOnPlayPlaceholder,
  onVolumeChange = onVolumeChangePlaceholder,
}: Props): JSX.Element {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [imageSrc, setImageSrc] = React.useState(backgroundImage);
  const [containerSide, setContainerSide] = React.useState(0);
  const [isInViewport, setIsInViewport] = React.useState(false);
  const [zoom, setZoom] = React.useState(1);
  const videoRef = React.useRef<HTMLVideoElement>();
  const controllersRef = React.useRef<HTMLElement>();
  const containerRef = React.useRef<HTMLElement>();
  const wrapperRef = React.useRef<HTMLElement>();
  const playPromiseRef = React.useRef<Promise<any>>();
  const userPausedRef = React.useRef(false);
  const timerIdRef = React.useRef<NodeJS.Timeout>();
  const volumeRef = React.useRef();

  const { isTabletAndLess, isMoreThanTablet } = useMedia();

  const url = new URL(src);
  const { searchParams } = url;
  const videoSrc = removeQueryString(src);
  const initPositionX = toNumber(searchParams.get('px')) || 0;
  const initPositionY = toNumber(searchParams.get('py')) || 0;
  const backgroundColor = searchParams.get('bc');
  const initWidth = toNumber(searchParams.get('bw')) || 0;
  const initHeight = toNumber(searchParams.get('bh')) || 0;
  const realWidth = toNumber(searchParams.get('rw')) || 0;
  const realHeight = toNumber(searchParams.get('rh')) || 0;
  const initZoom = toNumber(searchParams.get('z')) || 1;
  const ratio = searchParams.get('r');

  let positionX = initPositionX;
  let positionY = initPositionY;

  const applyRatio = (isTabletAndLess && ratio === '4:5') || !fullScreen;
  const containerWidth = applyRatio
    ? min([containerSide, MEDIA_BOX_SIZE.WIDTH]) || 0
    : containerSide;

  const { width, height } = calculateMediaSizeBasedOnBoxAndRatio(
    src,
    containerSide,
    isTabletAndLess
  );

  if (
    initWidth &&
    initHeight &&
    realWidth &&
    realHeight &&
    (initWidth !== width || initHeight !== height)
  ) {
    positionX = (initPositionX * width) / realWidth;
    positionY = (initPositionY * height) / realHeight;
  }

  const isPreparing = !width || !height;
  const hasScreenshot = Boolean(screenshotSrc);

  const position = React.useMemo(
    () => ({
      x: applyRatio ? positionX : 0,
      y: applyRatio ? positionY : 0,
    }),
    [applyRatio, positionX, positionY]
  );

  const aspect = React.useMemo(() => {
    if (!width || !height) {
      return 1;
    }
    return width / height;
  }, [width, height]);

  const resizeEventListener = React.useMemo(
    () =>
      debounce(() => {
        if (containerRef.current) {
          setContainerSide(containerRef.current?.offsetWidth);
        }
      }, 100),
    []
  );

  useResizeWindow(resizeEventListener, true);

  const videoRefSetter = (ref: React.MutableRefObject<HTMLVideoElement>) => {
    videoRef.current = ref.current;
    setVideoRef(ref);
  };

  const updateZoom = React.useCallback(() => {
    const videoLoaded = Boolean(
      videoRef.current?.offsetWidth && videoRef.current?.offsetHeight
    );
    const zoomCoordinate: number = isTabletAndLess ? 0.12 : 0;

    setZoom(() =>
      videoLoaded
        ? max([
            width /
              (referenceRef?.current?.offsetWidth ||
                videoRef.current?.offsetWidth ||
                0),
            height /
              (referenceRef?.current?.offsetHeight ||
                videoRef.current?.offsetHeight ||
                0),
          ]) || 0 + zoomCoordinate
        : initZoom
    );
  }, [width, height, initZoom]);

  React.useEffect(() => {
    updateZoom();
  }, [updateZoom, containerSide]);

  const playVideo = React.useCallback(() => {
    if (isLoading) {
      timerIdRef.current = setTimeout(playVideo, intervalStepMS);
      return;
    }
    setIsPlaying(() => true);
    playPromiseRef.current = videoRef.current?.play();
  }, [isLoading]);

  const pauseVideo = React.useCallback(() => {
    setIsPlaying(() => false);
    if (playPromiseRef.current !== undefined) {
      playPromiseRef.current.then(() => {
        videoRef.current?.pause();
        playPromiseRef.current = undefined;
      });
      return;
    }
    videoRef.current?.pause();
  }, []);

  const handleClickOnPlay = React.useCallback(() => {
    if (isPlaying) {
      onClickOnPlay(false);
      pauseVideo();
      userPausedRef.current = true;
      return;
    }
    if (volumeRef.current) {
      volumeRef.current.handleChange(initialVolume);
    }
    onClickOnPlay(true);
    playVideo();
    userPausedRef.current = false;
  }, [isPlaying, playVideo, pauseVideo, onClickOnPlay]);

  const handleClickOnWrapper = (evt: React.MouseEvent) => {
    onClickOnVideo(evt);
  };

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let passedMS = 0;
    let steps = 0;

    const updateLoading = () => {
      passedMS += intervalStepMS;
      steps += 1;

      if (passedMS > maxWaitingMS) {
        setIsLoading(() => false);
        setLoadingError(() => true);
        clearTimeout(timeoutId);
        return;
      }
      if (
        isNumber(videoRef.current?.readyState) &&
        videoRef.current?.readyState >= 2
      ) {
        setIsLoading(() => false);
        clearTimeout(timeoutId);
        return;
      }

      const delay =
        (steps < 5 && 250) ||
        (steps < 10 && 500) ||
        (steps < 20 && 1000) ||
        1500;
      timeoutId = setTimeout(updateLoading, delay);
    };

    updateLoading();

    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    const ref = videoRef.current;

    ref?.addEventListener('play', playVideo);
    ref?.addEventListener('pause', pauseVideo);

    return () => {
      ref?.removeEventListener('play', playVideo);
      ref?.removeEventListener('pause', pauseVideo);
    };
  }, [playVideo, pauseVideo]);

  React.useEffect(() => {
    if (
      !generateBackgroundImage ||
      containerWidth <= width ||
      backgroundImage
    ) {
      return;
    }
    getVideoImage(videoSrc)
      .then((imgSrc: string) => setImageSrc(() => imgSrc))
      .catch((err: any) => console.log(err));
  }, [
    videoSrc,
    generateBackgroundImage,
    backgroundImage,
    containerWidth,
    width,
  ]);

  React.useEffect(() => {
    if (
      !isLoading &&
      !isPlaying &&
      isInViewport &&
      applyRatio &&
      !userPausedRef.current &&
      playInViewport
    ) {
      playVideo();
    }
  }, [
    playInViewport,
    isLoading,
    isPlaying,
    isInViewport,
    applyRatio,
    playVideo,
  ]);

  React.useEffect(() => {
    if (isPlaying && !isInViewport && applyRatio && pauseOutOfViewPort) {
      pauseVideo();
    }
  }, [
    playInViewport,
    pauseOutOfViewPort,
    isPlaying,
    isInViewport,
    applyRatio,
    pauseVideo,
  ]);

  React.useEffect(() => {
    if (volumeRef.current) {
      volumeRef.current.handleChange(initialVolume);
    }
  }, [initialVolume]);

  const onScroll = React.useMemo(
    () =>
      throttle(() => {
        const ref = wrapperRef.current;
        if (!ref) {
          return;
        }

        const { height: wrapperHeight, top } =
          ref.getBoundingClientRect() || {};
        const centerFromTop = top + wrapperHeight / 2;

        if (centerFromTop < 0 || centerFromTop > document.body.offsetHeight) {
          setIsInViewport(() => false);
        } else {
          setIsInViewport(() => true);
        }
      }, 100),
    []
  );

  useScrollMove(onScroll);

  React.useEffect(() => {
    onScroll();
  }, [onScroll]);

  React.useEffect(() => {
    if (action === 'play' && !isLoading) {
      playVideo();
    }
    if (action === 'pause') {
      pauseVideo();
    }
  }, [action, isLoading, playVideo, pauseVideo]);

  // video initial config
  React.useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.preload = 'metadata';
    videoRef.current.load();
    videoRef.current.loop = true;
    videoRef.current.autoplay = false;
    videoRef.current.playsInline = true;

    return () => clearTimeout(timerIdRef.current);
  }, []);

  return (
    <Flex
      style={{
        height:
          ((fullScreen || fullHeight) && '100%') ||
          (applyRatio && height) ||
          '100%',
        backgroundColor,
      }}
      className={cnj(
        classes.container,
        fullScreen &&
          !fullHeight &&
          isMoreThanTablet &&
          classes.containerPaddingTop,
        containerClassName
      )}
      ref={containerRef}
    >
      {isLoading && showLoading && <LinearLoading />}
      {showOnScreenIcon &&
        !isLoading &&
        !isPlaying &&
        !isMoreThanTablet &&
        applyRatio &&
        !fullScreen && (
          <Icon
            name="play"
            color="white"
            type="fas"
            className={classes.onScreenIcon}
          />
        )}
      {!isPreparing && imageSrc && (
        <img
          loading="lazy"
          alt=""
          src={makeImageSrc(imageSrc)}
          className={classes.background}
        />
      )}
      {applyRatio && !fullScreen && (
        <Flex
          className={classes.scrollHack}
          style={{
            bottom: isTabletAndLess
              ? 0
              : controllersRef.current?.offsetHeight || 100,
          }}
          onClick={handleClickOnWrapper}
        />
      )}
      {isPreparing && (
        <Skeleton width="100%" height="100%" className={classes.skeleton} />
      )}
      <Flex
        className={classes.videoOuterWrapper}
        style={{
          width: (applyRatio && width) || '100%',
          backgroundImage: hasScreenshot ? `url(${screenshotSrc})` : undefined,
        }}
        ref={wrapperRef}
      >
        <Flex
          style={{
            width: containerSide,
            height: applyRatio ? max([containerSide, height]) : '100%',
            position: 'absolute',
          }}
        >
          <EasyCropper
            video={videoSrc}
            showGrid={false}
            zoomWithScroll={false}
            zoom={!preventZooming || applyRatio ? zoom : 1}
            crop={position}
            aspect={aspect}
            classes={{
              containerClassName: `${classes.cropContainer} ${!isPlaying && hasScreenshot && !userPausedRef.current && classes.cropContainerHidden}`,
              mediaClassName: classes.cropMedia,
              cropAreaClassName: classes.cropArea,
            }}
            mediaProps={{
              loading: 'lazy',
            }}
            setVideoRef={videoRefSetter}
            onMediaLoaded={updateZoom}
            onCropChange={onPositionChangePlaceholder}
          />
        </Flex>
      </Flex>
      {!forceHideAllControls ? (
        <>
          {(!isTabletAndLess || showControls) && !isPreparing && (
            <Flex
              className={cnj(
                classes.controls,
                fullScreen && isMoreThanTablet && classes.controlsFullWidth,
                fullScreen && classes.controlsFullScreen
              )}
              ref={controllersRef}
              style={{
                maxWidth: applyRatio ? width : containerWidth,
              }}
            >
              <Flex className={classes.topControllers} flexDir="row">
                <PlayButton
                  isPlaying={isPlaying}
                  isLoading={isLoading}
                  onClick={handleClickOnPlay}
                />
                {!isLoading && <Timer videoRef={videoRef} />}
                <VolumeButton
                  ref={volumeRef}
                  videoRef={videoRef}
                  disabled={isLoading}
                  onVolumeChange={onVolumeChange}
                />
              </Flex>
              <TimeSlider isPlaying={isPlaying} videoRef={videoRef} />
            </Flex>
          )}
          {isTabletAndLess &&
            showVolumeForSmallScreen &&
            isPlaying &&
            !showControls &&
            !isPreparing && (
              <VolumeButton
                ref={volumeRef}
                containerClassName={classes.volumeContainer}
                videoRef={videoRef}
                showOnClick
              />
            )}
        </>
      ) : null}
    </Flex>
  );
}

export default FancyVideoPlayer;
