import classes from './GlobalSearchInput.searchResult.module.scss';

import React, { useMemo } from 'react';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Spinner from '@lobox/uikit/Spinner';
import { useTranslation } from '@lobox/utils';
import ResultItem from './GlobalSearchInput.resultItem';
import { GlobalSearchTextResult } from './GlobalSearchTextResult';
import SectionTitle from './GlobalsSearchInput.sectionTitle';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';
import type { ApiType } from '@shared/hooks/useGlobalSearchUtilities';
import useSearchQueries from '@shared/hooks/useSearchQueries';

export interface Props {
  className?: string;
  inputValue: string;
  filteredOption: Array<any>;
  onSelectHandler: (item: any, type: ApiType) => any;
  emptyMessage?: React.ReactElement;
  isLoading: boolean;
  dataRecents: any;
  dataSuggestions: any;
  isLocation?: boolean;
  handleClickOutside?: () => void;
}

const GlobalSearchInputSearchResult: React.FC<Props> = ({
  onSelectHandler: onSelect,
  inputValue,
  className,
  isLoading,
  emptyMessage,
  filteredOption,
  dataRecents,
  dataSuggestions,
  isLocation,
}) => {
  const { t } = useTranslation();
  const isEmpty = !filteredOption?.length;
  const {
    removeAllRecentSearches,
    removeRecentSearch,
    moduleSpecificSearchResultPage,
  } = useGlobalSearchUtilities();
  const { placeTitle } = useSearchQueries();
  const isJobs = moduleSpecificSearchResultPage?.module === 'jobs';

  function removeAll() {
    removeAllRecentSearches({
      moduleType: isLocation ? 'LOCATIONS' : isJobs ? 'JOBS' : 'GLOBAL',
    });
  }
  const indexOfDividerBetweenOtherItemsAndFirstJob = useMemo(
    () =>
      filteredOption?.some(
        (item: any) =>
          item?.type !== 'JOB' || (item?.type === 'JOB' && item?.isSpecific)
      ) &&
      filteredOption?.findIndex(
        (item: any) => item?.type === 'JOB' && !item?.isSpecific
      ) - 1,
    [filteredOption]
  );

  const onSelectHandler = (item: any, type: ApiType) => {
    if (item.type === 'HASHTAG') {
      onSelect(item, type);
    } else {
      onSelect(item, type);
    }
  };

  if (isLoading) {
    return (
      <Flex className={classes.wrapperSpinner}>
        <Spinner />
      </Flex>
    );
  }

  if (!inputValue && isEmpty && !dataRecents?.length && !dataSuggestions) {
    return (
      <Flex className={classes.wrapper}>
        <GlobalSearchTextResult text={t('no_recent_searches')} />
      </Flex>
    );
  }
  if (inputValue && !isLoading && isEmpty) {
    if (!!emptyMessage && typeof emptyMessage !== 'string') return emptyMessage;
    return (
      <Flex className={classes.wrapper}>
        <GlobalSearchTextResult
          text={(emptyMessage as unknown as string) || t('nothing_found')}
        />
      </Flex>
    );
  }
  const isRecentVisible =
    !!dataRecents?.length &&
    (isLocation ? !inputValue || placeTitle === inputValue : !inputValue);
  const isSuggestionVisible =
    !!dataSuggestions?.length &&
    (isLocation ? !inputValue || placeTitle === inputValue : !inputValue);
  const isItemVisible =
    !!filteredOption?.length &&
    (isLocation ? !!inputValue && placeTitle !== inputValue : !!inputValue);
  const isHashtag = inputValue?.startsWith('#');

  const sortedFilterOptions = isHashtag
    ? [...filteredOption]?.sort((x, y) =>
        x.type === 'HASHTAG' ? -1 : y.type === 'HASHTAG' ? 1 : 0
      )
    : filteredOption;

  return (
    <Flex className={classes.wrapper}>
      {isRecentVisible && (
        <>
          <SectionTitle
            text={t('recent_searches')}
            onClick={dataRecents?.length > 0 && removeAll}
          />
          {dataRecents.map((item: any) => (
            <ResultItem
              key={item.value || item.label}
              onClick={() =>
                onSelectHandler(item, isLocation ? 'location' : 'recents')
              }
              deleteAction={() =>
                removeRecentSearch(item?.key, {
                  module: isLocation ? 'locations' : undefined,
                })
              }
              item={item}
              itemType={isLocation ? 'locationRecents' : 'recents'}
              isLocation={isLocation}
            />
          ))}
        </>
      )}
      {isSuggestionVisible && (
        <>
          <SectionTitle text={t('try_searching_for')} />
          {dataSuggestions.map((item: any) => (
            <ResultItem
              key={item.value || item.label}
              onClick={() =>
                onSelectHandler(item, isLocation ? 'location' : 'suggestions')
              }
              item={item}
              itemType={isLocation ? 'locationSuggestions' : 'suggestions'}
              isLocation={isLocation}
              boldDisabled
            />
          ))}
        </>
      )}
      {isItemVisible && (
        <>
          {sortedFilterOptions.map((item, index) => (
            <>
              <ResultItem
                key={item.value || item.label}
                onClick={() => {
                  onSelectHandler(item, isLocation ? 'location' : 'items');
                }}
                item={item}
                itemType="items"
                isLocation={isLocation}
              />
              {index === indexOfDividerBetweenOtherItemsAndFirstJob && (
                <Divider className={classes.divider} />
              )}
            </>
          ))}
        </>
      )}
    </Flex>
  );
};

export default GlobalSearchInputSearchResult;
