'use client';

import Flex from '@shared/uikit/Flex';
import React from 'react';

import classes from './placeholder.module.scss';
import Icon from '@shared/uikit/Icon';

export const Placeholder = (): JSX.Element => {
  return (
    <Flex className={classes.container}>
      <Icon name="image" color="graphene_60" size={54} type="far" />
    </Flex>
  );
};
