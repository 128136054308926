'use client';

import classes from './index.module.scss';

import React, { useEffect } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Drawer from '@lobox/uikit/Drawer';
import Flex from '@lobox/uikit/Flex';
import Media from '@lobox/uikit/Media';
import {
  event,
  routeNames,
  useAuthState,
  useDisclosure,
  useGlobalDispatch,
  useGlobalState,
} from '@lobox/utils';
import {
  MAIN_CENTER_WRAPPER_ID,
  OPEN_DRAWER_EVENT,
} from '@shared/constants/enums';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import useIsPagePublished from '@shared/hooks/useIsPagePublished';
import SocialConnectionsModal from '@shared/components/Organism/SocialConnectionsModal';
import ShareEntityTabbedModal from '@shared/components/Organism/ShareEntityTabbedModal';
import Header from '@shared/components/layouts/AppLayout/AppLayout.Header';
import LeftNavigation from '@shared/components/layouts/AppLayout/AppLayout.LeftNavigation';
import Bottombar from '@shared/components/layouts/AppLayout/AppLayout.Bottombar';
import ViewMedia from '@shared/components/Organism/Message/components/organism/ViewMedia';
import Loading from '@shared/components/layouts/AppLayout/AppLayout.ChatPanel/partials/ClosedRightPanelItems.loading';
import ProfilePanel from '@shared/components/layouts/AppLayout/AppLayout.ProfilePanel';
import dynamic from 'next/dynamic';
import { useLocation } from '@lobox/utils';
import useTheme from '@lobox/uikit/utils/useTheme';

const ChatPanel = dynamic(
  () => import('@shared/components/layouts/AppLayout/AppLayout.ChatPanel'),
  {
    ssr: false,
    loading: () => <Loading />,
  }
);

const ShareEntityModal = dynamic(
  () => import('@shared/components/Organism/ShareEntityModal'),
  { ssr: false }
);

const AppDrawer = dynamic(
  () => import('@shared/components/layouts/AppLayout/AppLayout.Drawer'),
  {
    ssr: false,
  }
);
const FeedbackModal = dynamic(
  () => import('@shared/components/Organism/FeedbackModal'),
  {
    ssr: false,
  }
);
const CreatePostModal = dynamic(
  () => import('@shared/components/Organism/CreatePostModal'),
  { ssr: false }
);
const ReportModal = dynamic(
  () => import('@shared/components/Organism/ReportModal'),
  { ssr: false }
);
const CreateJobModalInUserProject = dynamic(
  () => import('@shared/components/Organism/CreateJobModalInUserProject'),
  { ssr: false }
);

const AppLayout: React.FC = ({ children }: React.PropsWithChildren) => {
  const { isDark } = useTheme();
  const { pathname } = useLocation();
  const { reFetchAppObject, authUser, isBusinessApp } = useGetAppObject();
  const isPagePublished = useIsPagePublished();
  const globalDispatch = useGlobalDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const objectNetworkModal = useGlobalState('objectNetworkModal');
  const isFeedbackModalOpen = useGlobalState('isFeedbackModalOpen');
  const isOpenLeftPanel = useGlobalState('isOpenLeftPanel');
  const isOpenRightPanel = useGlobalState('isOpenRightPanel');
  const isOpenProfilePanel = useGlobalState('isOpenProfilePanel');
  const { isOpen: isOpenShareEntityModal } = useGlobalState('shareEntityModal');
  const { isOpen: isOpenReportModal } = useGlobalState('reportModal');
  const isOpenCreateJobModalInUserProject = useGlobalState(
    'isOpenCreateJobModalInUserProject'
  );
  const viewMedia = useGlobalState('viewMedia');
  const isLoggedIn = useAuthState('isLoggedIn');

  const isUnAuthAvailability =
    !isLoggedIn && pathname?.includes(routeNames.schedulesAvailability);

  const { isOpen: isShareEntityModalOpen } = useGlobalState(
    'shareEntityTabbedModal'
  );

  useEffect(() => {
    if (!authUser?.publicSetting) {
      reFetchAppObject();
    }
  }, [authUser]);

  useEffect(() => {
    globalDispatch({ type: 'SET_SHOW_RIGHT_PANEL', payload: false });
  }, [pathname]);
  useEffect(() => {
    if (isOpenProfilePanel) {
      globalDispatch({
        type: 'SET_SHOW_PROFILE_PANEL',
        payload: false,
      });
    }
  }, [pathname]);
  const toggleLeftPanelHandler = () => {
    globalDispatch({
      type: isOpenLeftPanel ? 'CLOSE_LEFT_PANEL' : 'OPEN_LEFT_PANEL',
    });
  };

  const closePanelHandler = () => {
    globalDispatch({
      type: 'CLOSE_LEFT_PANEL',
    });
  };
  const visibleChatPanel =
    isLoggedIn && pathname !== routeNames.messages && isPagePublished;

  useEffect(() => {
    event.on(OPEN_DRAWER_EVENT, () => {
      onOpen();
    });

    return () => {
      event.off(OPEN_DRAWER_EVENT);
    };
  }, []); // eslint-disable-line

  return (
    <>
      <meta
        id="theme-color-meta"
        name="theme-color"
        content={isDark ? '#314f8e' : '#5384ee'}
      />
      <Flex className={classes.appRoot}>
        <Header
          key={isBusinessApp ? 'left_business' : 'left_user'}
          toggleLeftPanel={toggleLeftPanelHandler}
        />
        <Flex
          className={cnj(
            classes.appMain,
            isUnAuthAvailability && classes.noMarginTop
          )}
        >
          {isLoggedIn && isPagePublished && (
            <Flex className={classes.mainLeft}>
              <LeftNavigation
                key={isBusinessApp ? 'left_business' : 'left_user'}
                handleClose={onClose}
                autoWide
                narrow
              />
            </Flex>
          )}
          <Flex
            id={MAIN_CENTER_WRAPPER_ID}
            className={cnj(
              classes.mainCenter,
              visibleChatPanel && classes.mainCenterLoggedIn,
              isOpenLeftPanel && classes.dark
            )}
          >
            {children}
          </Flex>
          {visibleChatPanel && (
            <Flex
              className={cnj(
                classes.mainRight,
                isOpenLeftPanel && classes.dark
              )}
            >
              <Media greaterThan="tablet">
                <ChatPanel />
              </Media>
            </Flex>
          )}
          {isOpenLeftPanel && (
            <Media greaterThan="tablet">
              <Drawer sliderDir="left" onBackDropClick={closePanelHandler}>
                <LeftNavigation
                  handleClose={closePanelHandler}
                  narrow={false}
                  wide
                />
              </Drawer>
            </Media>
          )}
          {isOpenProfilePanel && <ProfilePanel />}
        </Flex>
        {isLoggedIn && (
          <Media at="tablet">
            <Bottombar />
          </Media>
        )}
      </Flex>
      {isFeedbackModalOpen && <FeedbackModal />}
      {objectNetworkModal?.isOpen && <SocialConnectionsModal />}
      {isShareEntityModalOpen ? (
        <ShareEntityTabbedModal />
      ) : (
        <CreatePostModal />
      )}

      {isOpenShareEntityModal && <ShareEntityModal />}
      {viewMedia?.isOpen && (
        <ViewMedia
          medias={viewMedia?.medias}
          selectedItem={viewMedia?.selectedItem}
        />
      )}
      {isOpen && isLoggedIn && <AppDrawer onClose={onClose} />}
      {isOpenReportModal && <ReportModal />}
      {isOpenCreateJobModalInUserProject && <CreateJobModalInUserProject />}
    </>
  );
};
export default AppLayout;
