import classes from './List.card.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Typography from '@lobox/uikit/Typography';
import Flex from '@lobox/uikit/Flex';
import Image from '@lobox/uikit/Image';
import isString from 'lodash/isString';

interface ListCardProps {
  title?: string;
  image?: any;
  className?: any;
}

const ListCard = ({ title, image, className }: ListCardProps): JSX.Element => {
  return (
    <Flex className={cnj(classes.listCardRoot, className)}>
      {image && isString(image) ? (
        <Flex className={cnj(classes.imageWrap)}>
          <Image className={classes.image} src={image} alt="lobox-empty-card" />
        </Flex>
      ) : (
        image?.()
      )}
      {title && (
        <Typography
          textAlign="center"
          size={18}
          font="bold"
          mt={15}
          height={23}
          className={classes.caption}
        >
          {title}
        </Typography>
      )}
    </Flex>
  );
};

export default ListCard;
