import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function HelpCenterHeader(props: React.SVGProps<SVGSVGElement>) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={360}
      height={128}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="url(#b)"
          d="M113.274 39.753V128H6.285c.273-3.903-17.337-55.39 5.228-84.793 32.041-41.748 59.413-6.584 95.539-3.654 2.049.163 4.111.25 6.222.2Z"
        />
        <path
          fill="url(#c)"
          d="M359.106 127.751h-11.897l-72.328.249H113.273V39.753c2.037-.05 4.099-.237 6.222-.524 7.092-.972 14.53-3.878 22.416-8.491 81.58-47.51 148.009-34.603 185.316 2.481a111.204 111.204 0 0 1 12.431 14.752c15.847 22.545 23.012 51.536 19.448 79.78Z"
        />
        <path
          fill="url(#d)"
          d="M328.196 59.954v67.859h-208.7V68.77h15.996V52.647h15.995V74.43h12.953v-4.876h22.565v8.23h9.811V59.318h26.291V27.633h20.777v16.896h4.744V30.376h20.777v21.81h18.84v-13.38h20.143v13.38h3.751v7.768h16.057Z"
        />
        <path
          fill="#393939"
          d="M89.192 64.966H70.638a1.556 1.556 0 0 1-1.552-1.558V45.427c0-.86.695-1.56 1.552-1.56h18.554c.857 0 1.553.7 1.553 1.56v17.98c0 .861-.696 1.56-1.553 1.56Z"
        />
        <path
          fill="#606060"
          d="M88.399 64.056H71.447a1.418 1.418 0 0 1-1.416-1.421V46.212c0-.785.634-1.421 1.416-1.421h16.952c.782 0 1.416.636 1.416 1.421v16.423c0 .785-.646 1.421-1.416 1.421Z"
        />
        <path
          fill="#848484"
          d="M79.916 47.16a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896ZM83.095 47.347c.124-.212.397-.3.609-.175.21.125.298.4.173.611-.124.212-.397.3-.608.175a.437.437 0 0 1-.174-.611ZM86.189 49.828a.444.444 0 0 1 .62.137.448.448 0 0 1-.136.624.444.444 0 0 1-.62-.137.472.472 0 0 1 .136-.624ZM88.138 54.273a.447.447 0 1 0-.827-.342.446.446 0 1 0 .827.342ZM86.944 58.096a.449.449 0 1 1-.633.637.449.449 0 0 1 .632-.637ZM83.803 61.7a.448.448 0 1 0-.002-.897.448.448 0 0 0 .002.896ZM79.916 62.846a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896ZM76.738 47.347c-.124-.212-.397-.3-.609-.175-.21.125-.297.4-.173.611.124.212.397.3.608.175.211-.112.286-.387.174-.611ZM73.631 49.828a.445.445 0 0 0-.62.137.449.449 0 0 0 .136.624.445.445 0 0 0 .62-.137.448.448 0 0 0-.136-.624ZM72.092 54.554a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896ZM72.873 58.096a.448.448 0 1 0 .633.637.448.448 0 0 0-.633-.637ZM76.017 61.7a.448.448 0 1 0-.001-.897.448.448 0 0 0 .001.896ZM80.252 54.555a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896Z"
        />
        <path fill="#848484" d="M80.316 48.482h-.125v5.624h.125v-5.624Z" />
        <path
          fill="#848484"
          d="m83.047 51.614-2.969 2.34.23.295 2.97-2.34-.23-.295Z"
        />
        <path
          fill="#606060"
          d="M119.495 39.229V128h-12.444V39.553c4.036.325 8.171.262 12.444-.324ZM339.646 48.045v79.394h-12.431v-94.12a110.023 110.023 0 0 1 12.431 14.726Z"
        />
        <path
          fill="url(#e)"
          d="M129.691 96.49h-2l2.447 31.036h4.843l-5.29-31.037Z"
        />
        <path
          fill="url(#f)"
          d="M250.364 118.361c-.298 3.404-2.211 4.452-3.043 4.364-.844-.087-5.762-1.97-5.862-1.284-.111.686 2.857 2.955 4.931 3.055 0 0-3.912 4.339-6.508 3.08-2.595-1.247-2.819-3.23-4.446-3.978-1.627-.748-3.005-.735-2.335.013.671.76 3.602 1.496 4.533 3.142.224.387.435.748.596 1.06l-5.476-.012c-1.739-.861-3.204-2.058-3.304-3.355-.012-.1-.012-.187-.025-.287l-.173-1.982s-2.807-3.292-1.354-6.023a4.259 4.259 0 0 1 1.118-1.297s2.62-1.596 4.756-1.459c0 0-1.652 2.793-2.124 4.439-.161.586-.186 1.023.075 1.16.273.15.646-.274 1.018-.948.932-1.683 1.95-4.975 1.95-4.975s1.577-.15 3.366-.175c.807-.012 1.651 0 2.421.075.845.075 1.602.212 2.087.449 0 0-.112.087-.286.237-.273.224-.695.586-1.167 1.01-1.416 1.259-3.254 3.03-2.571 3.192.559.125 2.297-.91 3.85-1.945 1.44-.96 2.72-1.92 2.72-1.92s5.551.96 5.253 4.364Z"
        />
        <path
          fill="url(#g)"
          d="M247.645 118.448s-15.077-.586-18.405 3.741c0 0-.733 2.22-3.006 4.227l-.347.973s2.608-2.768 3.552-2.93c-.013 0-1.466-5.362 18.206-6.011Z"
        />
        <path
          fill="url(#h)"
          d="M226.867 126.404c-.125.137-.323.336-.596.598-.1.088-.187.187-.286.287a4.63 4.63 0 0 0-.41.536h-.708c.72-.897 1.366-1.396 1.366-1.396.062-.038 1.354-.823.634-.025Z"
        />
        <path
          fill="#606060"
          d="M212.784 116.316s6.086-3.417 3.242-8.055c0 0-2.273-2.856-4.707-3.579 0 0 1.292 6.92 0 7.083-1.292.162-.894-7.819-.894-7.819s-6.408-3.092-8.917-2.855c0 0 3.974 6.758 2.67 6.596-1.291-.162-5.762-7.008-5.762-7.008s-6.57-1.222-7.626 2.681c-1.055 3.903.646 5.861 1.627 6.11.969.25 7.216.162 7.054.973-.161.81-4.384 2.12-6.731 1.384 0 0 2.596 6.434 5.999 6.11 3.402-.324 4.458-2.444 6.569-2.606 2.111-.162 3.651.411 2.596.972-1.056.574-4.62.188-6.321 1.646-1.702 1.459-3.08 2.345.086 3.006 3.167.648 8.843.249 9.973-2.195l1.142-2.444Z"
        />
        <path
          fill="#606060"
          d="M193.809 104.595s17.026 5.536 18.976 11.721c0 0-.087 2.768 1.627 5.948v1.222s-1.789-4.152-2.757-4.726c-.013 0 3.8-5.374-17.846-14.165Z"
        />
        <path
          fill="#606060"
          d="M215.975 127.539h-.969c-.024-1.409-.198-3.005-.67-4.215a4.448 4.448 0 0 0-.199-.436 14.632 14.632 0 0 1-.422-.898c-.497-1.222.695.274.695.274s1.416 2.182 1.565 5.275ZM222.462 111.603s-8.023-4.514-4.272-10.625c0 0 2.993-3.753 6.197-4.725 0 0-1.714 9.127 0 9.339 1.714.212 1.18-10.3 1.18-10.3s8.445-4.077 11.761-3.753c0 0-5.241 8.903-3.527 8.691 1.713-.212 7.587-9.227 7.587-9.227s8.656-1.609 10.047 3.541-.856 7.731-2.136 8.056c-1.279.324-9.513.212-9.301 1.284.211 1.072 5.774 2.793 8.867 1.82 0 0-3.416 8.48-7.911 8.056-4.496-.424-5.874-3.217-8.656-3.429-2.782-.212-4.806.536-3.415 1.284s6.097.249 8.333 2.157c2.248 1.921 4.061 3.093-.112 3.953-4.173.861-11.649.324-13.152-2.893l-1.49-3.229Z"
        />
        <path
          fill="#393939"
          d="M247.472 96.153s-22.453 7.295-25.012 15.45c0 0 .112 3.653-2.136 7.831v1.608s2.347-5.474 3.639-6.222c-.012 0-5.03-7.083 23.509-18.667Z"
        />
        <path
          fill="#393939"
          d="M221.231 119.072c-.111.287-.298.673-.559 1.197-.086.187-.173.374-.26.574-.77 1.932-.919 4.601-.895 6.708h-1.291c-.149-4.688 2.086-8.105 2.086-8.105.05-.05 1.565-1.97.919-.374Z"
        />
        <path
          fill="#233862"
          d="m169.358 87.611-.422.087c-1.913.387-3.775-.86-4.173-2.78l-.447-2.245a3.568 3.568 0 0 1 2.77-4.19l.422-.087c1.912-.387 3.775.86 4.172 2.78l.448 2.245c.372 1.92-.87 3.803-2.77 4.19Z"
        />
        <path
          fill="url(#i)"
          d="M127.691 96.664s10.892-5.138 25.819-1.72l-23.819 1.72h-2Z"
        />
        <path
          fill="url(#j)"
          d="m134.983 128-5.303-31.336s16.691-5.624 29.631 0l7.7 31.136-32.028.2Z"
        />
        <path
          fill="url(#k)"
          d="M182.545 127.801h-30.489a33.946 33.946 0 0 1-1.192-3.492c-.869-3.055-1.428-6.422-1.788-9.714 0-.037-.013-.087-.013-.124-.621-5.711-.621-11.21-.534-14.515.05-1.908.124-3.092.124-3.142.062-.025.137-.05.199-.075a63.446 63.446 0 0 1 4.657-1.77h.013a58.33 58.33 0 0 1 3.613-1.11 54.437 54.437 0 0 1 5.676-1.247 42.345 42.345 0 0 1 5.601-.524h.223c.448-.013.87 0 1.28 0 4.172.087 6.954.923 8.358 1.484.198.075.36.15.509.212.012.012.037.012.049.025.162.075.286.137.373.174.012 0 .025.013.037.013.037.025.087.05.124.062l2.199 23.206.981 10.537Z"
        />
        <path
          fill="url(#l)"
          d="M212.937 122.788c-3.055 1.421-21.224 2.618-22.975 2.12-.894-.262-4.645-3.579-8.408-7.644-3.564-3.853-7.165-8.392-8.37-11.659-2.496-6.709-.348-12.67-.348-12.67s4.632-.024 6.533 1.123c1.887 1.147 12.692 21.136 14.145 21.66 1.44.536 18.566 1.508 20.528 2.444-.025 0 1.95 3.204-1.105 4.626Z"
        />
        <path
          fill="#FFE5C2"
          d="M168.625 92.873v1.135s.311 3.529-4.024 4.053c-4.334.523-7.612-1.372-7.501-4.09.385-2.108.808-3.567.845-5.312a11.934 11.934 0 0 0-.162-2.12l8.396-.187 2.471.399-.025 6.122Z"
        />
        <path
          fill="#FED2A4"
          d="m168.649 86.763-.025 6.11c-.894.424-1.9.673-3.031.673-3.862 0-7.699-6.234-7.823-7.007l10.879.224Z"
        />
        <path
          fill="#FFE5C2"
          d="M169.146 89.73s-10.755 2.195-12.009-4.55c-1.255-6.747-3.502-10.999 3.316-12.695 6.805-1.683 8.631.636 9.599 2.781.957 2.157 1.851 13.73-.906 14.465Z"
        />
        <path
          fill="url(#m)"
          d="M172.124 70.266s-3.005-.225-6.545-1.77c-2.869-1.248-6.495.136-7.662.76a1.96 1.96 0 0 1 .285-.71s-.521.373-.608.897c-.05.037-.075.062-.062.075 0 0-.596-.112-.981.062 0 0 .273-.037.36.137.012.013.025.038.037.05-4.471 2.182-4.881 9.29-3.44 10.924 1.739 1.97 3.825 6.421 3.825 6.421s.758-1.06.435-2.793c-.323-1.746 1.179-1.746 3.067-2.531 1.888-.798.311-3.005 1.962-4.352 1.652-1.347 7.725-.561 7.638-1.596 2.273-.81 1.689-5.337 1.689-5.574Z"
        />
        <path
          fill="url(#n)"
          d="M152.059 127.801a33.946 33.946 0 0 1-1.192-3.492c-.869-3.055-1.428-6.422-1.788-9.714 0-.037-.013-.087-.013-.124.025.012.062.074.087.037 1.193-1.446 4.434-3.03 8.358 2.731.783 1.147 4.223 9.202 4.769 10.549h-10.221v.013Z"
        />
        <path
          fill="#FFE5C2"
          d="M155.236 127.801c-.087-.524.013-1.173.41-1.996 0 0 .509-.324 1.167-.71.994-.561 2.36-1.247 2.969-1.135.111.025.26.05.434.1 1.466.349 4.881 1.409 5.39 2.02.422.511 1.006 1.159 1.403 1.721h-11.773Z"
        />
        <path
          fill="url(#o)"
          d="M157.498 127.801H128.76c-.074-.374-.074-.711.025-.973.857-2.17 2.533-5.748 4.458-9.589a253.433 253.433 0 0 1 4.434-8.442c2.037-3.678 3.899-6.758 4.992-8.03.87-.998 1.95-1.833 3.093-2.52a22.171 22.171 0 0 1 2.881-1.446c.062-.025.136-.05.199-.075 1.999-.81 3.589-1.172 3.589-1.172s.024.91.024 2.307c0 4.065-.149 12.258-1.44 14.265-.36.562-1.031 1.397-1.875 2.382-.025.025-.05.05-.075.087a70.72 70.72 0 0 1-1.49 1.696c-2.993 3.367-6.793 7.332-7.265 7.819l-.05.05h.05l8.06.112 2.471.037 5.129.075c.348.15.633.399.844.711.51.735.659 1.795.684 2.706Z"
        />
        <path
          fill="url(#p)"
          d="M212.933 122.788c-3.055 1.421-21.224 2.618-22.975 2.119-.894-.261-4.644-3.578-8.407-7.643.186.025 1.912-2.856 9.773 4.04 0 0 2.062.923 7.663.399 5.588-.511 12.779-.711 13.946 1.085Z"
        />
        <path
          fill="#233862"
          d="m160.253 87.611-.423.087c-1.912.387-3.775-.86-4.172-2.78l-.447-2.245a3.568 3.568 0 0 1 2.769-4.19l.422-.087c1.913-.387 3.776.86 4.173 2.78l.447 2.245c.385 1.92-.869 3.803-2.769 4.19Z"
        />
        <path
          fill="#233862"
          d="M156.928 79.518c-.286-.523-.708-4.7.434-8.03.559-1.609 1.453-2.531 2.571-3.142.41-.225.857-.35 1.329-.35l1.13.026c-3.713 1.583-4.583 10.025-4.285 10.935-.012-.025-.024-.05-.024-.062l-1.155.623ZM167.766 88.31c-6.247 0-9.339-4.951-9.364-5l.559-.338-.273.175.273-.175c.025.05 2.943 4.689 8.805 4.689h.112l.012.66c-.049-.012-.087-.012-.124-.012Z"
        />
        <path
          fill="url(#q)"
          d="m159.249 85.416-.211.038a1.765 1.765 0 0 1-2.074-1.384l-.223-1.122c-.187-.948.422-1.896 1.378-2.083l.211-.037a1.765 1.765 0 0 1 2.074 1.384l.224 1.122a1.788 1.788 0 0 1-1.379 2.082Z"
        />
        <path
          fill="#233862"
          d="M168.536 88.534h-.583a.553.553 0 0 1-.547-.549c0-.299.249-.548.547-.548h.583c.298 0 .547.25.547.548 0 .3-.249.549-.547.549Z"
        />
        <path
          fill="url(#r)"
          d="M195.101 127.813h-20.777c0-.923.745-1.659 1.652-1.659h19.125v1.659Z"
        />
        <path
          fill="url(#s)"
          d="M195.04 104.906h32.115l-7.7 22.72h-32.326l7.911-22.72Z"
        />
        <path
          fill="url(#t)"
          d="m227.441 105.106-7.712 22.707h-32.327l7.911-22.707h32.128Z"
        />
        <path
          fill="url(#u)"
          d="M210.315 116.054c-.199.873-1.068 1.571-1.925 1.571s-1.403-.698-1.205-1.571c.199-.873 1.068-1.571 1.925-1.571s1.404.698 1.205 1.571Z"
        />
        <path
          fill="url(#v)"
          d="m227.454 105.081-7.7 22.719h-15.039l18.007-22.719h4.732Z"
        />
        <path
          fill="url(#w)"
          d="M43.616 78.795s-5.24 2.694-1.416 8.467c0 0-4.495.524-5.638-.773 0 0 .174.573.373.848 0 0-4.36-.574-5.924-2.232-1.565-1.659 3.316-23.73 14.927-19.216 0 0 10.333.948 7.402 12.632-2.93 11.684.92 12.332.92 12.332s-1.156.35-1.677.262c0 0-.708-.411-.783-.86 0 0 .038.685.199.922 0 0-4.223-.299-6.036-2.232-1.825-1.932-3.353-7.806-2.347-10.15Z"
        />
        <path
          fill="url(#x)"
          d="m51.092 84.569-.422.062a3.43 3.43 0 0 1-3.862-2.943l-.298-2.207c-.261-1.87 1.055-3.629 2.93-3.878l.423-.062a3.43 3.43 0 0 1 3.862 2.942l.298 2.195a3.452 3.452 0 0 1-2.93 3.89Z"
        />
        <path
          fill="#FFE5C2"
          d="M52.296 102.213c-1.565 1.21-2.559 5.586-5.067 6.31-9.451 2.706-14.518-8.554-16.182-14.054 1.267-.81 2.484-1.047 3.192-1.16a36.433 36.433 0 0 1 2.011-.274c1.031-.885 2.46-4.339 2.546-9.601l.46.1 7.637 1.683s-.223 2.42-.273 4.701c-.037 1.783-.1 2.207.038 2.42.596.099 1.291 1.62 1.291 1.62v.013c.1.15 2.471 3.653 4.347 8.242Z"
        />
        <path
          fill="#ED985F"
          d="M46.88 85.205s-.224 2.419-.273 4.7c-4.297-.211-6.483-4.19-7.365-6.384l7.638 1.684Z"
        />
        <path
          fill="url(#y)"
          d="M85.74 124.995s-4.496.162-9.377.187h-1.738c-3.974-.013-7.874-.162-9.538-.586-1.627-.424-7.874-4.664-12.866-9.814-.385.923-.683 1.634-.683 1.696a28.337 28.337 0 0 1-.683 3.429c-1.13 4.24-3.018 7.806-3.092 7.931H24.19s3.95-6.148 2.882-21.784c-.025-.399 3.638-13.006 3.638-13.006s2.844-.499 6.135-.76c.31.286.944 8.578 5.067 9.065.67.074 4.968 6.658 4.968 6.658l1.304-6.733-1.54-8.928c1.974.586 3.055.785 4.694 1.446a5.206 5.206 0 0 1 2.62 2.332c2.882 5.275 12.208 22.295 13.165 22.694.894.375 4.26 1.048 7.811 1.696 4.757.873 9.873 1.696 9.873 1.696l.932 2.781Z"
        />
        <path
          fill="#FFE5C2"
          d="M48.161 87.511s-10.158-.2-9.935-6.546c.224-6.347-.944-10.65 5.552-10.799 6.495-.137 7.674 2.332 8.11 4.464.434 2.133-1.081 12.769-3.727 12.881Z"
        />
        <path
          fill="url(#z)"
          d="M76.377 125.169h-1.739c-3.974-.012-7.874-.162-9.538-.586-1.626-.424-7.873-4.663-12.866-9.813 0 0 1.056-4.053 1.503-4.726.46-.686 2.248 5.91 6.433 8.591 4.185 2.681 10.742 4.564 13.922 3.804 2.148-.512 2.36 1.421 2.285 2.73Z"
        />
        <path
          fill="#fff"
          d="M46.16 83.983s.062.76 1.726.885c0 0 1.404.1 1.453-.71.013-.013-1.266-.787-3.179-.175Z"
        />
        <path
          fill="#233862"
          d="M46.707 86.551c-5.775-1.833-7.19-7.32-7.203-7.37l.62-.149-.31.075.31-.075c.013.05 1.354 5.2 6.782 6.92l.111.038-.186.611c-.05-.037-.087-.05-.124-.05Z"
        />
        <path
          fill="url(#A)"
          d="m47.363 86.975-.546-.174a.534.534 0 0 1-.348-.661.531.531 0 0 1 .658-.35l.546.175a.534.534 0 0 1 .348.661.521.521 0 0 1-.658.35Z"
        />
        <path
          fill="url(#B)"
          d="M48.73 69.318s-2.596 7.731-6.508 9.053c-3.9 1.31-5.19-.237-5.19-.237s2.88-2.656 3.203-6.945c0 0 6.31-5.026 8.495-1.87Z"
        />
        <path
          fill="url(#C)"
          d="M43.875 71.6s5.303-.299 6.88 1.222c1.143 1.098 1.118 1.659 1.205 3.118 0 0 2.322-3.591-.062-6.098-2.534-2.668-8.023 1.758-8.023 1.758Z"
        />
        <path
          fill="url(#D)"
          d="M48.508 70.141s-1.329 7.407-6.172 10.088c0 0 4.694-4.826 4.781-9.651.075-4.826 1.391-.437 1.391-.437Z"
        />
        <path
          fill="url(#E)"
          d="M31.008 85.093c1.564 1.658 5.923 2.231 5.923 2.231-.198-.274-.372-.847-.372-.847 2.235 1.82 7.75.498 7.75.498-4.397-.972-2.969-7.332-1.479-7.606.87-4.8 2.596-10.774 3.403-13.455-.198-.037-.31-.037-.31-.037-11.612-4.514-16.48 17.557-14.915 19.216Z"
        />
        <path
          fill="url(#F)"
          d="m40.545 83.908-.422.062a3.431 3.431 0 0 1-3.862-2.943l-.298-2.207c-.261-1.87 1.055-3.628 2.93-3.878l.423-.062a3.43 3.43 0 0 1 3.862 2.943l.298 2.207c.248 1.87-1.068 3.616-2.93 3.878Z"
        />
        <path
          fill="url(#G)"
          d="M37.8 75.877c-.248-.474.621-4.115 1.913-6.945.62-1.372 2.185-3.192 3.303-3.666.41-.175.944-.1 1.379 0 .323.075 1.217.486 1.217.486-3.676 1.147-6.88 8.89-6.644 9.689-.013-.025-.013-.038-.025-.063l-1.143.5Z"
        />
        <path
          fill="url(#H)"
          d="m39.699 81.725-.211.025a1.71 1.71 0 0 1-1.925-1.459l-.15-1.097c-.123-.935.522-1.808 1.454-1.933l.21-.025a1.71 1.71 0 0 1 1.926 1.46l.149 1.097a1.712 1.712 0 0 1-1.453 1.932Z"
        />
        <path
          fill="#FFE5C2"
          d="M64.352 121.316s4.36-.249 4.782-.025c.683.375 3.03 1.883 3.39 2.32.373.436 1.329 2.207 1.329 2.207s-.41.112-.609-.05c-.186-.175-1.614-1.683-1.614-1.683l1.465 2.232s-.509-.025-.708-.25c-.198-.224-1.564-1.608-1.564-1.608l-.46-.088s.472.387.733.724c.26.349.422.71.422.71s-.422-.074-.546-.137c-.125-.062-1.354-1.134-1.354-1.134l.646.885s-.435-.025-.584-.125c-.149-.1-1.093-.985-1.267-.972-.174 0-1.39-.05-1.912-.325-.522-.274-2.161-.91-2.161-.91l.012-1.771Z"
        />
        <path
          fill="url(#I)"
          d="M37.456 91.04s-2.919.848-3.577 1.522c0 0 3.043 7.818 7.439 11.472l1.18-.836v1.896l4.396 2.893c-.012 0-9.662-14.577-9.438-16.947Z"
        />
        <path
          fill="url(#J)"
          d="M50.843 119.882c-1.13 4.24-3.018 7.806-3.092 7.931h1.49-15.362s1.614.013 1.602 0c-.037-.137 2.347-6.646-1.602-9.302-3.962-2.669-5.402-9.215-5.402-9.215l12.605-3.093s5.19 7.607 6.669 9.066c1.13 1.11 3.775 1.197 3.775 1.197l-.683 3.416Z"
        />
        <path
          fill="url(#K)"
          d="M46.645 92.337s1.341 10.637.236 15.65c0 0 1.8-3.317 2.049-3.928l-.298-1.821 1.155.412s-1.018-8.48-1.95-9.964c-.931-1.484-1.267-1.633-1.267-1.633l.075 1.284Z"
        />
        <path
          fill="#FFE5C2"
          d="M85.74 124.995s-5.625.212-11.114.187c-1.366-2.12.31-4.664.31-4.664a527.34 527.34 0 0 0 9.873 1.696l.932 2.781Z"
        />
        <path
          fill="url(#L)"
          d="M86.273 127.813H65.496c0-.923.745-1.658 1.652-1.658H86.26v1.658h.012Z"
        />
        <path
          fill="url(#M)"
          d="m118.339 105.106-7.712 22.707H78.301l7.91-22.707h32.128Z"
        />
        <path
          fill="url(#N)"
          d="m118.34 105.106-7.637 22.707h-31.93l7.911-22.707h31.656Z"
        />
        <path
          fill="url(#O)"
          d="M101.487 116.254c-.198.872-1.068 1.571-1.924 1.571-.87 0-1.404-.699-1.205-1.571.199-.873 1.068-1.572 1.925-1.572.869 0 1.403.699 1.204 1.572Z"
        />
        <path
          fill="url(#P)"
          d="m118.341 105.106-7.637 22.707h-14.63l17.834-22.707h4.433Z"
        />
        <path
          fill="#FFE5C2"
          d="M64.354 123.087s-10.693.935-16.604.674v-5.836c3.875 1.072 16.604 3.392 16.604 3.392v1.77Z"
        />
        <path
          fill="url(#Q)"
          d="M33.88 92.574s-6.11.137-8.01 2.195c-1.888 2.057 1.875 13.654 1.875 13.654s11.4 14.016 17.1 15.001c0 0 2.497.336 3.652.361 0 0-1.143-3.329.981-5.424 0 0-2.446-.686-2.546-.985-.087-.312-13.052-24.802-13.052-24.802Z"
        />
        <path
          fill="#2C3A64"
          d="M289.445 66.138s-6.197-2.319-10.059 2.233c-2.223 2.606-16.405 29.764-16.244 32.146.174 2.382 1.528 3.566 1.528 3.566s-.597-2.082-.149-3.167c0 0-.286 3.666 1.962 5.225 1.167.81 30.376-.773 32.922-4.327 5.514-7.719-6.532-11.721-6.234-20.064.174-4.85 4.905-15.125-3.726-15.612Z"
        />
        <path
          fill="url(#R)"
          d="m295.358 83.434-.409.087a3.435 3.435 0 0 1-4.037-2.693l-.434-2.183c-.373-1.858.832-3.678 2.682-4.052l.41-.087a3.433 3.433 0 0 1 4.036 2.693l.435 2.182c.372 1.846-.845 3.679-2.683 4.053Z"
        />
        <path
          fill="url(#S)"
          d="M321.847 127.763h-19.373c-3.291-5.237-6.172-12.32-6.855-17.819-.323-2.618-.137-4.875.732-6.422 2.708-4.8-1.589-10.437-1.589-10.437s5.911 1.684 7.29 6.572c1.403 4.988 5.153 21.572 5.153 21.572l14.394 2.968c.683 1.696.248 3.566.248 3.566Z"
        />
        <path
          fill="url(#T)"
          d="M321.85 127.763h-19.374c-3.291-5.237-6.172-12.32-6.855-17.819l2.869-.885s3.639 16.671 5.75 16.921c2.123.249 17.61 1.783 17.61 1.783Z"
        />
        <path
          fill="#FFE5C2"
          d="M297.633 100.978c-1.491 1.31-3.403 2.457-5.874 3.342-9.265 3.292-13.823-4.376-15.81-9.763 1.217-.898 2.422-1.197 3.117-1.36.683-.162 1.391-.299 1.987-.398.982-.948 2.186-4.49 1.95-9.752l.46.075 7.724 1.21s-.074 2.431.013 4.713c.074 1.783.236 3.48.621 3.853l.956.1v.012c.124.15 2.707 3.492 4.856 7.968Z"
        />
        <path
          fill="#ED985F"
          d="M291.186 84.332s-.075 2.431.012 4.713c-4.297.05-6.719-3.778-7.737-5.923l7.725 1.21Z"
        />
        <path
          fill="url(#U)"
          d="M301.305 109.059c-.024.162-2.086 6.908-2.111 7.083-.72 5.648-2.012 11.671-2.012 11.671h-2.844l-18.727-.037s2.26-6.846-3.701-17.345c-.199-.35-.398-.686-.609-1.048-.844-1.396 4.508-16.36 4.508-16.36s2.348-.411 5.639-.686c.31.287 2.595 8.679 6.731 8.929 5.402.336 3.353-9.016 3.353-9.016 2.198.449 2.993.611 5.812 1.958 0 0 4.086 13.928 3.961 14.851Z"
        />
        <path
          fill="#FFE5C2"
          d="M292.601 86.551s-10.158.424-10.32-5.923c-.174-6.347-1.59-10.574 4.881-11.123 6.47-.536 7.799 1.846 8.37 3.953.571 2.12-.298 12.819-2.931 13.093Z"
        />
        <path
          fill="#fff"
          d="M290.391 83.147s.111.76 1.776.773c0 0 1.403.013 1.415-.81 0 .012-1.316-.686-3.191.037Z"
        />
        <path
          fill="#233862"
          d="M291.099 85.678c-5.875-1.471-7.626-6.858-7.638-6.92l.608-.187-.298.1.298-.1c.013.05 1.665 5.1 7.191 6.484.037.012.075.012.112.025l-.149.623c-.05 0-.087-.012-.124-.025Z"
        />
        <path
          fill="#2C3A64"
          d="m291.781 86.065-.558-.137a.531.531 0 0 1-.385-.649.529.529 0 0 1 .645-.386l.559.137c.286.075.46.362.385.648a.535.535 0 0 1-.646.387ZM286.852 65.565s6.532-1.022 9.24 4.44c2.061 4.164.347 6.758-.46 7.556 0 0-1.068-5.973-3.067-6.484 0 0-1.292-.4-2.161.636-.882 1.022-2.149 5.598-3.614 6.297 0 0-4.533 1.122-4.757-.462-.223-1.583-.099-6.534 1.044-7.32 1.142-.81 3.775-4.663 3.775-4.663Z"
        />
        <path
          fill="url(#V)"
          d="m284.776 83.434-.409.087a3.434 3.434 0 0 1-4.037-2.693l-.434-2.182c-.373-1.858.832-3.679 2.682-4.053l.41-.087a3.433 3.433 0 0 1 4.036 2.693l.435 2.183c.372 1.845-.832 3.678-2.683 4.052Z"
        />
        <path
          fill="url(#W)"
          d="M281.548 75.59c-.273-.448-.683-4.102.422-6.995.534-1.396 1.404-2.207 2.496-2.743.398-.2.832-.3 1.28-.3l1.092.013c-3.601 1.372-4.433 8.741-4.16 9.527-.012-.025-.025-.037-.025-.05l-1.105.549Z"
        />
        <path
          fill="url(#X)"
          d="m283.809 81.302-.211.037a1.709 1.709 0 0 1-2.012-1.346l-.224-1.085a1.716 1.716 0 0 1 1.341-2.02l.212-.038a1.708 1.708 0 0 1 2.011 1.347l.224 1.085c.174.922-.422 1.833-1.341 2.02Z"
        />
        <path
          fill="url(#Y)"
          d="M275.627 127.763s2.26-6.846-3.701-17.345l2.223-2.781s3.924 11.173 8.842 14.44c4.906 3.255 10.668 2.569 11.363 5.724l-18.727-.038Z"
        />
        <path
          fill="url(#Z)"
          d="M275.811 93.01s-4.036.35-6.743 5.15c-2.708 4.801-7.849 22.745-7.849 29.591h21.634s.422-1.871-.249-3.567l-13.387-2.593s8.059-12.008 8.146-17.196c.075-5.162-1.552-11.385-1.552-11.385Z"
        />
        <path
          fill="#FFE5C2"
          d="M282.99 125.556s4.21-.411 4.918-.224c.695.187 2.695 1.022 3.142 1.309.447.287 1.304 1.122 1.304 1.122s-.534.1-.857.025c-.323-.075-1.267-.137-1.528-.162-.248-.025-1.167.162-1.167.162h-6.532l.72-2.232Z"
        />
        <path
          fill="url(#aa)"
          d="M321.152 127.801h-20.777c0-.923.745-1.659 1.652-1.659h19.125v1.659Z"
        />
        <path
          fill="url(#ab)"
          d="m353.206 105.081-7.7 22.72H313.18l7.911-22.72h32.115Z"
        />
        <path
          fill="url(#ac)"
          d="m353.207 105.081-7.626 22.508-.074.212h-31.855l7.911-22.72h31.644Z"
        />
        <path
          fill="url(#ad)"
          d="M336.366 116.229c-.198.873-1.068 1.571-1.925 1.571-.869 0-1.403-.698-1.204-1.571.198-.873 1.068-1.571 1.925-1.571s1.403.711 1.204 1.571Z"
        />
        <path
          fill="url(#ae)"
          d="m353.207 105.081-7.7 22.72h-14.679l17.958-22.72h4.421Z"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={56.637}
          x2={56.637}
          y1={22.925}
          y2={128}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={236.639}
          x2={236.639}
          y1={-0.003}
          y2={128}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={364.399}
          x2={119.496}
          y1={77.723}
          y2={77.723}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#313131" />
          <stop offset={1} stopColor="#4A4A4A" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={127.351}
          x2={143.951}
          y1={101.222}
          y2={116.243}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={254.347}
          x2={227.48}
          y1={120.354}
          y2={120.354}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#313131" />
          <stop offset={1} stopColor="#4A4A4A" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={251.419}
          x2={225.887}
          y1={122.905}
          y2={122.905}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#313131" />
          <stop offset={1} stopColor="#4A4A4A" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={227.469}
          x2={224.867}
          y1={126.936}
          y2={126.936}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#313131" />
          <stop offset={1} stopColor="#4A4A4A" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={126.484}
          x2={126.575}
          y1={94.212}
          y2={97.32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={148.345}
          x2={148.345}
          y1={94.164}
          y2={128}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={146.896}
          x2={156.629}
          y1={97.529}
          y2={133.279}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={169.982}
          x2={176.468}
          y1={97.828}
          y2={131.058}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={162.509}
          x2={162.509}
          y1={68.038}
          y2={87.029}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={148.449}
          x2={152.633}
          y1={115.387}
          y2={129.929}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={127.361}
          x2={136.655}
          y1={100.471}
          y2={132.436}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={180.084}
          x2={180.699}
          y1={117.959}
          y2={126.877}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={156.522}
          x2={157.942}
          y1={81.51}
          y2={86.118}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={184.713}
          x2={184.713}
          y1={126.154}
          y2={127.813}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7298DB" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={207.142}
          x2={207.142}
          y1={104.906}
          y2={127.626}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7298DB" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="t"
          x1={185.531}
          x2={189.048}
          y1={108.568}
          y2={132.462}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="u"
          x1={208.75}
          x2={208.75}
          y1={114.483}
          y2={117.625}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="v"
          x1={203.652}
          x2={209.587}
          y1={108.545}
          y2={131.431}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="w"
          x1={42.53}
          x2={42.53}
          y1={65.345}
          y2={91.065}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="x"
          x1={46.122}
          x2={48.933}
          y1={76.904}
          y2={85.866}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="y"
          x1={21.314}
          x2={26.919}
          y1={97.708}
          y2={135.087}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="z"
          x1={51.105}
          x2={53.697}
          y1={112.308}
          y2={128.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="A"
          x1={46.37}
          x2={46.626}
          y1={85.955}
          y2={87.23}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="B"
          x1={42.899}
          x2={42.899}
          y1={68.303}
          y2={78.851}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="C"
          x1={48.439}
          x2={48.439}
          y1={69.004}
          y2={75.909}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="D"
          x1={45.432}
          x2={45.432}
          y1={68.343}
          y2={80.177}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="E"
          x1={38.503}
          x2={38.503}
          y1={65.323}
          y2={87.343}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="F"
          x1={35.575}
          x2={38.387}
          y1={76.243}
          y2={85.205}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="G"
          x1={37.391}
          x2={41.016}
          y1={66.795}
          y2={77.032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="H"
          x1={37.222}
          x2={38.614}
          y1={77.914}
          y2={82.364}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="I"
          x1={33.27}
          x2={38.797}
          y1={93.624}
          y2={109.975}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="J"
          x1={27.399}
          x2={32.739}
          y1={109.499}
          y2={131.437}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="K"
          x1={46.42}
          x2={55.096}
          y1={93.635}
          y2={99.984}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="L"
          x1={64.525}
          x2={64.562}
          y1={126.408}
          y2={128.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="M"
          x1={76.429}
          x2={79.947}
          y1={108.568}
          y2={132.462}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="N"
          x1={76.924}
          x2={80.481}
          y1={108.568}
          y2={132.45}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="O"
          x1={99.923}
          x2={99.923}
          y1={114.682}
          y2={117.825}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="P"
          x1={95.033}
          x2={101.072}
          y1={108.568}
          y2={131.381}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="Q"
          x1={24.212}
          x2={34.333}
          y1={97.333}
          y2={127.49}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="R"
          x1={290.05}
          x2={292.822}
          y1={75.833}
          y2={84.816}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="S"
          x1={293.489}
          x2={304.602}
          y1={98.373}
          y2={131.979}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="T"
          x1={294.395}
          x2={297.994}
          y1={111.911}
          y2={131.353}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="U"
          x1={269.804}
          x2={280.522}
          y1={97.673}
          y2={132.618}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="V"
          x1={279.468}
          x2={282.24}
          y1={75.834}
          y2={84.816}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="W"
          x1={280.981}
          x2={285.11}
          y1={67.083}
          y2={75.952}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="X"
          x1={281.148}
          x2={282.523}
          y1={77.511}
          y2={81.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="Y"
          x1={270.877}
          x2={275.675}
          y1={110.712}
          y2={131.269}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="Z"
          x1={260.201}
          x2={273.408}
          y1={98.308}
          y2={130.184}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="aa"
          x1={299.404}
          x2={299.441}
          y1={126.395}
          y2={128.178}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ab"
          x1={311.309}
          x2={314.831}
          y1={108.545}
          y2={132.451}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ac"
          x1={311.803}
          x2={315.366}
          y1={108.545}
          y2={132.439}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ad"
          x1={334.801}
          x2={334.801}
          y1={114.658}
          y2={117.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="ae"
          x1={329.782}
          x2={335.801}
          y1={108.545}
          y2={131.384}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h360v128H0z" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={360}
      height={128}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#EBF3FA"
          d="M113.274 39.753V128H6.285c.273-3.903-17.337-55.39 5.228-84.793 32.041-41.748 59.413-6.584 95.539-3.654 2.049.163 4.111.25 6.222.2Z"
        />
        <path
          fill="#F3F8FC"
          d="M359.106 127.751h-11.897l-72.328.249H113.273V39.753c2.037-.05 4.099-.237 6.222-.524 7.092-.972 14.53-3.878 22.416-8.491 81.58-47.51 148.009-34.603 185.316 2.481a111.204 111.204 0 0 1 12.431 14.752c15.847 22.545 23.012 51.536 19.448 79.78Z"
        />
        <path
          fill="#E8F0FD"
          d="M328.196 59.954v67.859h-208.7V68.77h15.996V52.647h15.995V74.43h12.953v-4.876h22.565v8.23h9.811V59.318h26.291V27.633h20.777v16.896h4.744V30.376h20.777v21.81h18.84v-13.38h20.143v13.38h3.751v7.768h16.057Z"
        />
        <path
          fill="#C0D8FB"
          d="M89.192 64.966H70.638a1.556 1.556 0 0 1-1.552-1.558V45.427c0-.86.695-1.56 1.552-1.56h18.554c.857 0 1.553.7 1.553 1.56v17.98c0 .861-.696 1.56-1.553 1.56Z"
        />
        <path
          fill="#DFEBFD"
          d="M88.399 64.056H71.447a1.418 1.418 0 0 1-1.416-1.421V46.212c0-.785.634-1.421 1.416-1.421h16.952c.782 0 1.416.636 1.416 1.421v16.423c0 .785-.646 1.421-1.416 1.421Z"
        />
        <path
          fill="#5384EE"
          fillOpacity={0.2}
          d="M79.916 47.16a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896ZM83.095 47.347c.124-.212.397-.3.609-.175.21.125.298.4.173.611-.124.212-.397.3-.608.175a.437.437 0 0 1-.174-.611ZM86.189 49.828a.444.444 0 0 1 .62.137.448.448 0 0 1-.136.624.444.444 0 0 1-.62-.137.472.472 0 0 1 .136-.624ZM88.138 54.273a.447.447 0 1 0-.827-.342.446.446 0 1 0 .827.342ZM86.944 58.096a.449.449 0 1 1-.633.637.449.449 0 0 1 .632-.637ZM83.803 61.7a.448.448 0 1 0-.002-.897.448.448 0 0 0 .002.896ZM79.916 62.846a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896ZM76.738 47.347c-.124-.212-.397-.3-.609-.175-.21.125-.297.4-.173.611.124.212.397.3.608.175.211-.112.286-.387.174-.611ZM73.631 49.828a.445.445 0 0 0-.62.137.449.449 0 0 0 .136.624.445.445 0 0 0 .62-.137.448.448 0 0 0-.136-.624ZM72.092 54.554a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896ZM72.873 58.096a.448.448 0 1 0 .633.637.448.448 0 0 0-.633-.637ZM76.017 61.7a.448.448 0 1 0-.001-.897.448.448 0 0 0 .001.896ZM80.252 54.555a.448.448 0 1 0-.002-.896.448.448 0 0 0 .002.896Z"
        />
        <path
          fill="#5384EE"
          fillOpacity={0.2}
          d="M80.316 48.482h-.125v5.624h.125v-5.624Z"
        />
        <path
          fill="#5384EE"
          fillOpacity={0.2}
          d="m83.047 51.614-2.969 2.34.23.295 2.97-2.34-.23-.295Z"
        />
        <path
          fill="#DEEDFF"
          d="M119.495 39.229V128h-12.444V39.553c4.036.325 8.171.262 12.444-.324ZM339.646 48.045v79.394h-12.431v-94.12a110.023 110.023 0 0 1 12.431 14.726Z"
        />
        <path
          fill="url(#b)"
          d="M129.691 96.49h-2l2.447 31.036h4.843l-5.29-31.037Z"
        />
        <path
          fill="url(#c)"
          d="M250.364 118.361c-.298 3.404-2.211 4.452-3.043 4.364-.844-.087-5.762-1.97-5.862-1.284-.111.686 2.857 2.955 4.931 3.055 0 0-3.912 4.339-6.508 3.08-2.595-1.247-2.819-3.23-4.446-3.978-1.627-.748-3.005-.735-2.335.013.671.76 3.602 1.496 4.533 3.142.224.387.435.748.596 1.06l-5.476-.012c-1.739-.861-3.204-2.058-3.304-3.355-.012-.1-.012-.187-.025-.287l-.173-1.982s-2.807-3.292-1.354-6.023a4.259 4.259 0 0 1 1.118-1.297s2.62-1.596 4.756-1.459c0 0-1.652 2.793-2.124 4.439-.161.586-.186 1.023.075 1.16.273.15.646-.274 1.018-.948.932-1.683 1.95-4.975 1.95-4.975s1.577-.15 3.366-.175c.807-.012 1.651 0 2.421.075.845.075 1.602.212 2.087.449 0 0-.112.087-.286.237-.273.224-.695.586-1.167 1.01-1.416 1.259-3.254 3.03-2.571 3.192.559.125 2.297-.91 3.85-1.945 1.44-.96 2.72-1.92 2.72-1.92s5.551.96 5.253 4.364Z"
        />
        <path
          fill="url(#d)"
          d="M247.645 118.448s-15.077-.586-18.405 3.741c0 0-.733 2.22-3.006 4.227l-.347.973s2.608-2.768 3.552-2.93c-.013 0-1.466-5.362 18.206-6.011Z"
        />
        <path
          fill="url(#e)"
          d="M226.867 126.404c-.125.137-.323.336-.596.598-.1.088-.187.187-.286.287a4.63 4.63 0 0 0-.41.536h-.708c.72-.897 1.366-1.396 1.366-1.396.062-.038 1.354-.823.634-.025Z"
        />
        <path
          fill="url(#f)"
          d="M212.784 116.316s6.086-3.417 3.242-8.055c0 0-2.273-2.856-4.707-3.579 0 0 1.292 6.92 0 7.083-1.292.162-.894-7.819-.894-7.819s-6.408-3.092-8.917-2.855c0 0 3.974 6.758 2.67 6.596-1.291-.162-5.762-7.008-5.762-7.008s-6.57-1.222-7.626 2.681c-1.055 3.903.646 5.861 1.627 6.11.969.25 7.216.162 7.054.973-.161.81-4.384 2.12-6.731 1.384 0 0 2.596 6.434 5.999 6.11 3.402-.324 4.458-2.444 6.569-2.606 2.111-.162 3.651.411 2.596.972-1.056.574-4.62.188-6.321 1.646-1.702 1.459-3.08 2.345.086 3.006 3.167.648 8.843.249 9.973-2.195l1.142-2.444Z"
        />
        <path
          fill="url(#g)"
          d="M193.809 104.595s17.026 5.536 18.976 11.721c0 0-.087 2.768 1.627 5.948v1.222s-1.789-4.152-2.757-4.726c-.013 0 3.8-5.374-17.846-14.165Z"
        />
        <path
          fill="url(#h)"
          d="M215.975 127.539h-.969c-.024-1.409-.198-3.005-.67-4.215a4.448 4.448 0 0 0-.199-.436 14.632 14.632 0 0 1-.422-.898c-.497-1.222.695.274.695.274s1.416 2.182 1.565 5.275Z"
        />
        <path
          fill="url(#i)"
          d="M222.462 111.603s-8.023-4.514-4.272-10.625c0 0 2.993-3.753 6.197-4.725 0 0-1.714 9.127 0 9.339 1.714.212 1.18-10.3 1.18-10.3s8.445-4.077 11.761-3.753c0 0-5.241 8.903-3.527 8.691 1.713-.212 7.587-9.227 7.587-9.227s8.656-1.609 10.047 3.541-.856 7.731-2.136 8.056c-1.279.324-9.513.212-9.301 1.284.211 1.072 5.774 2.793 8.867 1.82 0 0-3.416 8.48-7.911 8.056-4.496-.424-5.874-3.217-8.656-3.429-2.782-.212-4.806.536-3.415 1.284s6.097.249 8.333 2.157c2.248 1.921 4.061 3.093-.112 3.953-4.173.861-11.649.324-13.152-2.893l-1.49-3.229Z"
        />
        <path
          fill="url(#j)"
          d="M247.472 96.153s-22.453 7.295-25.012 15.45c0 0 .112 3.653-2.136 7.831v1.608s2.347-5.474 3.639-6.222c-.012 0-5.03-7.083 23.509-18.667Z"
        />
        <path
          fill="url(#k)"
          d="M221.231 119.072c-.111.287-.298.673-.559 1.197-.086.187-.173.374-.26.574-.77 1.932-.919 4.601-.895 6.708h-1.291c-.149-4.688 2.086-8.105 2.086-8.105.05-.05 1.565-1.97.919-.374Z"
        />
        <path
          fill="#233862"
          d="m169.358 87.611-.422.087c-1.913.387-3.775-.86-4.173-2.78l-.447-2.245a3.568 3.568 0 0 1 2.77-4.19l.422-.087c1.912-.387 3.775.86 4.172 2.78l.448 2.245c.372 1.92-.87 3.803-2.77 4.19Z"
        />
        <path
          fill="url(#l)"
          d="M127.691 96.664s10.892-5.138 25.819-1.72l-23.819 1.72h-2Z"
        />
        <path
          fill="url(#m)"
          d="m134.983 128-5.303-31.336s16.691-5.624 29.631 0l7.7 31.136-32.028.2Z"
        />
        <path
          fill="url(#n)"
          d="M182.545 127.801h-30.489a33.946 33.946 0 0 1-1.192-3.492c-.869-3.055-1.428-6.422-1.788-9.714 0-.037-.013-.087-.013-.124-.621-5.711-.621-11.21-.534-14.515.05-1.908.124-3.092.124-3.142.062-.025.137-.05.199-.075a63.446 63.446 0 0 1 4.657-1.77h.013a58.33 58.33 0 0 1 3.613-1.11 54.437 54.437 0 0 1 5.676-1.247 42.345 42.345 0 0 1 5.601-.524h.223c.448-.013.87 0 1.28 0 4.172.087 6.954.923 8.358 1.484.198.075.36.15.509.212.012.012.037.012.049.025.162.075.286.137.373.174.012 0 .025.013.037.013.037.025.087.05.124.062l2.199 23.206.981 10.537Z"
        />
        <path
          fill="url(#o)"
          d="M212.937 122.788c-3.055 1.421-21.224 2.618-22.975 2.12-.894-.262-4.645-3.579-8.408-7.644-3.564-3.853-7.165-8.392-8.37-11.659-2.496-6.709-.348-12.67-.348-12.67s4.632-.024 6.533 1.123c1.887 1.147 12.692 21.136 14.145 21.66 1.44.536 18.566 1.508 20.528 2.444-.025 0 1.95 3.204-1.105 4.626Z"
        />
        <path
          fill="#FFE5C2"
          d="M168.625 92.873v1.135s.311 3.529-4.024 4.053c-4.334.523-7.612-1.372-7.501-4.09.385-2.108.808-3.567.845-5.312a11.934 11.934 0 0 0-.162-2.12l8.396-.187 2.471.399-.025 6.122Z"
        />
        <path
          fill="#FED2A4"
          d="m168.649 86.763-.025 6.11c-.894.424-1.9.673-3.031.673-3.862 0-7.699-6.234-7.823-7.007l10.879.224Z"
        />
        <path
          fill="#FFE5C2"
          d="M169.146 89.73s-10.755 2.195-12.009-4.55c-1.255-6.747-3.502-10.999 3.316-12.695 6.805-1.683 8.631.636 9.599 2.781.957 2.157 1.851 13.73-.906 14.465Z"
        />
        <path
          fill="url(#p)"
          d="M172.124 70.266s-3.005-.225-6.545-1.77c-2.869-1.248-6.495.136-7.662.76a1.96 1.96 0 0 1 .285-.71s-.521.373-.608.897c-.05.037-.075.062-.062.075 0 0-.596-.112-.981.062 0 0 .273-.037.36.137.012.013.025.038.037.05-4.471 2.182-4.881 9.29-3.44 10.924 1.739 1.97 3.825 6.421 3.825 6.421s.758-1.06.435-2.793c-.323-1.746 1.179-1.746 3.067-2.531 1.888-.798.311-3.005 1.962-4.352 1.652-1.347 7.725-.561 7.638-1.596 2.273-.81 1.689-5.337 1.689-5.574Z"
        />
        <path
          fill="url(#q)"
          d="M152.059 127.801a33.946 33.946 0 0 1-1.192-3.492c-.869-3.055-1.428-6.422-1.788-9.714 0-.037-.013-.087-.013-.124.025.012.062.074.087.037 1.193-1.446 4.434-3.03 8.358 2.731.783 1.147 4.223 9.202 4.769 10.549h-10.221v.013Z"
        />
        <path
          fill="#FFE5C2"
          d="M155.236 127.801c-.087-.524.013-1.173.41-1.996 0 0 .509-.324 1.167-.71.994-.561 2.36-1.247 2.969-1.135.111.025.26.05.434.1 1.466.349 4.881 1.409 5.39 2.02.422.511 1.006 1.159 1.403 1.721h-11.773Z"
        />
        <path
          fill="url(#r)"
          d="M157.498 127.801H128.76c-.074-.374-.074-.711.025-.973.857-2.17 2.533-5.748 4.458-9.589a253.433 253.433 0 0 1 4.434-8.442c2.037-3.678 3.899-6.758 4.992-8.03.87-.998 1.95-1.833 3.093-2.52a22.171 22.171 0 0 1 2.881-1.446c.062-.025.136-.05.199-.075 1.999-.81 3.589-1.172 3.589-1.172s.024.91.024 2.307c0 4.065-.149 12.258-1.44 14.265-.36.562-1.031 1.397-1.875 2.382-.025.025-.05.05-.075.087a70.72 70.72 0 0 1-1.49 1.696c-2.993 3.367-6.793 7.332-7.265 7.819l-.05.05h.05l8.06.112 2.471.037 5.129.075c.348.15.633.399.844.711.51.735.659 1.795.684 2.706Z"
        />
        <path
          fill="url(#s)"
          d="M212.933 122.788c-3.055 1.421-21.224 2.618-22.975 2.119-.894-.261-4.644-3.578-8.407-7.643.186.025 1.912-2.856 9.773 4.04 0 0 2.062.923 7.663.399 5.588-.511 12.779-.711 13.946 1.085Z"
        />
        <path
          fill="#233862"
          d="m160.253 87.611-.423.087c-1.912.387-3.775-.86-4.172-2.78l-.447-2.245a3.568 3.568 0 0 1 2.769-4.19l.422-.087c1.913-.387 3.776.86 4.173 2.78l.447 2.245c.385 1.92-.869 3.803-2.769 4.19Z"
        />
        <path
          fill="#233862"
          d="M156.928 79.518c-.286-.523-.708-4.7.434-8.03.559-1.609 1.453-2.531 2.571-3.142.41-.225.857-.35 1.329-.35l1.13.026c-3.713 1.583-4.583 10.025-4.285 10.935-.012-.025-.024-.05-.024-.062l-1.155.623ZM167.766 88.31c-6.247 0-9.339-4.951-9.364-5l.559-.338-.273.175.273-.175c.025.05 2.943 4.689 8.805 4.689h.112l.012.66c-.049-.012-.087-.012-.124-.012Z"
        />
        <path
          fill="url(#t)"
          d="m159.249 85.416-.211.038a1.765 1.765 0 0 1-2.074-1.384l-.223-1.122c-.187-.948.422-1.896 1.378-2.083l.211-.037a1.765 1.765 0 0 1 2.074 1.384l.224 1.122a1.788 1.788 0 0 1-1.379 2.082Z"
        />
        <path
          fill="#233862"
          d="M168.536 88.534h-.583a.553.553 0 0 1-.547-.549c0-.299.249-.548.547-.548h.583c.298 0 .547.25.547.548 0 .3-.249.549-.547.549Z"
        />
        <path
          fill="url(#u)"
          d="M195.101 127.813h-20.777c0-.923.745-1.659 1.652-1.659h19.125v1.659Z"
        />
        <path
          fill="url(#v)"
          d="M195.04 104.906h32.115l-7.7 22.72h-32.326l7.911-22.72Z"
        />
        <path
          fill="url(#w)"
          d="m227.441 105.106-7.712 22.707h-32.327l7.911-22.707h32.128Z"
        />
        <path
          fill="url(#x)"
          d="M210.315 116.054c-.199.873-1.068 1.571-1.925 1.571s-1.403-.698-1.205-1.571c.199-.873 1.068-1.571 1.925-1.571s1.404.698 1.205 1.571Z"
        />
        <path
          fill="url(#y)"
          d="m227.454 105.081-7.7 22.719h-15.039l18.007-22.719h4.732Z"
        />
        <path
          fill="url(#z)"
          d="M43.616 78.795s-5.24 2.694-1.416 8.467c0 0-4.495.524-5.638-.773 0 0 .174.573.373.848 0 0-4.36-.574-5.924-2.232-1.565-1.659 3.316-23.73 14.927-19.216 0 0 10.333.948 7.402 12.632-2.93 11.684.92 12.332.92 12.332s-1.156.35-1.677.262c0 0-.708-.411-.783-.86 0 0 .038.685.199.922 0 0-4.223-.299-6.036-2.232-1.825-1.932-3.353-7.806-2.347-10.15Z"
        />
        <path
          fill="url(#A)"
          d="m51.092 84.569-.422.062a3.43 3.43 0 0 1-3.862-2.943l-.298-2.207c-.261-1.87 1.055-3.629 2.93-3.878l.423-.062a3.43 3.43 0 0 1 3.862 2.942l.298 2.195a3.452 3.452 0 0 1-2.93 3.89Z"
        />
        <path
          fill="#FFE5C2"
          d="M52.296 102.213c-1.565 1.21-2.559 5.586-5.067 6.31-9.451 2.706-14.518-8.554-16.182-14.054 1.267-.81 2.484-1.047 3.192-1.16a36.433 36.433 0 0 1 2.011-.274c1.031-.885 2.46-4.339 2.546-9.601l.46.1 7.637 1.683s-.223 2.42-.273 4.701c-.037 1.783-.1 2.207.038 2.42.596.099 1.291 1.62 1.291 1.62v.013c.1.15 2.471 3.653 4.347 8.242Z"
        />
        <path
          fill="#ED985F"
          d="M46.88 85.205s-.224 2.419-.273 4.7c-4.297-.211-6.483-4.19-7.365-6.384l7.638 1.684Z"
        />
        <path
          fill="url(#B)"
          d="M85.74 124.995s-4.496.162-9.377.187h-1.738c-3.974-.013-7.874-.162-9.538-.586-1.627-.424-7.874-4.664-12.866-9.814-.385.923-.683 1.634-.683 1.696a28.337 28.337 0 0 1-.683 3.429c-1.13 4.24-3.018 7.806-3.092 7.931H24.19s3.95-6.148 2.882-21.784c-.025-.399 3.638-13.006 3.638-13.006s2.844-.499 6.135-.76c.31.286.944 8.578 5.067 9.065.67.074 4.968 6.658 4.968 6.658l1.304-6.733-1.54-8.928c1.974.586 3.055.785 4.694 1.446a5.206 5.206 0 0 1 2.62 2.332c2.882 5.275 12.208 22.295 13.165 22.694.894.375 4.26 1.048 7.811 1.696 4.757.873 9.873 1.696 9.873 1.696l.932 2.781Z"
        />
        <path
          fill="#FFE5C2"
          d="M48.161 87.511s-10.158-.2-9.935-6.546c.224-6.347-.944-10.65 5.552-10.799 6.495-.137 7.674 2.332 8.11 4.464.434 2.133-1.081 12.769-3.727 12.881Z"
        />
        <path
          fill="url(#C)"
          d="M76.377 125.169h-1.739c-3.974-.012-7.874-.162-9.538-.586-1.626-.424-7.873-4.663-12.866-9.813 0 0 1.056-4.053 1.503-4.726.46-.686 2.248 5.91 6.433 8.591 4.185 2.681 10.742 4.564 13.922 3.804 2.148-.512 2.36 1.421 2.285 2.73Z"
        />
        <path
          fill="#fff"
          d="M46.16 83.983s.062.76 1.726.885c0 0 1.404.1 1.453-.71.013-.013-1.266-.787-3.179-.175Z"
        />
        <path
          fill="#233862"
          d="M46.707 86.551c-5.775-1.833-7.19-7.32-7.203-7.37l.62-.149-.31.075.31-.075c.013.05 1.354 5.2 6.782 6.92l.111.038-.186.611c-.05-.037-.087-.05-.124-.05Z"
        />
        <path
          fill="url(#D)"
          d="m47.363 86.975-.546-.174a.534.534 0 0 1-.348-.661.531.531 0 0 1 .658-.35l.546.175a.534.534 0 0 1 .348.661.521.521 0 0 1-.658.35Z"
        />
        <path
          fill="url(#E)"
          d="M48.73 69.318s-2.596 7.731-6.508 9.053c-3.9 1.31-5.19-.237-5.19-.237s2.88-2.656 3.203-6.945c0 0 6.31-5.026 8.495-1.87Z"
        />
        <path
          fill="url(#F)"
          d="M43.875 71.6s5.303-.299 6.88 1.222c1.143 1.098 1.118 1.659 1.205 3.118 0 0 2.322-3.591-.062-6.098-2.534-2.668-8.023 1.758-8.023 1.758Z"
        />
        <path
          fill="url(#G)"
          d="M48.508 70.141s-1.329 7.407-6.172 10.088c0 0 4.694-4.826 4.781-9.651.075-4.826 1.391-.437 1.391-.437Z"
        />
        <path
          fill="url(#H)"
          d="M31.008 85.093c1.564 1.658 5.923 2.231 5.923 2.231-.198-.274-.372-.847-.372-.847 2.235 1.82 7.75.498 7.75.498-4.397-.972-2.969-7.332-1.479-7.606.87-4.8 2.596-10.774 3.403-13.455-.198-.037-.31-.037-.31-.037-11.612-4.514-16.48 17.557-14.915 19.216Z"
        />
        <path
          fill="url(#I)"
          d="m40.545 83.908-.422.062a3.431 3.431 0 0 1-3.862-2.943l-.298-2.207c-.261-1.87 1.055-3.628 2.93-3.878l.423-.062a3.43 3.43 0 0 1 3.862 2.943l.298 2.207c.248 1.87-1.068 3.616-2.93 3.878Z"
        />
        <path
          fill="url(#J)"
          d="M37.8 75.877c-.248-.474.621-4.115 1.913-6.945.62-1.372 2.185-3.192 3.303-3.666.41-.175.944-.1 1.379 0 .323.075 1.217.486 1.217.486-3.676 1.147-6.88 8.89-6.644 9.689-.013-.025-.013-.038-.025-.063l-1.143.5Z"
        />
        <path
          fill="url(#K)"
          d="m39.699 81.725-.211.025a1.71 1.71 0 0 1-1.925-1.459l-.15-1.097c-.123-.935.522-1.808 1.454-1.933l.21-.025a1.71 1.71 0 0 1 1.926 1.46l.149 1.097a1.712 1.712 0 0 1-1.453 1.932Z"
        />
        <path
          fill="#FFE5C2"
          d="M64.352 121.316s4.36-.249 4.782-.025c.683.375 3.03 1.883 3.39 2.32.373.436 1.329 2.207 1.329 2.207s-.41.112-.609-.05c-.186-.175-1.614-1.683-1.614-1.683l1.465 2.232s-.509-.025-.708-.25c-.198-.224-1.564-1.608-1.564-1.608l-.46-.088s.472.387.733.724c.26.349.422.71.422.71s-.422-.074-.546-.137c-.125-.062-1.354-1.134-1.354-1.134l.646.885s-.435-.025-.584-.125c-.149-.1-1.093-.985-1.267-.972-.174 0-1.39-.05-1.912-.325-.522-.274-2.161-.91-2.161-.91l.012-1.771Z"
        />
        <path
          fill="url(#L)"
          d="M37.456 91.04s-2.919.848-3.577 1.522c0 0 3.043 7.818 7.439 11.472l1.18-.836v1.896l4.396 2.893c-.012 0-9.662-14.577-9.438-16.947Z"
        />
        <path
          fill="url(#M)"
          d="M50.843 119.882c-1.13 4.24-3.018 7.806-3.092 7.931h1.49-15.362s1.614.013 1.602 0c-.037-.137 2.347-6.646-1.602-9.302-3.962-2.669-5.402-9.215-5.402-9.215l12.605-3.093s5.19 7.607 6.669 9.066c1.13 1.11 3.775 1.197 3.775 1.197l-.683 3.416Z"
        />
        <path
          fill="url(#N)"
          d="M46.645 92.337s1.341 10.637.236 15.65c0 0 1.8-3.317 2.049-3.928l-.298-1.821 1.155.412s-1.018-8.48-1.95-9.964c-.931-1.484-1.267-1.633-1.267-1.633l.075 1.284Z"
        />
        <path
          fill="#FFE5C2"
          d="M85.74 124.995s-5.625.212-11.114.187c-1.366-2.12.31-4.664.31-4.664a527.34 527.34 0 0 0 9.873 1.696l.932 2.781Z"
        />
        <path
          fill="url(#O)"
          d="M86.273 127.813H65.496c0-.923.745-1.658 1.652-1.658H86.26v1.658h.012Z"
        />
        <path
          fill="url(#P)"
          d="m118.339 105.106-7.712 22.707H78.301l7.91-22.707h32.128Z"
        />
        <path
          fill="url(#Q)"
          d="m118.34 105.106-7.637 22.707h-31.93l7.911-22.707h31.656Z"
        />
        <path
          fill="url(#R)"
          d="M101.487 116.254c-.198.872-1.068 1.571-1.924 1.571-.87 0-1.404-.699-1.205-1.571.199-.873 1.068-1.572 1.925-1.572.869 0 1.403.699 1.204 1.572Z"
        />
        <path
          fill="url(#S)"
          d="m118.341 105.106-7.637 22.707h-14.63l17.834-22.707h4.433Z"
        />
        <path
          fill="#FFE5C2"
          d="M64.354 123.087s-10.693.935-16.604.674v-5.836c3.875 1.072 16.604 3.392 16.604 3.392v1.77Z"
        />
        <path
          fill="url(#T)"
          d="M33.88 92.574s-6.11.137-8.01 2.195c-1.888 2.057 1.875 13.654 1.875 13.654s11.4 14.016 17.1 15.001c0 0 2.497.336 3.652.361 0 0-1.143-3.329.981-5.424 0 0-2.446-.686-2.546-.985-.087-.312-13.052-24.802-13.052-24.802Z"
        />
        <path
          fill="#2C3A64"
          d="M289.445 66.138s-6.197-2.319-10.059 2.233c-2.223 2.606-16.405 29.764-16.244 32.146.174 2.382 1.528 3.566 1.528 3.566s-.597-2.082-.149-3.167c0 0-.286 3.666 1.962 5.225 1.167.81 30.376-.773 32.922-4.327 5.514-7.719-6.532-11.721-6.234-20.064.174-4.85 4.905-15.125-3.726-15.612Z"
        />
        <path
          fill="url(#U)"
          d="m295.358 83.434-.409.087a3.435 3.435 0 0 1-4.037-2.693l-.434-2.183c-.373-1.858.832-3.678 2.682-4.052l.41-.087a3.433 3.433 0 0 1 4.036 2.693l.435 2.182c.372 1.846-.845 3.679-2.683 4.053Z"
        />
        <path
          fill="url(#V)"
          d="M321.847 127.763h-19.373c-3.291-5.237-6.172-12.32-6.855-17.819-.323-2.618-.137-4.875.732-6.422 2.708-4.8-1.589-10.437-1.589-10.437s5.911 1.684 7.29 6.572c1.403 4.988 5.153 21.572 5.153 21.572l14.394 2.968c.683 1.696.248 3.566.248 3.566Z"
        />
        <path
          fill="url(#W)"
          d="M321.85 127.763h-19.374c-3.291-5.237-6.172-12.32-6.855-17.819l2.869-.885s3.639 16.671 5.75 16.921c2.123.249 17.61 1.783 17.61 1.783Z"
        />
        <path
          fill="#FFE5C2"
          d="M297.633 100.978c-1.491 1.31-3.403 2.457-5.874 3.342-9.265 3.292-13.823-4.376-15.81-9.763 1.217-.898 2.422-1.197 3.117-1.36.683-.162 1.391-.299 1.987-.398.982-.948 2.186-4.49 1.95-9.752l.46.075 7.724 1.21s-.074 2.431.013 4.713c.074 1.783.236 3.48.621 3.853l.956.1v.012c.124.15 2.707 3.492 4.856 7.968Z"
        />
        <path
          fill="#ED985F"
          d="M291.186 84.332s-.075 2.431.012 4.713c-4.297.05-6.719-3.778-7.737-5.923l7.725 1.21Z"
        />
        <path
          fill="url(#X)"
          d="M301.305 109.059c-.024.162-2.086 6.908-2.111 7.083-.72 5.648-2.012 11.671-2.012 11.671h-2.844l-18.727-.037s2.26-6.846-3.701-17.345c-.199-.35-.398-.686-.609-1.048-.844-1.396 4.508-16.36 4.508-16.36s2.348-.411 5.639-.686c.31.287 2.595 8.679 6.731 8.929 5.402.336 3.353-9.016 3.353-9.016 2.198.449 2.993.611 5.812 1.958 0 0 4.086 13.928 3.961 14.851Z"
        />
        <path
          fill="#FFE5C2"
          d="M292.601 86.551s-10.158.424-10.32-5.923c-.174-6.347-1.59-10.574 4.881-11.123 6.47-.536 7.799 1.846 8.37 3.953.571 2.12-.298 12.819-2.931 13.093Z"
        />
        <path
          fill="#fff"
          d="M290.391 83.147s.111.76 1.776.773c0 0 1.403.013 1.415-.81 0 .012-1.316-.686-3.191.037Z"
        />
        <path
          fill="#233862"
          d="M291.099 85.678c-5.875-1.471-7.626-6.858-7.638-6.92l.608-.187-.298.1.298-.1c.013.05 1.665 5.1 7.191 6.484.037.012.075.012.112.025l-.149.623c-.05 0-.087-.012-.124-.025Z"
        />
        <path
          fill="#2C3A64"
          d="m291.781 86.065-.558-.137a.531.531 0 0 1-.385-.649.529.529 0 0 1 .645-.386l.559.137c.286.075.46.362.385.648a.535.535 0 0 1-.646.387ZM286.852 65.565s6.532-1.022 9.24 4.44c2.061 4.164.347 6.758-.46 7.556 0 0-1.068-5.973-3.067-6.484 0 0-1.292-.4-2.161.636-.882 1.022-2.149 5.598-3.614 6.297 0 0-4.533 1.122-4.757-.462-.223-1.583-.099-6.534 1.044-7.32 1.142-.81 3.775-4.663 3.775-4.663Z"
        />
        <path
          fill="url(#Y)"
          d="m284.776 83.434-.409.087a3.434 3.434 0 0 1-4.037-2.693l-.434-2.182c-.373-1.858.832-3.679 2.682-4.053l.41-.087a3.433 3.433 0 0 1 4.036 2.693l.435 2.183c.372 1.845-.832 3.678-2.683 4.052Z"
        />
        <path
          fill="url(#Z)"
          d="M281.548 75.59c-.273-.448-.683-4.102.422-6.995.534-1.396 1.404-2.207 2.496-2.743.398-.2.832-.3 1.28-.3l1.092.013c-3.601 1.372-4.433 8.741-4.16 9.527-.012-.025-.025-.037-.025-.05l-1.105.549Z"
        />
        <path
          fill="url(#aa)"
          d="m283.809 81.302-.211.037a1.709 1.709 0 0 1-2.012-1.346l-.224-1.085a1.716 1.716 0 0 1 1.341-2.02l.212-.038a1.708 1.708 0 0 1 2.011 1.347l.224 1.085c.174.922-.422 1.833-1.341 2.02Z"
        />
        <path
          fill="url(#ab)"
          d="M275.627 127.763s2.26-6.846-3.701-17.345l2.223-2.781s3.924 11.173 8.842 14.44c4.906 3.255 10.668 2.569 11.363 5.724l-18.727-.038Z"
        />
        <path
          fill="url(#ac)"
          d="M275.811 93.01s-4.036.35-6.743 5.15c-2.708 4.801-7.849 22.745-7.849 29.591h21.634s.422-1.871-.249-3.567l-13.387-2.593s8.059-12.008 8.146-17.196c.075-5.162-1.552-11.385-1.552-11.385Z"
        />
        <path
          fill="#FFE5C2"
          d="M282.99 125.556s4.21-.411 4.918-.224c.695.187 2.695 1.022 3.142 1.309.447.287 1.304 1.122 1.304 1.122s-.534.1-.857.025c-.323-.075-1.267-.137-1.528-.162-.248-.025-1.167.162-1.167.162h-6.532l.72-2.232Z"
        />
        <path
          fill="url(#ad)"
          d="M321.152 127.801h-20.777c0-.923.745-1.659 1.652-1.659h19.125v1.659Z"
        />
        <path
          fill="url(#ae)"
          d="m353.206 105.081-7.7 22.72H313.18l7.911-22.72h32.115Z"
        />
        <path
          fill="url(#af)"
          d="m353.207 105.081-7.626 22.508-.074.212h-31.855l7.911-22.72h31.644Z"
        />
        <path
          fill="url(#ag)"
          d="M336.366 116.229c-.198.873-1.068 1.571-1.925 1.571-.869 0-1.403-.698-1.204-1.571.198-.873 1.068-1.571 1.925-1.571s1.403.711 1.204 1.571Z"
        />
        <path
          fill="url(#ah)"
          d="m353.207 105.081-7.7 22.72h-14.679l17.958-22.72h4.421Z"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={127.351}
          x2={143.951}
          y1={101.222}
          y2={116.243}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={238.964}
          x2={238.964}
          y1={112.932}
          y2={127.748}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={236.8}
          x2={236.8}
          y1={118.443}
          y2={127.35}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={225.979}
          x2={225.979}
          y1={126.052}
          y2={127.818}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={203.62}
          x2={203.62}
          y1={100.498}
          y2={121.231}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7298DB" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={204.143}
          x2={204.143}
          y1={104.641}
          y2={123.404}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={214.788}
          x2={214.788}
          y1={121.524}
          y2={127.513}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={234.533}
          x2={234.533}
          y1={90.765}
          y2={118.091}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7298DB" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={233.941}
          x2={233.941}
          y1={96.214}
          y2={120.934}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={219.81}
          x2={219.81}
          y1={118.461}
          y2={127.512}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={126.484}
          x2={126.575}
          y1={94.212}
          y2={97.32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={148.345}
          x2={148.345}
          y1={94.164}
          y2={128}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={146.896}
          x2={156.629}
          y1={97.529}
          y2={133.279}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={169.982}
          x2={176.468}
          y1={97.828}
          y2={131.058}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={162.509}
          x2={162.509}
          y1={68.038}
          y2={87.029}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={148.449}
          x2={152.633}
          y1={115.387}
          y2={129.929}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={127.361}
          x2={136.655}
          y1={100.471}
          y2={132.436}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={180.084}
          x2={180.699}
          y1={117.959}
          y2={126.877}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="t"
          x1={156.522}
          x2={157.942}
          y1={81.51}
          y2={86.118}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="u"
          x1={184.713}
          x2={184.713}
          y1={126.154}
          y2={127.813}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7298DB" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="v"
          x1={207.142}
          x2={207.142}
          y1={104.906}
          y2={127.626}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7298DB" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="w"
          x1={185.531}
          x2={189.048}
          y1={108.568}
          y2={132.462}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="x"
          x1={208.75}
          x2={208.75}
          y1={114.483}
          y2={117.625}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="y"
          x1={203.652}
          x2={209.587}
          y1={108.545}
          y2={131.431}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="z"
          x1={42.53}
          x2={42.53}
          y1={65.345}
          y2={91.065}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="A"
          x1={46.122}
          x2={48.933}
          y1={76.904}
          y2={85.866}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="B"
          x1={21.314}
          x2={26.919}
          y1={97.708}
          y2={135.087}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="C"
          x1={51.105}
          x2={53.697}
          y1={112.308}
          y2={128.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="D"
          x1={46.37}
          x2={46.626}
          y1={85.955}
          y2={87.23}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="E"
          x1={42.899}
          x2={42.899}
          y1={68.303}
          y2={78.851}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="F"
          x1={48.439}
          x2={48.439}
          y1={69.004}
          y2={75.909}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="G"
          x1={45.432}
          x2={45.432}
          y1={68.343}
          y2={80.177}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="H"
          x1={38.503}
          x2={38.503}
          y1={65.323}
          y2={87.343}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="I"
          x1={35.575}
          x2={38.387}
          y1={76.243}
          y2={85.205}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="J"
          x1={37.391}
          x2={41.016}
          y1={66.795}
          y2={77.032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="K"
          x1={37.222}
          x2={38.614}
          y1={77.914}
          y2={82.364}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="L"
          x1={33.27}
          x2={38.797}
          y1={93.624}
          y2={109.975}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="M"
          x1={27.399}
          x2={32.739}
          y1={109.499}
          y2={131.437}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="N"
          x1={46.42}
          x2={55.096}
          y1={93.635}
          y2={99.984}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="O"
          x1={64.525}
          x2={64.562}
          y1={126.408}
          y2={128.19}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="P"
          x1={76.429}
          x2={79.947}
          y1={108.568}
          y2={132.462}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="Q"
          x1={76.924}
          x2={80.481}
          y1={108.568}
          y2={132.45}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="R"
          x1={99.923}
          x2={99.923}
          y1={114.682}
          y2={117.825}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="S"
          x1={95.033}
          x2={101.072}
          y1={108.568}
          y2={131.381}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="T"
          x1={24.212}
          x2={34.333}
          y1={97.333}
          y2={127.49}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="U"
          x1={290.05}
          x2={292.822}
          y1={75.833}
          y2={84.816}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="V"
          x1={293.489}
          x2={304.602}
          y1={98.373}
          y2={131.979}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="W"
          x1={294.395}
          x2={297.994}
          y1={111.911}
          y2={131.353}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="X"
          x1={269.804}
          x2={280.522}
          y1={97.673}
          y2={132.618}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="Y"
          x1={279.468}
          x2={282.24}
          y1={75.834}
          y2={84.816}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="Z"
          x1={280.981}
          x2={285.11}
          y1={67.083}
          y2={75.952}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="aa"
          x1={281.148}
          x2={282.523}
          y1={77.511}
          y2={81.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ab"
          x1={270.877}
          x2={275.675}
          y1={110.712}
          y2={131.269}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ac"
          x1={260.201}
          x2={273.408}
          y1={98.308}
          y2={130.184}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ad"
          x1={299.404}
          x2={299.441}
          y1={126.395}
          y2={128.178}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ae"
          x1={311.309}
          x2={314.831}
          y1={108.545}
          y2={132.451}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="af"
          x1={311.803}
          x2={315.366}
          y1={108.545}
          y2={132.439}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="ag"
          x1={334.801}
          x2={334.801}
          y1={114.658}
          y2={117.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="ah"
          x1={329.782}
          x2={335.801}
          y1={108.545}
          y2={131.384}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h360v128H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HelpCenterHeader;
