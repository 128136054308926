import classes from './ResponsiveGlobalSearch.component.module.scss';

import React from 'react';
import IconButton from '@lobox/uikit/Button/IconButton';
import useMedia from '@lobox/uikit/utils/useMedia';
import ResponsiveGlobalSearchDesktop from './ResponsiveGlobalSearch.desktop';
import ResponsiveGlobalSearchMobile from './ResponsiveGlobalSearch.mobile';
import { useAuthState } from '@lobox/utils';

interface Props {
  iconViewClassName?: string;
  defaultView?: 'icon' | 'input' | 'text';
  isFlexOne?: boolean;
}

const ResponsiveGlobalSearch: React.FC<Props> = ({
  iconViewClassName,
  defaultView,
  isFlexOne,
}) => {
  const isLoggedIn = useAuthState('isLoggedIn');
  const { isMoreThanTablet } = useMedia();
  if (!isLoggedIn) return null;

  if (typeof isMoreThanTablet === 'undefined') {
    return null;
  }

  return isMoreThanTablet ? (
    <ResponsiveGlobalSearchDesktop />
  ) : (
    <ResponsiveGlobalSearchMobile
      iconViewClassName={iconViewClassName}
      defaultView={defaultView}
      isFlexOne={isFlexOne}
    />
  );
};

export default ResponsiveGlobalSearch;
