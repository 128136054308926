import { getExternalUrls } from '@shared/utils/getUrlsFromText';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

export function useExtractLinks(debounceInterval: number = 100) {
  const [links, setLinks] = useState<string[]>([]);
  const hasLinkToPreview = links?.length > 0;

  const checkText = useCallback(
    debounce((text: string) => {
      if (!text) return setLinks([]);
      const _links: string[] = getExternalUrls(text);
      //_links are different from the current state
      if (
        links?.length !== _links?.length ||
        links?.some((link, i) => link !== _links[i])
      )
        setLinks(_links);
    }, debounceInterval),
    [links]
  );

  return { links, checkText, hasLinkToPreview };
}
