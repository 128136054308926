import React from 'react';
import IconButton from '../Button/IconButton';
import useTheme from '../utils/useTheme';

export interface CarouselNextProps {
  className?: string;
  onClick?: (...args: any[]) => any;
  iconClassName?: string;
}

const CarouselNext: React.FC<CarouselNextProps> = ({
  className,
  onClick,
  iconClassName,
}) => {
  const { isRtl } = useTheme();

  return (
    <IconButton
      className={className}
      name={isRtl ? 'chevron-left' : 'chevron-right'}
      type="fas"
      size="md15"
      colorSchema="secondary"
      onClick={onClick}
      iconClassName={iconClassName}
    />
  );
};

export default CarouselNext;
