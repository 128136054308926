import classes from './ResponsiveGlobalSearch.mobile.module.scss';

import React, { useEffect, useRef } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import SearchInputV2 from '@lobox/uikit/SearchInputV2';
import Tooltip from '@lobox/uikit/Tooltip';
import {
  routeNames,
  useDisclosure,
  useGlobalDispatch,
  useGlobalState,
  useHistory,
  useTranslation,
} from '@lobox/utils';
import { ModalDialog } from '@shared/components/molecules';
import GlobalSearchInput from '@shared/components/molecules/GlobalSearchInput';
import SearchHeaderTitle from '@shared/components/molecules/SearchHeaderTitle';
import SearchFilterIcon from '@shared/components/molecules/SearchFilterIcon';
import { useGlobalSearchUtilities } from '@shared/components/molecules/GlobalSearchInput';
import { searchFilterQueryParams } from '@shared/constants/search';
import useSearchFilters from '@shared/hooks/useSearchFilters';
import { useRouter } from 'next/navigation';
import { persistantSearchQuery } from '../GlobalSearchInput/PersistantSearchQuery';
import useClientRouter from '@shared/utils-pkg/hooks/useClientRouter';

interface Props {
  defaultView?: 'icon' | 'text' | 'input';
  iconViewClassName?: string;
  isFlexOne?: boolean;
}

const ResponsiveGlobalSearch: React.FC<Props> = ({
  iconViewClassName,
  defaultView = 'icon',
  isFlexOne = true,
}) => {
  const { moduleSpecificSearchResultPage, isSearchAllPage, searchForText } =
    useGlobalSearchUtilities();
  const router = useRouter();
  const globalDispatch = useGlobalDispatch();
  const isOpenProfilePanel = useGlobalState('isOpenProfilePanel');
  const searchHistoryCount = useGlobalState('searchHistoryCount');
  const isSearchSubPage = useGlobalState('isSearchSubPage');
  const isSearchStartedFromMain = useGlobalState('isSearchStartedFromMain');
  const { searchFilters } = useSearchFilters();
  const { isLoading: isLoadingRouter } = useClientRouter({});

  const inputValue = isLoadingRouter
    ? persistantSearchQuery.query
    : searchFilters[searchFilterQueryParams.query];

  useEffect(() => {
    if (isLoadingRouter) return;
    persistantSearchQuery.query = inputValue;
  }, [inputValue]);

  const isJobs = moduleSpecificSearchResultPage?.module === 'jobs';
  const { t } = useTranslation();
  const inputRef = useRef<
    HTMLInputElement & { simulatePressEnter: Function }
  >();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isHashtag = inputValue?.startsWith('#');

  useEffect(() => {
    router.prefetch(routeNames.searchAll);
  }, []);

  const onBackHandler = () => {
    if (isSearchSubPage && isSearchStartedFromMain) {
      window.history.go(searchHistoryCount * -1 + 1);
      globalDispatch({ type: 'RESET_SEARCH_SUBPAGE' });
      globalDispatch({ type: 'SET_SEARCH_HISTORY_COUNT', payload: 1 });
      return;
    }
    if (searchHistoryCount > 0) {
      window.history.go(searchHistoryCount * -1);
      globalDispatch({ type: 'RESET_SEARCH_HISTORY_COUNT' });
      globalDispatch({ type: 'RESET_SEARCH_STARTED_FROM_MAIN' });
      return;
    }
    window.history.back();
  };

  const onClearSearch = () => {
    searchForText('');
    persistantSearchQuery.query = '';
  };

  const onCloseHandler = () => {
    if (isOpenProfilePanel) {
      globalDispatch({
        type: 'SET_SHOW_PROFILE_PANEL',
        payload: false,
      });
    }
    onClose();
  };

  const views = {
    icon: (
      <Tooltip
        triggerWrapperClassName={cnj(classes.searchButton, iconViewClassName)}
        trigger={
          <IconButton
            onClick={onOpen}
            type="far"
            size="md20"
            colorSchema="secondary2"
            name="search-light"
          />
        }
      >
        {t('search')}
      </Tooltip>
    ),
    text: (
      <Flex
        className={cnj(classes.globalSearchInputRoot, isJobs && classes.isJobs)}
      >
        <IconButton
          onClick={onBackHandler}
          type="far"
          size="md20"
          name="chevron-left"
        />
        <SearchHeaderTitle onClick={onOpen} className={classes.centerItem} />
        <SearchFilterIcon
          className={classes.searchFilterIcon}
          variant="rectangle"
        />
      </Flex>
    ),
    input: (
      <Flex
        className={cnj(classes.globalSearchInputRoot, isJobs && classes.isJobs)}
      >
        <IconButton
          onClick={onBackHandler}
          type="far"
          size="md20"
          name="chevron-left"
          className={classes.backButtonMarginRight}
        />
        <SearchInputV2
          key={inputValue}
          onFocus={onOpen}
          ref={inputRef}
          placeholder={t('search')}
          value={inputValue}
          inputStyle={cnj(
            classes.inputStyle,
            isHashtag && classes.hashtagInput
          )}
          className={classes.centerItem}
          trashIconProps={{
            color: 'thirdText',
          }}
          onClearSearch={onClearSearch}
        />
        {!isSearchAllPage && (
          <SearchFilterIcon
            className={classes.searchFilterIcon}
            variant="rectangle"
          />
        )}
      </Flex>
    ),
  } as const;

  return (
    <>
      <Flex style={isFlexOne ? { flex: 1 } : undefined} />
      {!isOpen ? (
        views[defaultView]
      ) : (
        <ModalDialog
          onClose={onCloseHandler}
          onBack={onCloseHandler}
          isOpen={isOpen}
        >
          <Flex
            className={cnj(
              cnj(classes.globalSearchInputRoot, isJobs && classes.isJobs),
              classes.padding
            )}
          >
            {!isJobs && (
              <IconButton
                onClick={onCloseHandler}
                className={classes.backButtonMarginRight}
                type="far"
                size="md20"
                name="chevron-left"
              />
            )}
            <GlobalSearchInput onClose={onCloseHandler} inputRef={inputRef} />
            {isJobs && (
              <IconButton
                onClick={() => {
                  inputRef.current?.simulatePressEnter();
                }}
                type="far"
                size="md20"
                className={classes.checkButton}
                name="check"
              />
            )}
          </Flex>
        </ModalDialog>
      )}
    </>
  );
};

export default ResponsiveGlobalSearch;
