import toNumber from 'lodash/toNumber';
import min from 'lodash/min';
import { MEDIA_BOX_SIZE } from './consts';

export const getSizeFromSrc = (src: string) => {
  const url = new URL(src);
  const { searchParams } = url;

  return {
    width: toNumber(searchParams.get('bw')) || 0,
    height: toNumber(searchParams.get('bh')) || 0,
  };
};

export const calculateMediaSizeBasedOnWidth = (
  src: string,
  containerWidth = MEDIA_BOX_SIZE.WIDTH
) => {
  const { width, height } = getSizeFromSrc(src);

  if (width && height && width > containerWidth) {
    return {
      height: (height * containerWidth) / width,
      width: containerWidth,
    };
  }
  return { width, height };
};

export const calculateMediaSizeBasedOnHeight = (
  src: string,
  boxMaxHeight = MEDIA_BOX_SIZE.HEIGHT
) => {
  const { width, height } = getSizeFromSrc(src);

  if (width && height && height > boxMaxHeight) {
    return {
      width: (width * boxMaxHeight) / height,
      height: boxMaxHeight,
    };
  }
  return { width, height };
};

export const calculateMediaSizeBasedOnBox = (
  src: string,
  boxMaxWidth = MEDIA_BOX_SIZE.WIDTH,
  boxMaxHeight = MEDIA_BOX_SIZE.HEIGHT
) => {
  let { width, height } = calculateMediaSizeBasedOnWidth(src, boxMaxWidth);

  if (width && height && height > boxMaxHeight) {
    width = (width * boxMaxHeight) / height;
    height = boxMaxHeight;
  }

  return { width, height };
};

export const calculateMediaSizeBasedOnBoxAndRatio = (
  src: string,
  containerWidth: number,
  isTabletAndLess: boolean,
  boxMaxWidth = MEDIA_BOX_SIZE.WIDTH,
  boxMaxHeight = MEDIA_BOX_SIZE.HEIGHT
) => {
  let { width, height } = getSizeFromSrc(src);

  const url = new URL(src);
  const { searchParams } = url;
  const ratio = searchParams.get('r');
  const applyRatio = isTabletAndLess && ratio === '4:5';

  const boxWithBasedOnRatio = applyRatio
    ? min([containerWidth, boxMaxWidth]) || 0
    : containerWidth;

  if (width && height && width > boxWithBasedOnRatio) {
    height = (height * boxWithBasedOnRatio) / width;
    width = boxWithBasedOnRatio;
  }
  if (width && height && height > boxMaxHeight) {
    width = (width * boxMaxHeight) / height;
    if (ratio === '0:0' && isTabletAndLess) {
      width = containerWidth;
    }
    height = boxMaxHeight;
  }

  return { width, height };
};
