import classes from './highlights.business.module.scss';

import React from 'react';
import {
  Endpoints,
  geocodeResponseNormalizer,
  hereApiResponseNormalizer,
  geoEndpoints,
  lookupResponseNormalizer,
  pageApi,
  useBusinessPage,
  useTranslation,
  searchPerson,
  geoApi,
} from '@lobox/utils';
import omit from 'lodash/omit';
import AvatarCard from '@lobox/uikit/AvatarCard';
import cnj from '@lobox/uikit/utils/cnj';
import EvolvementHome from '@shared/svg/Evolvement';
import CelebrationHome from '@shared/svg/Celebration';
import ChangeHome from '@shared/svg/Change';
import ExpensionHome from '@shared/svg/Expension';
import Highlight from '@shared/svg/Highlight';
import MarketValue from '@shared/svg/MarketValue';
import RecommendationWrite from '@shared/svg/RecommendationWrite';
import GetPromoted from '@shared/svg/GetPromoted';
import GroupIcon from '@shared/svg/GroupIcon';
import Award from '@shared/svg/Award';
import Fund from '@shared/svg/Fund';
import Conference from '@shared/svg/Conference';
import HighlightCard from '@shared/components/molecules/HighlightCard';
import HighlightImage from '@shared/svg/Highlights';
import HIGHLIGHT_TYPES from './highlightTypes';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import Typography from '@lobox/uikit/Typography';

const useHighlights = (): any => {
  const { t } = useTranslation();
  const { data: businessPage, refetch } = useBusinessPage({
    isEnabled: false,
  });
  const { authUser } = useGetAppObject();

  const DATE_FIELD = {
    name: 'date',
    cp: 'datePicker',
    wrapStyle: 'responsive-margin-top',
    variant: 'input',
    picker: 'month',
    required: true,
    label: t('date'),
  };

  const highlights: any = {
    list: {
      id: 'list',
      title: t('create_highlight'),
      image: () => (
        <HighlightImage className={classes.highlightImage} type="SIMPLE" />
      ),
      items: [
        {
          id: 'expension',
          title: t('expension'),
          image: () => <ExpensionHome />,
        },
        {
          id: 'change',
          title: t('change'),
          image: () => <ChangeHome />,
        },
        {
          id: 'evolvement',
          title: t('evolvement'),
          image: () => <EvolvementHome />,
        },
        {
          id: 'celebration',
          title: t('celebration'),
          image: () => <CelebrationHome />,
        },
      ],
    },
    expension: {
      id: 'expension',
      title: t('expension'),
      image: () => (
        <HighlightCard
          className={classes.highlightCard}
          type="BUSSINES_GET_FUNDED"
        />
      ),
      items: [
        {
          parentId: 'expension',
          id: 'getFunded',
          title: t('get_funded'),
          subTitle: t('get_funded_h_desc'),
          image: () => <Fund />,
          form: {
            title: t('get_funded'),
            logo: HIGHLIGHT_TYPES.GET_FUNDED,
            highlightTransform: ({ amount, date, institution, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.GET_FUNDED,
                  institutionName: institution?.label,
                  institutionPageId: institution?.value,
                  subTitle: amount,
                  startDate: date,
                },
                ['date']
              ),
            groups: [
              {
                name: 'title',
                cp: 'input',
                label: t('title'),
                type: 'text',
                maxLength: 100,
                required: true,
                helperText: t('exmp_ang_f_sec'),
              },
              {
                name: 'institution',
                cp: 'asyncAutoComplete',
                label: t('provider'),
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                required: true,
                maxLength: 100,
                normalizer: hereApiResponseNormalizer,
                url: `${Endpoints.App.Common.suggestPlace}`,
                wrapStyle: 'responsive-margin-top',
                renderItem: ({ item }: any) => (
                  <AvatarCard
                    data={{
                      title: item.label,
                      image: item.image,
                    }}
                    avatarProps={{ isCompany: true, size: 'sm' }}
                  />
                ),
              },
              {
                name: 'currency',
                cp: 'asyncAutoComplete',
                label: t('currency'),
                wrapStyle: cnj(
                  classes.leftItem,
                  classes.halfWidth,
                  classes.formItem
                ),
                type: 'text',
                required: true,
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                url: Endpoints.App.Common.searchCurrency,
                normalizer: (data: any) =>
                  data?.map(({ id: value, name, symbol, code }: any) => ({
                    label: `${name} (${symbol})`,
                    name,
                    symbol,
                    value,
                    code,
                  })),
              },
              {
                name: 'amount',
                cp: 'input',
                label: t('amount'),
                wrapStyle: cnj(
                  classes.rightItem,
                  classes.halfWidth,
                  classes.formItem
                ),
                type: 'number',
                maxLength: 100,
                required: true,
              },
              {
                ...DATE_FIELD,
                label: t('date'),
              },
            ],
          },
        },
        {
          parentId: 'expension',
          id: 'getAcquired',
          title: t('get_aquired'),
          subTitle: t('get_aquired_desc'),
          image: () => <Award />,
          form: {
            title: t('get_aquired'),
            logo: HIGHLIGHT_TYPES.ACQUIRED,
            highlightTransform: ({ name, date, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.ACQUIRED,
                  title: name,
                  startDate: date,
                },
                ['name', 'date']
              ),
            groups: [
              {
                name: 'name',
                cp: 'input',
                label: t('aquired_name'),
                type: 'text',
                maxLength: 100,
                required: true,
              },
              DATE_FIELD,
            ],
          },
        },
      ],
    },
    change: {
      id: 'change',
      title: t('change'),
      image: () => (
        <HighlightCard className={classes.highlightCard} type="NEW_LOCATION" />
      ),
      items: [
        {
          parentId: 'change',
          id: 'newLocation',
          title: t('new_location'),
          subTitle: t('new_location'),
          image: () => <GetPromoted />,
          form: {
            title: t('new_location'),
            logo: HIGHLIGHT_TYPES.NEW_LOCATION,
            highlightTransform: ({ at, date, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.NEW_LOCATION,
                  title: at?.label,
                  startDate: date,
                },
                ['at', 'date']
              ),
            postOnSuccess: async ({ at: { position } }: any) => {
              const id = businessPage?.id;
              const newLocation = {
                lat: position?.lat,
                lon: position?.lon,
              };
              pageApi.updatePageLocations({
                id,
                page: {
                  ...businessPage,
                  locations: [...(businessPage?.locations || []), newLocation],
                },
              });
            },
            groups: [
              {
                name: 'at',
                cp: 'asyncAutoComplete',
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                label: t('at_where'),
                apiFunc: geoApi.suggestCity,
                required: true,
              },
              {
                ...DATE_FIELD,
                required: false,
              },
            ],
          },
        },
        {
          parentId: 'change',
          id: 'changedName',
          title: t('changed_name'),
          subTitle: t('changed_name_desc'),
          image: () => <RecommendationWrite />,
          postOnSuccess: async ({ title }: any) => {
            const id = businessPage?.id;
            try {
              await pageApi.updatePageTitle({
                id,
                title,
              });
              window.location.reload();
            } catch (error) {
              console.log(error);
            }
          },
          form: {
            title: t('changed_name'),
            logo: HIGHLIGHT_TYPES.CHANGED_NAME,
            highlightTransform: ({
              currentName,
              previousName,
              date,
              ...rest
            }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.CHANGED_NAME,
                  title: currentName,
                  subTitle: previousName,
                  lineThrough: true,
                  startDate: date,
                },
                ['date']
              ),

            groups: [
              {
                name: 'previousName',
                cp: 'input',
                label: t('old_name_of_pg'),
                type: 'text',
                maxLength: 100,
                required: true,
              },
              {
                name: 'currentName',
                cp: 'input',
                label: t('changed_name_input'),
                type: 'text',
                maxLength: 100,
                required: true,
                wrapStyle: 'responsive-margin-top',
              },
              DATE_FIELD,
            ],
          },
        },
      ],
    },
    evolvement: {
      id: 'evolvement',
      title: t('evolvement'),
      image: () => (
        <HighlightCard type="MARKET_VALUE" className={classes.highlightCard} />
      ),
      items: [
        {
          parentId: 'evolvement',
          id: 'marketValue',
          title: t('market_value'),
          subTitle: t('market_value_desc'),
          image: () => <MarketValue />,
          form: {
            title: t('market_value'),
            logo: HIGHLIGHT_TYPES.MARKET_VALUE,
            highlightTransform: ({
              currentValue,
              previousValue,
              date,
              ...rest
            }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.MARKET_VALUE,
                  title: currentValue,
                  subTitle: previousValue,
                  startDate: date,
                },
                ['date']
              ),
            groups: [
              {
                name: 'currency',
                cp: 'asyncAutoComplete',
                label: t('currency'),
                wrapStyle: classes.formItem,
                type: 'text',
                required: true,
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                url: Endpoints.App.Common.searchCurrency,
                normalizer: (data: any) =>
                  data?.map(({ id: value, name, symbol, code }: any) => ({
                    label: `${name} (${symbol})`,
                    name,
                    symbol,
                    value,
                    code,
                  })),
              },
              {
                name: 'currentValue',
                cp: 'input',
                label: t('current_value'),
                type: 'text',
                maxLength: 100,
                wrapStyle: cnj(
                  classes.halfWidth,
                  classes.leftItem,
                  classes.formItem
                ),
                required: true,
              },
              {
                name: 'previousValue',
                cp: 'input',
                label: t('previous_value'),
                type: 'text',
                maxLength: 100,
                wrapStyle: cnj(
                  classes.halfWidth,
                  classes.rightItem,
                  classes.formItem
                ),
                required: true,
              },
              DATE_FIELD,
            ],
          },
        },
        {
          parentId: 'evolvement',
          id: 'stockValue',
          title: t('stock_value_change'),
          subTitle: t('stock_value_change_desc'),
          image: () => <MarketValue />,
          form: {
            title: t('stock_value_change'),
            logo: HIGHLIGHT_TYPES.STOCK_VALUE_CHANGE,
            highlightTransform: ({
              currentValue,
              previousValue,
              date,
              ...rest
            }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.STOCK_VALUE_CHANGE,
                  title: currentValue,
                  subTitle: previousValue,
                  startDate: date,
                },
                ['date']
              ),
            groups: [
              {
                name: 'currency',
                cp: 'asyncAutoComplete',
                label: t('currency'),
                wrapStyle: classes.formItem,
                type: 'text',
                required: true,
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                url: Endpoints.App.Common.searchCurrency,
                normalizer: (data: any) =>
                  data?.map(({ id: value, name, symbol, code }: any) => ({
                    label: `${name} (${symbol})`,
                    name,
                    symbol,
                    value,
                    code,
                  })),
              },
              {
                name: 'currentValue',
                cp: 'input',
                label: t('current_stock_value'),
                type: 'text',
                maxLength: 100,
                wrapStyle: cnj(
                  classes.halfWidth,
                  classes.leftItem,
                  classes.formItem
                ),
                required: true,
              },
              {
                name: 'previousValue',
                cp: 'input',
                label: t('previous_stock_value'),
                type: 'text',
                maxLength: 100,
                wrapStyle: cnj(
                  classes.halfWidth,
                  classes.rightItem,
                  classes.formItem
                ),
                required: true,
              },
              DATE_FIELD,
            ],
          },
        },
        {
          parentId: 'evolvement',
          id: 'getInvested',
          title: t('get_invested'),
          subTitle: t('get_invested_desc'),
          image: () => <Fund />,
          form: {
            title: t('get_invested'),
            logo: HIGHLIGHT_TYPES.GET_INVESTED,
            highlightTransform: ({ company, amount, date, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.GET_INVESTED,
                  title: company?.label,
                  subTitle: amount,
                  startDate: date,
                },
                ['company', 'amount', 'date']
              ),
            groups: [
              {
                name: 'company',
                cp: 'asyncAutoComplete',
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                label: t('investod_name_input'),
                url: `${Endpoints.App.Common.suggestPlace}`,
                normalizer: hereApiResponseNormalizer,
                required: true,
                maxLength: 100,
                renderItem: ({ item }: any) => (
                  <AvatarCard
                    data={{
                      title: item.label,
                      image: item.image,
                    }}
                    avatarProps={{ isCompany: true, size: 'sm' }}
                  />
                ),
              },
              {
                name: 'currency',
                cp: 'asyncAutoComplete',
                label: t('currency'),
                wrapStyle: cnj(
                  classes.leftItem,
                  classes.halfWidth,
                  classes.formItem
                ),
                type: 'text',
                required: true,
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                url: Endpoints.App.Common.searchCurrency,
                normalizer: (data: any) =>
                  data?.map(({ id: value, name, symbol, code }: any) => ({
                    label: `${name} (${symbol})`,
                    name,
                    symbol,
                    value,
                    code,
                  })),
              },
              {
                name: 'amount',
                cp: 'input',
                label: t('amount'),
                type: 'text',
                required: true,
                maxLength: 100,
                wrapStyle: cnj(
                  classes.halfWidth,
                  classes.rightItem,
                  classes.formItem
                ),
              },
              DATE_FIELD,
            ],
          },
        },
        {
          parentId: 'evolvement',
          id: 'goingPublic',
          title: t('going_public'),
          subTitle: t('going_public_desc'),
          image: () => <Conference />,
          form: {
            title: t('going_public'),
            logo: HIGHLIGHT_TYPES.IPO,
            highlightTransform: ({ initialValue, date, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.IPO,
                  title: initialValue,
                  startDate: date,
                },
                ['initialValue', 'date']
              ),
            groups: [
              {
                name: 'currency',
                cp: 'asyncAutoComplete',
                label: t('currency'),
                type: 'text',
                required: true,
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                url: Endpoints.App.Common.searchCurrency,
                normalizer: (data: any) =>
                  data?.map(({ id: value, name, symbol, code }: any) => ({
                    label: `${name} (${symbol})`,
                    name,
                    symbol,
                    value,
                    code,
                  })),
              },
              {
                name: 'initialValue',
                cp: 'input',
                label: t('initial_stock_value'),
                type: 'text',
                maxLength: 100,
                required: true,
                wrapStyle: 'responsive-margin-top',
              },
              DATE_FIELD,
            ],
          },
        },
      ],
    },
    celebration: {
      id: 'celebration',
      title: t('celebration'),
      image: () => (
        <HighlightCard type="ANNIVERSARY" className={classes.highlightCard} />
      ),
      items: [
        {
          parentId: 'celebration',
          id: 'anniversary',
          title: t('anniversary'),
          subTitle: t('anniversary_desc'),
          image: () => <Highlight />,
          form: {
            title: t('anniversary'),
            logo: HIGHLIGHT_TYPES.ANNIVERSARY,
            highlightTransform: ({ date, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.ANNIVERSARY,
                  startDate: date,
                },
                ['date']
              ),
            groups: [
              {
                name: 'title',
                helperText: t('expm_mrg_annivers'),
                cp: 'input',
                label: t('title'),
                type: 'text',
                maxLength: 100,
                required: true,
              },
              DATE_FIELD,
            ],
          },
        },
        {
          parentId: 'celebration',
          id: 'newTeamMember',
          title: t('new_team_member'),
          subTitle: t('new_team_member_desc'),
          image: () => <GroupIcon />,
          form: {
            title: t('new_team_member'),
            logo: HIGHLIGHT_TYPES.NEW_TEAM_MEMBER,
            highlightTransform: ({ position, date, ...rest }: any) =>
              omit(
                {
                  ...rest,
                  type: HIGHLIGHT_TYPES.NEW_TEAM_MEMBER,
                  title: position?.label,
                  startDate: date,
                },
                ['date', 'position']
              ),
            groups: [
              {
                name: 'position',
                cp: 'asyncAutoComplete',
                visibleRightIcon: true,
                rightIconProps: { name: 'search' },
                label: t('new_member_position'),
                url: Endpoints.App.Common.getOccupations,
                normalizer: lookupResponseNormalizer,
                required: true,
                maxLength: 100,
              },
              {
                name: 'member',
                label: t('team_member'),
                cp: 'asyncAutoComplete',
                apiFunc: searchPerson,
                maxLength: 100,
                wrapStyle: 'responsive-margin-top',
                required: true,
                normalizer: (data) =>
                  data?.content
                    ?.map((item) => ({
                      value: item.id,
                      label: item.fullName,
                      image: item.croppedImageUrl,
                    }))
                    ?.filter((item) => item?.value !== authUser?.id),
                visibleRightIcon: true,
                renderItem: ({ item }: any) => (
                  <AvatarCard
                    data={{
                      title: item.label,
                      image: item.image,
                    }}
                    avatarProps={{ size: 'sm' }}
                  />
                ),
              },
              DATE_FIELD,
            ],
          },
        },
      ],
    },
  };
  return { highlights };
};

export default useHighlights;
