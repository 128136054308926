import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Flex from '@lobox/uikit/Flex';
import RadioButton from '@lobox/uikit/RadioButton';
import Tag from '@lobox/uikit/Tag';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import { PAGE_ROLES, redirectUrl, useTranslation } from '@lobox/utils';
import openBusinessApp from '@shared/utils/openBusiness';
import urls from '@shared/constants/urls';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import useGetMyPages from '@shared/hooks/api-hook/useGetMyPages';
import { getPageRoleIndex } from '@shared/utils/getPageRoleIndex';
import isFunction from 'lodash/isFunction';
import type { DataReturnType } from '@shared/hooks/api-hook/useGetMyPages';
import classes from './index.module.scss';
import { pageStatus } from '@shared/constants/enums';

interface LeftPanelProps {
  narrow?: boolean;
  autoWide?: boolean;
  wide?: boolean;
  isDrawer?: boolean;
  handleClose?: Function;
}

interface MyPagesProps {
  handleClose?: Function;
}

const MyPages: React.FC<LeftPanelProps> = ({ handleClose }: MyPagesProps) => {
  const { t } = useTranslation();
  const { data: myPages }: any = useGetMyPages();
  const { businessPage, authUser, isBusinessApp, checkIsAuthUser } =
    useGetAppObject();

  if (!myPages || !myPages?.length) {
    return null;
  }

  const pages = [
    {
      isPerson: true,
      page: {
        username: authUser.username,
        title: authUser.fullName,
        croppedImageUrl: authUser.croppedImageUrl,
      },
      isUserApp: true,
      id: authUser?.id,
    },
    ...myPages.reduce((prev, curr: DataReturnType) => {
      return curr?.page?.status === pageStatus.PUBLISHED
        ? [
            {
              ...curr,
              pageRoleIndex: curr.roles.reduce(
                (prev, curr) => prev + getPageRoleIndex(curr),
                0
              ),
              roles: curr.roles.map((role) => t(PAGE_ROLES[role].label)),
            },
            ...prev,
          ]
        : prev;
    }, []),
  ]?.sort((a, b) => (a.pageRoleIndex < b.pageRoleIndex ? -1 : 1));

  const onClick = (id: string, page: DataReturnType['page']) => {
    if (id === authUser?.id) {
      redirectUrl(`${urls.base}/${page.username}`);
    } else {
      openBusinessApp(page.username);
      if (isFunction(handleClose)) {
        handleClose();
      }
    }
  };

  return (
    <Flex className={classes.myPagesRoot}>
      <Typography font="700" size={16} height={20}>
        {t('profiles')}
      </Typography>

      {pages.map(({ roles, page, id, isPerson }) => {
        const isActive =
          page?.username === businessPage?.username ||
          (page?.username === authUser?.username && !isBusinessApp);

        return (
          <BaseButton
            key={id}
            className={classes.row}
            onClick={() => onClick(id, page)}
          >
            <Tooltip
              placement="top"
              triggerWrapperClassName={classes.containerProps}
              trigger={
                <AvatarCard
                  noHover
                  infoWrapClassName={classes.infoWrapModified}
                  data={{
                    title: page.title,
                    subTitle: `@${page.username}`,
                    image: page.croppedImageUrl,
                  }}
                  key={id}
                  titleProps={{
                    size: 16,
                    font: '700',
                    height: 20,
                    isTruncated: true,
                    className: classes.truncatedText,
                  }}
                  subTitleProps={{
                    color: 'fifthText',
                    height: 21,
                    size: 15,
                    mt: 0,
                    isTruncated: true,
                    className: classes.truncatedText,
                  }}
                  avatarProps={{
                    size: 'sm',
                    isCompany: !isPerson,
                  }}
                  containerProps={{
                    className: classes.containerProps,
                  }}
                  action={
                    <Tag
                      label={
                        checkIsAuthUser(page.ownerId)
                          ? t(PAGE_ROLES.OWNER.label)
                          : roles?.join(',')
                      }
                      visibleIcon={false}
                      disabledHover
                      className={classes.roleTag}
                      labelProps={{
                        noWrap: true,
                        className: classes.tagLabel,
                      }}
                      variant="badge"
                    />
                  }
                />
              }
            >
              {page?.title}
            </Tooltip>

            <RadioButton value={isActive} />
          </BaseButton>
        );
      })}
    </Flex>
  );
};

export default MyPages;
