import classes from './Main.footer.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import FilePicker from '@lobox/uikit/FilePicker';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import {
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
} from '@lobox/utils';
import {
  useCreatePostDispatch,
  useCreatePostState,
} from '../../context/createPost.provider';

interface ActionsProps {
  enableButton: boolean;
  onMapClicked: () => void;
  onUserMentionClicked: () => void;
  onPickedFile?: (file: any) => void;
  onSubmitClick: () => void;
  onHighlightClick: () => void;
  isAttachmentPost?: boolean;
  hasRichText?: boolean;
  richTextLength?: number;
  isActivePhoto: boolean;
  isActiveUser: boolean;
  isActiveHighlight: boolean;
  isActiveMap: boolean;
}

const Actions: React.FC<ActionsProps> = ({
  onMapClicked,
  onUserMentionClicked,
  enableButton,
  onSubmitClick,
  onHighlightClick,
  isAttachmentPost,
  hasRichText,
  richTextLength,
  isActivePhoto,
  isActiveMap,
  isActiveUser,
  isActiveHighlight,
}) => {
  const containerRef = React.useRef<HTMLElement>();
  const { t } = useTranslation();
  const { attachment } = useGlobalState('createPostModal');
  const isEditMode = useCreatePostState('isEditMode');
  const globalDispatch = useGlobalDispatch();
  const dispatch = useCreatePostDispatch();

  isAttachmentPost = isAttachmentPost || !!attachment;

  const handleSubmit = () => {
    onSubmitClick();
  };

  const handleFilePicker = (files: any) => {
    dispatch({ type: 'ADD_FILE', payload: [...files] });
    globalDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: true,
        closeModalOnBack: false,
        currentTab: 'media',
      },
    });
  };

  const boundariesElement = containerRef?.current
    ? containerRef.current.closest('[role="tabpanel"]')
    : undefined;

  return (
    <>
      {hasRichText && (
        <Flex className={classes.errorWrapper}>
          <Typography size={13} height={15} color="error">
            {`${t('long_post_error_1')} ${richTextLength} ${t(
              'long_post_error_2'
            )}`}
          </Typography>
        </Flex>
      )}
      <Flex ref={containerRef} className={classes.container}>
        <Flex flexDir="row" className={classes.buttons}>
          <FilePicker
            type="media"
            isMulti
            onFilePicked={handleFilePicker}
            disabled={isAttachmentPost || isEditMode}
            buttonComponent={
              <Flex className={cnj(classes.icon)}>
                <Tooltip
                  placement="top"
                  disabled={isAttachmentPost}
                  disablePortal={false}
                  modifiers={{
                    preventOverflow: {
                      enabled: true,
                      boundariesElement,
                    },
                  }}
                  trigger={
                    <BaseButton disabled={isAttachmentPost}>
                      <IconButton
                        className={cnj(
                          isActivePhoto && classes.activeCircle,
                          isAttachmentPost && classes.disableAction
                        )}
                        iconProps={{
                          color: 'error',
                        }}
                        name="photo-video"
                        type="fas"
                        disabled={isAttachmentPost}
                      />
                    </BaseButton>
                  }
                >
                  {t('post_add_m_t')}
                </Tooltip>
              </Flex>
            }
          />
          <Tooltip
            triggerWrapperClassName={classes.icon}
            placement="top"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement,
              },
            }}
            trigger={
              <BaseButton onClick={onUserMentionClicked}>
                <IconButton
                  className={cnj(isActiveUser && classes.activeUser)}
                  iconProps={{
                    color: 'darkTangerine',
                  }}
                  name="user-tag"
                  type="fas"
                />
              </BaseButton>
            }
          >
            {t('tag_person')}
          </Tooltip>
          <Tooltip
            triggerWrapperClassName={classes.icon}
            placement="top"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement,
              },
            }}
            trigger={
              <BaseButton onClick={onMapClicked}>
                <IconButton
                  className={cnj(isActiveMap && classes.activeMap)}
                  iconProps={{
                    color: 'success',
                  }}
                  name="map-marker-plus"
                  type="fas"
                />
              </BaseButton>
            }
          >
            {isActiveMap ? t('edit_location') : t('checkin')}
          </Tooltip>
          <Tooltip
            triggerWrapperClassName={classes.icon}
            placement="top"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement,
              },
            }}
            disabled={isAttachmentPost || isActiveHighlight}
            trigger={
              <BaseButton
                onClick={onHighlightClick}
                disabled={isAttachmentPost}
              >
                <IconButton
                  className={cnj(isActiveHighlight && classes.activeHighlight)}
                  iconProps={{
                    color: 'brand',
                  }}
                  name="trophy-alt"
                  type="fas"
                  disabled={isAttachmentPost}
                />
              </BaseButton>
            }
          >
            {isAttachmentPost
              ? undefined
              : isActiveHighlight
                ? t('edit_highlight')
                : t('add_highlight')}
          </Tooltip>
        </Flex>
        <Flex className={classes.submitContainer}>
          <Button
            schema="primary-blue"
            disabled={!enableButton}
            className={classes.submitBtn}
            label={t('post')}
            labelSize={15}
            onClick={handleSubmit}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default Actions;
