import useGetAppObject from './useGetAppObject';
import { pageStatus } from '../constants/enums';

const useIsPagePublished = () => {
  const { isBusinessApp, businessPage } = useGetAppObject();

  const isPagePublished =
    !isBusinessApp || businessPage?.status === pageStatus.PUBLISHED;
  return isPagePublished;
};

export default useIsPagePublished;
