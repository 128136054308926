import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const ChangeIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='highlight_change_113a'>
        <path
          fill='#5384EE'
          d='M41.823 22.176A24.34 24.34 0 0 0 24.5 15l-1.914 24.5L24.499 64a24.34 24.34 0 0 0 17.324-7.176A24.34 24.34 0 0 0 49 39.5a24.34 24.34 0 0 0-7.176-17.324Z'
        />
        <path
          fill='#83B4FF'
          d='M7.176 22.176A24.34 24.34 0 0 0 0 39.5a24.34 24.34 0 0 0 7.176 17.324A24.34 24.34 0 0 0 24.5 64V15a24.34 24.34 0 0 0-17.324 7.176Z'
        />
        <path
          fill='#3F73E3'
          d='M39.793 24.206A21.487 21.487 0 0 0 24.5 17.871L22.585 39.5l1.914 21.629c5.777 0 11.209-2.25 15.294-6.335A21.487 21.487 0 0 0 46.128 39.5c0-5.777-2.25-11.209-6.335-15.294Z'
        />
        <path
          fill='#5384EE'
          d='M9.206 24.206A21.487 21.487 0 0 0 2.871 39.5c0 5.777 2.25 11.209 6.335 15.294A21.487 21.487 0 0 0 24.5 61.129V17.87a21.487 21.487 0 0 0-15.294 6.335Z'
        />
        <path
          fill='#F1F4F9'
          d='m31.412 51.64-4.023-5.467a7.23 7.23 0 0 1-2.887.6l-1.915 3.05 1.915 3.649c2.512 0 4.87-.667 6.91-1.832Z'
        />
        <path
          fill='#fff'
          d='M17.294 40.457h3.993l-7.408-10.069L6.47 40.457h4.092c.493 7.26 6.556 13.015 13.938 13.015v-6.7c-3.685 0-6.737-2.756-7.207-6.315Z'
        />
        <path
          fill='#F1F4F9'
          d='M38.438 38.543c-.494-7.26-6.556-13.015-13.939-13.015l-1.914 3.65 1.914 3.05c3.686 0 6.738 2.756 7.208 6.315h-3.993l7.408 10.069 7.407-10.069h-4.091Z'
        />
        <path
          fill='#fff'
          d='m17.59 27.36 4.021 5.467a7.23 7.23 0 0 1 2.888-.6v-6.699c-2.512 0-4.87.667-6.91 1.832Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 15h49v49H0z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='highlight_change_113a'>
        <path
          fill='#5384EE'
          d='M41.823 22.176A24.34 24.34 0 0 0 24.5 15l-1.914 24.5L24.499 64a24.34 24.34 0 0 0 17.324-7.176A24.34 24.34 0 0 0 49 39.5a24.34 24.34 0 0 0-7.176-17.324Z'
        />
        <path
          fill='#83B4FF'
          d='M7.176 22.176A24.34 24.34 0 0 0 0 39.5a24.34 24.34 0 0 0 7.176 17.324A24.34 24.34 0 0 0 24.5 64V15a24.34 24.34 0 0 0-17.324 7.176Z'
        />
        <path
          fill='#3F73E3'
          d='M39.793 24.206A21.487 21.487 0 0 0 24.5 17.871L22.585 39.5l1.914 21.629c5.777 0 11.209-2.25 15.294-6.335A21.487 21.487 0 0 0 46.128 39.5c0-5.777-2.25-11.209-6.335-15.294Z'
        />
        <path
          fill='#5384EE'
          d='M9.206 24.206A21.487 21.487 0 0 0 2.871 39.5c0 5.777 2.25 11.209 6.335 15.294A21.487 21.487 0 0 0 24.5 61.129V17.87a21.487 21.487 0 0 0-15.294 6.335Z'
        />
        <path
          fill='#F1F4F9'
          d='m31.412 51.64-4.023-5.467a7.23 7.23 0 0 1-2.887.6l-1.915 3.05 1.915 3.649c2.512 0 4.87-.667 6.91-1.832Z'
        />
        <path
          fill='#fff'
          d='M17.294 40.457h3.993l-7.408-10.069L6.47 40.457h4.092c.493 7.26 6.556 13.015 13.938 13.015v-6.7c-3.685 0-6.737-2.756-7.207-6.315Z'
        />
        <path
          fill='#F1F4F9'
          d='M38.438 38.543c-.494-7.26-6.556-13.015-13.939-13.015l-1.914 3.65 1.914 3.05c3.686 0 6.738 2.756 7.208 6.315h-3.993l7.408 10.069 7.407-10.069h-4.091Z'
        />
        <path
          fill='#fff'
          d='m17.59 27.36 4.021 5.467a7.23 7.23 0 0 1 2.888-.6v-6.699c-2.512 0-4.87.667-6.91 1.832Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 15h49v49H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChangeIcon;
