import classes from './ShareEntityViaMessage.component.module.scss';

import React, { useRef, useState } from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import Form from '@lobox/uikit/Form';
import RichText from '@lobox/uikit/RichText';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import useTheme from '@lobox/uikit/utils/useTheme';
import useToast from '@lobox/uikit/Toast/useToast';
import {
  removeEmptyFromObject,
  removeRichTextBreakLines,
  translateReplacer,
  useGlobalDispatch,
  useTranslation,
  useUpdateInfinityData,
  uuId,
} from '@lobox/utils';
import sendWSMessage from '@shared/utils/sendWSMessage';
import {
  messageFileTypes,
  messageTypes,
} from '@shared/components/Organism/Message/constants';
import messageNormalizer from '@shared/components/Organism/Message/utils/normalizers';
import {
  useGetChatRoomsListQueryKey,
  useGetMessageObject,
} from '@shared/components/Organism/Message/hooks';
import AddRecipientsModal from './AddRecipientsModal';
import type { Dispatch, SetStateAction } from 'react';
import type { PageType, UserType } from '@lobox/utils';
import { ModalBody, ModalFooter } from '../../../../molecules/Modal';
import type {
  IMessageFile,
  IMessageOptionalData,
} from '@shared/types/messaging/message';
import Avatars from './Avatars';
import UserHeader from '../../../CreatePost/contents/Main/Main.header';
import CreateAttachmentPost from '@shared/components/Organism/CreatePost/components/Attachment/Attachment.component';
import EmojiPickerButton from '@shared/uikit/EmojiPickerButton';

type ObjectType = UserType | PageType;
type MessageUserType = ObjectType & { isGroupChat: boolean };

export interface ShareEntityModalProps {
  entity: 'resume' | 'profile' | 'post' | 'page' | 'job' | 'meeting';
  entityData?: any;
  onClose?: () => void;
  setIsHeaderVisible: Dispatch<SetStateAction<boolean>>;
}

export const ShareEntityViaMessage = ({
  entity = 'job',
  entityData,
  onClose,
  setIsHeaderVisible,
}: ShareEntityModalProps): JSX.Element => {
  if (!entity) {
    throw new Error(`entity prop is required for this component`);
  }

  const [isAddRecipientsModalOpen, setIsAddRecipientsModalOpen] =
    useState(false);

  const { t } = useTranslation();
  const { authObject } = useGetMessageObject();
  const globalDispatch = useGlobalDispatch();
  const textRef = useRef<string>(null);
  const richTextRef = useRef<{ onChooseEmoji?: Function }>();
  const queryKey = useGetChatRoomsListQueryKey();
  const chatRoomsCacheData = useUpdateInfinityData(queryKey);
  const toast = useToast();
  const { isDark } = useTheme();

  function sendMessage({
    users,
    messageOptionalData,
    file,
  }: {
    users: Array<MessageUserType>;
    messageOptionalData?: IMessageOptionalData;
    file?: IMessageFile;
  }) {
    const innerHTML = removeRichTextBreakLines(textRef.current);
    users.forEach((user) => {
      const wsMessage = removeEmptyFromObject({
        text: innerHTML || '',
        type: messageTypes.MESSAGE_TYPE_TEXT,
        id: uuId().create(),
        muc: user.isGroupChat ? 1 : undefined,
        to: user.id,
        uid: authObject.id,
        data: messageOptionalData,
        meta: file,
      });
      sendWSMessage(wsMessage);
      chatRoomsCacheData.replace({ id: user.id }, (oldData: any) => ({
        ...oldData,
        createdAt: new Date(),
        lastMessage: messageNormalizer.wsMessageToMessage({
          wsMessage,
          authUserId: authObject.id,
        }),
      }));
    });

    setTimeout(() => chatRoomsCacheData.refetch(), 250);
  }

  const shareContentObj: any = {
    resume: {
      message: t('write_a_message_3dot'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        sendMessage({
          users,
          file: {
            type: messageFileTypes.Document_Files,
            url: entityData.link,
            name: `${entityData.fullName}_resume`,
            // ext: ,
            // size: undefined,
          },
        });
        if (onClose) onClose?.();
        toast({
          type: 'success',
          icon: 'check-circle',
          message: t('resume_shared_succ'),
        });
      },
    },
    post: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { postId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();
        toast({
          type: 'success',
          icon: 'check-circle',
          message: t('post_shared_succ'),
        });
      },
    },
    profile: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { userId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();
        toast({
          type: 'success',
          icon: 'check-circle',
          message: t('user_profile_sh_succ'),
        });
      },
    },
    page: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { pageId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();
        toast({
          type: 'success',
          icon: 'check-circle',
          message: t('page_sh_succ'),
        });
      },
    },
    job: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { jobId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();
        toast({
          type: 'success',
          icon: 'check-circle',
          message: t('page_sh_succ'),
        });
      },
    },
    availability: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = {
          availabilityData: {
            entityData,
            attendee: {
              userId: entityData?.userId,
              croppedImageUrl: entityData?.croppedImageUrl,
            },
          },
        };
        sendMessage({ users, messageOptionalData });
        onClose?.();
        toast({
          type: 'success',
          icon: 'check-circle',
          message: t('availability_suc_shared'),
        });
      },
    },
    task: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { taskId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();

        toast({
          title: t('task_sent'),
          message: `${translateReplacer(
            t('task_sent_success'),
            entityData?.title
          )}.`,
          type: 'success',
          icon: 'check-circle',
          actionButton: () => (
            <Button
              schema="ghost-brand"
              onClick={onViewMessages}
              label={t('view_messages')}
              variant="text"
            />
          ),
        });
      },
    },
    birthday: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { birthdayId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();

        toast({
          title: t('birthday_sent'),
          message: `${translateReplacer(
            t('birthday_sent_success'),
            entityData?.title
          )}.`,
          type: 'success',
          icon: 'check-circle',
          actionButton: () => (
            <Button
              schema="ghost-brand"
              onClick={onViewMessages}
              label={t('view_messages')}
              variant="text"
            />
          ),
        });
      },
    },
    holiday: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { holidayId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();

        toast({
          title: t('holiday_sent'),
          message: `${translateReplacer(
            t('holiday_sent_success'),
            entityData?.title
          )}.`,
          type: 'success',
          icon: 'check-circle',
          actionButton: () => (
            <Button
              schema="ghost-brand"
              onClick={onViewMessages}
              label={t('view_messages')}
              variant="text"
            />
          ),
        });
      },
    },
    meeting: {
      message: t('write_a_message'),
      onSend: ({ users }: { users: Array<MessageUserType> }) => {
        const messageOptionalData = { meetingId: entityData.id };
        sendMessage({ users, messageOptionalData });
        onClose?.();

        toast({
          title: t('message_sent'),
          message: `${translateReplacer(
            t('meeting_sent_success'),
            entityData?.title
          )}.`,
          type: 'success',
          icon: 'check-circle',
          actionButton: () => (
            <Button
              schema="ghost-brand"
              onClick={onViewMessages}
              label={t('view_messages')}
              variant="text"
            />
          ),
        });
      },
    },
  };
  const handleChange = (text: string) => {
    textRef.current = text;
  };
  const onCloseAddRecipientsModal = () => {
    setIsAddRecipientsModalOpen(false);
    setIsHeaderVisible(true);
  };
  const openAddRecipientsModal = () => {
    setIsAddRecipientsModalOpen(true);
    setIsHeaderVisible(false);
  };

  const onViewMessages = () => {
    globalDispatch({
      type: 'SET_SHOW_RIGHT_PANEL',
      payload: false,
    });

    globalDispatch({
      type: 'OPEN_MESSAGE_PANEL',
    });

    onClose?.();
  };

  return (
    <Form
      local
      initialValues={{ users: undefined }}
      onSuccess={shareContentObj?.[entity]?.onSend}
      className={classes.form}
    >
      {({ values }) => (
        <>
          <AddRecipientsModal
            value={values?.users}
            onChange={console.log}
            isOpen={isAddRecipientsModalOpen}
            onClose={onCloseAddRecipientsModal}
            modalDialogClassName={cnj(
              !isAddRecipientsModalOpen && classes.hidden
            )}
          />
          <ModalBody
            className={cnj(isAddRecipientsModalOpen && classes?.hidden)}
          >
            <UserHeader classNames={{ container: classes.userCardContainer }} />
            <RichText
              spaceFromEmoji
              label={t('write_a_message')}
              labelProps={{ size: 15 }}
              variant="comment-input"
              onChange={handleChange}
              fixMentionDropdown={false}
              className={cnj(classes.richText)}
              isFocus
              showEmoji={false}
              ref={richTextRef}
            />
            <Flex className={classes.emojiButtonWrapper}>
              <EmojiPickerButton
                onEmojiSelect={richTextRef?.current?.onChooseEmoji}
                placement="top-end"
                emojiProps={{ size: 'md20' }}
              />
            </Flex>
            {/* <TemplateButton
              classNames={{
                wrapper: cnj(
                  classes.templateButton,
                  ['job', 'meeting', 'task'].includes(entity) &&
                    classes.templateButtonMarginBottom
                ),
              }}
            /> */}
            <Flex className={classes.sharedObjectWrapper}>
              <CreateAttachmentPost
                onAfterClick={onClose}
                entityType={entity}
                entityData={entityData}
                hideActionButtons
                classNames={{ attachmentWrapper: classes.noEvents }}
              />
            </Flex>
          </ModalBody>
          {!isAddRecipientsModalOpen && (
            <ModalFooter
              className={cnj(
                classes.modalFooter,
                values?.users?.length && classes.footerWithUsers
              )}
            >
              {values?.users?.length ? (
                <Avatars
                  onClick={openAddRecipientsModal}
                  className={classes.avatars}
                  avatars={values?.users}
                />
              ) : null}
              <Flex className={classes.footerButtonsWrapper}>
                <Button
                  leftIcon={values?.users?.length > 0 ? 'pen-light' : 'plus'}
                  leftType="far"
                  labelFont="bold"
                  schema={isDark ? 'semi-transparent' : 'semi-transparent'}
                  label={
                    values?.users?.length
                      ? t('edit_recipients')
                      : t('add_recipients')
                  }
                  onClick={openAddRecipientsModal}
                  className={classes.button}
                />
                {values?.users?.length > 0 && (
                  <DividerVertical height={0} distance={4} />
                )}
                {values?.users?.length > 0 && (
                  <SubmitButton
                    className={classes.button}
                    label={t('send')}
                    schema="primary-blue"
                  />
                )}
              </Flex>
            </ModalFooter>
          )}
        </>
      )}
    </Form>
  );
};
