import classes from './PreferencesModal.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import type { ComponentProps, FC } from 'react';
import PreferencesSingleItem from './PreferencesSingleItem';
import SchedulePreferencesModal from './PreferencesModal.schedule';
import { useProfilePanelState } from '@shared/hooks/useSettingsState';

interface Props {
  onBack: () => void;
}

type Keys = undefined | 'schedule';

const subModalComponents: Record<Keys, any> = {
  schedule: SchedulePreferencesModal,
} as const;

const PreferencesModal: FC<Props> = ({ onBack }) => {
  const { state, setProfilePanelState, closePanel } = useProfilePanelState();
  const opennedSubModal = Object?.keys?.(state?.settings?.preferences)?.[0];

  const { t } = useTranslation();

  const items: Array<ComponentProps<typeof PreferencesSingleItem>> = [
    {
      title: 'home_preferences',
      subtitle: 'preferences_subtitle_description',
    },
    {
      title: 'people_preferences',
      subtitle: 'preferences_subtitle_description',
    },
    {
      title: 'pages_preferences',
      subtitle: 'preferences_subtitle_description',
    },
    {
      title: 'schedule_preferences',
      subtitle: 'schedule_preferences_subtitle_description',
      onClick: () =>
        setProfilePanelState({ settings: { preferences: { schedule: {} } } }),
    },
    {
      title: 'message_preferences',
      subtitle: 'preferences_subtitle_description',
    },
    {
      title: 'jobs_preferences',
      subtitle: 'preferences_subtitle_description',
    },
  ];

  const onCloseProfilePanel = () => {
    closePanel();
  };

  if (opennedSubModal) {
    const SubModal = subModalComponents[opennedSubModal];
    return (
      <SubModal
        onBack={() => setProfilePanelState({ settings: { preferences: {} } })}
      />
    );
  }

  return (
    <FixedRightSideModalDialog onClickOutside={onCloseProfilePanel}>
      <Flex className={classes.root}>
        <Flex className={cnj(classes.header)}>
          <IconButton
            onClick={onBack}
            size="md20"
            name="chevron-left"
            colorSchema="transparent"
          />
          <Typography
            font="700"
            color="hover2_coal"
            className={classes.title}
            size={20}
            height={24}
          >
            {t('preferences')}
          </Typography>
          <Flex className={classes.fillerItem} />
        </Flex>
        <Flex className={classes.body}>
          {items.map((item) => (
            <PreferencesSingleItem {...item} />
          ))}
        </Flex>
      </Flex>
    </FixedRightSideModalDialog>
  );
};

export default PreferencesModal;
