import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const ExpensionIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='url(#a)'>
        <path
          fill='#ECECEC'
          d='M28.529 51.566V41.533a3.22 3.22 0 0 0-2.821-3.248 3.129 3.129 0 0 0-3.436 3.114v2.596l-2.768-2.768a3.89 3.89 0 0 1-4.868 4.317l-2.16 6.478a3.128 3.128 0 0 0 .079 2.19l3.46 8.302v1.565H28.53v-2.347l.724-1.448a7.82 7.82 0 0 0 .193-6.578l-.917-2.14Z'
        />
        <path
          fill='#57A4FF'
          d='m30.57 41.227-.172.172h.156c.008-.057.008-.115.016-.172Z'
        />
        <path
          fill='#8493A8'
          fillOpacity={0.2}
          d='M31.671 44.542a3.89 3.89 0 0 1-1.117-3.143h-.157l-1.87 1.869v5.17a3.91 3.91 0 0 0 3.91 3.91h.783a1.564 1.564 0 0 0 1.564-1.565v-5.13a3.886 3.886 0 0 1-3.113-1.11Z'
        />
        <path
          fill='#5384EE'
          d='m33.884 37.911 5.529-5.537-4.974-4.974a3.92 3.92 0 0 1-1.11 2.213 3.95 3.95 0 0 1-5.53 0 3.915 3.915 0 0 1 2.214-6.64L25.039 18l-5.537 5.53a3.91 3.91 0 1 0-3.316 3.315l-5.53 5.53 5.53 5.536a3.91 3.91 0 1 0 3.316 3.316l2.768 2.769v-2.597a3.128 3.128 0 0 1 6.257 0v1.87l2.04-2.042a3.91 3.91 0 1 0 3.317-3.316Z'
        />
        <path
          fill='#3F73E3'
          d='m33.884 37.911 5.53-5.537-4.975-4.974a3.873 3.873 0 0 1-2.937 3.23 26.75 26.75 0 0 1-3.933 8.525 3.108 3.108 0 0 1 .958 2.244v1.87l2.041-2.042a3.91 3.91 0 1 0 3.316-3.316Z'
        />
        <path
          fill='#072252'
          d='M48.079 33.579h-4.693a.782.782 0 1 1 0-1.564h4.693a.782.782 0 0 1 0 1.564ZM42.541 24.585a.782.782 0 0 1-.39-1.46l4.066-2.346a.782.782 0 0 1 .782 1.355l-4.067 2.346a.776.776 0 0 1-.39.105ZM46.603 44.918a.775.775 0 0 1-.39-.105l-4.068-2.346a.783.783 0 1 1 .783-1.354l4.066 2.346a.782.782 0 0 1-.39 1.46ZM7.412 33.579H2.72a.782.782 0 1 1 0-1.564h4.692a.782.782 0 0 1 0 1.564ZM8.257 24.585a.776.776 0 0 1-.39-.105L3.8 22.134a.782.782 0 0 1 .782-1.355l4.066 2.347a.782.782 0 0 1-.39 1.459ZM4.195 44.918a.782.782 0 0 1-.39-1.46l4.066-2.345a.782.782 0 1 1 .782 1.354l-4.067 2.346a.775.775 0 0 1-.39.105Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M1 17h48v47H1z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='url(#a)'>
        <path
          fill='#ECECEC'
          d='M28.529 51.566V41.533a3.22 3.22 0 0 0-2.821-3.248 3.129 3.129 0 0 0-3.436 3.114v2.596l-2.768-2.768a3.89 3.89 0 0 1-4.868 4.317l-2.16 6.478a3.128 3.128 0 0 0 .079 2.19l3.46 8.302v1.565H28.53v-2.347l.724-1.448a7.82 7.82 0 0 0 .193-6.578l-.917-2.14Z'
        />
        <path
          fill='#57A4FF'
          d='m30.57 41.227-.172.172h.156c.008-.057.008-.115.016-.172Z'
        />
        <path
          fill='#8493A8'
          fillOpacity={0.2}
          d='M31.671 44.542a3.89 3.89 0 0 1-1.117-3.143h-.157l-1.87 1.869v5.17a3.91 3.91 0 0 0 3.91 3.91h.783a1.564 1.564 0 0 0 1.564-1.565v-5.13a3.886 3.886 0 0 1-3.113-1.11Z'
        />
        <path
          fill='#5384EE'
          d='m33.884 37.911 5.529-5.537-4.974-4.974a3.92 3.92 0 0 1-1.11 2.213 3.95 3.95 0 0 1-5.53 0 3.915 3.915 0 0 1 2.214-6.64L25.039 18l-5.537 5.53a3.91 3.91 0 1 0-3.316 3.315l-5.53 5.53 5.53 5.536a3.91 3.91 0 1 0 3.316 3.316l2.768 2.769v-2.597a3.128 3.128 0 0 1 6.257 0v1.87l2.04-2.042a3.91 3.91 0 1 0 3.317-3.316Z'
        />
        <path
          fill='#3F73E3'
          d='m33.884 37.911 5.53-5.537-4.975-4.974a3.873 3.873 0 0 1-2.937 3.23 26.75 26.75 0 0 1-3.933 8.525 3.108 3.108 0 0 1 .958 2.244v1.87l2.041-2.042a3.91 3.91 0 1 0 3.316-3.316Z'
        />
        <path
          fill='#072252'
          d='M48.079 33.579h-4.693a.782.782 0 1 1 0-1.564h4.693a.782.782 0 0 1 0 1.564ZM42.541 24.585a.782.782 0 0 1-.39-1.46l4.066-2.346a.782.782 0 0 1 .782 1.355l-4.067 2.346a.776.776 0 0 1-.39.105ZM46.603 44.918a.775.775 0 0 1-.39-.105l-4.068-2.346a.783.783 0 1 1 .783-1.354l4.066 2.346a.782.782 0 0 1-.39 1.46ZM7.412 33.579H2.72a.782.782 0 1 1 0-1.564h4.692a.782.782 0 0 1 0 1.564ZM8.257 24.585a.776.776 0 0 1-.39-.105L3.8 22.134a.782.782 0 0 1 .782-1.355l4.066 2.347a.782.782 0 0 1-.39 1.459ZM4.195 44.918a.782.782 0 0 1-.39-1.46l4.066-2.345a.782.782 0 1 1 .782 1.354l-4.067 2.346a.775.775 0 0 1-.39.105Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M1 17h48v47H1z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExpensionIcon;
