import classes from './ShowGalleryLightBox.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Modal from '@lobox/uikit/Modal';
import useMedia from '@lobox/uikit/utils/useMedia';
import useTheme from '@lobox/uikit/utils/useTheme';
import forEach from 'lodash/forEach';
// eslint-disable-next-line import/order
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FancyVideoPlayer from '../FancyVideoPlayer';
import Image from '@lobox/uikit/Image';
import { Placeholder } from './placeholder';

interface StyleProps {
  backdrop?: string;
  wrapper?: string;
  content?: string;
}

interface GalleryProps {
  media: Array<object>;
  videosRef?: React.MutableRefObject<
    React.MutableRefObject<HTMLVideoElement>[]
  >;
  rightComponent?: React.ReactNode;
  selectedItem?: number;
  modalStyles?: StyleProps;
  onClose: () => void;
}

const ShowGalleryLightBox: React.FC<GalleryProps> = ({
  media,
  videosRef,
  rightComponent,
  selectedItem = -1,
  modalStyles = {},
  onClose,
}) => {
  const { isMoreThanTablet } = useMedia();
  const {
    theme: { colors },
  } = useTheme();
  const [swipe, setSwipe] = React.useState(false);
  const [carouselIndex, setCarouselIndex] = React.useState(selectedItem);
  const localVideosRef = React.useRef<
    React.MutableRefObject<HTMLVideoElement>[]
  >([]);
  const lastPlayingVideoIndex = React.useRef(-1);

  function handleClick(handler: () => void) {
    handler();
  }

  function handleSwipe() {
    setSwipe(!swipe);
  }

  const handleClickOnClose = () => {
    forEach(localVideosRef?.current, (locRef, ii) => {
      if (!locRef || !videosRef) return;

      videosRef.current[ii].current.currentTime = locRef.current.currentTime;
      videosRef.current[ii].current.volume = locRef.current.volume;
      if (lastPlayingVideoIndex.current === ii && !locRef.current.paused) {
        videosRef.current[ii].current.play();
        videosRef.current[ii].current.muted = isMoreThanTablet ? false : true;
      }
      locRef.current.pause();
    });
    onClose();
  };

  const arrowNext = (clickHandler: () => void, hasNext: boolean) =>
    hasNext ? (
      <Button
        className={cnj('control-arrow', 'control-next', classes.arrowWrapper)}
        onClick={() => handleClick(clickHandler)}
      >
        <IconButton
          type="far"
          name="chevron-right"
          size="lg"
          colorSchema="dark"
          className={classes.arrowButton}
        />
      </Button>
    ) : null;

  const arrowPrev = (clickHandler: () => void, hasPrev: boolean) =>
    hasPrev ? (
      <Button
        className={cnj('control-arrow', 'control-prev', classes.arrowWrapper)}
        onClick={() => handleClick(clickHandler)}
      >
        <IconButton
          type="far"
          name="chevron-left"
          size="lg"
          colorSchema="dark"
          className={classes.arrowButton}
        />
      </Button>
    ) : null;

  React.useEffect(() => {
    lastPlayingVideoIndex.current = -1;
    forEach(videosRef?.current, (ref, ii) => {
      if (selectedItem === ii && ref.current.played) {
        lastPlayingVideoIndex.current = ii;
      }
      ref.current.pause();
      localVideosRef.current[ii].current.currentTime = ref.current.currentTime;
      localVideosRef.current[ii].current.volume = ref.current.volume;
      localVideosRef.current[ii].current.muted = false;
    });
  }, [videosRef]);

  React.useEffect(() => {
    if (isMoreThanTablet) return;
    const themeColorMetaEl = document.getElementById('theme-color-meta');
    if (!themeColorMetaEl) return;
    const color = themeColorMetaEl.getAttribute('content') || '';
    themeColorMetaEl.setAttribute('content', colors.black);
    return () => {
      themeColorMetaEl.setAttribute('content', color);
    };
  }, [isMoreThanTablet]);

  return (
    <Modal
      styles={{
        backdrop: classes.backdrop,
        wrapper: classes.wrapper,
        content: classes.content,
        ...modalStyles,
      }}
    >
      <Flex className={classes.leftSide}>
        <Flex className={classes.topBar}>
          <IconButton
            onClick={handleClickOnClose}
            name="times"
            size="lg"
            type="far"
            colorSchema="dark"
            className={classes.closeButton}
          />
          {!!rightComponent && (
            <Flex className={classes.swipeButton}>
              <IconButton
                onClick={handleSwipe}
                name={swipe ? 'arrow-to-left' : 'arrow-to-right'}
                type="far"
                size="lg"
                colorSchema="dark"
              />
            </Flex>
          )}
        </Flex>
        <Flex className="carouselContainer">
          <Carousel
            swipeable
            emulateTouch
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={false}
            showArrows={media?.length > 1}
            // a hack! to prevent autoplay in some cases
            interval={60 * 60 * 60 * 24}
            transitionTime={200}
            renderArrowNext={arrowNext}
            renderArrowPrev={arrowPrev}
            selectedItem={selectedItem}
            className="carousel"
            onChange={setCarouselIndex}
          >
            {media?.map(({ type, path, url, secondaryUrl, id }: any, index) => (
              <Flex className="carouselItem" key={id || url}>
                {type === 'image' && (
                  <Image
                    defaultTag
                    className={classes.img}
                    resolution="original"
                    src={secondaryUrl || url || path}
                    alt={secondaryUrl || url}
                    placeholderComponent={<Placeholder />}
                  />
                )}
                {type === 'video' && (
                  <FancyVideoPlayer
                    src={url}
                    fullScreen
                    showControls
                    preventZooming
                    containerClassName={classes.videoContainer}
                    action={carouselIndex === index ? 'play' : 'pause'}
                    playInViewport={false}
                    referenceRef={videosRef?.current[index]}
                    setVideoRef={(ref) => {
                      localVideosRef.current[index] = ref;
                    }}
                  />
                )}
              </Flex>
            ))}
          </Carousel>
        </Flex>
      </Flex>
      {!swipe && rightComponent}
    </Modal>
  );
};

export default ShowGalleryLightBox;
