import classes from './index.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Form from '@lobox/uikit/Form';
import Typography from '@lobox/uikit/Typography';
import { useGlobalDispatch, useTranslation } from '@lobox/utils';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '../../../../molecules/Modal';
import {
  useCreatePostDispatch,
  useCreatePostState,
} from '../../context/createPost.provider';
import ModalDialog from '@shared/components/molecules/Modal/BasicModal/Modal.Dialog';

export type Person = any;
const MAX = 10;

const TagPerson = (): JSX.Element => {
  const { t } = useTranslation();
  const persons = useCreatePostState('selectedUsers');
  const createPostDispatch = useCreatePostDispatch();
  const appDispatch = useGlobalDispatch();

  const onSuccess = ({ users }) => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        currentTab: 'main',
      },
    });
    createPostDispatch({
      type: 'SET_USERS',
      payload: users?.length ? users : undefined,
    });
  };

  function handleBackModal() {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        currentTab: 'main',
      },
    });
  }

  return (
    <Form local initialValues={{ users: persons }} onSuccess={onSuccess}>
      {({ values: { users }, submitForm }) => (
        <ModalDialog isOpen onClose={handleBackModal} onBack={handleBackModal}>
          <ModalHeaderSimple
            visibleHeaderDivider
            backButtonProps={{
              onClick: handleBackModal,
            }}
            hideBack={false}
            closeButtonProps={{
              className: classes.hideCloseBtn,
            }}
            title={t('tag_profiles')}
          />
          <ModalBody className={classes.modalBody}>
            <DynamicFormBuilder
              groups={[
                {
                  name: 'users',
                  cp: 'userPicker',
                  required: true,
                  placeholder: t('search'),
                  isMultiSelect: true,
                  max: MAX,
                  hideCounter: true,
                },
              ]}
            />
          </ModalBody>
          <ModalFooter className={classes.modalFooter}>
            <Typography
              className={classes.countText}
              color="gray"
              font="400"
              size={13}
              height={15}
            >
              {`${users?.length ?? 0}/${MAX}`}
            </Typography>
            <Button
              className={classes.done}
              disabled={!users?.length}
              onClick={submitForm}
              label={t('done')}
            />
          </ModalFooter>
        </ModalDialog>
      )}
    </Form>
  );
};

export default TagPerson;
