import React from 'react';
import { useGlobalState } from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import useUserHighlights from '@shared/constants/highlights.user';
import useBusinessHighlights from '@shared/constants/highlights.business';
import CreatePost from '../CreatePost';

const useHighlights = isBusinessApp ? useBusinessHighlights : useUserHighlights;

const CreatePostModal = ({
  isOpen,
  post,
  isCreation,
  ...rest
}: {
  isOpen?: boolean;
  post: any;
  isCreation?: boolean;
  [x: string]: any;
}): JSX.Element => {
  const { highlights } = useHighlights();
  const { isOpenModal, selectedPostForEdit } =
    useGlobalState('createPostModal');

  return (
    <>
      {(isOpenModal || isOpen) && (
        <CreatePost
          highlights={highlights}
          selectedPostForEdit={post || selectedPostForEdit}
          isCreation={isCreation}
          {...rest}
        />
      )}
    </>
  );
};

export default CreatePostModal;
