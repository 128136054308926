import { PAGE_ROLES } from '@lobox/utils';


export const getPageRoleIndex = (role: string) => {
    switch (role) {
      case PAGE_ROLES.OWNER.value:
        return 1;
      case PAGE_ROLES.ADMIN.value:
        return 2;
      case PAGE_ROLES.EDITOR.value:
        return 3;
      case PAGE_ROLES.RECRUITER.value:
        return 4;
      default:
        return 5;
    };
  }