import classes from './ResponsiveGlobalSearch.desktop.module.scss';

import React, { useEffect, useRef } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import GlobalSearchInput from '@shared/components/molecules/GlobalSearchInput';
import useSearchInputMargin from '@shared/hooks/useSearchInputMargin';
import { makeDynamicStyles } from '@shared/uikit/utils/makeDynamicStyles';
import { useRouter } from 'next/navigation';
import { routeNames } from '@lobox/utils';

interface Props {}

const ResponsiveGlobalSearch: React.FC<Props> = () => {
  const { marginLeft, marginRight, isLoaded } = useSearchInputMargin();
  const inputRef = useRef<
    HTMLInputElement & { simulatePressEnter: Function }
  >();
  const router = useRouter();

  useEffect(() => {
    router.prefetch(routeNames.searchAll);
  }, []);

  if (!isLoaded) return null;

  return (
    <Flex
      className={cnj(classes.globalSearchInputRoot)}
      {...makeDynamicStyles({ marginLeft, marginRight })}
    >
      <GlobalSearchInput inputRef={inputRef} />
    </Flex>
  );
};

export default ResponsiveGlobalSearch;
