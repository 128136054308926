import classes from './MyPagesModal.module.scss';

import BottomSheet from '@lobox/uikit/BottomSheet';
import Flex from '@lobox/uikit/Flex';
import MyPages from '@shared/components/Organism/MyPages';
import React, { useRef } from 'react';
import type { FC } from 'react';

interface MyPagesModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const MyPagesModal: FC<MyPagesModalProps> = (props) => {
  const { isOpen, onClose } = props;

  const bottomSheetRef = useRef(null);

  return (
    <BottomSheet open={isOpen} ref={bottomSheetRef} onRequestClose={onClose}>
      <Flex className={classes.wrapper}>
        <MyPages />
      </Flex>
    </BottomSheet>
  );
};

export default MyPagesModal;
