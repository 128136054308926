import classes from '../Highlights.component.module.scss';

import React from 'react';
import {
  ModalBody,
  ModalBodyImage,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import SubCategoryContent from './Subcategory.content';

interface Props<T> {
  handlePrev: (item: T) => void;
  handleNext: (item: T) => void;
  image: string;
  items: Array<T>;
  title: string;
}

const StepTwo = ({
  handlePrev,
  handleNext,
  title,
  image,
  items,
}: Props<any>) => {
  return (
    <>
      <ModalHeaderSimple
        backButtonProps={{
          onClick: handlePrev,
        }}
        closeButtonProps={{
          className: classes.closeBtn,
        }}
        title={title}
        hideBack={false}
        visibleHeaderDivider
      />
      <ModalBody className={classes.highlightBody}>
        <ModalBodyImage bottomDivider={false} image={image} />
        <SubCategoryContent data={items} onItemClicked={handleNext} />
      </ModalBody>
    </>
  );
};

export default StepTwo;
