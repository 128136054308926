import {
  getMostPopularHashtags,
  jobsApi,
  suggestHashtags,
  suggestObjects,
} from '@lobox/utils';
import type { TextArg } from '@shared/hooks/useGlobalSearchUtilities';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';
import { getEvenItemsFromArrays } from './normalizers';
import { itemNormalizers, typeOrder } from './shared';

type Rtype = any[];

export type ItemType = 'PAGE' | 'PERSON' | 'HASHTAG' | 'JOB' | 'TEXT';

const currentModuleMapper = {
  jobs: 'JOB',
  people: 'PERSON',
  pages: 'PAGE',
};

export async function searchGlobally(
  textArg: TextArg,
  itemTypes: ItemType[] = ['PAGE', 'PERSON', 'HASHTAG', 'JOB'],
  currentGeneralModule: ReturnType<
    typeof useGlobalSearchUtilities
  >['currentGeneralModule'],
  flags: Record<string, boolean>
): Promise<Rtype> {
  const args: any = { size: 5, text: textArg?.params?.text };
  const isJob = itemTypes.includes('JOB');
  const isHashtag = itemTypes.includes('HASHTAG');
  const isPage = itemTypes.includes('PAGE');
  const isPerson = itemTypes.includes('PERSON');
  const isHashtagSign = textArg?.params?.text === '#';
  if (isHashtagSign) {
    const res = await getMostPopularHashtags({
      size: 20,
      includefollowersCounter: true,
      includeMyFollowing: true,
    });
    return res?.content?.map((item) => ({
      type: 'HASHTAG',
      label: item?.id,
      title: item?.id,
    }));
  }

  const tryCatchWrapper = async (
    prom: (funcArgs: any) => Promise<any>,
    funcArgs: any,
    defaultValue: any
  ) => {
    let data: any = defaultValue;
    try {
      data = await prom(funcArgs);
    } catch (err) {
      console.log(err);
    }
    return data;
  };
  const jobPromise: Promise<any[]> = new Promise((res) => {
    res(
      isJob
        ? tryCatchWrapper(jobsApi.searchJobsSkillsPages, { params: args }, [])
        : []
    );
  });
  const personOrPageOrTextPromise: Promise<Required<{ content: any[] }>> =
    new Promise((res) => {
      res(
        isPerson || isPage
          ? tryCatchWrapper(suggestObjects, args, { content: [] })
          : { content: [] }
      );
    });
  const hashtagPromise: Promise<Required<{ content: any[] }>> = new Promise(
    (res) => {
      res(
        isHashtag
          ? tryCatchWrapper(suggestHashtags, args, { content: [] })
          : { content: [] }
      );
    }
  );

  const res = await Promise.all([
    jobPromise,
    personOrPageOrTextPromise,
    hashtagPromise,
  ]);
  const normalizedRes = normalizer(res, [
    itemNormalizers.job,
    itemNormalizers.personOrPageOrText,
    itemNormalizers.hashtag,
  ]);
  const stringJobs = normalizedRes[0]?.filter((item) => !item?.isSpecific);
  const jobs = normalizedRes[0]?.filter((item) => item?.isSpecific);
  const people = normalizedRes[1]?.filter(
    (item: any) => item?.type === 'PERSON'
  );
  const pages = normalizedRes[1]?.filter((item: any) => item?.type === 'PAGE');
  const hashtags = normalizedRes[2];
  const result = getEvenItemsFromArrays(
    [stringJobs, jobs, people, pages, hashtags],
    20,
    typeOrder
  )?.filter((item) => itemTypes.includes(item?.type));

  if (currentGeneralModule !== 'all') {
    result?.sort((x, y) => {
      x.type === currentModuleMapper[currentGeneralModule]
        ? 1
        : y.type === currentModuleMapper[currentGeneralModule]
          ? -1
          : 0;
    });
  }
  return result;
}

function normalizer(res: any[], normalizers: Function[]) {
  return res?.map((group, index) => normalizers[index](group));
}
