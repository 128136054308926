import React from 'react';
import { useGlobalDispatch } from '@lobox/utils';
import ShowGalleryLightBox from '../../../../ShowGalleryLightBox/ShowGalleryLightBox';

interface ViewMediaProps {
  medias: Array<{ type: 'image' | 'video'; path: string; id: string }>;
  selectedItem?: number;
}
const ViewMedia: React.FC<ViewMediaProps> = ({ medias, selectedItem }) => {
  const dispatch = useGlobalDispatch();

  const closeHandler = () => {
    dispatch({ type: 'CLOSE_VIEW_MEDIA' });
  };

  return (
    <ShowGalleryLightBox
      onClose={closeHandler}
      media={medias}
      selectedItem={selectedItem}
    />
  );
};

export default ViewMedia;
