import classes from './ShareEntityViaEmail.component.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';

import type { FC } from 'react';
import Attachment from '../../../CreatePost/components/Attachment/Attachment.component';
import ShareEntityViaEmailFooter from './ShareEntityViaEmail.footer';

interface ShareEntityViaEmailProps {
  entityData?: any;
}

const ShareEntityViaEmail: FC<ShareEntityViaEmailProps> = (props) => {
  const { entityData } = props;

  const { attachment } = entityData;
  const hasAttachment = !!attachment;
  const footerNoMarginTop = ['profile']?.includes(attachment?.type);

  return (
    <Flex className={classes.sharedPostContainerWrapper}>
      {hasAttachment ? (
        <Flex className={cnj('contentElement', classes.sharedPostContainer)}>
          <Attachment
            entityType={attachment.type}
            entityData={attachment.data}
            hideActionButtons
            classNames={{ attachmentWrapper: classes.noEvents }}
          />
        </Flex>
      ) : null}

      <ShareEntityViaEmailFooter
        entityType={attachment.type}
        classNames={{
          wrapper: footerNoMarginTop && classes.footerNoMarginTop,
        }}
        entityData={{ ...attachment?.data }}
      />
    </Flex>
  );
};

export default ShareEntityViaEmail;
