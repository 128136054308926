import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const HelpPrivacy: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#393939" />
      <g clipPath="url(#a)">
        <path
          fill="#5384EE"
          d="M41.97 24.093v13.969c0 12.721-8.64 23.814-20.98 26.938C8.67 61.887.031 50.783.031 38.062v-13.97C9.732 23 15.227 19.31 21.001 15c5.772 4.31 11.267 8 20.968 9.093Z"
        />
        <path
          fill="#4269BE"
          d="M20.998 59.484a22.458 22.458 0 0 1-15.67-21.422v-9.464c6.526-1.423 11.32-4.052 15.67-7.052 4.444 3.083 9.145 5.66 15.67 7.062v9.464a22.454 22.454 0 0 1-15.67 21.412Z"
        />
        <path
          fill="#fff"
          d="m17.524 48.546-5.165-5.164 2.619-2.63 2.546 2.537 9.495-9.485 2.619 2.619-12.114 12.123Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 15h42v50H0z" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <g clipPath="url(#a)">
        <path
          fill="#5384EE"
          d="M41.97 24.093v13.969c0 12.721-8.64 23.814-20.98 26.938C8.67 61.887.031 50.783.031 38.062v-13.97C9.732 23 15.227 19.31 21.001 15c5.772 4.31 11.267 8 20.968 9.093Z"
        />
        <path
          fill="#4269BE"
          d="M20.998 59.484a22.458 22.458 0 0 1-15.67-21.422v-9.464c6.526-1.423 11.32-4.052 15.67-7.052 4.444 3.083 9.145 5.66 15.67 7.062v9.464a22.454 22.454 0 0 1-15.67 21.412Z"
        />
        <path
          fill="#fff"
          d="m17.524 48.546-5.165-5.164 2.619-2.63 2.546 2.537 9.495-9.485 2.619 2.619-12.114 12.123Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 15h42v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HelpPrivacy;
