import classes from '../Highlights.component.module.scss';

import React from 'react';
import {
  ModalBody,
  ModalBodyImage,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import Typography from '@lobox/uikit/Typography';
import ListContent from './List.content';
import { useTranslation } from '@lobox/utils';
import cnj from '@lobox/uikit/utils/cnj';

interface Props<T> {
  handlePrev: (item: T) => void;
  handleNext: (item: T) => void;
  image: string;
  items: Array<T>;
  isPrevStepMainTab: boolean;
}

const StepOne = ({
  handlePrev,
  handleNext,
  image,
  isPrevStepMainTab,
  items,
}: Props<any>) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeaderSimple
        backButtonProps={{
          onClick: handlePrev,
          className: cnj(classes.show, !isPrevStepMainTab && classes.hideBack),
        }}
        closeButtonProps={{
          onClick: handlePrev,
          className: cnj(classes.hide, !isPrevStepMainTab && classes.show),
        }}
        title={t('create_highlight')}
        hideBack={false}
        visibleHeaderDivider
      />
      <ModalBody className={classes.highlightBody}>
        <ModalBodyImage bottomDivider={false} image={image} />
        <Typography
          font="bold"
          size={22}
          height={24}
          color="thirdText"
          className={classes.title}
        >
          {t('highlight_title')}
        </Typography>
        <ListContent data={items} onItemClicked={handleNext} />
      </ModalBody>
    </>
  );
};

export default StepOne;
