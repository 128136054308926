import classes from './index.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import HeroIcon from '@lobox/uikit/HeroIcon';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import { useTranslation } from '@lobox/utils';

interface SidebarItem {
  title: string;
  subTitle: string;
  icon: string;
  onClick: () => void;
  action?: React.ReactNode;
  isActive?: boolean;
  isHoverable?: boolean;
}

const SidebarItem = ({
  isActive,
  title,
  subTitle,
  icon,
  onClick,
  action,
  isHoverable = true,
}: SidebarItem): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BaseButton
      className={cnj(
        isHoverable && classes.headerItemWrapperHover,
        classes.headerItemWrapper,
        isActive && classes.active
      )}
      onClick={onClick}
    >
      <HeroIcon
        iconSize={18}
        iconType="far"
        size={40}
        iconName={icon}
        color={isActive ? 'brand' : 'coal'}
      />
      <Flex className={classes.textWrapper}>
        <Typography
          font="700"
          color={isActive ? 'brand' : 'thirdText'}
          size={16}
          height={20}
        >
          {t(title)}
        </Typography>
        <Typography
          isTruncated
          color="primaryDisabledText"
          size={12}
          height={16}
        >
          {t(subTitle)}
        </Typography>
      </Flex>
      <Flex className={classes.actionWrapper}>{action}</Flex>
    </BaseButton>
  );
};

export default SidebarItem;
