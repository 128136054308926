import classes from './Main.highlight.module.scss';

import React from 'react';
import { FeedElementProvider } from '@shared/components/Organism/FeedCard/Context/feedElement.provider';
import HighlightPreview from '@shared/components/Organism/FeedCard/Components/HighlightPreview';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import { useCreatePostDispatch } from '../../context/createPost.provider';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import { IPost, useGlobalDispatch, useTranslation } from '@lobox/utils';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';

interface Props {
  post: IPost;
}
const MainHighlight = ({ post }: Props) => {
  const createPostDispatch = useCreatePostDispatch();
  const { openConfirmDialog } = useOpenConfirm();
  const { t } = useTranslation();
  const appDispatch = useGlobalDispatch();

  const editHandler = () => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isPrevStepMainTab: true,
        currentTab: 'highlight',
        defaultActiveH: post.highlight,
      },
    });
  };

  const deleteHandler = () =>
    openConfirmDialog({
      title: t('remove_highlight'),
      message: t('are_y_s_w_remove_h'),
      confirmButtonText: t('remove'),
      cancelButtonText: t('cancel'),
      confirmCallback: () =>
        createPostDispatch({
          type: 'SET_HIGHLIGHT',
          payload: null,
        }),
    });

  return (
    <Flex className={classes.mainHighlightRoot}>
      <Flex className={classes.buttonsWrapper}>
        {post?.highlight?.type !== HIGHLIGHT_TYPES.GET_PROMOTED && (
          <IconButton type="far" name="pen" onClick={editHandler} size="md18" />
        )}
        <IconButton
          type="far"
          name="times"
          onClick={deleteHandler}
          size="md18"
        />
      </Flex>
      <FeedElementProvider
        initialValue={{
          feedElement: {
            post,
          },
        }}
      >
        <HighlightPreview showDivider />
      </FeedElementProvider>
    </Flex>
  );
};

export default MainHighlight;
