import classes from './List.content.module.scss';

import React from 'react';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Card from './List.card';

interface HighlightListItemsProps {
  data: any[];
  onItemClicked: (item: any) => void;
}
const HighlightListItems: React.FC<HighlightListItemsProps> = ({
  data,
  onItemClicked,
}) => {

  return (
    <Flex
      className={cnj(
        classes.cardContent,
        isBusinessApp && classes.cardContentBusiness
      )}
    >
      {data?.map((item) => (
        <Flex key={item.id} onClick={() => onItemClicked(item)}>
          <Card {...{ ...item }} />
        </Flex>
      ))}
    </Flex>
  );
};

export default HighlightListItems;
