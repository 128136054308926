import classes from './SettingsPage.module.scss';

import React, { useCallback } from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Switch from '@lobox/uikit/Switch';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  routeNames,
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
} from '@lobox/utils';
import urls from '@shared/constants/urls';
import { useHistory } from '@lobox/utils';
import SettingsContent from '@shared/components/Organism/SettingsModal/SettingsContent.component';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { PermissionsGate } from '@shared/components/molecules';
import useHasPermission from '@shared/hooks/useHasPermission';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import {
  FixedRightSideModalDialog,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';
import PreferencesModal from '@shared/components/Organism/PreferencesModal/PreferencesModal';
import type { FC } from 'react';
import { useProfilePanelState } from '@shared/hooks/useSettingsState';

const DARK_MODE = 'dar_mode';

interface SettingsProps {
  onGoBack: VoidFunction;
  closeProfilePanel: VoidFunction;
}

const Settings: FC<SettingsProps> = (props) => {
  const { onGoBack, closeProfilePanel } = props;
  const { state, setProfilePanelState } = useProfilePanelState();
  const isPreferencesModalOpen = !!state?.settings?.preferences;
  const history = useHistory();
  const appDispatch = useGlobalDispatch();
  const { t } = useTranslation();
  const { isDark, changeTheme } = useTheme();
  const isFeedbackModalOpen = useGlobalState('isFeedbackModalOpen');
  const canSeeSettingsScreen = useHasPermission([SCOPES.canSeeSettingsScreen]);

  const openFeedbackModal = useCallback(() => {
    if (!isFeedbackModalOpen) {
      appDispatch({ type: 'TOGGLE_FEEDBACK_MODAL' });
    }
  }, [appDispatch, isFeedbackModalOpen]);

  const openPreferencesModal = useCallback(() => {
    setProfilePanelState({ settings: { preferences: {} } });
  }, [setProfilePanelState]);

  const closePreferencesModal = useCallback(() => {
    setProfilePanelState({ settings: {} });
  }, [setProfilePanelState]);
  const changeThemeHandler = () => {
    changeTheme();
  };

  const changeThemeAction = (
    <Switch value={isDark} className={classes.switch} />
  );

  const settings = isBusinessApp
    ? [
        {
          title: 'page_details',
          subTitle: 'name_username_l',
          icon: 'id-card',
          routeName: routeNames.settingsPageDetail,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'account',
          subTitle: 'account_type_delete',
          icon: 'user-circle',
          routeName: routeNames.settingsPageAccount,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'page_roles',
          subTitle: 'mgn_page_mem',
          icon: 'user-cog',
          routeName: routeNames.settingsPageRoles,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'privacy',
          subTitle: 'visi_block_user',
          icon: 'eye-slash',
          routeName: routeNames.settingsPagePrivacy,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'help_support',
          subTitle: 'faq_privacy_policy',
          icon: 'question-circle',
        },
        {
          title: 'feedback',
          subTitle: 'contact_us',
          icon: 'comment-alt-edit',
          onClick: openFeedbackModal,
        },
        {
          title: DARK_MODE,
          subTitle: isDark ? 'turn_on_l' : 'turn_off_l',
          icon: isDark ? 'lightbulb' : 'lightbulb-slash',
          onClick: changeThemeHandler,
          rightAction: changeThemeAction,
        },
      ]
    : [
        {
          title: 'profile_details',
          subTitle: 'name_username_l',
          icon: 'id-card',
          routeName: routeNames.settingsProfileDetail,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'account',
          subTitle: 'account_type_delete',
          icon: 'user-circle',
          routeName: routeNames.settingsAccount,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'privacy',
          subTitle: 'visi_block_user',
          icon: 'eye-slash',
          routeName: routeNames.settingsPrivacy,
          scopes: [SCOPES.canSeeSettingsScreen],
        },
        {
          title: 'security_login',
          subTitle: 'password_l_i_d',
          icon: 'shield-check',
          routeName: routeNames.settingsSecurity,
        },
        {
          title: 'help_support',
          subTitle: 'faq_privacy_policy',
          icon: 'question-circle',
        },
        {
          title: 'preferences',
          subTitle: 'sub_preferences',
          icon: 'user-cog',
          onClick: openPreferencesModal,
          doNotCloseAfterClick: true,
        },
        {
          title: 'feedback',
          subTitle: 'contact_us',
          icon: 'comment-alt-edit',
          onClick: openFeedbackModal,
        },
        {
          title: DARK_MODE,
          subTitle: isDark ? 'turn_on_l' : 'turn_off_l',
          icon: isDark ? 'lightbulb' : 'lightbulb-slash',
          onClick: changeThemeHandler,
          rightAction: changeThemeAction,
        },
      ];

  const onItemClickHandler = (item: any) => {
    if (item.title === 'help_support') {
      window.location.href = urls.helpAndSupport;
    } else if (item.onClick) {
      item.onClick();
    } else {
      history.push(item.routeName);
    }
    if (!item?.doNotCloseAfterClick) {
      closeProfilePanel();
    }
  };

  const { isMoreThanTablet } = useMedia();

  const seeAllHandler = () => {
    closeProfilePanel();

    history.push(
      isMoreThanTablet
        ? isBusinessApp
          ? routeNames.settingsPageDetail
          : routeNames.settingsProfileDetail
        : routeNames.settings
    );
  };

  if (isPreferencesModalOpen) {
    return <PreferencesModal onBack={closePreferencesModal} />;
  }

  return (
    <FixedRightSideModalDialog onClickOutside={closeProfilePanel}>
      <Flex className={classes.root}>
        <ModalHeaderSimple
          backButtonProps={{ onClick: onGoBack }}
          className={cnj(
            isBusinessApp && !canSeeSettingsScreen && classes.marginBottom
          )}
          title={t('settings')}
          visibleHeaderDivider
          rightContent={() => {
            return (
              <>
                <Flex className={classes.filler} />
                <PermissionsGate scopes={[SCOPES.canSeeSettingsScreen]}>
                  <BaseButton onClick={seeAllHandler}>
                    <Typography
                      color="brand"
                      className={classes.subTitle}
                      font="700"
                    >
                      {t('see_all')}
                    </Typography>
                  </BaseButton>
                </PermissionsGate>
              </>
            );
          }}
          noCloseButton
          hideBack={false}
        />

        <Flex className={classes.contentWrapper}>
          <SettingsContent
            items={settings}
            onItemClickHandler={onItemClickHandler}
          />
        </Flex>
      </Flex>
    </FixedRightSideModalDialog>
  );
};

export default Settings;
