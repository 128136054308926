import {
  useReactQuery,
  QueryKeys,
  getUnSeenNotificationsCount,
} from '@lobox/utils';
import type { UseQueryResult } from '@tanstack/react-query';

interface IUnSeenCount {
  lastOpenedDate: Date;
  unSeenCount: string;
  unOpenedCount: string;
}

export type ReturnType = UseQueryResult<IUnSeenCount>;

const useGetUnSeenNotificationsCount = (): ReturnType =>
  useReactQuery({
    action: {
      apiFunc: getUnSeenNotificationsCount,
      key: [QueryKeys.unSeenNotificationCounts],
    },
    config: {
      refetchOnWindowFocus: true,
      enabled: true,
      refetchInterval: 1000 * 60,
    },
  });

export default useGetUnSeenNotificationsCount;
