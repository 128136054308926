import classes from './ActionRow.module.scss';

import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import React from 'react';
import type { FC } from 'react';
import Link from '@lobox/uikit/Link';
import BaseButton from '@lobox/uikit/Button/BaseButton';

interface ActionRowProps {
  label: string;
  icon: string;
  onClick: VoidFunction;
  rightIcon?: ReactNode;
  to?: string;
}

const ActionRow: FC<ActionRowProps> = (props) => {
  const { to, label, icon, onClick, rightIcon } = props;

  const CP = to ? Link : BaseButton;

  return (
    <CP to={to} onClick={onClick}>
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.iconWrapper}>
            <Icon name={icon} size={20} type="far" />
          </div>

          <Typography color="primaryText" size={16} font="500">
            {label}
          </Typography>
        </div>

        {rightIcon || null}
      </div>
    </CP>
  );
};

export default ActionRow;
