import classes from './index.module.scss';

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import FixedRightSideModal from '@shared/components/molecules/Modal/FixedRightSideModalDialog';
import Avatar from '@lobox/uikit/Avatar';
import AvatarCard from '@lobox/uikit/AvatarCard';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Button from '@lobox/uikit/Button';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';

import useGetAppObject from '@shared/hooks/useGetAppObject';

import {
  event,
  routeNames,
  useGlobalDispatch,
  useTranslation,
} from '@lobox/utils';

import eventKeys from '@shared/constants/event-keys';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { useHistory, useLocation } from '@lobox/utils';
import urls from '@shared/constants/urls';
import useGetMyPages from '@shared/hooks/api-hook/useGetMyPages';
import HalfArrowIcon from '@shared/svg/HalfArrowIcon';
import openBusinessApp from '@shared/utils/openBusiness';
import type { FC } from 'react';
import BottomBar from '../../AppLayout.Bottombar';
import AppLayoutHeader from '../../AppLayout.Header';
import ActionRow from '../partials/ActionRow';
import MyPagesModal from './MyPagesModal';
import FooterLinksPage from './FooterLinksPage';

interface ProfilePanelMobileProps {
  closeProfilePanel: VoidFunction;
  handleLogout: VoidFunction;
}

const PorfilePanelMobile: FC<ProfilePanelMobileProps> = (props) => {
  const { closeProfilePanel: close, handleLogout } = props;
  const { isBusinessApp, getAppObjectPropValue, authUser, reFetchAppObject } =
    useGetAppObject();

  useEffect(() => {
    reFetchAppObject();
  }, []);

  const { data: myPagesData } = useGetMyPages();

  const [showFooterLinks, setShowFooterLinks] = useState(false);
  const [isPagesModalOpen, setIsPagesModalOpen] = useState(false);
  const { pathname } = useLocation();

  const history = useHistory();
  const location = useLocation();
  const globalDispatch = useGlobalDispatch();

  const { t } = useTranslation();

  const username = getAppObjectPropValue({
    userKey: 'username',
    pageKey: 'username',
  });
  const usernameRoute = `/${username}`;

  const closeProfilePanel = (routeName: string) => {
    if (pathname === routeName) {
      close();
    }
  };

  const pages = [
    {
      page: {
        username: authUser?.username,
        title: authUser?.fullName,
        croppedImageUrl: authUser?.croppedImageUrl,
        id: authUser?.id,
      },
    },
    ...(myPagesData || []),
  ]?.filter((item) => item?.page?.username !== username);

  const recentPage = pages?.[0];

  const title = getAppObjectPropValue({
    userKey: 'fullName',
    pageKey: 'title',
  });

  const croppedImageUrl = getAppObjectPropValue({
    userKey: 'croppedImageUrl',
    pageKey: 'croppedImageUrl',
  });

  const togglePagesModal = () => {
    setIsPagesModalOpen((prev) => !prev);
  };

  const toggleFooterLinksPage = () => {
    setShowFooterLinks((prev) => !prev);
  };

  const onOpenProfilePage = () => {
    closeProfilePanel(usernameRoute);
    history.push({ pathname: usernameRoute });
  };

  const onSwitchToRecentPageClick = () => {
    const userName = recentPage.page.username;
    const { id } = recentPage.page;

    if (id === authUser?.id) {
      history.push({ pathname: usernameRoute });
    } else {
      openBusinessApp(userName);
      // closeProfilePanel(usernameRoute);
    }
  };

  const actions = useMemo(
    () => [
      {
        id: 'home',
        label: t('home'),
        icon: 'house-light',
        link: routeNames.home,
        onClick: () => {
          event.trigger(eventKeys.scrollToTopFeedList);
        },
        scopes: [SCOPES.canSeeHomeScreen],
      },
      {
        id: 'pages',
        label: t('pages'),
        icon: 'flag-light',
        link: routeNames.pagesDiscover,
        onClick: () => {
          event.trigger(eventKeys.scrollToTopFeedList);
          globalDispatch({
            type: 'PARENT_PATHNAME',
            payload: location.pathname,
          });
        },
        scopes: [SCOPES.canSeePagesScreen],
      },
      {
        id: 'schedules',
        label: t('schedules'),
        icon: 'calendar-light',
        link: routeNames.schedulesCalendarDay,
        onClick: () => {
          event.trigger(eventKeys.scrollToTopFeedList);
          globalDispatch({
            type: 'PARENT_PATHNAME',
            payload: location.pathname,
          });
        },
        scopes: [SCOPES.canSeePagesScreen],
      },
      {
        id: 'messages',
        label: t('messages'),
        icon: 'comment-alt-lines-light',
        link: routeNames.messages,
        scopes: [SCOPES.canSeeMessagesScreen],
      },
      {
        id: 'jobs',
        label: t('jobs'),
        icon: 'briefcase-blank-light',
        link: isBusinessApp ? routeNames.openJobs : routeNames.discoverJobs,
        onClick: () => {
          event.trigger(eventKeys.scrollToTopFeedList);
          globalDispatch({
            type: 'PARENT_PATHNAME',
            payload: location.pathname,
          });
        },
        scopes: [SCOPES.canSeePeopleScreen],
      },
      {
        id: 'help',
        label: t('help'),
        icon: 'square-question-light',
        onClick: () => {
          window.location.href = urls.helpAndSupport;
        },
      },
      {
        id: 'feedback',
        label: t('feedback'),
        icon: 'message-exclamation-light',
        onClick: () => {
          globalDispatch({ type: 'TOGGLE_FEEDBACK_MODAL' });
          close();
        },
      },
      {
        id: 'settings',
        label: t('settings'),
        icon: 'gear-light',
        link: routeNames.settings,
        scopes: [SCOPES.canSeeSettingsScreen],
      },
    ],
    [t, isBusinessApp, globalDispatch, location.pathname]
  );

  if (showFooterLinks) {
    return (
      <FooterLinksPage
        closeProfilePanel={closeProfilePanel}
        onGoBack={toggleFooterLinksPage}
      />
    );
  }

  return (
    <>
      <FixedRightSideModal>
        <AppLayoutHeader />

        <Flex className={classes.content}>
          <Flex className={classes.avatarRow}>
            <AvatarCard
              onClick={onOpenProfilePage}
              data={{
                title,
                subTitle: `@${username}`,
                image: croppedImageUrl,
              }}
              titleProps={{
                size: 16,
                font: '700',
                height: 20,
                isTruncated: true,
                className: classes.truncatedText,
              }}
              subTitleProps={{
                color: 'fifthText',
                height: 21,
                size: 15,
                mt: 0,
                isTruncated: true,
                className: classes.truncatedText,
              }}
              avatarProps={{
                size: 'sm',
                isCompany: isBusinessApp,
              }}
            />

            <Flex className={classes.row}>
              {recentPage && (
                <BaseButton
                  onClick={onSwitchToRecentPageClick}
                  className={classes.recentPageContainer}
                >
                  <HalfArrowIcon />

                  <Avatar
                    size="xs"
                    className={classes.avatar}
                    isCompany={
                      recentPage?.page?.username !== authUser?.username
                    }
                    imgSrc={recentPage?.page?.croppedImageUrl}
                  />

                  <HalfArrowIcon className={classes.rotatedArrow} />
                </BaseButton>
              )}

              {myPagesData?.length > 1 && (
                <>
                  <Flex className={classes.smallSpacing} />
                  <IconButton name="chevron-down" onClick={togglePagesModal} />
                </>
              )}
            </Flex>
          </Flex>

          <Flex className={classes.divider}>
            <Divider />
          </Flex>

          <Flex className={classes.smallSpacing} />

          <Flex className={classes.actionsContainer}>
            {actions.map((action) => (
              <Fragment key={action.id}>
                <Flex className={classes.actionItem}>
                  <ActionRow
                    to={action.link}
                    icon={action.icon}
                    label={action.label}
                    onClick={() => {
                      action?.onClick?.();
                      if (action.link) {
                        closeProfilePanel(action.link);
                      }
                    }}
                  />
                </Flex>
              </Fragment>
            ))}
          </Flex>

          <Flex className={classes.smallSpacing} />

          <Flex className={classes.divider}>
            <Divider />
          </Flex>

          <Flex className={classes.smallSpacing} />

          <ActionRow
            {...{
              id: 'invite',
              label: t('invite'),
              icon: 'envelope-open-text-light',
            }}
            to={routeNames.invite}
            onClick={() => {
              closeProfilePanel(routeNames.invite);
            }}
          />

          <Flex className={classes.footerRow}>
            <Button
              onClick={handleLogout}
              label={t('logout_cap')}
              rightIcon="sign-out-alt"
              rightType="far"
              schema="gray-semi-transparent"
              className={classes.fullWidth}
            />

            <Flex className={classes.smallSpacing} />

            <Button
              rightIcon="link"
              label={t('footer_links')}
              schema="gray-semi-transparent"
              className={classes.fullWidth}
              onClick={toggleFooterLinksPage}
            />
          </Flex>
        </Flex>

        <BottomBar />
      </FixedRightSideModal>

      <MyPagesModal isOpen={isPagesModalOpen} onClose={togglePagesModal} />
    </>
  );
};

export default PorfilePanelMobile;
