import React from 'react';

const Highlight: React.FC = (props: any) => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="clip0high">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0high)">
      <path
        d="M53.2932 35.1035V38.8104C53.2932 39.6293 52.6294 40.2932 51.8105 40.2932H19.1898C18.3709 40.2932 17.707 39.6293 17.707 38.8104V35.1035H53.2932Z"
        fill="#5384EE"
      />
      <path
        d="M53.2932 4.70688V9.15516H17.707V4.70688C17.7095 3.88899 18.3719 3.22656 19.1898 3.22412H51.8105C52.6284 3.22656 53.2908 3.88899 53.2932 4.70688Z"
        fill="#64A2FF"
      />
      <path
        d="M53.2932 9.15527V35.1036C53.2908 35.9214 52.6284 36.5839 51.8105 36.5863H19.1898C18.3719 36.5839 17.7095 35.9214 17.707 35.1036V9.15527H53.2932Z"
        fill="#3F73E3"
      />
      <path
        d="M33.2761 2.48276C33.2761 1.66385 32.6122 1 31.7933 1C30.9744 1 30.3105 1.66385 30.3105 2.48276V4.7069C30.3105 5.5258 30.9744 6.18965 31.7933 6.18965C32.6122 6.18965 33.2761 5.5258 33.2761 4.7069V2.48276Z"
        fill="#072252"
      />
      <path
        d="M25.862 2.48276C25.862 1.66385 25.1981 1 24.3792 1C23.5603 1 22.8965 1.66385 22.8965 2.48276V4.7069C22.8965 5.5258 23.5603 6.18965 24.3792 6.18965C25.1981 6.18965 25.862 5.5258 25.862 4.7069V2.48276Z"
        fill="#072252"
      />
      <path
        d="M40.6901 2.48276C40.6901 1.66385 40.0263 1 39.2074 1C38.3885 1 37.7246 1.66385 37.7246 2.48276V4.7069C37.7246 5.5258 38.3885 6.18965 39.2074 6.18965C40.0263 6.18965 40.6901 5.5258 40.6901 4.7069V2.48276Z"
        fill="#072252"
      />
      <path
        d="M48.1042 2.48276C48.1042 1.66385 47.4403 1 46.6214 1C45.8025 1 45.1387 1.66385 45.1387 2.48276V4.7069C45.1387 5.5258 45.8025 6.18965 46.6214 6.18965C47.4403 6.18965 48.1042 5.5258 48.1042 4.7069V2.48276Z"
        fill="#072252"
      />
      <path
        d="M21.8131 14.2118H21.1847C20.8377 14.2118 20.5566 13.9305 20.5566 13.5835C20.5566 13.2365 20.8377 12.9551 21.1847 12.9551H21.8131C22.1601 12.9551 22.4415 13.2365 22.4415 13.5835C22.4415 13.9305 22.1601 14.2118 21.8131 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M24.956 14.2118H24.3276C23.9806 14.2118 23.6992 13.9305 23.6992 13.5835C23.6992 13.2365 23.9806 12.9551 24.3276 12.9551H24.956C25.303 12.9551 25.5841 13.2365 25.5841 13.5835C25.5841 13.9305 25.303 14.2118 24.956 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M28.0966 14.2118H27.4682C27.1212 14.2118 26.8398 13.9305 26.8398 13.5835C26.8398 13.2365 27.1212 12.9551 27.4682 12.9551H28.0966C28.4436 12.9551 28.725 13.2365 28.725 13.5835C28.725 13.9305 28.4436 14.2118 28.0966 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M31.2389 14.2118H30.6105C30.2635 14.2118 29.9824 13.9305 29.9824 13.5835C29.9824 13.2365 30.2635 12.9551 30.6105 12.9551H31.2389C31.5859 12.9551 31.8673 13.2365 31.8673 13.5835C31.8673 13.9305 31.5859 14.2118 31.2389 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M34.3798 14.2118H33.7514C33.4044 14.2118 33.123 13.9305 33.123 13.5835C33.123 13.2365 33.4044 12.9551 33.7514 12.9551H34.3798C34.7268 12.9551 35.0079 13.2365 35.0079 13.5835C35.0079 13.9305 34.7268 14.2118 34.3798 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M37.5224 14.2118H36.894C36.547 14.2118 36.2656 13.9305 36.2656 13.5835C36.2656 13.2365 36.547 12.9551 36.894 12.9551H37.5224C37.8694 12.9551 38.1508 13.2365 38.1508 13.5835C38.1508 13.9305 37.8694 14.2118 37.5224 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M40.663 14.2118H40.0346C39.6876 14.2118 39.4062 13.9305 39.4062 13.5835C39.4062 13.2365 39.6876 12.9551 40.0346 12.9551H40.663C41.01 12.9551 41.2914 13.2365 41.2914 13.5835C41.2914 13.9305 41.01 14.2118 40.663 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M43.8053 14.2118H43.1772C42.8302 14.2118 42.5488 13.9305 42.5488 13.5835C42.5488 13.2365 42.8302 12.9551 43.1772 12.9551H43.8053C44.1526 12.9551 44.4337 13.2365 44.4337 13.5835C44.4337 13.9305 44.1526 14.2118 43.8053 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M46.9462 14.2118H46.3178C45.9708 14.2118 45.6895 13.9305 45.6895 13.5835C45.6895 13.2365 45.9708 12.9551 46.3178 12.9551H46.9462C47.2932 12.9551 47.5746 13.2365 47.5746 13.5835C47.5746 13.9305 47.2932 14.2118 46.9462 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M50.0888 14.2118H49.4604C49.1134 14.2118 48.832 13.9305 48.832 13.5835C48.832 13.2365 49.1134 12.9551 49.4604 12.9551H50.0888C50.4358 12.9551 50.7172 13.2365 50.7172 13.5835C50.7172 13.9305 50.4358 14.2118 50.0888 14.2118Z"
        fill="#83B4FF"
      />
      <path
        d="M40.6634 33.0624H27.4682C27.1212 33.0624 26.8398 32.781 26.8398 32.434C26.8398 32.087 27.1212 31.8057 27.4682 31.8057H40.6634C41.0104 31.8057 41.2918 32.087 41.2918 32.434C41.2918 32.781 41.0104 33.0624 40.6634 33.0624Z"
        fill="#83B4FF"
      />
      <path
        d="M44.4337 33.0624H43.1772C42.8302 33.0624 42.5488 32.781 42.5488 32.434C42.5488 32.087 42.8302 31.8057 43.1772 31.8057H44.4337C44.7807 31.8057 45.0621 32.087 45.0621 32.434C45.0621 32.781 44.7807 33.0624 44.4337 33.0624Z"
        fill="#83B4FF"
      />
      <path
        d="M30.2141 25.6783L32.7082 28.164L42.1194 16.5773C42.398 16.2347 42.8315 16.0565 43.2705 16.1043C43.7099 16.1519 44.0949 16.4191 44.2935 16.8136V16.8167C44.5101 17.2477 44.4686 17.7632 44.1859 18.1538L34.2463 31.9145C34.0241 32.2232 33.6741 32.4145 33.2946 32.435C32.915 32.4559 32.5463 32.3035 32.2922 32.0208L28.2706 27.5701C27.9141 27.1747 27.836 26.6014 28.074 26.1251L28.1274 26.0189C28.3145 25.6441 28.6701 25.3819 29.0836 25.3146C29.497 25.247 29.9177 25.3824 30.2141 25.6783Z"
        fill="white"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
  </svg>
);
export default Highlight;
