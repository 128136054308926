import { geoApi, lookupApi, QueryKeys, useReactQuery } from '@lobox/utils';

const useDiscoverPlace = ({ isEnabled = false, text }: any): any =>
  useReactQuery({
    action: {
      apiFunc: () => geoApi.searchPlace({ text }),
      key: [QueryKeys.discoverPlace, text],
    },
    config: {
      enabled: isEnabled,
    },
  });

export default useDiscoverPlace;
