import classes from './Search.component.module.scss';

import React, { forwardRef, useRef } from 'react';
import isFunction from 'lodash/isFunction';
import { useTranslation } from '@lobox/utils';
import type { FocusEvent, HtmlHTMLAttributes } from 'react';
import cnj from '../utils/cnj';
import type { IconProps } from '../Icon';
import Icon from '../Icon';
import Flex from '../Flex';

export interface SearchInputV2Props {
  className?: any;
  inputStyle?: any;
  placeholder?: string;
  inputId?: string;
  onChange: (val: string) => void;
  value?: string;
  onClearSearch?: () => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  variant?: 'circle' | 'square';
  size?: 'large' | 'small';
  inputProps?: any;
  trashIconId?: string;
  inputRootClassName?: string;
  trashIconProps?: IconProps;
  searchIconProps?: IconProps;
}

const SearchInputV2 = (
  {
    className,
    inputStyle,
    onChange,
    value,
    onClearSearch,
    onFocus,
    variant = 'circle',
    placeholder,
    inputId,
    size = 'large',
    inputProps,
    trashIconId,
    inputRootClassName,
    trashIconProps = {},
    searchIconProps = {},
  }: SearchInputV2Props,
  ref: any
) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HtmlHTMLAttributes<HTMLDivElement>>();
  const inputRef = useRef<HTMLInputElement>();

  const handleFocus = (e: any) => {
    if (isFunction(onFocus)) {
      onFocus(e);
    }
  };
  const handleChanged = (e: any) => {
    const val = e.target.value;
    onChange(val);
  };

  const clear = () => {
    onClearSearch?.();
    if (onChange) {
      onChange('');
    }
  };

  return (
    <Flex ref={wrapperRef} className={cnj(classes.wrapper, className)}>
      <Flex
        className={cnj(
          classes.inputRoot,
          size === 'large' && classes.inputRootLarge,
          inputRootClassName
        )}
      >
        <input
          {...inputProps}
          id={inputId}
          ref={inputRef}
          value={value}
          placeholder={placeholder || t('search')}
          className={cnj(
            classes.input,
            variant === 'square' && classes.inputSquare,
            !!value && classes.inputActive,
            inputStyle
          )}
          onChange={handleChanged}
          onFocus={handleFocus}
        />
        {value && (
          <Flex
            ref={ref}
            id={trashIconId}
            onClick={clear}
            onKeyDown={clear}
            role="button"
            tabIndex="-1"
          >
            <Icon
              name="trash"
              type="fal"
              color="primaryText"
              size={14}
              {...trashIconProps}
              className={cnj(
                classes.clean,
                size === 'large' && classes.cleanLarge,
                trashIconProps?.className
              )}
            />
          </Flex>
        )}
        {!value && (
          <Flex>
            <Icon
              name="search"
              type="fal"
              color="inputPlaceholder"
              size={14}
              {...searchIconProps}
              className={cnj(
                classes.searchIcon,
                size === 'large' && classes.searchIconLarge,
                searchIconProps?.className
              )}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default forwardRef<any, SearchInputV2Props>(SearchInputV2);
