import React, { useEffect, useRef } from 'react';
import Flex from '../Flex';
import Typography from '../Typography';
import useHorizontalScroll from '../utils/useHorizontalScroll';
import cnj from '../utils/cnj';
import PrevButton from './Carousel.Prev';
import NextButton from './Carousel.Next';
import classes from './Carousel.Component.module.scss';

export interface CarouselProps {
  className?: string;
  headClassName?: string;
  areaClassName?: string;
  title?: string;
  moveWalkDistance?: number;
  children: React.ReactNode;
  visibleHead?: boolean;
  showCenterButtons?: boolean;
  resetObservers?: any[];
}

const Carousel: React.FC<CarouselProps> = ({
  className,
  headClassName,
  areaClassName,
  title,
  moveWalkDistance = 50,
  children,
  visibleHead = true,
  showCenterButtons,
  resetObservers = [],
}) => {
  const carouselRef = useRef<HTMLElement | undefined>();

  const nextHandler = () => {
    const carouselWrapper = carouselRef?.current;
    const currentPosition = carouselWrapper?.scrollLeft || 0;
    carouselWrapper?.scrollTo({
      left: currentPosition + moveWalkDistance,
      behavior: 'smooth',
    });
  };
  const prevHandler = () => {
    const carouselWrapper = carouselRef?.current;
    const currentPosition = carouselWrapper?.scrollLeft || 0;
    carouselWrapper?.scrollTo({
      left: currentPosition - moveWalkDistance,
      behavior: 'smooth',
    });
  };

  useHorizontalScroll(carouselRef);

  const hasCenterButtons = Boolean(
    showCenterButtons &&
      carouselRef?.current &&
      carouselRef?.current.scrollWidth > carouselRef?.current.clientWidth
  );

  useEffect(() => {
    if (!carouselRef?.current) return;
    carouselRef.current.scrollLeft = 0;
  }, [...resetObservers]);

  return (
    <Flex className={cnj(classes.carouselRoot, className)}>
      {visibleHead && (
        <Flex
          flexDir="row"
          className={cnj(classes.carouselHead, headClassName)}
        >
          <Typography
            font="700"
            size={20}
            height={23.44}
            color="primaryText"
            mr="auto"
          >
            {title}
          </Typography>
          {!showCenterButtons && (
            <Flex flexDir="row" className={classes.buttonsWrapper}>
              <PrevButton onClick={prevHandler} className={classes.button} />
              <NextButton onClick={nextHandler} className={classes.button} />
            </Flex>
          )}
        </Flex>
      )}
      <Flex className={cnj(showCenterButtons && classes.centerButtonsWrapper)}>
        <Flex
          flexDir="row"
          ref={carouselRef}
          className={cnj(classes.carouselArea, areaClassName)}
        >
          {hasCenterButtons && (
            <>
              <PrevButton
                onClick={prevHandler}
                className={cnj(classes.absButton, classes.leftCenterButton)}
                iconClassName={classes.icon}
              />
              <NextButton
                onClick={nextHandler}
                className={cnj(classes.absButton, classes.rightCenterButton)}
                iconClassName={classes.icon}
              />
            </>
          )}
          <Flex flexDir="row">{children}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Carousel;
