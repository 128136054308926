import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const HelpAccount: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#393939" />
      <path
        fill="#5384EE"
        d="M36.93 58.167v4.305a1.01 1.01 0 0 1-1.004 1.016H3.786a1.01 1.01 0 0 1-1.005-1.016v-4.305a10.03 10.03 0 0 1 3.816-7.89l8.735-6.867.861-.826 7.145-.217 1.042 1.043 8.735 6.868a10.03 10.03 0 0 1 3.816 7.889Z"
      />
      <path
        fill="#3F73E3"
        d="M25.662 44.418 24.38 43.41l-1.042-1.043-2.185.066-4.96.15-.861.827-8.735 6.868a10.03 10.03 0 0 0-3.816 7.889v4.305c0 .561.45 1.016 1.004 1.016H8.06v-3.36a10.03 10.03 0 0 1 3.816-7.889l1.45-1.14c1.307-1.027 3.02-1.402 4.609-.937a6.83 6.83 0 0 0 1.922.275c3.237 0 5.964-2.255 6.729-5.293l-.923-.726Z"
      />
      <path
        fill="#FFE5C2"
        d="M24.379 43.408c0 2.528-2.025 4.578-4.524 4.578-2.498 0-4.523-2.05-4.523-4.578V33.096h9.047v10.312Z"
      />
      <path
        fill="#485363"
        d="M29.386 25.097v-2.213c0-3.335-2.671-6.039-5.967-6.039h-8.246l-.784-1.518a.598.598 0 0 0-.87-.223 6.694 6.694 0 0 0-2.917 5.54v4.453l2.037 5.695h14.552l2.195-5.695Z"
      />
      <path
        fill="#072252"
        d="M15.33 25.835V21.38c0-1.701.643-3.316 1.762-4.536h-1.92l-.783-1.518a.598.598 0 0 0-.87-.223 6.694 6.694 0 0 0-2.917 5.54v4.453l2.037 5.695h4.465l-1.773-4.957Z"
      />
      <path
        fill="#FED2A4"
        d="M19.995 43.216a11.63 11.63 0 0 0 4.384-.854v-9.264h-9.047V43.41c0 2.529 2.025 4.578 4.523 4.578.56 0 1.1-.105 1.595-.293-1.012-.385-1.855-1.91-2.372-3.095a.986.986 0 0 1 .902-1.384h.015Z"
      />
      <path
        fill="#FFE5C2"
        d="M10.602 25.098v5.62l1.368.288.598 4.461 6.761 4.219 2.84.318 2.173-.317a9.516 9.516 0 0 0 5.044-8.425v-6.164c-6.296-1.165-12.558-1.178-18.784 0Z"
      />
      <path
        fill="#FED2A4"
        d="M15.33 30.207v-5.775a50.455 50.455 0 0 0-4.728.666v6.164c0 5.25 4.205 9.505 9.392 9.505a9.413 9.413 0 0 0 4.355-1.081c-4.997-.217-9.018-4.369-9.018-9.48Z"
      />
      <path
        fill="#5384EE"
        d="M10.522 63.488v-4.736a.913.913 0 0 0-.908-.919.913.913 0 0 0-.907.919v4.736h1.815ZM31.002 63.488v-4.736a.913.913 0 0 0-.907-.919.913.913 0 0 0-.907.919v4.736h1.814Z"
      />
      <path
        fill="#83B4FF"
        d="M26.814 59.904V47.72c0-1.085-.893-1.965-1.994-1.965H11.732c-1.101 0-1.994.88-1.994 1.965v12.184h17.076Z"
      />
      <path
        fill="#83B4FF"
        d="M29.814 45.755h-5.29c1.1 0 1.993.88 1.993 1.965v12.184h5.29V47.72c0-1.085-.892-1.965-1.993-1.965Z"
      />
      <path
        fill="#fff"
        d="M33.754 59.768H7.79a.669.669 0 0 0-.674.664c0 1.35 1.11 2.444 2.479 2.444h22.352c1.37 0 2.479-1.094 2.479-2.444a.669.669 0 0 0-.673-.664Z"
      />
      <path
        fill="#ECECEC"
        d="M33.754 59.768h-4.617v3.108h2.812c1.369 0 2.479-1.094 2.479-2.444a.669.669 0 0 0-.674-.664Z"
      />
      <path
        fill="#5384EE"
        d="M20.772 55.046c1.24 0 2.245-.99 2.245-2.213 0-1.222-1.005-2.213-2.245-2.213-1.24 0-2.245.99-2.245 2.213 0 1.222 1.005 2.213 2.245 2.213Z"
      />
      <path
        fill="#072252"
        d="M0 63.56C0 64.354.654 65 1.461 65h34.026c.48 0 .87-.385.87-.858v-1.688l-.126-.336H1.46C.654 62.118 0 62.764 0 63.56Z"
      />
      <path
        fill="#072252"
        d="M40.06 62.118h-3.83v2.024c0 .474-.39.858-.87.858h4.7c.806 0 1.46-.645 1.46-1.44 0-.797-.654-1.442-1.46-1.442Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={42.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <path
        fill="#5384EE"
        d="M36.93 58.167v4.305a1.01 1.01 0 0 1-1.004 1.016H3.786a1.01 1.01 0 0 1-1.005-1.016v-4.305a10.03 10.03 0 0 1 3.816-7.89l8.735-6.867.861-.826 7.145-.217 1.042 1.043 8.735 6.868a10.03 10.03 0 0 1 3.816 7.889Z"
      />
      <path
        fill="#3F73E3"
        d="M25.662 44.418 24.38 43.41l-1.042-1.043-2.185.066-4.96.15-.861.827-8.735 6.868a10.03 10.03 0 0 0-3.816 7.889v4.305c0 .561.45 1.016 1.004 1.016H8.06v-3.36a10.03 10.03 0 0 1 3.816-7.889l1.45-1.14c1.307-1.027 3.02-1.402 4.609-.937a6.83 6.83 0 0 0 1.922.275c3.237 0 5.964-2.255 6.729-5.293l-.923-.726Z"
      />
      <path
        fill="#FFE5C2"
        d="M24.379 43.408c0 2.528-2.025 4.578-4.524 4.578-2.498 0-4.523-2.05-4.523-4.578V33.096h9.047v10.312Z"
      />
      <path
        fill="#485363"
        d="M29.386 25.097v-2.213c0-3.335-2.671-6.039-5.967-6.039h-8.246l-.784-1.518a.598.598 0 0 0-.87-.223 6.694 6.694 0 0 0-2.917 5.54v4.453l2.037 5.695h14.552l2.195-5.695Z"
      />
      <path
        fill="#072252"
        d="M15.33 25.835V21.38c0-1.701.643-3.316 1.762-4.536h-1.92l-.783-1.518a.598.598 0 0 0-.87-.223 6.694 6.694 0 0 0-2.917 5.54v4.453l2.037 5.695h4.465l-1.773-4.957Z"
      />
      <path
        fill="#FED2A4"
        d="M19.995 43.216a11.63 11.63 0 0 0 4.384-.854v-9.264h-9.047V43.41c0 2.529 2.025 4.578 4.523 4.578.56 0 1.1-.105 1.595-.293-1.012-.385-1.855-1.91-2.372-3.095a.986.986 0 0 1 .902-1.384h.015Z"
      />
      <path
        fill="#FFE5C2"
        d="M10.602 25.098v5.62l1.368.288.598 4.461 6.761 4.219 2.84.318 2.173-.317a9.516 9.516 0 0 0 5.044-8.425v-6.164c-6.296-1.165-12.558-1.178-18.784 0Z"
      />
      <path
        fill="#FED2A4"
        d="M15.33 30.207v-5.775a50.455 50.455 0 0 0-4.728.666v6.164c0 5.25 4.205 9.505 9.392 9.505a9.413 9.413 0 0 0 4.355-1.081c-4.997-.217-9.018-4.369-9.018-9.48Z"
      />
      <path
        fill="#5384EE"
        d="M10.522 63.488v-4.736a.913.913 0 0 0-.908-.919.913.913 0 0 0-.907.919v4.736h1.815ZM31.002 63.488v-4.736a.913.913 0 0 0-.907-.919.913.913 0 0 0-.907.919v4.736h1.814Z"
      />
      <path
        fill="#83B4FF"
        d="M26.814 59.904V47.72c0-1.085-.893-1.965-1.994-1.965H11.732c-1.101 0-1.994.88-1.994 1.965v12.184h17.076Z"
      />
      <path
        fill="#83B4FF"
        d="M29.814 45.755h-5.29c1.1 0 1.993.88 1.993 1.965v12.184h5.29V47.72c0-1.085-.892-1.965-1.993-1.965Z"
      />
      <path
        fill="#fff"
        d="M33.754 59.768H7.79a.669.669 0 0 0-.674.664c0 1.35 1.11 2.444 2.479 2.444h22.352c1.37 0 2.479-1.094 2.479-2.444a.669.669 0 0 0-.673-.664Z"
      />
      <path
        fill="#ECECEC"
        d="M33.754 59.768h-4.617v3.108h2.812c1.369 0 2.479-1.094 2.479-2.444a.669.669 0 0 0-.674-.664Z"
      />
      <path
        fill="#5384EE"
        d="M20.772 55.046c1.24 0 2.245-.99 2.245-2.213 0-1.222-1.005-2.213-2.245-2.213-1.24 0-2.245.99-2.245 2.213 0 1.222 1.005 2.213 2.245 2.213Z"
      />
      <path
        fill="#072252"
        d="M0 63.56C0 64.354.654 65 1.461 65h34.026c.48 0 .87-.385.87-.858v-1.688l-.126-.336H1.46C.654 62.118 0 62.764 0 63.56Z"
      />
      <path
        fill="#072252"
        d="M40.06 62.118h-3.83v2.024c0 .474-.39.858-.87.858h4.7c.806 0 1.46-.645 1.46-1.44 0-.797-.654-1.442-1.46-1.442Z"
      />
    </svg>
  );
};
export default HelpAccount;
