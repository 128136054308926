import { uniqBy } from 'lodash';
import {
  ItemType,
  itemNormalizers,
  suggestionsTypeORder,
  typeOrder,
} from './shared';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';

type Item = {
  createdDate: string;
  croppedImageUrl: string;
  hashtag: string | null;
  id: string;
  name: string | null;
  occupationName: string | null;
  surname: string | null;
  title: string;
  type: ItemType;
  username: string;
  locationId?: string;
  locationOrigin?: string;
};
type Args = {
  id: string;
  userId: string;
  hashtags: Item[];
  pages: Item[];
  people: Item[];
  jobs: Item[];
  locations: Item[];
};
type Key = keyof Args;
type ArrayKeys = keyof Pick<Args, 'hashtags' | 'pages' | 'people' | 'jobs'>;

function suggestions(
  args: Partial<Args>,
  itemTypes: ItemType[] = ['PAGE', 'PERSON', 'HASHTAG', 'JOB']
): any[] {
  const modifiedArgs = Object.keys(args)
    ?.filter((key) => Array.isArray(args[key as Key]))
    ?.map((arrayKey) =>
      args[arrayKey as ArrayKeys]?.map((singleItem: Item) => ({
        ...singleItem,
        value:
          singleItem?.type === 'LOCATION'
            ? singleItem?.locationId
            : singleItem?.id,
        label:
          singleItem?.type === 'LOCATION'
            ? singleItem?.title
            : singleItem?.type === 'HASHTAG'
              ? singleItem?.hashtag
              : singleItem?.type === 'JOB'
                ? singleItem?.title
                : singleItem?.type === 'PAGE'
                  ? singleItem?.title
                  : singleItem?.type === 'PERSON'
                    ? `${singleItem?.name} ${singleItem?.surname}`
                    : singleItem?.username,
        imgUrl: '',
        type: singleItem?.type,
        secondaryTitle: singleItem?.locationOrigin,
      }))
    );

  const filteredArrays = modifiedArgs?.map((arr) =>
    arr?.filter((item: any) => itemTypes.includes(item?.type))?.filter(Boolean)
  );

  const res = getEvenItemsFromArrays(filteredArrays, 4, suggestionsTypeORder);
  res?.reverse?.();
  return res;
}

type RecentArgs = {
  id: string | null;
  userId: string | null;
  global: any[];
  hashtags: any[];
  jobs: any[];
  pages: any[];
  people: any[];
  locations: any[];
};

function recents(
  args: RecentArgs,
  module: ReturnType<
    typeof useGlobalSearchUtilities
  >['moduleSpecificSearchResultPage']['module'] = 'all'
): any[] {
  let items = [];
  switch (module) {
    case 'jobs': {
      items = args?.jobs;
      break;
    }
    case 'locations': {
      items = args?.locations;
      break;
    }
    default: {
      items = args?.global;
    }
  }
  const normalizedItems = items?.map((item: Required<{ type: ItemType }>) => {
    let res: any;
    switch (item?.type) {
      case 'JOB': {
        res = itemNormalizers.job([item] as any);
        break;
      }
      case 'HASHTAG': {
        res = itemNormalizers.hashtag([item] as any);
        break;
      }
      case 'LOCATION': {
        res = itemNormalizers.location([item] as any);
        break;
      }
      default: {
        res = itemNormalizers.personOrPageOrText({ content: [item] as any });
      }
    }
    return res;
  });
  const uniqueTester = (item: any) => item?.key + item?.label;

  return uniqBy(normalizedItems?.flat(), uniqueTester);
}
export function getEvenItemsFromArrays(
  arrays: any[][],
  wantedCount: number,
  ordersObject: typeof typeOrder | typeof suggestionsTypeORder
): any[] {
  const result: any[] = [];
  while (result?.length < wantedCount && arrays?.flat()?.length > 0) {
    arrays.forEach((array) => {
      const item = array.shift();
      if (item) result.push(item);
    });
  }

  return result.sort((a, b) => {
    const typeA = a.type === 'JOB' && a.isSpecific ? 'SPECIFIC_JOB' : a.type;
    const typeB = b.type === 'JOB' && b.isSpecific ? 'SPECIFIC_JOB' : b.type;

    return ordersObject[typeA as ItemType] - ordersObject[typeB as ItemType];
  });
}

export const normalizers = {
  suggestions,
  recents,
};
