import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import TagsInput from 'react-tagsinput';
import {
  useOutsideListener,
  useComponentDidUpdate,
  useStateCallback,
  useDebounceState,
} from '@lobox/utils';
import isFunction from 'lodash/isFunction';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Typography from '../Typography';
import Center from '../Layout/Center';
import Icon from '../Icon';
import classes from './InputTags.module.scss';

export interface InputTagsProps {
  className?: string;
  helperText?: string;
  errorText?: string;
  label?: string;
  maxTags?: number;
  onChange?: (tags: Array<string>) => void;
  validateDelay?: number;
  onlyUnique?: boolean;
  enterIcon?: {
    name: string;
    classes?: string;
  };
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InputTags = (
  {
    className,
    helperText,
    errorText,
    label,
    maxTags,
    onChange,
    validateDelay = 1000,
    onlyUnique = true,
    enterIcon,
  }: InputTagsProps,
  ref: any
) => {
  const wrapperRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [error, setError] = useState(false);
  const [focused, setFocus] = useState(false);
  const [tags, setTags] = useStateCallback<Array<string>>([]);
  const { debounceValue, value, setValue } = useDebounceState<string>(
    '',
    validateDelay
  );

  const labelColor = error ? 'error' : focused ? 'brand' : 'border';

  const handleClearInput = () => {
    setValue('', () => {
      inputRef.current.clearInput();
    });
  };
  const handleChange = (tgs: Array<string>) => {
    setTags(tgs, (newTags: Array<string>) => {
      handleClearInput();
      if (isFunction(onChange)) {
        onChange(newTags);
      }
    });
  };
  const handleInputChange = (e: any) => {
    setError(false);
    const v = e?.target?.value;
    setValue(v);
  };

  useEffect(() => {
    if (
      debounceValue &&
      emailRegex.test(debounceValue) &&
      !tags?.includes(debounceValue)
    ) {
      const newTags = [...(tags || []), debounceValue];
      handleChange(newTags);
    }
    if (debounceValue && !emailRegex.test(debounceValue)) {
      inputRef?.current.focus();
      setError(true);
    }
  }, [debounceValue]); // eslint-disable-line

  const handleClickArea = () => {
    inputRef?.current.focus();
    setFocus(true);
  };
  const pasteSplit = (data: string): Array<string> => {
    const separators = [',', ';', ' ', '\n', '\r'];
    return data.split(new RegExp(separators.join('|'))).map((d) => d.trim());
  };

  useOutsideListener(wrapperRef?.current, () => {
    if (!value && !tags?.length) {
      setFocus(false);
    }
  });

  useComponentDidUpdate<string>(
    (prevDeps, nextDeps) => {
      if (prevDeps?.split(',').length > nextDeps?.split(',').length) {
        handleClickArea();
      }
    },
    tags?.join(',') || ''
  );

  useImperativeHandle(ref, () => ({
    handleClear: () => {
      setTags([], handleClearInput);
    },
  }));

  const trim = (e: any) => {
    const v = e?.target?.value || '';
    e.target.value = v?.trim();
  };

  const borderColor = error ? 'error' : focused ? 'brand' : 'border';
  const hoverBorderColor = error ? 'error' : 'brand';

  const isEmpty = !tags?.length;

  return (
    <Flex
      ref={wrapperRef}
      className={cnj(
        classes.inputTagsContainer,
        isEmpty && classes.empty,
        className
      )}
    >
      <Flex
        onClick={handleClickArea}
        className={cnj(
          classes.inputWrapper,
          classes[`borderColor-${borderColor}`],
          classes[`hoverBorderColor-${hoverBorderColor}`]
        )}
      >
        {label && (
          <Typography
            className={cnj(
              classes.label,
              focused && classes.active,
              !tags?.length && classes.defaultActive
            )}
            color={labelColor}
          >
            {label}
          </Typography>
        )}
        <Center className={classes.inputInnerWrapper}>
          <TagsInput
            ref={inputRef}
            maxTags={maxTags}
            onlyUnique={onlyUnique}
            validationRegex={emailRegex}
            className={classes.inputTagsRoot}
            value={tags || []}
            onChange={handleChange}
            pasteSplit={pasteSplit}
            addKeys={[9, 13, 32, 188, 186]}
            inputProps={{
              placeholder: '',
              onInput: trim,
              onChange: handleInputChange,
              style:
                (tags?.length || 0) > 9
                  ? {
                      display: 'none',
                    }
                  : {},
            }}
          />
          {enterIcon?.name && value && (
            <Icon
              color="gray"
              type="far"
              name={enterIcon?.name}
              size={14}
              className={enterIcon?.classes}
            />
          )}
        </Center>
      </Flex>
      {(error || helperText) && (
        <Typography
          size={13}
          height={15}
          color="border"
          className={cnj(classes.helperText, error && classes.errorText)}
        >
          {error ? errorText || helperText : helperText}
        </Typography>
      )}
    </Flex>
  );
};

export default forwardRef(InputTags);
