import { routeNames } from '@lobox/utils';
import { searchGroupTypes } from '@shared/constants/search';
import type { CreateAttachmentPostProps } from '@shared/components/Organism/CreatePost/components/Attachment/Attachment.component';

export function getSharedEntityUrl(
  entityType: CreateAttachmentPostProps['entityType'] = 'job',
  entityData: any
): string {
  console.log('dadadaman', entityData);
  let url = window.location.href;
  switch (entityType) {
    case 'job': {
      url = `${window.location.origin}/${routeNames.searchJobs}`;
      const params = new URLSearchParams({
        searchEntity: 'jobs',
        currentEntityId: entityData?.id,
      });
      url = url.concat(`?${params?.toString()}`);
      break;
    }
    case 'post': {
      url = `${window.location.origin}/${routeNames.searchPosts}`;
      const params = new URLSearchParams({
        searchEntity: 'posts',
        currentEntityId: entityData?.id || entityData?.postId,
      });
      url = url.concat(`?${params?.toString()}`);
      break;
    }
    case 'profile':
    case 'page': {
      const { username } = entityData;
      url = `${window.location.origin}/${username}`;
      break;
    }
    case 'availability': {
      const { userId, id: availabilityId } = entityData;
      url = `${window.location.origin}${routeNames.schedulesAvailability}`;
      const params = new URLSearchParams({
        userId,
        availabilityId,
      });
      url = url.concat(`?${params?.toString()}`);
      break;
    }
    case 'MEETING':
    case 'TASK':
    case 'BIRTHDAY':
    case 'HOLIDAY': {
      const { type, id: eventId } = entityData;
      url = `${window.location.origin}${routeNames.schedulesCalendarDay}`;
      const params = new URLSearchParams({
        state: JSON.stringify({
          eventId,
          schedulesEventType: type,
          visibleForm: false,
          scheduleCreationModalData: {
            eventId,
            schedulesEventType: type,
            visibleForm: false,
          },
        }),
      });
      url = url.concat(`?${params?.toString()}`);
      break;
    }
    default: {
      url = 'unhandledURL';
      break;
    }
  }
  return url;
}
