import React from 'react';
import Slider from 'react-slider';
import type { ReactSliderProps } from 'react-slider';

const SimpleSlider = (
  props: ReactSliderProps,
  ref: React.LegacyRef<Slider>
) => <Slider {...props} ref={ref} />;

export default React.forwardRef(SimpleSlider);
