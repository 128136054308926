import classes from './PreferencesModal.schedule.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import type { ComponentProps, FC } from 'react';
import PreferencesSingleItem from './PreferencesSingleItem';
import ScheduleAvailabilityPreferencesModal from './PreferencesModal.schedule.availability';
import { useProfilePanelState } from '@shared/hooks/useSettingsState';
import ScheduleCalendarPreferencesModal from './PreferencesModal.schedule.calendar';

interface Props {
  onBack: () => void;
}

type Keys = undefined | 'availability' | 'calendar';

const subModalComponents: Record<Keys, any> = {
  availability: ScheduleAvailabilityPreferencesModal,
  calendar: ScheduleCalendarPreferencesModal,
} as const;

const SchedulePreferencesModal: FC<Props> = ({ onBack }) => {
  const { state, setProfilePanelState, closePanel } = useProfilePanelState();
  const opennedSubModal = Object?.keys?.(
    state?.settings?.preferences?.schedule
  )?.[0];
  const { t } = useTranslation();

  const items: Array<ComponentProps<typeof PreferencesSingleItem>> = [
    { title: 'general', subtitle: 'default_time_formats' },
    {
      title: 'calendar',
      subtitle: 'view_integrations',
      onClick: () =>
        setProfilePanelState({
          settings: { preferences: { schedule: { calendar: true } } },
        }),
    },
    {
      title: 'availability',
      subtitle: 'set_availability',
      onClick: () =>
        setProfilePanelState({
          settings: { preferences: { schedule: { availability: true } } },
        }),
    },
    { title: 'meetings', subtitle: 'general_audio_video_integrations' },
    { title: 'tasks', subtitle: '' },
    { title: 'events', subtitle: 'birthdays_workshops' },
  ];

  const onCloseProfilePanel = () => {
    closePanel();
  };

  if (opennedSubModal) {
    const SubModal = subModalComponents[opennedSubModal];
    return (
      <SubModal
        onBack={() =>
          setProfilePanelState({
            settings: { preferences: { schedule: {} } },
          })
        }
      />
    );
  }

  return (
    <FixedRightSideModalDialog onClickOutside={onCloseProfilePanel}>
      <Flex className={classes.root}>
        <Flex className={cnj(classes.header)}>
          <IconButton
            onClick={onBack}
            size="md20"
            name="chevron-left"
            colorSchema="transparent"
          />
          <Typography
            font="700"
            color="hover2_coal"
            className={classes.title}
            size={20}
            height={24}
          >
            {t('schedule_preferences')}
          </Typography>
          <Flex className={classes.fillerItem} />
        </Flex>
        <Flex className={classes.body}>
          {items.map((item) => (
            <PreferencesSingleItem {...item} />
          ))}
        </Flex>
      </Flex>
    </FixedRightSideModalDialog>
  );
};

export default SchedulePreferencesModal;
