import React from 'react';
import Link from '@lobox/uikit/Link';
import Typography from '@lobox/uikit/Typography';
import { routeNames, useTranslation } from '@lobox/utils';
import useSetNotificationOpened from '@shared/hooks/useSetNotificationOpened';
import {
  FixedRightSideModalDialog,
  ModalBody,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';
import NotificationList from '@shared/components/Organism/NotificationList';
import classes from './NotificationModal.module.scss';
export interface NotificationModalProps {
  handleCloseModal: () => void;
}

const NotificationModal = ({
  handleCloseModal,
}: NotificationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { setNotificationsOpened } = useSetNotificationOpened();
  React.useEffect(() => {
    setNotificationsOpened();
  }, []);

  return (
    <FixedRightSideModalDialog onClickOutside={handleCloseModal}>
      <ModalHeaderSimple
        rightContent={() => (
          <Link className={classes.link} to={routeNames.notifications}>
            <Typography
              onClick={handleCloseModal}
              height={21}
              font="700"
              color="brand"
              size={15}
            >
              {t('see_all')}
            </Typography>
          </Link>
        )}
        title={t('notifications')}
        closeButtonProps={{ className: classes.closeButton }}
        backButtonProps={{ className: classes.closeButton }}
      />
      <ModalBody className={classes.modalBody}>
        <NotificationList isModalView />
      </ModalBody>
    </FixedRightSideModalDialog>
  );
};

export default NotificationModal;
