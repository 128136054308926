import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const EvolvementIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='highlight_evol_113a'>
        <path
          fill='#83B4FF'
          d='M3.552 50.527v10.48h7.48v-10.48a.925.925 0 0 0-.926-.926H4.477a.925.925 0 0 0-.925.926ZM14.025 46.04v14.968h7.48V46.04a.926.926 0 0 0-.926-.925H14.95a.926.926 0 0 0-.925.925ZM24.497 40.43v20.578h7.48V40.43a.926.926 0 0 0-.926-.926h-5.629a.926.926 0 0 0-.925.926ZM34.967 36.69v24.318h7.48V36.69a.926.926 0 0 0-.926-.926h-5.628a.926.926 0 0 0-.926.926Z'
        />
        <path
          fill='#5384EE'
          d='M24.497 51.075v9.933h7.48V47.442a65.175 65.175 0 0 1-7.48 3.633ZM19.812 52.813c-1.949.648-3.884 1.2-5.787 1.66v6.535h7.48v-8.78c-.558.2-1.123.395-1.693.585ZM3.552 56.102v4.906h7.48v-5.884a59.297 59.297 0 0 1-7.48.978ZM34.967 45.611v15.397h7.48V39.394c-1.953 2.14-4.485 4.25-7.48 6.218Z'
        />
        <path
          fill='#072252'
          d='M0 63.074v-1.14c0-.512.414-.926.926-.926h44.148c.512 0 .926.414.926.925v1.141a.926.926 0 0 1-.926.926H.926A.926.926 0 0 1 0 63.074Z'
        />
        <path
          fill='#fff'
          d='M40.951 18h-6.554a.926.926 0 0 0-.925.926v1.14c0 .512.414.926.925.926h2.943l-7.854 7.854h-6.205c-.42 0-.82.176-1.103.485l-8.812 9.612H4.386a.926.926 0 0 0-.926.926v1.14c0 .512.415.926.926.926h9.64c.419 0 .819-.176 1.102-.485l8.812-9.613h6.167c.397 0 .777-.157 1.058-.438l8.291-8.291v2.942c0 .511.415.926.926.926h1.14a.926.926 0 0 0 .926-.926v-6.554c0-.826-.67-1.496-1.496-1.496Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 18h46v46H0z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='highlight_evol_113a'>
        <path
          fill='#83B4FF'
          d='M3.552 50.527v10.48h7.48v-10.48a.925.925 0 0 0-.926-.926H4.477a.925.925 0 0 0-.925.926ZM14.025 46.04v14.968h7.48V46.04a.926.926 0 0 0-.926-.925H14.95a.926.926 0 0 0-.925.925ZM24.497 40.43v20.578h7.48V40.43a.926.926 0 0 0-.926-.926h-5.629a.926.926 0 0 0-.925.926ZM34.967 36.69v24.318h7.48V36.69a.926.926 0 0 0-.926-.926h-5.628a.926.926 0 0 0-.926.926Z'
        />
        <path
          fill='#5384EE'
          d='M24.497 51.075v9.933h7.48V47.442a65.175 65.175 0 0 1-7.48 3.633ZM19.812 52.813c-1.949.648-3.884 1.2-5.787 1.66v6.535h7.48v-8.78c-.558.2-1.123.395-1.693.585ZM3.552 56.102v4.906h7.48v-5.884a59.297 59.297 0 0 1-7.48.978ZM34.967 45.611v15.397h7.48V39.394c-1.953 2.14-4.485 4.25-7.48 6.218Z'
        />
        <path
          fill='#072252'
          d='M0 63.074v-1.14c0-.512.414-.926.926-.926h44.148c.512 0 .926.414.926.925v1.141a.926.926 0 0 1-.926.926H.926A.926.926 0 0 1 0 63.074Z'
        />
        <path
          fill='#5384EE'
          d='M40.951 18h-6.554a.926.926 0 0 0-.925.926v1.14c0 .512.414.926.925.926h2.943l-7.854 7.854h-6.205c-.42 0-.82.176-1.103.485l-8.812 9.612H4.386a.926.926 0 0 0-.926.926v1.14c0 .512.415.926.926.926h9.64c.419 0 .819-.176 1.102-.485l8.812-9.613h6.167c.397 0 .777-.157 1.058-.438l8.291-8.291v2.942c0 .511.415.926.926.926h1.14a.926.926 0 0 0 .926-.926v-6.554c0-.826-.67-1.496-1.496-1.496Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 18h46v46H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EvolvementIcon;
