import classes from './Checkin.locations.module.scss';

import React from 'react';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Tooltip from '@lobox/uikit/Tooltip';
import Spinner from '@lobox/uikit/Spinner';
import {
  getPlaceIcon,
  preventClickHandler,
  useTranslation,
} from '@lobox/utils';
import type { ILocation } from '@lobox/utils';

interface LocationsProps {
  items: ILocation[];
  selectedLocation: ILocation;
  onSelectLocation: (item?: ILocation) => void;
  isLoading: boolean;
}

const Locations: React.FC<LocationsProps> = ({
  items,
  selectedLocation,
  onSelectLocation,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<any>(selectedLocation);

  const toggleSelect = (item: any) => {
    setSelected(item);
    if (onSelectLocation) {
      onSelectLocation(item);
    }
  };
  const removeSelected = (e: any) => {
    preventClickHandler(e);
    setSelected(undefined);
    if (onSelectLocation) {
      onSelectLocation(undefined);
    }
  };

  return (
    <Flex className={classes.list}>
      {isLoading ? (
        <Spinner />
      ) : (
        items?.map((item: ILocation) => (
          <Flex
            key={item.value}
            className={cnj(
              classes.listItem,
              selected?.value === item.value && classes.itemSelected
            )}
            onClick={() => toggleSelect(item)}
          >
            <Flex className={classes.item}>
              <Flex className={classes.avatarContainer}>
                <Flex
                  className={cnj(
                    classes.icon,
                    selected?.value === item.value && classes.selectedIcon
                  )}
                >
                  <Icon
                    name={
                      item.category
                        ? getPlaceIcon(item.category)
                        : 'map-marker-alt'
                    }
                    color={selected?.value === item.value ? 'white' : 'brand'}
                  />
                </Flex>
              </Flex>
              <Flex className={classes.textPart}>
                <Typography
                  color={selected?.value === item.value ? 'white' : 'thirdText'}
                  font="700"
                  size={16}
                >
                  {item?.title}
                </Typography>
              </Flex>
            </Flex>
            <Flex>
              {selected?.value === item.value && (
                <Flex onClick={removeSelected}>
                  <Tooltip
                    placement="left"
                    trigger={
                      <Flex className={classes.removeIcon}>
                        <Icon color="white" name="times" type="fas" size={20} />
                      </Flex>
                    }
                  >
                    {t('remove_location')}
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          </Flex>
        ))
      )}
    </Flex>
  );
};

export default Locations;
