import classes from './Media.component.module.scss';

import React from 'react';
import {
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
  mediaCanBeUploaded,
} from '@lobox/utils';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import {
  ModalBody,
  ModalHeaderSimple,
  ModalFooter,
} from '@shared/components/molecules/Modal';
import { Alert } from '@shared/components/Organism/Notification/Notification.layout';
import {
  useCreatePostState,
  useCreatePostDispatch,
} from '../../context/createPost.provider';
import PostMediaItems from '../../components/Media';

const Media = (): JSX.Element => {
  const { t } = useTranslation();
  const { openConfirmDialog } = useOpenConfirm();
  const { closeModalOnBack } = useGlobalState('createPostModal');
  const appDispatch = useGlobalDispatch();
  const createPostDispatch = useCreatePostDispatch();
  const files = useCreatePostState('files');

  const closeModal = () => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        selectedPostForEdit: undefined,
        isOpenModal: false,
        attachment: undefined,
        currentTab: undefined,
      },
    });
  };

  const handleBackClicked = () => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: !closeModalOnBack,
        currentTab: 'main',
      },
    });
    createPostDispatch({ type: 'CLEAR_FILES' });
    createPostDispatch({
      type: 'SET_CROPPER_DATA',
      payload: { cropperData: {} },
    });
  };

  const handleCloseClicked = () => {
    openConfirmDialog({
      title: t('confirm_title'),
      isReverse: true,
      message: t('confirm_desc'),
      confirmButtonText: t('confirm_ok'),
      cancelButtonText: t('confirm_cancel'),
      isAjaxCall: false,
      cancelCallback: () => closeModal(),
    });
  };

  const handleClickOnDone = () => {
    if (!files.length) {
      appDispatch({
        type: 'SET_CREATE_POST_MODAL',
        payload: {
          isOpenModal: true,
          currentTab: 'main',
        },
      });
      return;
    }
    createPostDispatch({
      type: 'SET_UPLOAD_SUBMITTED',
      payload: { uploadSubmitted: true },
    });
  };

  const cannotUpload = !mediaCanBeUploaded(files);

  return (
    <>
      <ModalHeaderSimple
        closeButtonProps={{
          className: classes.closeBtn,
        }}
        backButtonProps={{
          onClick: handleBackClicked,
        }}
        title={t('edit_media_title')}
        hideBack={false}
      />
      <ModalBody className={classes.body}>
        <PostMediaItems />
        {cannotUpload && (
          <Flex className={classes.alertContainer}>
            <Alert variant="error" text={t('limit_100mb_rm_media')} />
          </Flex>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleClickOnDone}
          schema="primary-blue"
          disabled={cannotUpload}
          label={t('next')}
        />
      </ModalFooter>
    </>
  );
};

export default Media;
