import React from 'react';

const RecommendationWrite = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0ss)">
      <path
        d="M17.6205 13.192C17.6205 7.89106 22.1704 3.59375 27.7829 3.59375C33.3955 3.59375 37.9453 7.891 37.9453 13.192C37.9453 17.4458 35.0152 21.0527 30.9575 22.3119L31.5676 24.32C31.6168 24.482 31.4739 24.6355 31.3231 24.5826L25.5367 22.5545C21.005 21.5888 17.6205 17.7639 17.6205 13.192Z"
        fill="#83B4FF"
      />
      <path
        d="M27.7825 14.601C27.0885 14.601 26.5259 13.9967 26.5259 13.2513C26.5259 12.5059 27.0885 11.9016 27.7825 11.9016C28.4765 11.9016 29.0391 12.5059 29.0391 13.2513C29.0391 13.9967 28.4765 14.601 27.7825 14.601Z"
        fill="white"
      />
      <path
        d="M32.8918 14.6002C32.1978 14.6002 31.6352 13.996 31.6352 13.2506C31.6352 12.5052 32.1978 11.9009 32.8918 11.9009C33.5858 11.9009 34.1484 12.5052 34.1484 13.2506C34.1484 13.996 33.5858 14.6002 32.8918 14.6002Z"
        fill="white"
      />
      <path
        d="M22.6731 14.601C21.9791 14.601 21.4165 13.9967 21.4165 13.2513C21.4165 12.5059 21.9791 11.9016 22.6731 11.9016C23.3671 11.9016 23.9297 12.5059 23.9297 13.2513C23.9297 13.9967 23.3671 14.601 22.6731 14.601Z"
        fill="white"
      />
      <path
        d="M25.0459 3.94727C21.6569 5.50334 19.3208 8.78597 19.3208 12.5842C19.3208 17.1561 22.7054 20.981 27.237 21.9467L31.2772 23.3628L31.5682 24.3207C31.6174 24.4827 31.4746 24.6362 31.3237 24.5833L25.5373 22.5553C21.0057 21.5896 17.6211 17.7647 17.6211 13.1928C17.6212 8.78769 20.7632 5.07615 25.0459 3.94727Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M48.6151 5.32611L46.7044 4.64861L42.6653 17.5563L47.3641 14.9205L49.8483 7.9144C50.2225 6.85913 49.6703 5.70033 48.6151 5.32611Z"
          fill="#5384EE"
        />
        <path
          d="M46.7043 4.64866L45.7489 4.30995C44.6936 3.93576 43.5349 4.48796 43.1607 5.54317L40.6765 12.5492L44.0983 18.0645L47.9376 7.23694C48.3117 6.18168 47.7595 5.02287 46.7043 4.64866Z"
          fill="#072252"
        />
        <path
          d="M39.2609 19.5762L46.3481 17.7872L47.3644 14.9212L45.4537 14.2436L39.2609 19.5762Z"
          fill="#F7FCFF"
        />
        <path
          d="M45.4543 14.2432L40.6775 12.5494L39.6612 15.4155L43.0831 20.9308L45.4543 14.2432Z"
          fill="#E1F5FE"
        />
        <path
          d="M34.1296 41.6277L31.916 44.8375C31.9676 45.556 32.0141 46.2045 32.0141 46.2045C32.0458 46.6453 32.5871 46.8373 32.8894 46.5148L35.124 44.1313L34.1296 41.6277Z"
          fill="#072252"
        />
        <path
          d="M34.1305 41.6278L31.7811 42.9458C31.7811 42.9458 31.8529 43.9456 31.9169 44.8376L34.3673 42.224L34.1305 41.6278Z"
          fill="#485363"
        />
        <path
          d="M36.1625 35.8954L33.2136 43.4537L35.1243 44.1312L38.1512 40.9025L36.1625 35.8954Z"
          fill="#FED2A4"
        />
        <path
          d="M36.2404 40.2257L36.1624 35.8961L31.4636 38.5319L31.7804 42.9462L33.2135 43.4544L36.2404 40.2257Z"
          fill="#FFE5C2"
        />
        <path
          d="M36.241 40.2251L38.1517 40.9026L46.3482 17.7866L44.4375 17.1091L36.5178 27.3121L36.241 40.2251Z"
          fill="#83B4FF"
        />
        <path
          d="M31.4629 38.5305L39.6581 15.418L44.4342 17.1115L36.239 40.224L31.4629 38.5305Z"
          fill="#5384EE"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0ss">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1ss">
        <rect
          width="33.1996"
          height="33.1996"
          fill="white"
          transform="translate(18 16.8743) rotate(-25.4765)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RecommendationWrite;
