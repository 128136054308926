import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import { useGlobalState } from '@lobox/utils';
import type { IPost } from '@lobox/utils';
import CreatePostComponent from './CreatePost.component';
import { CreatePostProvider } from './context/createPost.provider';
// import { makeUpdatePostObject } from './createPost.utils';

interface CreatePostCompProps {
  highlights: any[];
  selectedPostForEdit: IPost;
  isCreation?: boolean;
}

const CreatePostComp = ({
  highlights,
  selectedPostForEdit,
  isCreation,
  ...rest
}: CreatePostCompProps): JSX.Element => {
  const { currentTab, attachment } = useGlobalState('createPostModal');

  return (
    <ErrorBoundaryToast>
      <CreatePostProvider
        initialValue={{
          highlights,
          isEditMode: !isCreation && !!selectedPostForEdit,
        }}
      >
        <CreatePostComponent
          selectedPostForEdit={selectedPostForEdit}
          attachment={attachment || selectedPostForEdit}
          currentTab={currentTab}
          {...rest}
        />
      </CreatePostProvider>
    </ErrorBoundaryToast>
  );
};

export default React.memo(CreatePostComp, () => true);
