import classes from './BulletLine.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';

type BulletLineProps = {
  visibleLine?: boolean;
  visibleDot?: boolean;
};
const BulletLine: React.FC<BulletLineProps> = ({ visibleLine, visibleDot }) => {
  return (
    <Flex className={classes.bulletWrapper}>
      {visibleDot && (
        <Flex className={classes.dotWrapper}>
          <Flex className={classes.dot} />
        </Flex>
      )}
      {visibleLine && <Flex className={classes.bulletLine} />}
    </Flex>
  );
};

export default BulletLine;
