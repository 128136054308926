import React from 'react';
import LogoIcon from '../shared/svg/LogoIcon';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import Flex from '../Flex/Flex.component';
import classes from './AppLogo.module.scss';

export interface AppLogoProps {
  className?: string;
  isBusinessApp?: boolean;
  label?: string;
  onClick?: any;
}

const AppLogo: React.FC<AppLogoProps> = ({
  label,
  isBusinessApp,
  className,
  onClick,
}) => {
  return (
    <Flex className={cnj(classes.appLogoRoot, className)} onClick={onClick}>
      <LogoIcon className={classes.logo} visibleBusiness={isBusinessApp} />
      {!!label && (
        <Typography
          font="400"
          size={15}
          height={21}
          color="brand"
          className={classes.businessWord}
        >
          {label}
        </Typography>
      )}
    </Flex>
  );
};

export default AppLogo;
