import classes from './PreferencesSingleItem.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import IconButton from '@lobox/uikit/Button/IconButton';
import { useTranslation } from '@lobox/utils';
import Link from '@lobox/uikit/Link';

interface Props {
  title: string;
  subtitle: string;
  onClick?: () => void;
  classNames?: {
    container: string;
  };
  routeName?: string;
}

const PreferencesSingleItem = ({
  title,
  subtitle,
  onClick,
  classNames,
  routeName,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const CP = routeName ? Link : BaseButton;
  const cpProps = routeName ? { to: routeName } : { onClick };

  return (
    <CP
      className={cnj(classes.headerItemWrapper, classNames?.container)}
      {...cpProps}
    >
      <Flex className={classes.textWrapper}>
        <Flex className={classes.titleWrapper}>
          <Typography font="700" color="thirdText" size={16} height={20}>
            {t(title)}
          </Typography>
          <IconButton
            type="far"
            name="chevron-right"
            size="md18"
            colorSchema="transparent"
            className={classes.arrowButton}
          />
        </Flex>
        {!!subtitle && (
          <Typography
            isTruncated
            color="fifthText"
            size={15}
            height={21}
            className={classes.subtitle}
          >
            {t(subtitle)}
          </Typography>
        )}
      </Flex>
    </CP>
  );
};

export default PreferencesSingleItem;
