import classes from './index.module.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { FollowersAndFollowings } from '@shared/components/molecules';
import Avatar from '@lobox/uikit/Avatar';
import Button from '@lobox/uikit/Button';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import Image from '@lobox/uikit/Image';
import {
  useGetObjectDetail,
  useGlobalDispatch,
  useTranslation,
  routeNames,
} from '@lobox/utils';
import { useHistory } from '@lobox/utils';
import urls from '@shared/constants/urls';
import MyPages from '@shared/components/Organism/MyPages';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import type { NetworkModalObjectDataType } from '@shared/types';
import type { FC } from 'react';
import ActionRow from '../partials/ActionRow';
import SettingsPage from './SettingsPage';
import HelpPage from './HelpPage';
import { useProfilePanelState } from '@shared/hooks/useSettingsState';

interface ProfilePanelDesktopProps {
  closeProfilePanel: VoidFunction;
  handleLogout: VoidFunction;
}

const ProfilePanelDesktop: FC<ProfilePanelDesktopProps> = (props) => {
  const { closeProfilePanel, handleLogout } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const { getAppObjectPropValue, reFetchAppObject } = useGetAppObject();

  useEffect(() => {
    reFetchAppObject();
  }, []);

  const [isShowingHelp, setIsShowingHelp] = useState(false);
  const { state, setProfilePanelState } = useProfilePanelState();
  const isShowingSettings = !!state?.settings;

  const globalDispatch = useGlobalDispatch();

  const username = getAppObjectPropValue({
    pageKey: 'username',
    userKey: 'username',
  });

  const { data: objectDetails } = useGetObjectDetail({
    username,
  });

  const title = getAppObjectPropValue({
    userKey: 'fullName',
    pageKey: 'title',
  });

  const network = getAppObjectPropValue({
    pageKey: 'network',
    userKey: 'network',
  });

  const croppedImageUrl = getAppObjectPropValue({
    userKey: 'croppedImageUrl',
    pageKey: 'croppedImageUrl',
  });

  const usernameAtSign = getAppObjectPropValue({
    userKey: 'usernameAtSign',
    pageKey: 'usernameAtSign',
  });

  const isPage = isBusinessApp;

  const headerImageUrl = (objectDetails as any)?.[
    isPage ? 'croppedHeaderImageUrl' : 'croppedHeaderImageLink'
  ];

  const categoryOrJobData = isPage
    ? t(objectDetails?.category?.label)
    : objectDetails?.occupation?.label;

  const location = objectDetails?.location?.label;

  const placeHolderImage = isPage ? urls.placeholderPage : urls.placeholderUser;

  const onClickPosts = () => {
    closeProfilePanel();
    history.push(`/${username}${routeNames.feed}`);
  };

  const onOpenProfilePage = () => {
    closeProfilePanel();
    history.push(`/${username}`);
  };

  const onToggleShowSettings = () => {
    if (isShowingSettings) {
      setProfilePanelState({});
    } else {
      setProfilePanelState({
        settings: {},
      });
    }
  };
  const onToggleShowHelp = () => {
    setIsShowingHelp((prev) => !prev);
  };

  const actions = useMemo(
    () => [
      {
        id: 'settings',
        label: t('settings'),
        icon: 'gear',
        onClick: onToggleShowSettings,
      },
      {
        id: 'help',
        label: t('help'),
        icon: 'square-question',
        onClick: onToggleShowHelp,
      },
      {
        id: 'feedback',
        label: t('feedback'),
        icon: 'message-exclamation',
        onClick: () => {
          closeProfilePanel();
          globalDispatch({ type: 'TOGGLE_FEEDBACK_MODAL' });
        },
      },
    ],
    []
  );

  const networkModalObject = {
    followers: network?.followersCounter,
    following: network?.followingsCounter,
    postsCounter: network?.postsCounter,
    objectId: objectDetails?.id,
    objectUsername: objectDetails?.username,
    isPage,
    network,
    objectTitle: title,
  } as NetworkModalObjectDataType;

  if (isShowingSettings) {
    return (
      <SettingsPage
        onGoBack={onToggleShowSettings}
        closeProfilePanel={closeProfilePanel}
      />
    );
  }
  if (isShowingHelp) {
    return (
      <HelpPage
        onGoBack={onToggleShowHelp}
        closeProfilePanel={closeProfilePanel}
      />
    );
  }

  return (
    <FixedRightSideModalDialog onClickOutside={closeProfilePanel}>
      <Flex className={classes.root}>
        <Flex className={classes.fullFlex}>
          <Flex className={classes.header}>
            <Image
              alt=" "
              className={classes.headerImage}
              src={headerImageUrl || placeHolderImage}
            />

            <IconButton
              size="md"
              name="times"
              onClick={closeProfilePanel}
              className={classes.closeIcon}
              iconProps={{
                color: 'white',
              }}
            />
          </Flex>

          <Flex className={classes.content}>
            <Avatar
              isCompany={isBusinessApp}
              size="vlg"
              imgSrc={croppedImageUrl}
              bordered
              className={classes.avatarContainer}
            />

            <Flex>
              <OverflowTip
                mb={4}
                size={20}
                color="thirdText"
                font="700"
                height={23}
              >
                {title}
              </OverflowTip>

              <Typography color="forthText" size={14} font="400" isWordWrap>
                {usernameAtSign}
              </Typography>

              <Flex className={classes.smallSpacing} />

              <Flex className={classes.row}>
                <Icon
                  color="primaryText"
                  name={isPage ? 'list-ul' : 'briefcase-blank'}
                  type="far"
                  size={14}
                />
                <OverflowTip
                  ml={6}
                  size={14}
                  font="400"
                  height={18}
                  isTruncated
                >
                  {categoryOrJobData}
                </OverflowTip>
              </Flex>

              {!!location && (
                <>
                  <Flex className={classes.smallSpacing} />

                  <Flex className={classes.row}>
                    <Icon
                      color="primaryText"
                      type="fal"
                      name="map-marker-alt"
                      size={14}
                    />

                    <Typography
                      ml={6}
                      size={14}
                      font="400"
                      height={18}
                      isTruncated
                    >
                      {location}
                    </Typography>
                  </Flex>
                </>
              )}

              <Flex className={classes.userInfoRow}>
                <FollowersAndFollowings
                  variant="col"
                  onClickPosts={onClickPosts}
                  networkModalObject={networkModalObject}
                />
              </Flex>

              <Button
                onClick={onOpenProfilePage}
                label={isPage ? t('view_page') : t('view_profile')}
                schema="semi-transparent"
              />
            </Flex>

            <Flex className={classes.normalSpacing} />

            {actions.map((item) => (
              <ActionRow
                {...item}
                key={item?.label}
                rightIcon={<Icon name="chevron-right" size={16} />}
              />
            ))}

            <Flex className={classes.normalSpacing} />

            <Divider />

            <Flex className={classes.normalSpacing} />

            <MyPages />
          </Flex>
        </Flex>

        <Divider />

        <Flex className={classes.logoutContainer}>
          <Button
            onClick={handleLogout}
            label={t('logout_cap')}
            rightIcon="sign-out-alt"
            rightType="far"
            schema="gray-semi-transparent"
          />
        </Flex>
      </Flex>
    </FixedRightSideModalDialog>
  );
};

export default ProfilePanelDesktop;
