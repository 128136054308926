import classes from './Checkin.component.module.scss';

import React from 'react';
import {
  useAuthUser,
  useDebounceState,
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
} from '@lobox/utils';
import Flex from '@lobox/uikit/Flex';
import SearchInput from '@lobox/uikit/SearchInput';
import Typography from '@lobox/uikit/Typography';
import {
  ModalBody,
  ModalDialog,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';
import useDiscoverPlace from '@shared/hooks/api-hook/useDiscoverPlace';
import Locations from './Checkin.locations';
import {
  useCreatePostDispatch,
  useCreatePostState,
} from '../../context/createPost.provider';

const CheckIn: React.FC<{ hasHeader?: boolean }> = ({ hasHeader = true }) => {
  const { t } = useTranslation();
  const { data: authUser } = useAuthUser();
  const selectedLocation = useCreatePostState('selectedLocation');
  const createPostDispatch = useCreatePostDispatch();
  const appDispatch = useGlobalDispatch();
  const { currentTab } = useGlobalState('createPostModal');
  const { setValue, debounceValue } = useDebounceState('', 350);

  const { data: suggestions = [], isLoading } = useDiscoverPlace({
    isEnabled: currentTab === 'checkin',
    text: debounceValue || authUser?.location?.label,
  });
  const handleSearch = (val: any) => setValue(val);

  function handleSelectLocation(item: any) {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        currentTab: 'main',
      },
    });

    createPostDispatch({
      type: 'SET_CHECK_IN',
      payload: item,
    });
  }

  function closeModal() {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        selectedPostForEdit: undefined,
        isOpenModal: false,
        attachment: undefined,
      },
    });
  }

  function handleBackModal() {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        currentTab: 'main',
      },
    });
  }

  return (
    <ModalDialog isOpen onBack={handleBackModal} onClose={handleBackModal}>
      <ModalHeaderSimple
        visibleHeaderDivider
        hideBack={false}
        backButtonProps={{
          onClick: handleBackModal,
        }}
        closeButtonProps={{
          className: classes.hideCloseBtn,
        }}
        title={t('checkin')}
      />
      <ModalBody className={classes.container}>
        <SearchInput onChange={handleSearch} className={classes.searchInput} />
        <Flex className={classes.listContainer}>
          {!isLoading && suggestions?.length === 0 && (
            <Flex className={classes.suggestionText}>
              <Typography color="border" font="400" size={15}>
                {t('empty_msg')}
              </Typography>
            </Flex>
          )}
          <Locations
            isLoading={isLoading}
            selectedLocation={selectedLocation}
            items={suggestions}
            onSelectLocation={handleSelectLocation}
          />
        </Flex>
      </ModalBody>
    </ModalDialog>
  );
};

export default CheckIn;
