import { formatDate, useTranslation } from '@lobox/utils';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import type { HighlightCardProps } from '@shared/components/molecules/HighlightCard/types';

const useHighlightCardData = () => {
  const { t } = useTranslation();

  const getHighlightCardProps = (
    type: keyof typeof HIGHLIGHT_TYPES,
    data: any
  ): HighlightCardProps => {
    const getData = (): HighlightCardProps['data'] => {
      switch (type) {
        case HIGHLIGHT_TYPES.HOBBY:
        case HIGHLIGHT_TYPES.SPORT:
        case HIGHLIGHT_TYPES.SIMPLE:
        case HIGHLIGHT_TYPES.ACQUIRED:
        case HIGHLIGHT_TYPES.ADVENTURE:
        case HIGHLIGHT_TYPES.INSTRUMENT:
        case HIGHLIGHT_TYPES.IPO:
        case HIGHLIGHT_TYPES.NEW_LOCATION:
        case HIGHLIGHT_TYPES.CONFERENCE_PARTICIPATION:
        case HIGHLIGHT_TYPES.NEW_TEAM_MEMBER:
        case HIGHLIGHT_TYPES.ANNIVERSARY:
          return {
            title: data?.title,
          };

        case HIGHLIGHT_TYPES.CHANGED_NAME:
        case HIGHLIGHT_TYPES.STOCK_VALUE_CHANGE:
        case HIGHLIGHT_TYPES.MARKET_VALUE:
          return {
            title: data?.title,
            subtitle: data?.subTitle,
            lineThrough: true,
          };

        case HIGHLIGHT_TYPES.JOB:
          return {
            title: data?.title,

            items: [
              {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
              data?.employmentType && {
                icon: 'clock',
                value: t(data?.employmentType),
              },
            ],
          };

        case HIGHLIGHT_TYPES.VOLUNTEERING:
          return {
            title: data?.title,
            items: [
              {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
              data?.employmentType && {
                icon: 'clock',
                value: t(data?.employmentType),
              },
              data?.volunteerCause && {
                icon: 'template',
                value: t(data?.volunteerCause),
              },
            ],
          };

        case HIGHLIGHT_TYPES.GET_PROMOTED:
          return {
            title: data?.title,

            items: [
              {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
            ],
          };

        case HIGHLIGHT_TYPES.ENTREPRENEURSHIP:
          return {
            title: data?.title,
            items: [
              {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
            ],
          };

        case HIGHLIGHT_TYPES.SCHOOL:
          return {
            title: data?.title,
            items: [
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
              data?.subTitle && {
                icon: 'book',
                value: data?.subTitle,
              },
              data?.degree && {
                icon: 'graduation',
                value: t(data?.degree),
              },
            ],
          };

        case HIGHLIGHT_TYPES.COURSE:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'building',
                value: data?.subTitle,
              },
            ],
          };

        case HIGHLIGHT_TYPES.GRADUATION:
          return {
            title: data?.title,
            items: [
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
              data?.subTitle && {
                icon: 'book',
                value: data?.subTitle,
              },
              data?.degree && {
                icon: 'graduation',
                value: t(data?.degree),
              },
            ],
          };

        case HIGHLIGHT_TYPES.STUDY_ABROAD:
          return {
            title: data?.title,
            items: [
              data?.location && {
                icon: 'map-marker-alt',
                value: data?.location?.title,
              },
              data?.subTitle && {
                icon: 'book',
                value: data?.subTitle,
              },
              data?.degree && {
                icon: 'graduation',
                value: t(data?.degree),
              },
            ],
          };

        case HIGHLIGHT_TYPES.TRAVEL:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'plane-departure',
                value: data?.subTitle,
              },
            ],
          };

        case HIGHLIGHT_TYPES.LICENSE:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.verificationCode && {
                icon: 'key',
                value: data?.verificationCode,
              },
              data?.link && {
                icon: 'link',
                value: data?.link,
                link: data?.link,
              },
            ],
          };

        case HIGHLIGHT_TYPES.GET_FUNDED:
        case HIGHLIGHT_TYPES.BUSSINES_GET_FUNDED:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'pay-check',
                value: `${data?.subTitle} ${data?.currency?.symbol || ''}`,
              },
              data?.institutionName && {
                icon: 'building',
                value: t(data?.institutionName),
              },
            ],
          };

        case HIGHLIGHT_TYPES.PATENT:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.patentId && {
                icon: 'key',
                value: data?.patentId,
              },
            ],
          };

        case HIGHLIGHT_TYPES.PUBLICATION:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.link && {
                icon: 'link',
                value: data?.link,
                link: data?.link,
              },
            ],
          };

        case HIGHLIGHT_TYPES.LANGUAGE:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'signal-bar',
                value: data?.subTitle,
              },
            ],
          };

        case HIGHLIGHT_TYPES.AWARD:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'building',
                value: data?.subTitle,
              },
            ],
          };

        case HIGHLIGHT_TYPES.CERTIFICATION:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'building',
                value: data?.subTitle,
              },
              data?.link && {
                icon: 'link',
                value: data?.link,
                link: data?.link,
              },
              data?.verificationCode && {
                icon: 'key',
                value: data?.verificationCode,
              },
            ],
          };

        case HIGHLIGHT_TYPES.SKILL:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'signal-bar',
                value: data?.subTitle,
              },
            ],
          };

        case HIGHLIGHT_TYPES.GET_INVESTED:
          return {
            title: data?.title,
            items: [
              data?.subTitle && {
                icon: 'pay-check',
                value: data?.subTitle,
              },
            ],
          };

        default:
          return {
            title: data?.title,
            items: [],
            subtitle: '',
          };
      }
    };

    const dataForProps = getData();
    const startDate = data?.startDate;
    const endDate = data?.endDate;
    const formattedEndDate = endDate
      ? formatDate(endDate, 'MMM y')
      : data?.currentlyWorking || data?.currentlyStudying
        ? t('present')
        : undefined;
    const formattedStartDate = startDate
      ? formatDate(startDate, 'MMM y')
      : undefined;

    const date = [formattedStartDate, formattedEndDate]
      .filter(Boolean)
      .join(' - ');

    return {
      type,
      title: t(`highlight_${type?.toLowerCase()}`),
      data: {
        date,
        ...dataForProps,
        items: dataForProps?.items?.filter(Boolean) || [],
      },
    };
  };

  return { getHighlightCardProps };
};

export default useHighlightCardData;
