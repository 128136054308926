import classes from '../Highlights.component.module.scss';

import React from 'react';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import List from './List';
import cnj from '@lobox/uikit/utils/cnj';
import Button from '@lobox/uikit/Button';
import { useTranslation } from '@lobox/utils';

interface Props<T> {
  handlePrev: (item: T) => void;
  handleNext: (item: T) => void;
  addNew: () => void;
  items: Array<T>;
  title: string;
  cp?: 'list' | 'progressBar';
  addAnotherButton: React.ReactElement;
}

const StepTree = ({
  handlePrev,
  handleNext,
  title,
  items,
  addNew,
  cp,
  addAnotherButton,
}: Props<any>) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeaderSimple
        backButtonProps={{
          onClick: handlePrev,
        }}
        closeButtonProps={{
          className: classes.closeBtn,
        }}
        title={title}
        hideBack={false}
        visibleHeaderDivider
      />
      <ModalBody
        className={cnj(classes.highlightBody, classes.userProfileList)}
      >
        <List
          addAnotherButton={addAnotherButton}
          cp={cp}
          data={items}
          onItemClicked={handleNext}
        />
      </ModalBody>
      {!!addNew && (
        <ModalFooter className={classes.modalFooter}>
          <Button
            onClick={addNew}
            schema="semi-transparent"
            leftIcon="plus"
            label={t('add_new')}
          />
        </ModalFooter>
      )}
    </>
  );
};

export default StepTree;
