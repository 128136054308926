let isDisabled: { [key: string]: boolean } = {};

export function throttle<T extends Function>(
  key: string,
  fn: T,
  interval: number,
  failedCallback?: Function
): () => T {
  return function () {
    if (isDisabled[key])
      return failedCallback && failedCallback?.apply(this, arguments);
    isDisabled[key] = true;
    setTimeout(() => (isDisabled[key] = false), interval);
    return fn.apply(this, arguments);
  };
}
