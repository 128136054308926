import classes from './ShareEntityCopyLink.component.module.scss';

import React, { useEffect } from 'react';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import Attachment from '../../../CreatePost/components/Attachment/Attachment.component';
import { ShareEntityCopyLinkFooter } from './ShareEntityCopyLink.footer';

type Props = { entityData: any };

export function ShareEntityCopyLink({ entityData = {} }: Props): JSX.Element {
  const { attachment } = entityData;
  const hasAttachment = !!attachment;
  const footerNoMarginTop = ['profile']?.includes(attachment?.type);
  const { authUser } = useGetAppObject();

  return (
    <Flex className={classes.sharedPostContainerWrapper}>
      {hasAttachment ? (
        <Flex className={cnj('contentElement', classes.sharedPostContainer)}>
          <Attachment
            entityType={attachment.type}
            entityData={attachment.data}
            hideActionButtons
            classNames={{ attachmentWrapper: classes.noEvents }}
          />
        </Flex>
      ) : null}
      <ShareEntityCopyLinkFooter
        entityType={attachment.type}
        classNames={{
          wrapper: footerNoMarginTop && classes.footerNoMarginTop,
        }}
        entityData={{ ...attachment?.data, userId: authUser?.id }}
      />
    </Flex>
  );
}
