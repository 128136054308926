import React from 'react';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import cnj from '@lobox/uikit/utils/cnj';
import HighlightCard from '@shared/components/molecules/HighlightCard';
import useFeedElement from '../../Context/useFeedElement';
import useHighlightCardData from './useHighlightCardData';
import classes from './HighlightPreview.component.module.scss';
import { useObjectClicks } from '@shared/hooks/useObjectClicks';

interface Props {
  className?: string;
  showDivider?: boolean;
}

const PostHighlightPreview = ({
  showDivider,
  className,
}: Props): JSX.Element => {
  const { getHighlightCardProps } = useHighlightCardData();
  const { highlightData, isSharedPost, hasBodyDescription } = useFeedElement();
  const { type, description } = highlightData || {};
  const { handleTagClick, handleHashtagClick } = useObjectClicks();

  return (
    <>
      <Flex
        className={cnj(
          classes.container,
          !showDivider && classes.container_bodyDescription,
          className
        )}
      >
        {isSharedPost && hasBodyDescription && (
          <Divider className={classes.divider} />
        )}
        <HighlightCard {...getHighlightCardProps(type, highlightData)} />
        {showDivider && <Flex className={classes.divider} />}
        {!!description && (
          <Flex className={classes.desc}>
            <RichTextView
              html={description}
              typographyProps={{
                size: 15,
                color: 'thirdText',
              }}
              showMore
              seeMoreTextProps={{}}
              onMentionClick={handleTagClick}
              onHashtagClick={handleHashtagClick}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default PostHighlightPreview;
