import classes from './Main.header.module.scss';

import React from 'react';
import { APP_ENTITIES } from '@lobox/utils';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import PostHeaderText from '@shared/components/Organism/FeedCard/Components/PostHeaderText';
import Typography from '@shared/uikit/Typography';

interface UserHeaderProps {
  withPersons?: any[];
  atLocation?: string;
  onLocationClicked?: () => void;
  onPersonsClicked?: () => void;
  classNames?: {
    container?: string;
  };
}

const UserHeader = ({
  atLocation,
  withPersons,
  onLocationClicked,
  onPersonsClicked,
  classNames,
}: UserHeaderProps): JSX.Element => {
  const { getAppObjectPropValue, isBusinessApp } = useGetAppObject();
  const username = getAppObjectPropValue({
    userKey: 'usernameAtSign',
    pageKey: 'usernameAtSign',
  });

  return (
    <Flex className={cnj(classes.container, classNames?.container)}>
      <Flex className={classes.avatar}>
        <Avatar
          isCompany={isBusinessApp}
          imgSrc={getAppObjectPropValue({
            userKey: 'croppedImageUrl',
            pageKey: 'croppedImageUrl',
          })}
          size="smd"
        />
      </Flex>
      <Flex className={classes.textsWrapper}>
        <Flex className={classes.headTexts}>
          <PostHeaderText
            postOwnerTitle={getAppObjectPropValue({
              userKey: 'fullName',
              pageKey: 'title',
            })}
            locationTitle={atLocation}
            tags={withPersons?.map((item) =>
              item.userType === APP_ENTITIES.person
                ? {
                    userType: APP_ENTITIES.person,
                    profileInfo: { ...item, userId: item.id },
                  }
                : { userType: APP_ENTITIES.page, pageInfo: item }
            )}
            onLocationClicked={onLocationClicked}
            onPersonsClicked={onPersonsClicked}
          />
        </Flex>
        {!!username && (
          <Typography size={12} height={14} color="secondaryDisabledText">
            {username}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

export default React.memo(UserHeader);
