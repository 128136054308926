import classes from './BottomBarItem.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import BaseButton from '@lobox/uikit/Button/BaseButton';

interface BottomBarProps {
  onClick?: any;
  active?: boolean;
  icon?: React.ReactNode;
  color?: string;
  visibleIndicator?: boolean;
  className?: string;
}

const BottomBarItem: React.FC<BottomBarProps> = ({
  onClick,
  active,
  icon,
  visibleIndicator = true,
  className,
}) => {
  return (
    <BaseButton
      className={cnj(
        classes.bottomBarRoot,
        active && classes.active,
        className
      )}
      onClick={onClick}
    >
      {visibleIndicator && (
        <Flex
          as="span"
          className={cnj(classes.indicator, active && classes.activeBackground)}
        />
      )}
      {icon}
    </BaseButton>
  );
};

export default BottomBarItem;
