import React from 'react';

const MarketValue = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9336 26.8701V35.5283H26.1125V26.8701C26.1125 26.4478 25.7702 26.1055 25.3479 26.1055H20.6982C20.2759 26.1055 19.9336 26.4478 19.9336 26.8701Z"
      fill="#83B4FF"
    />
    <path
      d="M28.5859 23.163V35.5286H34.7648V23.163C34.7648 22.7407 34.4225 22.3984 34.0002 22.3984H29.3505C28.9282 22.3985 28.5859 22.7408 28.5859 23.163Z"
      fill="#83B4FF"
    />
    <path
      d="M37.2363 18.529V35.5287H43.4152V18.529C43.4152 18.1067 43.0729 17.7644 42.6506 17.7644H38.0009C37.5787 17.7645 37.2363 18.1068 37.2363 18.529Z"
      fill="#83B4FF"
    />
    <path
      d="M45.8848 15.4396V35.5287H52.0636V15.4396C52.0636 15.0173 51.7213 14.675 51.299 14.675H46.6494C46.2271 14.6751 45.8848 15.0174 45.8848 15.4396Z"
      fill="#83B4FF"
    />
    <path
      d="M37.2363 27.3231V35.5284H43.4152V24.3218C41.5173 25.4037 39.4466 26.4145 37.2363 27.3231Z"
      fill="#5384EE"
    />
    <path
      d="M33.3669 28.7583C31.7566 29.2935 30.1583 29.7495 28.5859 30.1292V35.5284H34.7648V28.2747C34.3038 28.4405 33.8378 28.6018 33.3669 28.7583Z"
      fill="#5384EE"
    />
    <path
      d="M19.9336 31.4762V35.5286H26.1125V30.668C23.975 31.0825 21.9026 31.3503 19.9336 31.4762Z"
      fill="#5384EE"
    />
    <path
      d="M45.8848 22.8096V35.5286H52.0636V17.6736C50.4504 19.4415 48.3586 21.1835 45.8848 22.8096Z"
      fill="#5384EE"
    />
    <path
      d="M17 37.235V36.2927C17 35.8704 17.3423 35.5281 17.7646 35.5281H54.2354C54.6577 35.5281 55 35.8704 55 36.2927V37.235C55 37.6573 54.6577 37.9996 54.2354 37.9996H17.7646C17.3423 37.9996 17 37.6573 17 37.235H17Z"
      fill="#072252"
    />
    <path
      d="M50.829 0H45.4147C44.9924 0 44.6501 0.342297 44.6501 0.764602V1.70696C44.6501 2.12926 44.9924 2.47156 45.4147 2.47156H47.8455L41.3578 8.95939H36.2314C35.885 8.95939 35.5545 9.10478 35.3205 9.3601L28.0414 17.3008H20.622C20.1997 17.3008 19.8574 17.6431 19.8574 18.0654V19.0078C19.8574 19.4301 20.1997 19.7724 20.622 19.7724H28.5851C28.9315 19.7724 29.262 19.627 29.4961 19.3717L36.7751 11.4309H41.8697C42.1974 11.4309 42.5117 11.3008 42.7435 11.069L49.5932 4.21926V6.65007C49.5932 7.07238 49.9355 7.41468 50.3578 7.41468H51.3002C51.7225 7.41468 52.0648 7.07238 52.0648 6.65007V1.23574C52.0647 0.553301 51.5115 0 50.829 0V0Z"
      fill="white"
    />
    <path
      d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
      fill="#3F73E3"
    />
    <path
      d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
      fill="white"
    />
    <path
      d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
      fill="#3F73E3"
    />
  </svg>
);

export default MarketValue;
