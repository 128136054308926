export type ItemType =
  | 'PAGE'
  | 'PERSON'
  | 'HASHTAG'
  | 'JOB'
  | 'TEXT'
  | 'LOCATION';

export const typeOrder: Record<ItemType | 'SPECIFIC_JOB', number> = {
  PERSON: 0,
  PAGE: 1,
  SPECIFIC_JOB: 2,
  HASHTAG: 3,
  JOB: 4,
  TEXT: 5,
  LOCATION: 0,
};

export const suggestionsTypeORder: Record<ItemType | 'SPECIFIC_JOB', number> = {
  PERSON: 5,
  PAGE: 4,
  SPECIFIC_JOB: 3,
  JOB: 3,
  HASHTAG: 2,
  TEXT: 1,
  LOCATION: 0,
};

type JobItem = {
  imgUrl: string;
  label: string;
  type: 'OCCUPATION' | 'SKILL' | 'PAGE' | 'JOB';
  value: string;
  location?: string;
  locationName?: string;
  title?: string;
};
type PersonOrPageOrTextItem = Required<{
  id: string;
  type: 'PERSON' | 'PAGE' | 'TEXT';
  croppedImageUrl: string;
  title: string | null;
  fullName: string | null;
  usernameAtSign: string;
  username: string;
  name?: string;
  surname?: string;
}>;
type HashtagItem = { title: string; type: 'HASHTAG' };

export const itemNormalizers = {
  job: (items: JobItem[]) =>
    items?.map((item) => ({
      ...item,
      type: 'JOB',
      isSpecific: item?.type === 'JOB',
      subtitle: item?.subtitle || item?.location || item?.locationName,
      label: item?.label || item?.title,
    })),
  personOrPageOrText: (data: { content: PersonOrPageOrTextItem[] }) =>
    data?.content?.map((item) => ({
      ...item,
      value: item?.id,
      label: item?.title || item?.fullName || `${item?.name} ${item?.surname}`,
      imgUrl: item?.croppedImageUrl,
      isSpecific: item?.type !== 'TEXT',
      subtitle:
        item?.usernameAtSign || item?.username
          ? `@${item?.username}`
          : item?.locationName,
    })),
  hashtag: (data: { content: HashtagItem[] }) =>
    data?.content?.map((item) => ({ ...item, label: item?.title })),

  location: (items: any) =>
    items?.map((item: any) => ({
      ...item,
      label: item?.title || item?.name,
      secondaryTitle: item?.locationOrigin,
      value: item?.id,
    })),
};
