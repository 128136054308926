import classes from './SubCategory.card.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Paper from '@lobox/uikit/Paper';
import Typography from '@lobox/uikit/Typography';
import type { TypographyProps } from '@lobox/uikit/Typography';

interface Props {
  title?: string;
  subTitle?: React.ReactNode;
  caption?: string;
  message?: string;
  image?: any;
  className?: any;
  actionTitle?: string;
  hoverColor?: string;
  hasTitleBorder?: boolean;
  contentClassName?: string;
  action?: Function;
  onItemClicked: () => void;
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  titleProps?: Omit<TypographyProps, 'children'>;
  subtitleProps?: Omit<TypographyProps, 'children'>;
}

const Card: React.FC<Props> = ({
  title,
  subTitle,
  caption,
  message,
  image,
  className,
  contentClassName,
  actionTitle,
  action,
  direction = 'column',
  hoverColor,
  hasTitleBorder,
  titleProps,
  subtitleProps,
}) => {
  const isRow = direction === 'row';
  const isRowReverse = direction === 'row-reverse';
  const isColumnReverse = direction === 'column-reverse';
  const hasTitle = title || subTitle;

  return (
    <Paper
      {...{
        title,
        subTitle,
        className,
        contentClassName,
        direction,
        hoverColor,
        hasTitleBorder,
        titleProps,
        subtitleProps,
      }}
    >
      {image && (
        <Flex
          className={cnj(
            classes.imageContainer,
            isRow && classes.imageContainerRow,
            isRowReverse && classes.imageContainerRowReverse,
            isColumnReverse && classes.imageContainerColumnReverse,
            hasTitle && classes.imageContainerHasTitle
          )}
        >
          <Flex
            className={cnj(
              classes.imageWrap,
              isRow && classes.imageWrapRow,
              isColumnReverse && classes.imageWrapColumnReverse,
              isRowReverse && classes.imageWrapRowReverse
            )}
          >
            {image()}
            {/* <img className={classes.image} src={image} alt="lobox-empty-card" /> */}
          </Flex>
        </Flex>
      )}
      {caption && (
        <Typography
          textAlign="center"
          size={20}
          font="bold"
          mt={30}
          height={23}
          className={classes.caption}
        >
          {caption}
        </Typography>
      )}
      {message && (
        <Typography
          mt={16}
          height={21}
          textAlign="center"
          className={classes.message}
        >
          {message}
        </Typography>
      )}
      {(action || actionTitle) && (
        <Button
          schema="semi-transparent"
          className={classes.action}
          onClick={action as any}
          label={actionTitle}
        />
      )}
    </Paper>
  );
};

export default Card;
