import classes from './ShareEntityCopyLink.footer.module.scss';

import React, { useRef } from 'react';
import { ModalFooter } from '@shared/components/molecules/Modal';
import Button from '@lobox/uikit/Button';
import Center from '@lobox/uikit/Layout/Center';
import copyToClipboard from '@lobox/uikit/utils/copyToClipboard';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import { CopylinkInput } from '@shared/components/molecules/CopyLinkInput';
import { useTranslation } from '@lobox/utils';
import { CreateAttachmentPostProps } from '@shared/components/Organism/CreatePost/components/Attachment/Attachment.component';

type Props = {
  entityType: CreateAttachmentPostProps['entityType'];
  classNames?: {
    wrapper?: string;
  };
  entityData: any;
};

export function ShareEntityCopyLinkFooter({
  entityType,
  classNames,
  entityData,
}: Props) {
  const [isLinkCopiedRecently, setIsLinkCopiedRecently] = React.useState(false);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  return (
    <ModalFooter className={classes.modalFooter}>
      <Center className={cnj(classes.copyLinkWrapper, classNames?.wrapper)}>
        <Flex className={classes.linkContainer}>
          <CopylinkInput
            ref={inputRef}
            entityType={entityType}
            entityData={entityData}
          />
        </Flex>
        {!isLinkCopiedRecently ? (
          <Button
            key={t('copy_link')}
            leftIcon="copy"
            leftType="far"
            schema="primary-blue"
            className={classes.copyLinkButton}
            label={t('copy_link')}
            labelSize={15}
            onClick={() => {
              copyToClipboard(inputRef.current?.value);
              setIsLinkCopiedRecently(true);
              setTimeout(() => setIsLinkCopiedRecently(false), 2000);
            }}
          />
        ) : (
          <Button
            key={t('link_copied')}
            leftIcon="check-circle"
            leftType="fas"
            schema="success"
            className={cnj(
              classes.copyLinkButton,
              isLinkCopiedRecently && classes.copyLinkButtonDisabled
            )}
            label={t('link_copied')}
            labelSize={15}
          />
        )}
      </Center>
    </ModalFooter>
  );
}
