import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import useIsPagePublished from '@shared/hooks/useIsPagePublished';
import ResponsiveGlobalSearch from '@shared/components/molecules/ResponsiveGlobalSearch';
import NotificationButton from './NotificationButton';
import Create from './Create';
import classes from './TopBarItems.module.scss';
import Media from '@lobox/uikit/Media';
import IconButton from '@lobox/uikit/Button/IconButton';
import useGetUnseenMessageCount from '@shared/hooks/api-hook/useGetUnseenMessageCount';

interface Props extends React.PropsWithChildren {
  isSearchPageOnMobile: boolean;
  isSearchAllPage: boolean;
  handleMessagesClicked: () => void;
}

const TopBarItems: React.FC<Props> = ({
  isSearchPageOnMobile,
  isSearchAllPage,
  handleMessagesClicked,
}) => {
  const isPagePublished = useIsPagePublished();
  const unseenMessageCount = useGetUnseenMessageCount();

  return (
    <Flex className={cnj(classes.topBarItemsRoot)}>
      {isPagePublished && (
        <>
          <ResponsiveGlobalSearch
            iconViewClassName={classes.responsiveGlobalSearch}
            defaultView={isSearchAllPage ? 'input' : 'icon'}
          />

          {!isSearchPageOnMobile && (
            <>
              <Create />
              <NotificationButton />
            </>
          )}
        </>
      )}
      {!isSearchPageOnMobile && (
        <Media lessThan="midDesktop">
          <IconButton
            badgeNumber={unseenMessageCount}
            onClick={handleMessagesClicked}
            name="comment-alt-lines-light"
            size="md20"
            type="far"
            colorSchema="secondary2"
          />
        </Media>
      )}
    </Flex>
  );
};

export default TopBarItems;
