import React from 'react';
import SimpleSlider from '@lobox/uikit/SimpleSlider';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import classes from './TimeSlider.module.scss';

type Props = {
  isPlaying: boolean;
  videoRef: React.RefObject<HTMLVideoElement | undefined>;
  step?: number;
};

export function TimeSlider({
  isPlaying,
  videoRef,
  step = 0.05,
}: Props): JSX.Element {
  const [currentTime, setCurrentTime] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(0);
  const intervalId = React.useRef<NodeJS.Timeout>();

  const duration = videoRef.current?.duration || 0;

  const starting = currentTime === 0;
  const ending = currentTime === duration;

  const handleChangeTime = React.useCallback(
    (time: number) => {
      if (!videoRef.current) return;
      setCurrentTime(() => time);
      videoRef.current.currentTime = time;
    },
    [videoRef]
  );

  const onTimeUpdate = React.useCallback(() => {
    setCurrentTime(() => videoRef.current?.currentTime || 0);
  }, [videoRef]);

  React.useEffect(() => {
    intervalId.current = setInterval(
      () => {
        setSliderValue((val) => {
          const newValue = val + step;
          const videoTime = videoRef.current?.currentTime || 0;

          if (newValue !== videoTime) {
            return videoTime;
          }
          return newValue >= duration ? duration : newValue;
        });
      },
      1000 / (1 / step)
    );

    return () => {
      clearInterval(intervalId.current);
    };
  }, [duration, videoRef, isPlaying, step]);

  React.useEffect(() => {
    const ref = videoRef.current;
    ref?.addEventListener('timeupdate', onTimeUpdate);

    return () => {
      ref?.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [onTimeUpdate, videoRef]);

  return (
    <Flex className={classes.container} flexDir="row">
      <SimpleSlider
        value={sliderValue}
        min={0}
        max={duration}
        step={step}
        onChange={handleChangeTime}
        className={classes.slider}
        thumbClassName={cnj(
          classes.thumbClassName,
          (ending || starting) && classes.trackClassName_rad
        )}
        trackClassName="trackClassName"
      />
    </Flex>
  );
}

export default React.memo(TimeSlider);
