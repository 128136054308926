import classes from './PreferencesModal.schedule.availability.module.scss';

import React, { Fragment, useEffect, useRef, useState } from 'react';
import Accordion from '@lobox/uikit/Accordion';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import Spinner from '@lobox/uikit/Spinner';
import Button from '@lobox/uikit/Button';
import Switch from '@lobox/uikit/Switch';
import {
  QueryKeys,
  schedulesApi,
  schedulesDb,
  useReactMutation,
  useReactQuery,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import EmptySectionInModules from '@shared/components/molecules/EmptySectionInModules/EmptySectionInModules';
import { throttle } from '@shared/utils/throttle';
import type { UnArray } from '@shared/types/schedules';
import EventForm from './EventForm';
import type { FC } from 'react';
import { useProfilePanelState } from '@shared/hooks/useSettingsState';
import type { Timesheet } from './types';

interface Props {
  onBack?: () => void;
  isNotModal?: boolean;
  classNames?: {
    body?: string;
  };
}

const defaultTimesheet: Timesheet = {
  title: '60 minute meeting',
  duration: '_60_MINUTES',
  id: '',
  // @ts-ignore
  dailyHours: schedulesDb.weekDays
    .map((item) => {
      if (['SUNDAY', 'SATURDAY'].includes(item.value)) return false;
      return {
        day: item?.value,
        start: '08:00',
        end: '23:59:59.999',
        id: '',
      };
    })
    ?.filter(Boolean),
};

const ScheduleAvailabilityPreferencesModal: FC<Props> = ({
  onBack,
  isNotModal,
  classNames,
}) => {
  const [newTimesheet, setNewTimesheet] = useState<Timesheet | undefined>();
  const { t } = useTranslation();
  const isInitialLoad = useRef(true);
  const { closePanel } = useProfilePanelState();

  const { data = {}, isLoading } = useReactQuery<any>({
    action: {
      apiFunc: schedulesApi.getPreferences,
      key: QueryKeys.schedulesPreferences,
    },
  });
  const { mutate: updateTimesheet } = useReactMutation({
    apiFunc: schedulesApi.putTimeSheet,
  });

  const { refetch, replace } = useUpdateQueryData(
    QueryKeys.schedulesPreferences
  );
  const _timesheets: Timesheet[] = data?.calendar?.timesheets || [];
  const timesheets = [..._timesheets, newTimesheet]?.filter(Boolean);
  const isNotEmpty = !!timesheets?.length;

  const removeNewTimesheet = () => {
    setNewTimesheet(undefined);
  };

  const onCloseProfilePanel = () => {
    closePanel();
  };

  const handleToggle = (timesheet: Timesheet) =>
    throttle(
      `TIMESHEET_SWITCH_KEY_${timesheet?.id}`,
      (active: boolean) => {
        if (!timesheet?.id) return;
        const _timesheet = { ...timesheet, active };
        const newTimesheets = timesheets.map((item) =>
          item?.id === _timesheet?.id ? _timesheet : item
        );
        const newData = {
          ...data,
          calendar: { ...data?.calendar, timesheets: newTimesheets },
        };
        replace(newData);
        updateTimesheet(_timesheet, {
          onSuccess: () => {
            refetch();
          },
          onError: () => {
            replace(newData);
          },
        });
      },
      500
    );
  useEffect(() => {
    if (!isInitialLoad.current || isLoading) return;
    isInitialLoad.current = false;
  }, [isLoading]);

  const Tag = isNotModal ? Fragment : FixedRightSideModalDialog;

  return (
    <Tag onClickOutside={isNotModal ? undefined : onCloseProfilePanel}>
      <Flex className={classes.root}>
        {!isNotModal && (
          <Flex className={classes.header}>
            <IconButton
              onClick={onBack}
              size="md20"
              name="chevron-left"
              colorSchema="transparent"
            />
            <Typography
              font="700"
              color="hover2_coal"
              className={classes.title}
              size={20}
              height={24}
            >
              {t('availability_preferences')}
            </Typography>
            <Flex className={classes.fillerItem} />
          </Flex>
        )}
        <Flex
          className={cnj(classes.body, classNames?.body)}
          key={String(!!newTimesheet)}
        >
          {isLoading ? (
            <Spinner />
          ) : isNotEmpty ? (
            <Accordion
              // @ts-ignore
              data={timesheets.map((item, index) => ({
                key: item?.id + index,
                id: item?.title + index,
                isExpanded:
                  index !== 0
                    ? !item?.id
                    : timesheets?.length === 1
                      ? true
                      : isInitialLoad.current,
                divider: index !== timesheets?.length - 1,
                header: ({ isExpanded }: { isExpanded: boolean }) => (
                  <Flex className={classes.titleWrapper}>
                    <Typography
                      font="700"
                      color="thirdText"
                      size={16}
                      height={20}
                    >
                      {item?.title}
                    </Typography>
                    <Flex flexDir="row">
                      {!!item?.id && (
                        <Switch
                          onChange={handleToggle(item)}
                          value={item?.active}
                          onClick={(e) => e?.stopPropagation()}
                        />
                      )}
                      <IconButton
                        name={isExpanded ? 'chevron-up' : 'chevron-down'}
                        size="md15"
                        className={classes.icon}
                        colorSchema="secondary"
                      />
                    </Flex>
                  </Flex>
                ),
                content: () => (
                  <>
                    <EventForm
                      data={item}
                      removeNewTimesheet={removeNewTimesheet}
                    />
                    <Flex className={classes.margin} />
                  </>
                ),
              }))}
            />
          ) : (
            <EmptySectionInModules
              {...{
                title: t('no_availability'),
                text: t('you_do_not_have_any_availability'),
                buttonProps: {
                  title: t('new_availability'),
                  onClick: () => setNewTimesheet(defaultTimesheet),
                },
              }}
            />
          )}
          {isNotEmpty && (
            <Button
              fullWidth
              label={t('new_availability')}
              onClick={() => setNewTimesheet(defaultTimesheet)}
              leftIcon="plus"
              className={classes.newAvailabilityButton}
            />
          )}
        </Flex>
      </Flex>
    </Tag>
  );
};

export default ScheduleAvailabilityPreferencesModal;
