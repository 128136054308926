import React, { forwardRef } from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import classes from './Center.component.module.scss';

export interface CenterProps extends React.HTMLAttributes<HTMLDivElement> {}

const Center = forwardRef<HTMLDivElement, CenterProps>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <Flex className={cnj(classes.container, className)} ref={ref} {...rest} />
  );
});

export default Center;
