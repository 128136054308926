// @ts-nocheck

import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import GoogleMap from '@lobox/uikit/GoogleMap';
import IconButton from '@lobox/uikit/Button/IconButton';
import useTheme from '@lobox/uikit/utils/useTheme';
import { useTranslation } from '@lobox/utils';
import classes from './Map.component.module.scss';

interface MapProps {
  position?: {
    lat: string | number;
    lng: string | number;
  };
  handleRemove?: (e: any) => void;
  onEditClicked?: () => void;
}

const PostMap: React.FC<MapProps> = ({
  position,
  handleRemove,
  onEditClicked,
}) => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  return (
    <Flex className={classes.container}>
      <Flex className={classes.actions}>
        <Button
          schema="background"
          label={t('edit_location')}
          onClick={onEditClicked}
        />

        <IconButton
          colorSchema="primary"
          name="times"
          type="fas"
          className={classes.removeIcon}
          onClick={handleRemove}
        />
      </Flex>
      <Flex className={classes.map}>
        <GoogleMap
          draggable={false}
          dragging={false}
          value={position}
          defaultZoom={13}
          center={[position.lat, position.lon]}
          className={classes.map}
        />
      </Flex>
    </Flex>
  );
};

export default PostMap;
