import {
  useReactQuery,
  QueryKeys,
  useAuthState,
  getMyPages,
} from '@lobox/utils';
import type { PageStatusType, RoleType } from '@lobox/utils';
import type { UseQueryResult } from '@tanstack/react-query';

export type DataReturnType = {
  createdDate: string;
  id: string;
  lastModifiedDate: string;
  roles: Array<RoleType>;
  userId: string;
  notificationBadge?: {
    lastOpenedDate: Date;
    unSeenCount: number | string;
    userId: string;
  };
  page: {
    id: string;
    category: string;
    croppedHeaderImageUrl: string;
    croppedImageUrl: string;
    headerImageUrl: string;
    imageUrl: string;
    status: PageStatusType;
    ownerId: string;
    pageNetworkModel: {
      followersCounter: number;
      followingsCounter: number;
      back: boolean;
      follow: boolean;
      id: string;
    };
    title: string;
    username: string;
  };
};

export type UseGetMyPagesReturnType = UseQueryResult<Array<DataReturnType>>;

const useGetMyPages = (): UseGetMyPagesReturnType => {
  const isLoggedIn = useAuthState('isLoggedIn');

  return useReactQuery<Array<DataReturnType>>({
    action: {
      apiFunc: getMyPages,
      key: [QueryKeys.myPages],
    },
    config: {
      enabled: isLoggedIn,
    },
  });
};

export default useGetMyPages;
