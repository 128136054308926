import React from 'react';

const Group = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0group)">
      <g filter="url(#filter0_d)">
        <path
          d="M54.2748 28.2417L49.1681 24.0049L49.1739 21.0111C50.0538 20.3116 50.6177 19.2388 50.6177 18.0352V16.0889C50.6177 13.9812 48.8904 12.2725 46.7598 12.2725C44.6291 12.2725 42.9019 13.9812 42.9019 16.0889V18.0352C42.9019 19.2415 43.4682 20.3163 44.3514 21.0156V24.0049L38.9223 28.6305C38.7546 28.7733 38.6582 28.9813 38.6582 29.2002V31.7878C38.6582 32.0943 38.9094 32.3428 39.2193 32.3428C41.4515 32.7401 43.7079 32.9581 45.9667 32.9997L53.718 32.6729C54.4469 32.6422 55.0218 32.0487 55.0218 31.327V29.8156C55.0219 29.2072 54.7481 28.6303 54.2748 28.2417Z"
          fill="#83B4FF"
        />
        <path
          d="M46.2183 34.2002V37.5905C46.2183 38.0324 45.8824 38.3907 45.4681 38.3907H21.4553C21.041 38.3907 20.7051 38.0324 20.7051 37.5905V34.2002C20.7051 31.7704 21.7573 29.4774 23.5562 27.9877L30.0818 22.5794L30.7253 21.9289L36.0632 21.7578L36.8416 22.5794L43.3672 27.9877C45.1661 29.4774 46.2183 31.7704 46.2183 34.2002Z"
          fill="#5384EE"
        />
        <path
          d="M37.7994 23.3733L36.8416 22.5794L36.0632 21.7578L34.4309 21.8101L30.7253 21.9289L30.0818 22.5794L23.5562 27.9877C21.7573 29.4775 20.7051 31.7704 20.7051 34.2002V37.5905C20.7051 38.0324 21.041 38.3907 21.4553 38.3907H24.6482V35.7449C24.6482 33.3151 25.7004 31.0221 27.4993 29.5324L28.5831 28.6342C29.5585 27.8257 30.8391 27.5306 32.0258 27.8966C32.4818 28.0373 32.9636 28.1127 33.4617 28.1127C35.8805 28.1127 37.9176 26.3377 38.489 23.9448L37.7994 23.3733Z"
          fill="#3F73E3"
        />
        <path
          d="M36.8411 22.5796C36.8411 24.5706 35.328 26.1845 33.4616 26.1845C31.5951 26.1845 30.082 24.5706 30.082 22.5796V14.459H36.8412V22.5796H36.8411Z"
          fill="#FFE5C2"
        />
        <path
          d="M40.5831 8.15918V6.41645C40.5831 3.79002 38.5871 1.66092 36.1248 1.66092H29.964L29.3781 0.465485C29.2541 0.212377 28.9515 0.130323 28.7292 0.289515C27.3664 1.26531 26.5488 2.9011 26.5488 4.65184V8.15918L28.0711 12.6436H38.9428L40.5831 8.15918Z"
          fill="#485363"
        />
        <path
          d="M30.0821 8.74032V5.23297C30.0821 3.89309 30.5622 2.62177 31.3978 1.66092H29.964L29.3781 0.465485C29.2541 0.212377 28.9515 0.130323 28.7292 0.289515C27.3664 1.26531 26.5488 2.9011 26.5488 4.65184V8.15918L28.0711 12.6436H31.4071L30.0821 8.74032Z"
          fill="#072252"
        />
        <path
          d="M33.566 22.4265C34.7228 22.4265 35.828 22.1875 36.8413 21.754V14.459H32.4596H32.0691H30.082V22.5796C30.082 24.5706 31.5951 26.1845 33.4616 26.1845C33.88 26.1845 34.2826 26.102 34.6525 25.9537C33.8965 25.6506 33.2671 24.4495 32.8803 23.5163C32.6659 22.9992 33.0245 22.4258 33.5546 22.4265H33.566Z"
          fill="#FED2A4"
        />
        <path
          d="M26.5488 8.15898V12.5844L27.571 12.8115L28.0176 16.3246L33.0695 19.6465L35.1907 19.8972L36.8142 19.6473C39.0539 18.397 40.5831 15.8962 40.5831 13.0129V8.15898C35.879 7.24124 31.2006 7.23092 26.5488 8.15898Z"
          fill="#FFE5C2"
        />
        <path
          d="M30.0821 12.1819V7.63477C28.9026 7.74999 27.7249 7.92442 26.5488 8.15901V13.013C26.5488 17.1468 29.6905 20.4979 33.566 20.4979C34.7301 20.4979 35.853 20.1817 36.8195 19.6465C33.086 19.4756 30.0821 16.2064 30.0821 12.1819Z"
          fill="#FED2A4"
        />
        <path
          d="M26.4887 38.3908V34.6612C26.4887 34.2618 26.1852 33.938 25.8108 33.938C25.4364 33.938 25.1328 34.2618 25.1328 34.6612V38.3908H26.4887Z"
          fill="#5384EE"
        />
        <path
          d="M41.7895 38.3908V34.6612C41.7895 34.2618 41.486 33.938 41.1116 33.938C40.7371 33.938 40.4336 34.2618 40.4336 34.6612V38.3908H41.7895Z"
          fill="#5384EE"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-4"
        y="-12"
        width="85"
        height="73"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.65625 0 0 0 0 0.697742 0 0 0 0 0.7875 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0group">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Group;
