import React from 'react';
import FilePicker from '@lobox/uikit/FilePicker';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import Icon from '@lobox/uikit/Icon';
import { useTranslation, mediaCanBeUploaded } from '@lobox/utils';
import range from 'lodash/range';
import map from 'lodash/map';
import {
  useCreatePostState,
  useCreatePostDispatch,
} from '../../../context/createPost.provider';
import BottomCarouselItem from './BottomCarouselItem.component';
import classes from './BottomCarouselSlides.component.module.scss';

interface Props {
  slideIndexes: number[];
  slideCount: number;
  bottomSliderIndex: number;
  onClickOnItem: (fileIndex: number) => void;
  onRemove: (fileIndex: number) => void;
}

const BottomCarouselSlides = ({
  slideIndexes,
  slideCount,
  bottomSliderIndex,
  onClickOnItem,
  onRemove,
}: Props): JSX.Element => {
  const dispatch = useCreatePostDispatch();
  const files = useCreatePostState('files');
  const { t } = useTranslation();

  const cannotUpload = !mediaCanBeUploaded(files);

  return (
    <>
      {map(slideIndexes, (fileIndex) => {
        const file = files[fileIndex];

        return file ? (
          <Flex key={file.id} className={classes.thumbnailContainer}>
            <BottomCarouselItem
              file={file}
              isActive={bottomSliderIndex === fileIndex}
              onClickOnRemove={(evt) => {
                if (fileIndex === undefined) {
                  return;
                }
                evt.stopPropagation();
                onRemove(fileIndex);
              }}
              onClick={() => {
                onClickOnItem(fileIndex);
              }}
            />
          </Flex>
        ) : (
          <Flex className={classes.thumbnailContainer}>
            <FilePicker
              isMulti
              disabled={cannotUpload}
              type="media"
              onFilePicked={(selectedFiles: any) => {
                dispatch({ type: 'ADD_FILE', payload: [...selectedFiles] });
              }}
              buttonComponent={
                <Flex className={classes.filePickerBtn}>
                  <Flex className={classes.circle}>
                    <Icon
                      color="colorIconSeventh"
                      name="photo-video"
                      type="fas"
                      className={classes.addNewIcon}
                    />
                  </Flex>
                  <Flex className={classes.filePickerTextContainer}>
                    <Icon
                      name="plus"
                      type="fas"
                      color="colorIconNinth"
                      size={12}
                    />
                    <Typography color="colorIconNinth" size={12} ml={5} mt={1}>
                      {t('add')}
                    </Typography>
                  </Flex>
                </Flex>
              }
            />
          </Flex>
        );
      })}
      {map(range(slideCount - slideIndexes.length), (number) => (
        <Flex key={number} className={classes.thumbnailContainer}>
          <div style={{ width: '80px', height: '80px' }} />
        </Flex>
      ))}
    </>
  );
};

export default BottomCarouselSlides;
