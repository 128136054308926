import classes from './HelpPage.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import useMedia from '@lobox/uikit/utils/useMedia';
import { landingRouteNames, useTranslation } from '@lobox/utils';
import urls from '@shared/constants/urls';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import CreateEntityModalItem from '@shared/components/Organism/CreateEntityModal/CreateEntityModal.item';
import CreateJob from '@shared/svg/CreateJob';
import Page from '@shared/svg/PageIcon';
import HelpCenterHeader from '@shared/svg/HelpCenterHeader';
import HelpAccount from '@shared/svg/HelpAccount';
import HelpLogin from '@shared/svg/HelpLogin';
import HelpPrivacy from '@shared/svg/HelpPrivacy';
import HelpPeople from '@shared/svg/HelpPeople';
import type { FC } from 'react';

interface HelpPageProps {
  onGoBack: VoidFunction;
  closeProfilePanel: VoidFunction;
}

const HelpPage: FC<HelpPageProps> = (props) => {
  const { onGoBack, closeProfilePanel } = props;
  const { isMoreThanTablet } = useMedia();

  const { t } = useTranslation();

  const items = [
    {
      title: t('account'),
      image: <HelpAccount />,
      subTitle: t('user_set_pers_info_visi'),
      to: landingRouteNames.accountSetting,
    },
    {
      title: t('login_password'),
      image: <HelpLogin />,
      subTitle: t('chnge_pass_lgin_iss_log'),
      to: landingRouteNames.loginPassword,
    },
    {
      title: t('privacy_security_cap'),
      image: <HelpPrivacy />,
      subTitle: t('privacy_agre_dta_saf'),
      to: landingRouteNames.helpPrivacySecurity,
    },
    {
      title: t('career'),
      image: <CreateJob />,
      subTitle: t('work_status_profile_set_re'),
      to: landingRouteNames.careerPreferences,
    },
    {
      title: t('page'),
      image: <Page />,
      subTitle: t('page_setting_page'),
      to: landingRouteNames.helpPages,
    },
    {
      title: t('people'),
      image: <HelpPeople />,
      subTitle: t('network_relation_report'),
      to: landingRouteNames.helpPeople,
    },
  ];

  return (
    <FixedRightSideModalDialog
      isOpen
      showConfirm={false}
      onClose={onGoBack}
      onBack={onGoBack}
      onClickOutside={closeProfilePanel}
    >
      <ModalHeaderSimple title={t('help')} hideBack={isMoreThanTablet} />

      <ModalBody>
        <Flex className={classes.headerImg}>
          <HelpCenterHeader />
        </Flex>
        <Flex className={classes.contentWrapper}>
          {items.map(({ key, title, subTitle, image, to }: any) => {
            const buttonClick = () => {
              window.location.href = to;
            };

            return (
              <CreateEntityModalItem
                key={key}
                onClick={buttonClick}
                image={image}
                title={title}
                subTitle={subTitle}
              />
            );
          })}
        </Flex>
      </ModalBody>
      <ModalFooter className={classes.footer}>
        <Button
          rightIcon="chevron-right"
          labelFont="bold"
          schema="semi-transparent"
          label={t('see_all')}
          to={urls.helpAndSupport}
          className={classes.seeAllButton}
        />
      </ModalFooter>
    </FixedRightSideModalDialog>
  );
};

export default HelpPage;
