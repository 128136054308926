import classes from './AdvanceCard.component.module.scss';

import React, { Fragment } from 'react';
import Avatar, { AvatarProps } from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import Typography from '@lobox/uikit/Typography';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import type { TypographyProps } from '@lobox/uikit/Typography';
import BulletLine from './BulletLine';
import { makeDynamicStyles } from '@shared/uikit/utils/makeDynamicStyles';
import { usePrefetchRoutes } from '@shared/hooks/usePrefetchRoutes';
import { useObjectClicks } from '@shared/hooks/useObjectClicks';

type ClassNameProps = {
  root?: string;
  image?: string;
  innerRoot?: string;
  thirdTextWrapperClassName?: string;
  fourthTextWrapperClassName?: string;
  longTextWrapperClassName?: string;
};
type TextProps = Omit<TypographyProps, 'children'> & {
  objectId?: string;
};

export interface AdvanceCardProps {
  data: {
    firstText?: string;
    firstTextHelper?: string;
    firstTextAdditionalProps?: any;
    secondText?: string;
    secondTextAdditionalProps?: any;
    secondTextHelper?: string;
    thirdText?: string;
    thirdTextAdditionalProps?: any;
    thirdTextHelper?: string;
    fourthText?: string;
    longText?: string;
    image?: any;
    objectId?: string;
  };
  avatarProps?: AvatarProps;
  firstTextProps?: TextProps;
  firstTextHelperProps?: TextProps;
  secondTextProps?: TextProps;
  secondTextHelperProps?: TextProps;
  thirdTextHelperProps?: TextProps;
  thirdTextProps?: TextProps;
  fourthTextProps?: TextProps;
  action?: React.ReactNode;
  bottomAction?: React.ReactNode;
  isSubCard?: boolean;
  visibleDivider?: boolean;
  classNames?: Partial<ClassNameProps>;
  visibleLine?: boolean;
  visibleDot?: boolean;
  disabledContent?: boolean;
  isCompany?: boolean;
  onClick?: (e: any) => void;
  visibleImage?: boolean;
  onClickAvatar?: (arg: any) => void;
  imageDim?: number;
}

const AdvanceCard = ({
  data,
  avatarProps,
  firstTextProps = {},
  firstTextHelperProps = {},
  secondTextProps = {},
  secondTextHelperProps = {},
  thirdTextProps = {},
  thirdTextHelperProps = {},
  fourthTextProps = {},
  action,
  bottomAction,
  isSubCard,
  visibleDivider,
  classNames,
  visibleDot,
  visibleLine,
  disabledContent,
  isCompany,
  onClick,
  visibleImage = true,
  onClickAvatar,
  imageDim = 42,
}: AdvanceCardProps): JSX.Element => {
  const {
    firstText,
    firstTextHelper,
    secondText,
    secondTextHelper,
    thirdText,
    thirdTextHelper,
    fourthText,
    longText,
    image,
    objectId,
    firstTextAdditionalProps,
    secondTextAdditionalProps,
    thirdTextAdditionalProps,
    username,
  } = data;
  const stopPropagation = (e: any) => e.stopPropagation();
  const ImageWrapper: any = objectId ? ObjectLink : Fragment;
  const imageWrapperProps = objectId ? { username, objectId } : {};
  const FirstTextWrapper: any = firstTextAdditionalProps?.objectId
    ? ObjectLink
    : Fragment;
  const FirstTextWrapperProps = firstTextAdditionalProps?.objectId
    ? { objectId: firstTextAdditionalProps?.objectId, enableHover: true }
    : {};
  const SecondTextWrapper: any = secondTextAdditionalProps?.objectId
    ? ObjectLink
    : Fragment;
  const SecondTextWrapperProps = secondTextAdditionalProps?.objectId
    ? { objectId: secondTextAdditionalProps?.objectId }
    : {};
  const ThirdTextWrapper: any = thirdTextAdditionalProps?.objectId
    ? ObjectLink
    : Fragment;
  const ThirdTextWrapperProps = thirdTextAdditionalProps?.objectId
    ? { objectId: thirdTextAdditionalProps?.objectId }
    : {};

  const { handleTagClick, handleHashtagClick } = useObjectClicks();

  usePrefetchRoutes([`/${username}`]);

  return (
    <Flex
      className={cnj(
        classes.rootTimelineItem,
        isSubCard && classes.rootTimelineItemSub,
        classNames?.root
      )}
    >
      <Flex className={cnj(classNames?.innerRoot, classes.timeLineItem)}>
        {visibleImage && (
          <Flex
            className={cnj(
              classes.contentElement,
              classes.imageWrapper,
              classNames?.image
            )}
            {...makeDynamicStyles({
              width: imageDim,
              height: imageDim,
            })}
          >
            {isSubCard && <BulletLine {...{ visibleLine, visibleDot }} />}
            {!isSubCard ? (
              React.isValidElement(image) ? (
                image
              ) : (
                <ImageWrapper {...imageWrapperProps}>
                  <Avatar
                    onClick={onClickAvatar}
                    size="md"
                    imgSrc={image}
                    isCompany={isCompany}
                    {...avatarProps}
                  />
                </ImageWrapper>
              )
            ) : null}
          </Flex>
        )}
        <Flex
          onClick={onClick}
          className={cnj(
            classes.infoWrapper,
            onClick && classes.infoWrapperWithOnclick
          )}
        >
          <Flex flexDir="row">
            <Flex>
              <Flex className={classes.row}>
                {firstText && (
                  <FirstTextWrapper {...FirstTextWrapperProps}>
                    <Typography
                      font="700"
                      size={20}
                      height={23}
                      color="thirdText"
                      {...firstTextProps}
                      className={cnj(
                        classes.contentElement,
                        firstTextProps?.className
                      )}
                    >
                      {firstText}
                    </Typography>
                  </FirstTextWrapper>
                )}
                {firstTextHelper && <Flex className={classes.vDivider} />}
                {firstTextHelper && (
                  <Typography
                    font="400"
                    size={15}
                    height={23}
                    color="thirdText"
                    {...firstTextHelperProps}
                  >
                    {firstTextHelper}
                  </Typography>
                )}
              </Flex>
              <Flex className={classes.contentElement}>
                {secondText && (
                  <Flex className={cnj(classes.row, classes.marginTop)}>
                    <SecondTextWrapper {...SecondTextWrapperProps}>
                      <Typography
                        font="400"
                        size={14}
                        height={16}
                        color="gray"
                        isWordWrap
                        {...secondTextProps}
                      >
                        {secondText}
                      </Typography>
                    </SecondTextWrapper>
                    {secondTextHelper && (
                      <Flex className={cnj(classes.vDivider)} />
                    )}
                    {secondTextHelper && (
                      <Typography
                        font="400"
                        size={14}
                        height={16}
                        color="gray"
                        {...secondTextHelperProps}
                      >
                        {secondTextHelper}
                      </Typography>
                    )}
                  </Flex>
                )}
                {thirdText && (
                  <Flex
                    className={cnj(
                      classes.row,
                      'responsive-margin-top',
                      classNames?.thirdTextWrapperClassName
                    )}
                  >
                    <ThirdTextWrapper {...ThirdTextWrapperProps}>
                      <Typography
                        font="700"
                        size={16}
                        height={19}
                        color="fifthText"
                        {...thirdTextProps}
                      >
                        {thirdText}
                      </Typography>
                    </ThirdTextWrapper>
                    {thirdTextHelper && <Flex className={classes.vDivider} />}
                    {thirdTextHelper && (
                      <Typography
                        font="400"
                        size={14}
                        height={19}
                        color="fifthText"
                        {...thirdTextHelperProps}
                      >
                        {thirdTextHelper}
                      </Typography>
                    )}
                  </Flex>
                )}
                {fourthText && (
                  <Typography
                    font="700"
                    size={16}
                    height={19}
                    color="fifthText"
                    {...fourthTextProps}
                    className={cnj(
                      classes.fourthText,
                      fourthTextProps.className
                    )}
                  >
                    {fourthText}
                  </Typography>
                )}
              </Flex>
            </Flex>
            <Flex className={classes.editIcon}>{action}</Flex>
          </Flex>

          <Flex
            className={cnj(
              classes.contentElement,
              disabledContent && classes.disabled
            )}
          >
            {longText && (
              <RichTextView
                className={cnj(
                  classes.description,
                  classNames?.longTextWrapperClassName
                )}
                html={longText}
                typographyProps={{
                  color: 'thirdText',
                }}
                showMore
                onMentionClick={handleTagClick}
                onHashtagClick={handleHashtagClick}
                row={3}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex as="span" onClick={stopPropagation}>
        {bottomAction}
      </Flex>
      {visibleDivider && <Divider className="responsive-margin-top" />}
    </Flex>
  );
};

export default AdvanceCard;
