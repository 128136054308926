import classes from './MediaItems.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import SimpleSlider from '@lobox/uikit/SimpleSlider';
import Skeleton from '@lobox/uikit/Skeleton';
import {
  convertBase64ToBlob,
  convertBlobToFile,
  getImageData,
  useGlobalDispatch,
  useResizeWindow,
} from '@lobox/utils';
import min from 'lodash/min';
import max from 'lodash/max';
import floor from 'lodash/floor';
import map from 'lodash/map';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import chunk from 'lodash/chunk';
import range from 'lodash/range';
import { Carousel } from 'react-responsive-carousel';
import { FastAverageColor } from 'fast-average-color';
import {
  useCreatePostDispatch,
  useCreatePostState,
} from '../../../context/createPost.provider';

import MainCarouselItem from './MainCarouselItem.component';
import BottomCarouselSlides from './BottomCarouselSlides.component';
import RatioActions from './RatioActions.component';
import useMedia from '@shared/uikit/utils/useMedia';

type Ratio = '0:0' | '1:1' | '4:5' | '16:9';
type Position = { x: number; y: number };
type Size = { width: number; height: number };
type ReadyToUpload = {
  original: any;
  file: Blob;
  position: Position;
  ratio: Ratio;
  zoom: number;
  size: Size;
  realSize: Size;
  backgroundColor: string;
};

const mediaMaxWidth = 800;
const widthBreakpoints = [0, 650, 750];
const fac = new FastAverageColor();

const MediaItems: React.FC = () => {
  const dispatch = useCreatePostDispatch();
  const uploadSubmitted = useCreatePostState('uploadSubmitted');
  const cropperData = useCreatePostState('cropperData');
  const files = useCreatePostState('files');
  const oldReadyToUploadFiles = useCreatePostState('readyToUploadFiles');
  const appDispatch = useGlobalDispatch();

  const firstFile = React.useMemo(
    () => (files?.length ? files[0] : {}),
    [files]
  );

  const filesCountRef = React.useRef(0);
  const isRemovingRef = React.useRef(false);
  const wrapperRef = React.useRef<HTMLElement>();
  const readyToUploadFiles = React.useRef<ReadyToUpload[]>([]);
  const hiddenCroppersRef = React.useRef<React.LegacyRef<any>[]>([]);
  const videosRef = React.useRef<{
    [key: string]: React.RefObject<HTMLVideoElement>;
  }>({});

  const { isTabletAndLess } = useMedia();

  const [wrapperSide, setWrapperSide] = React.useState(0);
  const [wrapperRatio, setWrapperRatio] = React.useState<Ratio>(
    isTabletAndLess ? '1:1' : cropperData.ratio
  );
  const [firstItemRealSize, setFirstItemRealSize] = React.useState({
    width: 0,
    height: 0,
  });
  const [cropperSize, setCropperSize] = React.useState({
    width: cropperData.width,
    height: cropperData.height,
  });
  const [hiddenCropperSize, setHiddenCropperSize] = React.useState({
    width: 0,
    height: 0,
  });
  const [croppersPositions, setCroppersPositions] = React.useState([]);
  const [croppersZooms, setCroppersZooms] = React.useState([]);
  const [croppersBackgroundColors, setCroppersBackgroundColors] =
    React.useState([]);
  const [mainSliderIndex, setMainSliderIndex] = React.useState(0);
  const [bottomSliderIndex, setBottomSliderIndex] = React.useState(0);
  const [videosSizes, setVideosSizes] = React.useState<Size[]>([]);

  React.useEffect(() => {
    if (!oldReadyToUploadFiles.length) {
      return;
    }

    const positions: any[] = [];
    const zooms: any[] = [];
    const colors: any[] = [];

    oldReadyToUploadFiles.forEach(
      ({ position, zoom, backgroundColor }: any) => {
        positions.push(position);
        zooms.push(zoom);
        colors.push(backgroundColor);
      }
    );

    setCroppersPositions(() => positions);
    setCroppersZooms(() => zooms);
    setCroppersBackgroundColors(() => colors);
  }, [oldReadyToUploadFiles]);

  React.useEffect(() => {
    if (files.length > filesCountRef.current) {
      setBottomSliderIndex(() => files.length - 1);
    }
    if (files.length < filesCountRef.current) {
      isRemovingRef.current = false;
    }
    filesCountRef.current = files.length;
  }, [files.length]);

  /*
    the real size and '0:0' ratio
    calculating based on width of first image
  */
  React.useEffect(() => {
    if (!firstFile.url) {
      return;
    }

    if (firstFile.type === 'image') {
      getImageData(firstFile.url).then((data) =>
        setFirstItemRealSize({
          width: data.width,
          height: data.height,
        })
      );
    }
  }, [firstFile]);

  const setVideoRef =
    (index: number) => (ref: React.MutableRefObject<HTMLVideoElement>) => {
      ref.current.loop = true;
      ref.current.playsInline = true;

      videosRef.current = {
        ...videosRef.current,
        [index]: ref,
      };
    };

  const setHiddenCroppersRef =
    (index: number) => (ref: React.RefObject<any>) => {
      hiddenCroppersRef.current[index] = ref;
    };

  const setImageChangesDebounces = React.useMemo(
    () =>
      map(files, () =>
        debounce(({ index, originalFile, position, zoom, ratio, size }) => {
          if (!hiddenCroppersRef.current[index]) {
            return;
          }

          const base64 = hiddenCroppersRef.current[index]
            ?.getImageScaledToCanvas()
            .toDataURL('image/jpeg');

          fac.getColorAsync(base64, { algorithm: 'dominant' }).then((color) => {
            convertBase64ToBlob(base64).then((cropped) => {
              setCroppersBackgroundColors((colors) => {
                if (color.hex === colors[index]) {
                  return colors;
                }
                const updatedColors = [...colors];
                updatedColors[index] = color.hex;
                return updatedColors;
              });
              readyToUploadFiles.current[index] = {
                file: convertBlobToFile(cropped),
                original: originalFile,
                position: position || { x: 0.5, y: 0.5 },
                zoom: zoom || 1,
                ratio,
                size,
                realSize: { width: 0, height: 0 },
                backgroundColor: color.hex,
              };
            });
          });
        }, 250)
      ),
    [files]
  );

  const handleImageChange = (index: number) => () => {
    setImageChangesDebounces[index]({
      index,
      originalFile: files[index],
      position: croppersPositions[index],
      zoom: croppersZooms[index],
      ratio: wrapperRatio,
      size: hiddenCropperSize,
    });
  };

  const handleCropperPositionChange =
    (index: number) => (position: Position) => {
      setCroppersPositions((positions) => {
        if (isEqual(position, positions[index])) {
          return positions;
        }
        const updatedPositions = [...positions];
        updatedPositions[index] = position;
        return updatedPositions;
      });
    };

  const handleChangeZoom = React.useCallback(
    (zoom: number) => {
      setCroppersZooms((zooms) => {
        const updateZooms = [...zooms];
        updateZooms[mainSliderIndex] = zoom;
        return updateZooms;
      });
    },
    [mainSliderIndex]
  );

  React.useEffect(() => {
    if (files[bottomSliderIndex]) {
      setMainSliderIndex(bottomSliderIndex);
    }
  }, [bottomSliderIndex, files]);

  const resizeEventListener = React.useMemo(
    () =>
      debounce(() => {
        if (wrapperRef.current) {
          setWrapperSide(wrapperRef.current?.offsetWidth);
        }
      }, 100),
    []
  );

  useResizeWindow(resizeEventListener, true);

  // set copper width and height
  React.useEffect(() => {
    if (!firstItemRealSize.width || !firstItemRealSize.height || !wrapperSide)
      return;

    let cropperWidth = wrapperSide;
    let cropperHeight = wrapperSide;

    // 0:0
    if (wrapperRatio === '0:0') {
      if (firstItemRealSize.width > firstItemRealSize.height) {
        cropperHeight =
          (firstItemRealSize.height * wrapperSide) / firstItemRealSize.width;
      }
      if (firstItemRealSize.width < firstItemRealSize.height) {
        cropperWidth = min([
          (firstItemRealSize.width * wrapperSide) / firstItemRealSize.height,
          wrapperSide < widthBreakpoints[1]
            ? wrapperSide - 80
            : widthBreakpoints[1],
        ]);
      }
    }
    // 4:5
    if (wrapperRatio === '4:5') {
      cropperWidth = (4 * wrapperSide) / 5;
    }
    if (wrapperRatio === '16:9') {
      cropperHeight = (9 * wrapperSide) / 16;
    }

    setCropperSize(() => ({
      width: floor(cropperWidth),
      height: floor(cropperHeight),
    }));
  }, [
    wrapperRatio,
    wrapperSide,
    firstItemRealSize.width,
    firstItemRealSize.height,
  ]);

  // set hidden cropper width and height
  React.useEffect(() => {
    let scaledWidth = 650;

    if (firstItemRealSize.width > 750) {
      scaledWidth = mediaMaxWidth;
    } else if (firstItemRealSize.width > 650) {
      scaledWidth = 650;
    }

    let cropperWidth = scaledWidth;
    let cropperHeight = scaledWidth;

    if (wrapperRatio === '0:0') {
      cropperHeight =
        (cropperWidth * firstItemRealSize.height) / firstItemRealSize.width;
      if (firstItemRealSize.width < firstItemRealSize.height) {
        cropperHeight = min([cropperHeight, mediaMaxWidth]);
        cropperWidth =
          (firstItemRealSize.width * mediaMaxWidth) / firstItemRealSize.height;
      }
    }
    if (wrapperRatio === '4:5') {
      cropperHeight = (scaledWidth * 5) / 4;
    }
    if (wrapperRatio === '16:9') {
      cropperHeight = (scaledWidth * 9) / 16;
    }

    setHiddenCropperSize(() => ({
      width: floor(cropperWidth),
      height: floor(cropperHeight),
    }));
  }, [wrapperRatio, firstItemRealSize.width, firstItemRealSize.height]);

  React.useEffect(() => {
    if (
      files[mainSliderIndex]?.type !== 'video' ||
      videosSizes[mainSliderIndex]
    ) {
      return;
    }

    const video = videosRef.current[mainSliderIndex].current;

    video.onloadeddata = () => {
      setTimeout(() => {
        setVideosSizes((sizes) => {
          const updatedSizes = [...sizes];
          updatedSizes[mainSliderIndex] = {
            width: video.offsetWidth,
            height: video.offsetHeight,
          };
          return updatedSizes;
        });
      }, 100);
    };
  }, [files, mainSliderIndex, videosSizes]);

  React.useEffect(() => {
    files.forEach((file, ii: number) => {
      if (file.type !== 'video' || !videosSizes[ii]) {
        return;
      }

      const videoWidth = videosSizes[ii].width;
      const videoHeight = videosSizes[ii].height;

      const realSize = {
        width: videoWidth,
        height: videoHeight,
      };

      if (!ii) {
        setFirstItemRealSize(realSize);
      }

      const zoom = max([
        cropperSize.width / (videoWidth || 1),
        cropperSize.height / (videoHeight || 1),
      ]);

      setCroppersZooms((zooms) => {
        const updateZooms = [...zooms];
        updateZooms[ii] = zoom;
        return updateZooms;
      });

      readyToUploadFiles.current[ii] = {
        ...file,
        original: file,
        position: croppersPositions[ii],
        zoom,
        ratio: wrapperRatio,
        size: hiddenCropperSize,
        realSize,
        backgroundColor: '#ffffffff',
      };
    });
  }, [
    wrapperRatio,
    cropperSize,
    videosSizes,
    croppersPositions,
    hiddenCropperSize,
    files,
  ]);

  React.useEffect(() => {
    if (!uploadSubmitted) {
      return;
    }

    dispatch({
      type: 'SET_READY_TO_UPLOAD_FILES',
      payload: { files: readyToUploadFiles.current },
    });
    dispatch({
      type: 'SET_CROPPER_DATA',
      payload: {
        cropperData: {
          width: cropperSize.width,
          height: cropperSize.height,
          ratio: wrapperRatio,
          wrapperSide,
        },
      },
    });
    dispatch({
      type: 'SET_UPLOAD_SUBMITTED',
      payload: { uploadSubmitted: false },
    });
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: true,
        currentTab: 'main',
      },
    });
  }, [
    uploadSubmitted,
    readyToUploadFiles,
    cropperSize.width,
    cropperSize.height,
    wrapperRatio,
    wrapperSide,
  ]);

  const numberOfVisibleThumbnails = floor(wrapperSide / 100);

  return (
    <>
      <Flex className={classes.singleFileContainer}>
        <Flex
          ref={wrapperRef}
          className={classes.wrapper}
          style={{ height: wrapperSide ? `${wrapperSide}px` : 'auto' }}
        >
          {!files.length && <Skeleton className={classes.skeleton} />}
          {/* main carousel */}
          <Carousel
            showIndicators={false}
            swipeable={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            className={classes.carousel}
            selectedItem={mainSliderIndex}
          >
            {map(files, (file, ii: number) => (
              <MainCarouselItem
                key={file.id || ii}
                file={file}
                firstItemSize={firstItemRealSize}
                cropperSize={cropperSize}
                hiddenCropperSize={hiddenCropperSize}
                wrapperRatio={wrapperRatio}
                position={croppersPositions[ii]}
                zoom={croppersZooms[ii]}
                backgroundColor={croppersBackgroundColors[ii]}
                isPreparing={
                  Boolean(videosSizes[mainSliderIndex]) &&
                  !videosSizes[mainSliderIndex]?.width
                }
                onPositionChange={handleCropperPositionChange(ii)}
                onImageChange={handleImageChange(ii)}
                setHiddenCroppersRef={setHiddenCroppersRef(ii)}
                setVideoRef={setVideoRef(ii)}
              />
            ))}
          </Carousel>
        </Flex>
      </Flex>
      {files[mainSliderIndex]?.type === 'image' && (
        <Flex className={classes.actionsContainer}>
          <SimpleSlider
            value={croppersZooms[mainSliderIndex] || 1}
            min={1}
            max={2}
            step={0.05}
            onChange={handleChangeZoom}
            className={classes.slider}
            thumbClassName={classes.thumbClassName}
            trackClassName={classes.trackClassName}
          />
        </Flex>
      )}
      <Flex flexDir="row" className={classes.actionsContainer}>
        <RatioActions
          setWrapperRatio={setWrapperRatio}
          wrapperRatio={wrapperRatio}
          // disabled={files[mainSliderIndex]?.type !== 'image'}
        />
      </Flex>
      <Flex className={classes.actionsContainer}>
        {/* bottom slider carousel */}
        <Carousel
          swipeable
          emulateTouch
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          autoPlay={false}
          // a hack! to prevent autoplay
          interval={60 * 60 * 60 * 24}
          className={cnj(classes.carousel)}
          swipeScrollTolerance={10}
          preventMovementUntilSwipeScrollTolerance
        >
          {map(
            chunk(range(files.length + 1), numberOfVisibleThumbnails),
            (filesIndexes, ii) => (
              <Flex
                key={ii}
                className={classes.bottomSliderWrapper}
                flexDir="row"
              >
                <BottomCarouselSlides
                  slideIndexes={filesIndexes}
                  slideCount={numberOfVisibleThumbnails}
                  bottomSliderIndex={bottomSliderIndex}
                  onClickOnItem={(fileIndex) => {
                    setBottomSliderIndex(fileIndex);
                  }}
                  onRemove={(fileIndex) => {
                    isRemovingRef.current = true;

                    setCroppersPositions((positions) =>
                      positions.filter((__, jj) => jj !== fileIndex)
                    );
                    setCroppersZooms((zooms) =>
                      zooms.filter((__, jj) => jj !== fileIndex)
                    );

                    const nextIndex =
                      mainSliderIndex === files.length - 1
                        ? bottomSliderIndex - 1
                        : bottomSliderIndex;

                    setBottomSliderIndex(() => (nextIndex < 0 ? 0 : nextIndex));

                    dispatch({
                      type: 'DELETE_FILE_BY_INDEX',
                      payload: { index: fileIndex },
                    });
                    readyToUploadFiles.current = filter(
                      readyToUploadFiles.current,
                      (__, jj) => jj !== fileIndex
                    );
                  }}
                />
              </Flex>
            )
          )}
        </Carousel>
      </Flex>
    </>
  );
};

export default MediaItems;
