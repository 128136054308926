import React from 'react';
import useMedia from '@lobox/uikit/utils/useMedia';
import { useTranslation } from '@lobox/utils';
import classes from './RatioActions.component.module.scss';

import RatioActionButton from './RatioActionButton.component';

type Ratio = '0:0' | '1:1' | '4:5' | '16:9';

type Props = {
  wrapperRatio: Ratio;
  setWrapperRatio: (ratio: Ratio) => void;
  disabled?: boolean;
};

function RatioActions({
  wrapperRatio,
  setWrapperRatio,
  disabled,
}: Props): JSX.Element {
  const { isTabletAndLess } = useMedia();
  const { t } = useTranslation();

  return (
    <>
      {!isTabletAndLess && (
        <RatioActionButton
          isActive={wrapperRatio === '0:0'}
          onClick={() => setWrapperRatio('0:0')}
          iconName="image"
        >
          {t('default')}
        </RatioActionButton>
      )}
      <RatioActionButton
        disabled={disabled}
        isActive={wrapperRatio === '1:1'}
        onClick={() => setWrapperRatio('1:1')}
        iconName="square"
        buttonClassName={isTabletAndLess ? '' : classes.middleButton}
      >
        1:1
      </RatioActionButton>
      <RatioActionButton
        disabled={disabled}
        isActive={wrapperRatio === '4:5'}
        onClick={() => setWrapperRatio('4:5')}
        iconName="rectangle-portrait"
        buttonClassName={classes.middleButton}
      >
        4:5
      </RatioActionButton>
      <RatioActionButton
        disabled={disabled}
        isActive={wrapperRatio === '16:9'}
        onClick={() => setWrapperRatio('16:9')}
        iconName="rectangle-wide"
      >
        16:9
      </RatioActionButton>
    </>
  );
}

export default React.memo(RatioActions);
