import React, { Fragment } from 'react';
import { landingRouteNames, useTranslation } from '@lobox/utils';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Button from '../Button';
import Typography from '../Typography';
import LogoCopyRight from '../LogoCopyRight';
import type { StyleProps } from './LanguageDropDown';
import LanguageDropDown from './LanguageDropDown';
import classes from './Footer.Small.module.scss';
import Link from '../Link';

interface FooterSmallProps {
  className?: string;
  logoCopyRightClassName?: string;
  linksClassName?: string;
  copyRightLabel?: string;
  itemProps?: {};
  logoGray?: boolean;
  center?: boolean;
  hasLanguagePicker?: boolean;
  languageStyles?: StyleProps;
}

const FooterSmall: React.FC<FooterSmallProps> = ({
  className,
  copyRightLabel,
  itemProps = {},
  logoCopyRightClassName,
  linksClassName,
  languageStyles,
  logoGray,
  center,
  hasLanguagePicker,
}) => {
  const { t, changeLanguage, currentLanguage } = useTranslation();

  const handleChangeLanguage = ({ value }: any) => {
    changeLanguage(value);
  };
  const footerData = [
    { link: landingRouteNames.helpCenter, text: t('help'), key: 'ab01' },
    {
      link: landingRouteNames.privacySecurity,
      text: t('privacy'),
      key: 'ab02',
    },
    {
      link: landingRouteNames.businessSolutions,
      text: t('business'),
      key: 'ab03',
    },
    { link: landingRouteNames.aboutUs, text: t('about'), key: 'ab04' },
  ];

  return (
    <Flex
      className={cnj(
        classes.footerSmallRoot,
        center && classes.center,
        className
      )}
    >
      <LogoCopyRight
        className={cnj(classes.logoCopyRightProps, logoCopyRightClassName)}
        copyRightLabel={copyRightLabel}
        logoGray={logoGray}
      />

      <Flex className={cnj(classes.links, linksClassName)}>
        {footerData.map(({ key, link, text }, index) => (
          <Fragment key={key}>
            {index !== 0 && (
              <Typography
                font="400"
                size={14}
                color="gray"
                className={classes.dot}
              >
                ·
              </Typography>
            )}
            <Link to={link}>
              <Button
                schema="ghost"
                label={text}
                labelFont="400"
                className={classes.link}
                labelClassName={classes.label}
                {...itemProps}
              />
            </Link>
          </Fragment>
        ))}
      </Flex>
      {hasLanguagePicker && (
        <LanguageDropDown
          onChange={handleChangeLanguage}
          currentLanguage={currentLanguage}
          styles={languageStyles}
        />
      )}
    </Flex>
  );
};

export default FooterSmall;
