import classes from './SearchHeaderTitle.component.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import useClientRouter from '@shared/utils-pkg/hooks/useClientRouter';
import { persistantSearchQuery } from '../GlobalSearchInput/PersistantSearchQuery';
import useSearchFilters from '@shared/hooks/useSearchFilters';
import Flex from '@shared/uikit/Flex';

interface Props {
  className?: string;
  onClick?: () => void;
}

const SearchHeaderTitle: React.FC<Props> = ({ onClick, className }) => {
  const { t } = useTranslation();
  const { searchFilters } = useSearchFilters();
  const { isLoading } = useClientRouter({});
  const text =
    (isLoading ? persistantSearchQuery.query : searchFilters.query) ||
    t('jobs');
  const country = searchFilters.placeTitle;

  if (isLoading) return <Flex className={classes.filler}></Flex>;
  return (
    <BaseButton className={className} onClick={onClick}>
      <Typography
        className={classes.titleWrapper}
        isWordWrap
        isTruncated
        lineNumber={1}
      >
        <Typography font="700">{text}</Typography>
        {country ? (
          <>
            <Typography ml={3} mr={3}>
              {t('in')}
            </Typography>
            <Typography font="700">{country}</Typography>
          </>
        ) : null}
      </Typography>
    </BaseButton>
  );
};

export default SearchHeaderTitle;
