import classes from './GlobalsSearchInput.component.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { useDebounceState, useTranslation } from '@lobox/utils';
import { searchFilterQueryParams } from '@shared/constants/search';
import Input from './GlobalsSearchInput.input';
import GlobalSearchInputSearchResultLayout from './GLobalSearchInput.searchResult.layout';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';
import useSearchFilters from '@shared/hooks/useSearchFilters';
import useSearchQueries from '@shared/hooks/useSearchQueries';

export interface Props {
  className?: string;
  onClose: () => void;
  inputRef: any;
}

const GlobalSearchInput: React.FC<Props> = ({
  onClose,
  inputRef,
  className,
}) => {
  const { t } = useTranslation();
  const textInputRef = useRef<HTMLInputElement>();
  const locationInputRef = useRef();
  const { moduleSpecificSearchResultPage } = useGlobalSearchUtilities();
  const { searchFilters } = useSearchFilters();
  const { searchedQuery } = useSearchQueries();
  const searchLocation = searchFilters?.[searchFilterQueryParams.placeTitle];
  const [focusedInput, setFocusedInput] = useState('text');

  const { value: textValue, setValue: onChangeText } = useDebounceState<string>(
    searchedQuery || '',
    250
  );
  const { value: locationValue, setValue: onChangeLocation } =
    useDebounceState<string>('', 250);

  useEffect(() => {
    onChangeLocation(searchLocation);
  }, [searchLocation]);

  const moduleSpecificSearchResultPageIsJobs =
    moduleSpecificSearchResultPage?.module === 'jobs';

  return (
    <Flex className={cnj(classes.inputWrapper, className)}>
      <Input
        inputRef={textInputRef}
        ref={inputRef}
        placeholder={
          moduleSpecificSearchResultPageIsJobs ? t('job_title') : t('search')
        }
        inputStyle={cnj(
          moduleSpecificSearchResultPageIsJobs &&
            classes.leftInputWhenHavingDoubleInputs
        )}
        onClose={onClose}
        value={textValue}
        onChange={onChangeText}
        name="text"
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
      >
        {(props: any) => <GlobalSearchInputSearchResultLayout {...props} />}
      </Input>
      {moduleSpecificSearchResultPageIsJobs && (
        <Input
          inputRef={locationInputRef}
          onChange={onChangeLocation}
          value={locationValue}
          placeholder={t('location')}
          inputStyle={classes.locationInputStyle}
          searchIconProps={{
            name: 'map-marker-alt',
            type: 'far',
            size: 18,
          }}
          emptyMessage={
            <Typography
              className={classes.empty}
              color="graphene_60"
              size={16}
              height={20}
            >
              {t('no_result_found')}
            </Typography>
          }
          name="location"
          onClose={onClose}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
        >
          {(props) => (
            <GlobalSearchInputSearchResultLayout {...props} isLocation />
          )}
        </Input>
      )}
    </Flex>
  );
};

export default GlobalSearchInput;
