import React from 'react';
import IconButton from '@lobox/uikit/Button/IconButton';
import Tooltip from '@lobox/uikit/Tooltip';
import { useJobsState } from '@shared/providers/JobsPorvider/jobs.provider';
import { useTranslation } from '@lobox/utils';
import { useSearchDispatch } from '@shared/contexts/search/search.provider';

interface SearchFilterIcon {
  className?: string;
  variant?: 'circle' | 'rectangle';
}

const SearchFilterIcon: React.FC<SearchFilterIcon> = ({
  className,
  variant,
}) => {
  const searchDispatch = useSearchDispatch();
  const isSearchFilterFormOpen = useJobsState('isSearchFilterFormOpen');
  const { t } = useTranslation();

  const toggleFilterModal = () =>
    searchDispatch({
      type: 'SET_IS_SEARCH_FILTER_FORM_OPEN',
      payload: !isSearchFilterFormOpen,
    });

  if (isSearchFilterFormOpen) {
    return (
      <IconButton
        className={className}
        onClick={toggleFilterModal}
        name="sliders-simple"
        size="md15"
        iconProps={{
          color: 'white',
        }}
        colorSchema="primary-blue"
        variant={variant}
      />
    );
  }
  return (
    <Tooltip
      trigger={
        <IconButton
          className={className}
          onClick={toggleFilterModal}
          name="sliders-simple"
          size="md15"
          colorSchema="semi-transparent"
          variant={variant}
        />
      }
    >
      {t('all_filters')}
    </Tooltip>
  );
};

export default SearchFilterIcon;
