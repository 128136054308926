import classes from './SearchInCarouselItem.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Image from '@lobox/uikit/Image';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import { usePlaceholder } from '@shared/hooks';

type Props = {
  onClick?: () => any;
  src?: string;
  alt?: string;
  label?: string;
  variant?: 'LARGE' | 'SMALL';
};

const SearchInCarouselItem: React.FC<Props> = ({
  onClick,
  src,
  alt,
  label,
  variant = 'LARGE',
}) => {
  const imagePlaceholder = usePlaceholder();
  const { isMoreThanTablet } = useMedia();
  const isSmall = variant === 'SMALL';

  return (
    <BaseButton onClick={onClick}>
      <Flex
        className={cnj(
          classes.imageWrapper,
          isSmall && classes.imageWrapperSmall
        )}
      >
        <Image
          resolution="medium"
          src={src || imagePlaceholder}
          alt={alt}
          className={classes.coverImage}
        />
        <Flex className={classes.layover} />
        <Typography
          color="white"
          height={isMoreThanTablet ? 30 : 25}
          size={isMoreThanTablet ? 24 : 20}
          font={700}
          className={classes.title}
        >
          {label}
        </Typography>
      </Flex>
    </BaseButton>
  );
};

export default SearchInCarouselItem;
