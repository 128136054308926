import React from 'react';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { useTranslation, withFormikContext } from '@lobox/utils';
import isEqual from 'lodash/isEqual';
import HighlightCard from '@shared/components/molecules/HighlightCard';
import type { WithFormikProps } from '@lobox/utils';
import classes from './Form.component.module.scss';
import { useFormikContext } from 'formik';

interface HighlightFormProps {
  formObj: any;
}

const HighlightFormContainer: React.FC<HighlightFormProps> = ({ formObj }) => {
  const { values } = useFormikContext();
  const groups = React.useMemo(
    () =>
      Array.isArray(formObj?.groups)
        ? formObj.groups
        : formObj?.groups?.({ values }),
    [formObj, values]
  );

  return (
    <>
      <HighlightCard title={formObj?.title} type={formObj?.logo} />

      <Flex className={classes.formContainer}>
        <DynamicFormBuilder groups={groups} />
      </Flex>
    </>
  );
};

export default HighlightFormContainer;
