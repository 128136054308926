import React, { useEffect, useState } from 'react';
import {
  isEmptyObjectValues,
  useGlobalState,
  useHistory,
  withFormikContext,
} from '@lobox/utils';
import {
  QueryKeys,
  useGlobalDispatch,
  useQuerySelector,
  useTranslation,
} from '@lobox/utils';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  useCreatePostDispatch,
  useCreatePostState,
} from '../../context/createPost.provider';
import isEqual from 'lodash/isEqual';
import { dropRight, last } from 'lodash';
import StepOne from './StepOne/StepOne.component';
import StepTwo from './StepTwo/StepTwo.component';
import StepTree from './StepTree/StepTree.component';
import StepFour from './StepFour';
import Button from '@lobox/uikit/Button';
import classes from './Highlights.component.module.scss';

const Steps = {
  LIST: 'LIST',
  SUB_CATEGORIES: 'SUB_CATEGORIES',
  SELECT_FOR_AUTO_FILL: 'SELECT_FOR_AUTO_FILL',
  FORM: 'FORM',
} as const;

type IStep = keyof typeof Steps;

interface ICashedType {
  id: string;
  key: string;
  title: string;
  image?: string;
  form?: any;
  items: Array<any>;
}

const PostHighlights: React.FC = ({ formikRefs, formikProps }) => {
  const { t } = useTranslation();
  const { openConfirmDialog } = useOpenConfirm();
  const globalDispatch = useGlobalDispatch();
  const history = useHistory();
  const authUser = useQuerySelector([QueryKeys.authUser]);
  const highlights = useCreatePostState('highlights');
  const selectedHighlight = useCreatePostState('selectedHighlight');
  const createPostModal = useGlobalState('createPostModal');

  const data = highlights || {};

  useEffect(() => {
    if (selectedHighlight?.values) {
      formikRefs.current.setValues(selectedHighlight.values);
    }
  }, [selectedHighlight]);

  const createPostDispatch = useCreatePostDispatch();
  const appDispatch = useGlobalDispatch();

  const [cashedData, setCashedData] = useState<ICashedType[]>([
    selectedHighlight
      ? selectedHighlight
      : {
          ...data.list,
          key: Steps.LIST,
        },
  ]);

  const handleNext = (step: IStep, item: ICashedType) => {
    setCashedData((prev) => [...prev, { ...item, key: step }]);
  };

  const closeHandler = () => {
    if (createPostModal.isPrevStepMainTab) {
      appDispatch({
        type: 'SET_CREATE_POST_MODAL',
        payload: {
          isOpenModal: true,
          currentTab: 'main',
        },
      });
    } else {
      appDispatch({
        type: 'SET_CREATE_POST_MODAL',
        payload: {
          selectedPostForEdit: undefined,
          isOpenModal: false,
          attachment: undefined,
          currentTab: 'main',
        },
      });
    }
  };

  const handlePrev = () => {
    if (cashedData.length === 1) {
      closeHandler();
    } else {
      setCashedData(dropRight(cashedData));
    }
  };

  const checkIsDisabled = () => {
    const { dirty, isValid } = formikProps;

    return !(
      dirty &&
      isValid &&
      isEmptyObjectValues(formikRefs?.current?.status)
    );
  };

  const currentStep = last(cashedData);

  useEffect(() => {
    if (currentStep?.key === Steps.FORM) {
      console.log({ currentStep });
      globalDispatch({
        type: 'SET_CREATE_POST_MODAL',
        payload: {
          form: currentStep?.form,
        },
      });
    }
  }, [currentStep]);

  const openUserProfile = () => {
    appDispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        selectedPostForEdit: undefined,
        isOpenModal: false,
        attachment: undefined,
      },
    });
    history.push(authUser?.userLink);
  };
  const formDiscardHandler = () => {
    if (formikProps.dirty) {
      openConfirmDialog({
        cancelCallback: closeHandler,
        title: t('confirm_title'),
        message: t('confirm_desc'),
        cancelButtonText: t('confirm_cancel'),
        confirmButtonText: t('confirm_ok'),
        isReverse: true,
      });
    } else {
      closeHandler();
    }
  };

  const tabs = {
    [Steps.LIST]: () => (
      <StepOne
        items={currentStep?.items}
        handleNext={(item) => {
          if (item.id === 'simple') {
            handleNext(Steps.FORM, item);
          } else {
            handleNext(Steps.SUB_CATEGORIES, data[item.id]);
          }
        }}
        image={currentStep?.image}
        title={t('create_highlight')}
        handlePrev={closeHandler}
        isPrevStepMainTab={createPostModal.isPrevStepMainTab}
      />
    ),
    [Steps.SUB_CATEGORIES]: () => (
      <StepTwo
        handleNext={async (item) => {
          if (item.fetchSelectionList) {
            const data = await item.fetchSelectionList();
            if (data.items?.length) {
              handleNext(Steps.SELECT_FOR_AUTO_FILL, {
                ...item,
                cp: data.cp,
                title: data.title,
                items: data.items,
              });
            } else {
              if (item.id === 'getPromoted') {
                openConfirmDialog({
                  title: t('add_an_exp'),
                  message: t('add_an_exp_desc'),
                  confirmButtonText: t('add_experience'),
                  cancelButtonText: t('cancel'),
                  isAjaxCall: false,
                  confirmCallback: openUserProfile,
                });
              } else {
                handleNext(Steps.FORM, item);
              }
            }
          } else {
            handleNext(Steps.FORM, item);
          }
        }}
        handlePrev={handlePrev}
        items={currentStep?.items}
        image={currentStep?.image}
        title={currentStep?.title}
      />
    ),
    [Steps.FORM]: () => (
      <StepFour
        handleSubmit={() => {
          createPostDispatch({
            type: 'SET_HIGHLIGHT',
            payload: {
              ...currentStep,
              values: formikRefs?.current?.values,
            },
          });
          appDispatch({
            type: 'SET_CREATE_POST_MODAL',
            payload: {
              currentTab: 'main',
            },
          });
        }}
        disabled={checkIsDisabled()}
        handleClose={formDiscardHandler}
        handlePrev={handlePrev}
        form={currentStep?.form}
        resetForm={() => formikRefs?.current.resetForm()}
      />
    ),
    [Steps.SELECT_FOR_AUTO_FILL]: () => (
      <StepTree
        handleNext={(item) => {
          formikRefs.current.setValues(item);
          createPostDispatch({
            type: 'SET_HIGHLIGHT',
            payload: {
              ...currentStep,
              key: Steps.FORM,
              values: item,
            },
          });
          appDispatch({
            type: 'SET_CREATE_POST_MODAL',
            payload: {
              currentTab: 'main',
            },
          });
        }}
        addNew={
          currentStep?.id !== 'getPromoted'
            ? () => handleNext(Steps.FORM, currentStep)
            : undefined
        }
        handlePrev={handlePrev}
        items={currentStep?.items}
        title={currentStep?.title}
        cp={currentStep?.cp}
        addAnotherButton={
          currentStep?.id === 'getPromoted' ? (
            <Button
              className={classes.addBtn}
              label={t('promotion_h_action')}
              schema="ghost-brand"
              onClick={openUserProfile}
            />
          ) : null
        }
      />
    ),
  };

  return <>{tabs?.[currentStep?.key]?.()}</>;
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default withFormikContext({
  refs: ['values', 'resetForm', 'setValues', 'status', 'setFieldValue'],
  props: ['dirty', 'isValid'],
})(React.memo(PostHighlights, areEqual));
