import classes from './Tabs.component.module.scss';

import React from 'react';
import Main from './contents/Main';
import Highlights from './contents/Highlights';
import CheckIn from './contents/CheckIn';
import Persons from './contents/Persons';
import Media from './contents/Media';
import Flex from '@lobox/uikit/Flex';

const tabContentVariant = {
  active: {
    display: 'flex',
    x: 0,
  },
  inactive: {
    display: 'none',
    x: 20,
  },
};

interface AnimatedViewProps {
  active: boolean;
  id: string;
  [x: string]: any;
}

interface ITabProps {
  id: string;
  active: boolean;
  attachment: any;
  [x: string]: any;
}

const AnimatedView: React.FC<AnimatedViewProps> = ({
  active,
  id,
  children,
}) => {
  return (
    <Flex
      role="tabpanel"
      id={id}
      transition={{ duration: 0.1 }}
      className={classes.tabContent}
      variants={tabContentVariant}
      animate={active ? 'active' : 'inactive'}
      initial="inactive"
      // to have animation remove this line
      style={{ display: active ? 'flex' : 'none' }}
    >
      {children}
    </Flex>
  );
};

const Tab1 = (props: ITabProps): JSX.Element => (
  <AnimatedView {...props}>
    <Main {...props} />
  </AnimatedView>
);
const Tab2 = (props: ITabProps): JSX.Element | null =>
  props.active ? (
    <AnimatedView {...props}>
      <Highlights />
    </AnimatedView>
  ) : null;
const Tab3 = (props: ITabProps): JSX.Element | null =>
  props.active ? (
    <AnimatedView {...props}>
      <CheckIn hasHeader />
    </AnimatedView>
  ) : null;
const Tab4 = (props: ITabProps): JSX.Element | null =>
  props.active ? (
    <AnimatedView {...props}>
      <Persons />
    </AnimatedView>
  ) : null;
const Tab5 = (props: ITabProps): JSX.Element | null =>
  props.active ? (
    <AnimatedView {...props}>
      <Media />
    </AnimatedView>
  ) : null;

// Configure our tabs and tab content here
const tabs = [
  {
    id: 'Main',
    content: Tab1,
  },
  {
    id: 'Highlights',
    content: Tab2,
  },
  {
    id: 'Persons',
    content: Tab3,
  },
  {
    id: 'Check In',
    content: Tab4,
  },
  {
    id: 'Media',
    content: Tab5,
  },
];

const TabsComponent = React.memo(
  ({
    activeIndex,
    attachment,
    ...rest
  }: {
    activeIndex: number;
    attachment?: any;
    [x: string]: any;
  }) =>
    tabs.map((tab, index) => (
      <tab.content
        key={tab.id}
        id={tab.id}
        active={activeIndex === index}
        attachment={attachment}
        {...rest}
      />
    ))
);

export default TabsComponent;
