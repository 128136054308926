import React, { Fragment, forwardRef } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import FooterSmall from '@lobox/uikit/Footer/FooterSmall';
import IconButton from '@lobox/uikit/Button/IconButton';
import { useLocation } from '@lobox/utils';
import { mainRoutes, routeNames, useTranslation, event } from '@lobox/utils';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import PermissionsGate from '@shared/components/molecules/PermissionsGate';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import LeftNavigationItem from './partials/LeftNavigationItem.component';
import classes from './index.module.scss';
import useHasPermission from '@shared/hooks/useHasPermission';
import eventKeys from '@shared/constants/event-keys';

interface LeftPanelProps {
  narrow?: boolean;
  autoWide?: boolean;
  wide?: boolean;
  handleClose: () => any;
}

const LeftNav = (
  { narrow, autoWide, wide, handleClose }: LeftPanelProps,
  ref: React.ForwardedRef<React.MutableRefObject<HTMLElement>>
) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isActiveRoute = (route: string): boolean => {
    if (route === mainRoutes.home) {
      return pathname === mainRoutes.home;
    }

    return pathname?.startsWith(route);
  };
  const isInviteActive = isActiveRoute(routeNames.invite);

  const {
    getAppObjectPropValue,
    authUser: user,
    businessPage: page,
  } = useGetAppObject();
  const objectLink = isBusinessApp
    ? `/${page?.username}`
    : `/${user?.username}`;

  const leftNavItemsList = [
    {
      id: 'profile',
      label:
        (narrow && ((isBusinessApp && t('page')) || t('you_cap'))) ||
        (isBusinessApp && page?.fullName) ||
        user?.fullName,
      svg: (
        <Avatar
          defaultTag
          imgSrc={getAppObjectPropValue({
            userKey: 'croppedImageUrl',
            pageKey: 'croppedImageUrl',
          })}
          size={narrow ? 'xs' : 'xxs'}
          isCompany={isBusinessApp}
          avatarInnerClassName={cnj(classes.avatarInnerClassName)}
        />
      ),
      rootRoute: objectLink,
      link: objectLink,
      scopes: [SCOPES.canSeeHomeScreen],
    },
    useHasPermission([SCOPES.canSeeHomeScreen]) && {
      id: 'home',
      rootRoute: mainRoutes.home,
      label: t('home'),
      icon: 'house',
      link: routeNames.home,
      scopes: [SCOPES.canSeeHomeScreen],
    },
    useHasPermission([SCOPES.canSeePeopleScreen]) && {
      id: 'people',
      rootRoute: mainRoutes.people,
      label: t('people'),
      icon: 'circle-user',
      link: routeNames.peopleDiscover,
      scopes: [SCOPES.canSeePeopleScreen],
    },
    useHasPermission([SCOPES.canSeePagesScreen]) && {
      id: 'pages',
      rootRoute: mainRoutes.pages,
      label: t('pages'),
      icon: 'flag',
      link: routeNames.pagesDiscover,
      scopes: [SCOPES.canSeePagesScreen],
    },
    useHasPermission([SCOPES.canSeePagesScreen]) && {
      id: 'schedules',
      rootRoute: mainRoutes.schedules,
      label: t('schedules'),
      icon: 'calendar',
      scopes: [SCOPES.canSeePagesScreen],
      link: routeNames.schedulesCalendarDay,
    },
    useHasPermission([SCOPES.canSeeMessagesScreen]) && {
      id: 'messages',
      rootRoute: mainRoutes.messages,
      label: t('messages'),
      icon: 'comment-alt-lines',
      link: routeNames.messages,
      scopes: [SCOPES.canSeeMessagesScreen],
    },
    useHasPermission([SCOPES.canSeePeopleScreen]) && {
      id: 'jobs',
      rootRoute: mainRoutes.jobs,
      label: t('jobs'),
      icon: 'briefcase-blank',
      link: isBusinessApp ? routeNames.openJobs : routeNames.discoverJobs,
      scopes: [SCOPES.canSeePeopleScreen],
      hasTopDivider: true,
    },
  ].filter(Boolean);

  const onClick = (isActive: boolean) => () => {
    setTimeout(handleClose, 0);
    if (isActive) {
      console.log({ isActive });
      event.trigger(eventKeys.scrollToTopFeedList);
    }
  };

  return (
    <Flex
      className={cnj(
        classes.leftPanelRoot,
        wide && classes.leftPanelRootWide,
        !narrow && classes.leftPanelRootNotNarrow
      )}
      ref={ref}
    >
      {leftNavItemsList.map(
        ({ svg, icon, id, label, link, rootRoute, hasTopDivider }: any) => {
          const isActive = isActiveRoute(rootRoute || link);

          return (
            <Fragment key={id}>
              {hasTopDivider && <Divider className={classes.divider} />}
              <LeftNavigationItem
                autoWide={autoWide}
                link={link}
                narrow={narrow}
                label={label}
                leftSvg={
                  svg || (
                    <IconButton
                      className={classes.iconButton}
                      name={
                        isActive || id === 'darkMode' ? icon : `${icon}-light`
                      }
                      size={narrow ? 'sm20' : 'md20'}
                      type={isActive ? 'fas' : 'far'}
                      colorSchema={
                        isActive ? 'tertiary-transparent' : 'secondary2'
                      }
                      noHover
                    />
                  )
                }
                isActive={isActive}
                onClick={onClick(isActive)}
              />
            </Fragment>
          );
        }
      )}

      <Flex className={classes.bottomWrapper}>
        <Flex className={classes.inviteWrapper}>
          <PermissionsGate scopes={[SCOPES.canSeeInviteScreen]}>
            <Divider className={classes.divider} />
            <LeftNavigationItem
              autoWide={autoWide}
              link="/invite"
              narrow={narrow}
              leftSvg={
                <IconButton
                  className={classes.iconButton}
                  name={
                    isInviteActive
                      ? 'envelope-open-text'
                      : 'envelope-open-text-light'
                  }
                  size={narrow ? 'sm20' : 'md20'}
                  type={isInviteActive ? 'fas' : 'far'}
                  colorSchema={
                    isInviteActive ? 'tertiary-transparent' : 'secondary2'
                  }
                  noHover
                />
              }
              label={t('invite')}
              isActive={isInviteActive}
              onClick={onClick(isInviteActive)}
            />
          </PermissionsGate>
        </Flex>

        {!narrow && (
          <FooterSmall
            logoGray
            center
            className={cnj(
              classes.footerCustom,
              wide && classes.footerCustomWide
            )}
            linksClassName={classes.linksClassName}
            copyRightLabel={`${t('copy_right')} ${new Date().getFullYear()}`}
            logoCopyRightClassName={classes.logoCopyRightClassName}
            hasLanguagePicker
            languageStyles={{
              dropDown: classes.languageDropDown,
              options: classes.languagePopper,
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default forwardRef<React.MutableRefObject<HTMLElement>, LeftPanelProps>(
  LeftNav
);
