import classes from './CreatePost.component.module.scss';

import React, { useState, useEffect, Fragment } from 'react';
import { event, useGlobalState } from '@lobox/utils';
import Form from '@lobox/uikit/Form';
import { ModalDialog } from '../../molecules/Modal';
import Tabs from './Tabs.component';
import { useCreatePostDispatch } from './context/createPost.provider';
import { makeUpdatePostObject } from './createPost.utils';
import Flex from '@shared/uikit/Flex';
import cnj from '@shared/uikit/utils/cnj';

const indexes = {
  main: 0,
  highlight: 1,
  checkin: 2,
  users: 3,
  media: 4,
};

interface CreatePostModalProps {
  selectedPostForEdit: any;
  attachment: any;
  currentTab: any;
  hasHeader?: boolean;
}

const CreatePostModal = ({
  selectedPostForEdit,
  attachment,
  currentTab,
  hasHeader = true,
  ...rest
}: CreatePostModalProps): JSX.Element => {
  const { form } = useGlobalState('createPostModal');
  const postDispatch = useCreatePostDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState(indexes[currentTab]);

  useEffect(() => {
    setActiveTabIndex(indexes['main']);
    (async () => {
      if (selectedPostForEdit) {
        const payload = await makeUpdatePostObject(selectedPostForEdit);
        postDispatch({
          type: 'INIT',
          payload,
        });
      }
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setActiveTabIndex(indexes[currentTab]);
      setIsLoaded(true);
    }, 0);
  }, [currentTab]);

  const onClose = React.useCallback(() => {
    event.trigger('createPost:backDropClicked');
    // appDispatch({
    //   type: 'SET_CREATE_POST_MODAL',
    //   payload: {
    //     isOpenModal: false,
    //     currentTab: 'main',
    //     selectedPost: undefined,
    //   },
    // });
  }, []);

  const ModalDialogTag = !hasHeader ? Fragment : ModalDialog;

  return (
    <Form
      enableReinitialize
      initialValues={{}}
      validationSchema={form?.validationSchema}
      className={classes.form}
    >
      {() => (
        <Flex className={classes.wrapper}>
          <ModalDialogTag
            isOpen
            onClose={onClose}
            modalDialogClassName={classes.dialog}
            modalClassName={cnj(!isLoaded && classes.hidden)}
          >
            <Tabs
              activeIndex={activeTabIndex}
              attachment={attachment}
              currentTab={currentTab}
              hasHeader={hasHeader}
              {...rest}
            />
          </ModalDialogTag>
        </Flex>
      )}
    </Form>
  );
};

export default CreatePostModal;
