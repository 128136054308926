import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const CelebrationIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='highlight_celebration_113a'>
        <path
          fill='#fff'
          d='m22.98 63.087-2.935-23.764 1.288-.159 1.117 1.266 2.706 21.413-.898 1.086-1.278.158Z'
        />
        <path
          fill='#ECECEC'
          d='m24.258 62.93-2.926-23.766 1.387-.171 2.935 23.764-1.396.172Z'
        />
        <path
          fill='#fff'
          d='M31.917 62.652h-1.04l-1.275-.438 3.684-10.72 1.26.432.875.507-3.504 10.22Z'
        />
        <path
          fill='#ECECEC'
          d='m32.152 63.09-1.262-.434 3.657-10.73 1.289.443-3.684 10.721Z'
        />
        <path
          fill='#fff'
          d='m15.875 63.89-4.405-7.278 1.05-.636 1.226-.062 3.863 6.529-.791.876-.943.57Z'
        />
        <path
          fill='#ECECEC'
          d='m16.819 63.318-4.298-7.342 1.256-.76 4.405 7.277-1.363.825Z'
        />
        <path
          fill='#83B4FF'
          d='M4.043 26.086a4.043 4.043 0 1 0 0-8.086 4.043 4.043 0 0 0 0 8.086ZM41.059 27.883a4.941 4.941 0 1 0 0-9.883 4.941 4.941 0 0 0 0 9.883Z'
        />
        <path
          fill='#E1F5FE'
          d='M41.957 64a4.043 4.043 0 1 0 0-8.086 4.043 4.043 0 0 0 0 8.086ZM5.487 35.797l1.906-1.906 1.905 1.906-1.905 1.906-1.906-1.906Z'
        />
        <path
          fill='#83B4FF'
          d='m31.636 31.253 1.906-1.905 1.905 1.905-1.905 1.906-1.906-1.905ZM15.216 39.472l-.041-6.824-5.645-3.834 6.477-2.148 1.902-6.553 2.338 6.305 6.82-.216-3.977 5.544 2.312 4.085-4.795-.543-5.39 4.184Z'
        />
        <path
          fill='#5384EE'
          d='m24.798 30.938 3.977-5.545-6.82.216-4.044-5.496 2.699 15.176 6.5 2.069-2.312-6.42Z'
        />
        <path
          fill='#83B4FF'
          d='m7.924 58.602-2.099-5.61-5.81-1.453L4.7 47.81l-.415-5.975 4.097 4.203 5.555-2.241-1.6 5.772 3.668 3.153-4.906.801-3.176 5.079Z'
        />
        <path
          fill='#5384EE'
          d='m13.235 48.67 1.6-5.772L9.28 45.14l-4.995-3.305 6.814 11.688 5.984-.262-3.848-4.59Z'
        />
        <path
          fill='#83B4FF'
          d='m39.954 51.445-4.147-3.058-5.837 1.345 2.483-5.451-3.083-5.136 5.951.677 3.932-4.519.117 5.78 5.513 2.343-5.213 2.95.284 5.069Z'
        />
        <path
          fill='#5384EE'
          d='m40.749 46.466 5.212-2.95-5.512-2.343-1.195-5.87-3.447 13.084 4.417 4.046.525-5.967Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 18h46v46H0z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={70}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='highlight_celebration_113a'>
        <path
          fill='#fff'
          d='m22.98 63.087-2.935-23.764 1.288-.159 1.117 1.266 2.706 21.413-.898 1.086-1.278.158Z'
        />
        <path
          fill='#ECECEC'
          d='m24.258 62.93-2.926-23.766 1.387-.171 2.935 23.764-1.396.172Z'
        />
        <path
          fill='#fff'
          d='M31.917 62.652h-1.04l-1.275-.438 3.684-10.72 1.26.432.875.507-3.504 10.22Z'
        />
        <path
          fill='#ECECEC'
          d='m32.152 63.09-1.262-.434 3.657-10.73 1.289.443-3.684 10.721Z'
        />
        <path
          fill='#fff'
          d='m15.875 63.89-4.405-7.278 1.05-.636 1.226-.062 3.863 6.529-.791.876-.943.57Z'
        />
        <path
          fill='#ECECEC'
          d='m16.819 63.318-4.298-7.342 1.256-.76 4.405 7.277-1.363.825Z'
        />
        <path
          fill='#83B4FF'
          d='M4.043 26.086a4.043 4.043 0 1 0 0-8.086 4.043 4.043 0 0 0 0 8.086ZM41.059 27.883a4.941 4.941 0 1 0 0-9.883 4.941 4.941 0 0 0 0 9.883Z'
        />
        <path
          fill='#E1F5FE'
          d='M41.957 64a4.043 4.043 0 1 0 0-8.086 4.043 4.043 0 0 0 0 8.086ZM5.487 35.797l1.906-1.906 1.905 1.906-1.905 1.906-1.906-1.906Z'
        />
        <path
          fill='#83B4FF'
          d='m31.636 31.253 1.906-1.905 1.905 1.905-1.905 1.906-1.906-1.905ZM15.216 39.472l-.041-6.824-5.645-3.834 6.477-2.148 1.902-6.553 2.338 6.305 6.82-.216-3.977 5.544 2.312 4.085-4.795-.543-5.39 4.184Z'
        />
        <path
          fill='#5384EE'
          d='m24.798 30.938 3.977-5.545-6.82.216-4.044-5.496 2.699 15.176 6.5 2.069-2.312-6.42Z'
        />
        <path
          fill='#83B4FF'
          d='m7.924 58.602-2.099-5.61-5.81-1.453L4.7 47.81l-.415-5.975 4.097 4.203 5.555-2.241-1.6 5.772 3.668 3.153-4.906.801-3.176 5.079Z'
        />
        <path
          fill='#5384EE'
          d='m13.235 48.67 1.6-5.772L9.28 45.14l-4.995-3.305 6.814 11.688 5.984-.262-3.848-4.59Z'
        />
        <path
          fill='#83B4FF'
          d='m39.954 51.445-4.147-3.058-5.837 1.345 2.483-5.451-3.083-5.136 5.951.677 3.932-4.519.117 5.78 5.513 2.343-5.213 2.95.284 5.069Z'
        />
        <path
          fill='#5384EE'
          d='m40.749 46.466 5.212-2.95-5.512-2.343-1.195-5.87-3.447 13.084 4.417 4.046.525-5.967Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 18h46v46H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CelebrationIcon;
