import classes from './FooterLinksPage.module.scss';

import React, { Fragment } from 'react';
import type { FC } from 'react';
import Link from '@lobox/uikit/Link';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { landingRouteNames, useHistory, useTranslation } from '@lobox/utils';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import BaseHeader from '@shared/components/molecules/Modal/BasicModal/Headers/Base';

interface FooterLinksPageProps {
  onGoBack: VoidFunction;
  closeProfilePanel: (arg: string) => void;
}

const FooterLinksPage: FC<FooterLinksPageProps> = (props) => {
  const { onGoBack, closeProfilePanel } = props;
  const history = useHistory();

  const { t } = useTranslation();

  const links = {
    [`${t('help_center')}`]: [
      {
        label: t('popular_topics'),
        link: landingRouteNames.helpCenter_popularTopics,
      },
      {
        label: t('faq'),
        link: landingRouteNames.helpCenter_faq,
      },
      {
        label: t('feedback'),
        link: landingRouteNames.helpCenter_feedback,
      },
    ],
    [`${t('privacy_security')}`]: [
      {
        label: t('user_agreement'),
        link: landingRouteNames.userAgreement_general,
      },
      {
        label: t('privacy'),
        link: landingRouteNames.privacy,
      },
      {
        label: t('about_cookies'),
        link: landingRouteNames.aboutCookies_ourCookies,
      },
      {
        label: t('security'),
        link: landingRouteNames.security,
      },
    ],
    [`${t('business_solutions')}`]: [
      {
        label: t('products'),
        link: landingRouteNames.businessSolutions_products,
      },
      {
        label: t('pricing'),
        link: landingRouteNames.businessSolutions_pricing,
      },
      {
        label: t('business_support'),
        link: landingRouteNames.businessSolutions_support,
      },
    ],
    [`${t('about_us')}`]: [
      {
        label: t('who_we_are'),
        link: landingRouteNames.aboutUs_whoWeAre,
      },
      {
        label: t('our_values'),
        link: landingRouteNames.aboutUs_ourValues,
      },
      {
        label: t('career'),
        link: landingRouteNames.aboutUs_career,
      },
      {
        label: t('team'),
        link: landingRouteNames.meetTheTeam,
      },
    ],
  };

  const linksKeysArray = Object.keys(links);

  const onItemClick = (link: string) => {
    closeProfilePanel(link);
    onGoBack();
  };

  return (
    <FixedRightSideModalDialog>
      <BaseHeader
        backButtonProps={{
          onClick: onGoBack,
        }}
      >
        <Typography
          font="700"
          size={20}
          height={24}
          color="thirdText"
          className={classes.sectionTitle}
        >
          {t('footer_links')}
        </Typography>
      </BaseHeader>

      <Flex className={classes.content}>
        {linksKeysArray.map((parent, index) => (
          <Fragment key={parent}>
            <Typography font="500" size={19} height={24} color="secondaryText">
              {parent}
            </Typography>

            <Flex className={classes.spacing} />

            {links[parent].map((child) => (
              <Link
                to={child.link}
                onClick={() => onItemClick(child.link)}
                key={child.label + child.link}
              >
                <Typography
                  className={classes.item}
                  font="400"
                  size={14}
                  color="fifthText"
                >
                  {child.label}
                </Typography>
              </Link>
            ))}

            {index !== linksKeysArray.length - 1 && (
              <Divider className={classes.divider} />
            )}
          </Fragment>
        ))}
      </Flex>
    </FixedRightSideModalDialog>
  );
};

export default FooterLinksPage;
