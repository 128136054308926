import classes from './GlobalSearchTextResult.module.scss';

import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import React from 'react';

export const GlobalSearchTextResult: React.FC<{ text: string }> = ({
  text,
}) => {
  return (
    <Typography
      font="400"
      size={15}
      height={21}
      color="secondaryDisabledText"
      className={cnj(classes.empty)}
    >
      {text}
    </Typography>
  );
};
