import classes from './Timer.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import { formatSeconds } from '@lobox/utils';
import Typography from '@lobox/uikit/Typography';

type Props = {
  videoRef: React.RefObject<HTMLVideoElement | undefined>;
};

export function Timer({ videoRef }: Props): JSX.Element {
  const [currentTime, setCurrentTime] = React.useState(0);

  const onTimeUpdate = React.useCallback(() => {
    setCurrentTime(() => videoRef.current?.currentTime || 0);
  }, [videoRef]);

  const duration = videoRef.current?.duration || 0;

  React.useEffect(() => {
    const ref = videoRef.current;
    ref?.addEventListener('timeupdate', onTimeUpdate);

    return () => {
      ref?.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [onTimeUpdate, videoRef]);

  return (
    <Flex className={classes.container} flexDir="row">
      <Typography color="white" variant="span">
        {formatSeconds(currentTime)}
      </Typography>
      <Typography color="white">&nbsp;/&nbsp;</Typography>
      <Typography color="white">{formatSeconds(duration)}</Typography>
    </Flex>
  );
}

export default React.memo(Timer);
