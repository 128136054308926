import classes from './GlobalsSearchInput.input.module.scss';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import SearchInputV2 from '@lobox/uikit/SearchInputV2';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  useReactQuery,
  depotsEndpoints,
  routeNames,
  useUrlQuery,
  useGlobalState,
  geoApi,
  useClickOutside,
} from '@lobox/utils';
import { useLocation } from '@lobox/utils';
import { searchFilterQueryParams } from '@shared/constants/search';
import type { LegacyRef, MutableRefObject } from 'react';
import type { SearchResultItem } from '@shared/components/Organism/types';
import useGlobalSearchUtilities from '@shared/hooks/useGlobalSearchUtilities';
import type { ApiType, TextArg } from '@shared/hooks/useGlobalSearchUtilities';
import { normalizers } from './utils/normalizers';
import { searchGlobally } from './utils/searchGlobally';
import { usePrefetchRoutes } from '@shared/hooks/usePrefetchRoutes';
import useClientRouter from '@shared/utils-pkg/hooks/useClientRouter';
import { persistantSearchQuery } from './PersistantSearchQuery';
import useSearchFiltersFields from '@shared/hooks/useSearchFiltersFields';
import useSearchQueries from '@shared/hooks/useSearchQueries';
import { useAuthCountry } from '@shared/hooks/useAuthCountry';

export interface InputProps {
  inputStyle?: string;
  placeholder: string;
  contentClassName?: string;
  searchIconProps?: any;
  inputRef?: MutableRefObject<HTMLInputElement>;
  trashIconClassName?: string;
  emptyMessage?: React.ReactElement;
  children?: (props: any) => React.ReactNode;
  onClose: () => void;
  defaultValue?: string;
  value: string;
  onChange: (s: string) => void;
  name: 'location' | 'text';
  setFocusedInput: (s: string) => void;
  focusedInput: string;
}

const Input = (
  {
    inputStyle,
    placeholder,
    children,
    contentClassName,
    trashIconClassName,
    searchIconProps,
    inputRef,
    emptyMessage,
    onClose,
    value = '',
    onChange: handleChange,
    name,
    setFocusedInput,
    focusedInput,
  }: InputProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  const isOpenRightPanel = useGlobalState('isOpenRightPanel');
  const wrapperRef = useRef<HTMLDivElement>();
  const { isMoreThanTablet } = useMedia();
  const { pathname } = useLocation();
  const [show, toggleShow] = useState(false);
  const isLocation = name === 'location';
  const {
    currentModule,
    onSelectHandler: onSelect,
    getQueryKey,
    searchForText,
    moduleSpecificSearchResultPage,
    currentGeneralModule,
  } = useGlobalSearchUtilities();
  const { groupsObject } = useSearchFiltersFields();
  const query = useUrlQuery();
  const { isLoading: isLoadingRouter } = useClientRouter({});
  const searchQuery = isLoadingRouter
    ? persistantSearchQuery.query
    : decodeURIComponent(query.get('query') || '');
  const isHashtag = value?.startsWith('#');
  const isJobs = moduleSpecificSearchResultPage?.module === 'jobs';
  const userProfileLocation = {
    label:
      groupsObject[searchFilterQueryParams.placeTitle]?.getDefaultValue?.(),
    value: groupsObject[searchFilterQueryParams.placeId]?.getDefaultValue?.(),
    isDefault: true,
  };
  const showingResults = (show || !isMoreThanTablet) && name === focusedInput;
  const authCountry = useAuthCountry();

  useEffect(() => {
    if (isLoadingRouter) return;
    persistantSearchQuery.query = searchQuery;
  }, [searchQuery]);

  function onChange(value: string) {
    persistantSearchQuery.query = value;
    handleChange?.(value);
  }

  const { data: filteredOption = [] as any, isFetching: isLoading } =
    useReactQuery({
      action: {
        apiFunc: (textArg: TextArg) =>
          searchGlobally(
            textArg,
            moduleSpecificSearchResultPage?.module === 'jobs'
              ? ['JOB']
              : undefined,
            currentGeneralModule
          ),
        key: getQueryKey(currentModule, 'items', placeholder, value),
        params: { text: value },
      },
    });

  const valueWithDefault = isLocation && !value ? 'a' : value;

  const { data: filteredLocations = [] as any, isFetching: isLoadingLocation } =
    useReactQuery({
      action: {
        spreadParams: true,
        apiFunc: geoApi.suggestPlace,
        key: [placeholder, valueWithDefault],
        params: { text: valueWithDefault },
      },
      config: { enabled: !!valueWithDefault },
    });

  const {
    data: dataSuggestionsGeneral = {},
    isFetching: isLoadingSuggestions,
  } = useReactQuery<any>({
    action: {
      key: getQueryKey('all', 'recents', 'suggestions'),
      url: depotsEndpoints.getHistory,
      params: {},
      beforeCache: (data: any) => ({
        recents: normalizers.recents(
          data?.searchHistory,
          isJobs ? 'jobs' : 'all'
        ),
        recentLocations: normalizers.recents(data?.searchHistory, 'locations'),
        rawSuggestions: data?.suggestionHistory,
      }),
    },
    config: {
      enabled: !isLocation,
    },
  });
  const {
    recents = [],
    rawSuggestions = [],
    recentLocations,
  } = dataSuggestionsGeneral;

  const dataSuggestions = normalizers.suggestions(
    rawSuggestions,
    isJobs ? ['JOB'] : undefined
  );

  const locationSuggestions = [
    ...normalizers
      .suggestions({ locations: [...(rawSuggestions?.locations || [])] }, [
        'LOCATION',
      ])
      ?.map((item) => ({ ...item, dontSearchSet: true })),
  ]?.reverse();
  const dataRecentLocations = recentLocations
    ?.slice(0, 2)
    ?.map((item) => ({ ...item, dontSearchSet: true }));

  const dataRecents = recents?.slice(0, 2);
  const isLoadingOverall = isLocation ? isLoadingLocation : isLoading;

  const onFocusHandler = () => {
    toggleShow(true);
    setFocusedInput(name);
  };

  const handleClickOutside = () => {
    setTimeout(() => {
      toggleShow(false);
      onClose?.();
      const input = wrapperRef.current?.querySelector('input');
      input?.blur?.();
    }, 0);
  };

  const closeOutside = () => {
    handleClickOutside();
    if (!isMoreThanTablet) return;
    if (isLocation) {
      simulatePressEnter();
    } else {
      if (searchQuery !== value) {
        searchForText(value);
      }
    }
  };

  const onSelectHandler = (item: SearchResultItem, type: ApiType) => {
    if (!item?.isSpecific && item?.type !== 'HASHTAG')
      setTimeout(() => onChange(item?.label), 0);
    onSelect[type](item);
    handleClickOutside();
    // if (isLocation && !isMoreThanTablet) toggleShow(false);
  };
  const onKeyDown = (e: any) => {
    const val = e.target.value;
    if (e.key === 'Enter') {
      if (isLocation) {
        const item: any =
          filteredLocations?.length === 1
            ? filteredLocations?.[0]
            : {
                label: authCountry?.title,
                value: authCountry?.id,
                isDefault: true,
              };
        onSelectHandler(item as any, 'location');
      } else {
        onChange(val);
        searchForText(val);
        handleClickOutside();
      }
    } else if (!show) toggleShow(true);
  };
  const simulatePressEnter = (val = inputRef.current?.value || value) => {
    onKeyDown({ target: { value: val }, key: 'Enter' });
  };

  const onClearSearch = () => {
    if (!wrapperRef.current) return;
    onChange('');
    const input = wrapperRef.current?.querySelector('input');
    input?.focus?.();
  };

  // useEffect(() => {
  //   if (!isLocation || !isAllSearchGroupType || urlParams?.isPlaceTitleFiltered)
  //     return;
  //   onSelect.location(userProfileLocation);
  // }, [urlParams.searchGroupType]);

  // useEffect(() => {
  //   if (!valueWithDefault) return;
  //   if (isLocation) {
  //     fetchLocations();
  //   } else {
  //     fetchItems();
  //   }
  // }, [valueWithDefault]);

  useEffect(() => {
    // Purge the input when navigating in the website, except for the
    // search pages or the search result pages
    if (moduleSpecificSearchResultPage.isActive) return;
    if (pathname.includes(routeNames.search)) return;
    onChange('');
  }, [pathname]);

  useEffect(() => {
    if (value === searchQuery || isLocation) return;
    onChange(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (!isMoreThanTablet && !isLocation) {
      const input = wrapperRef.current?.querySelector('input');
      input?.focus?.();
    }
  }, []);

  useImperativeHandle(ref, () => ({ simulatePressEnter }));

  usePrefetchRoutes([
    routeNames.searchAll,
    routeNames.searchJobs,
    routeNames.searchPages,
    routeNames.searchPeople,
    routeNames.searchPosts,
  ]);

  return (
    <Flex className={classes.inputClassName} ref={wrapperRef}>
      <SearchInputV2
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        inputStyle={cnj(
          classes.inputStyle,
          isHashtag && classes.hashtagInput,
          inputStyle
        )}
        onChange={onChange}
        onClearSearch={onClearSearch}
        onFocus={onFocusHandler}
        inputProps={{
          value,
          autoComplete: 'off',
          maxLength: 150,
          onKeyDown,
        }}
        trashIconProps={{
          color: 'thirdText',
          className: trashIconClassName,
        }}
        searchIconProps={searchIconProps}
      />
      <Flex
        key={name}
        className={cnj(
          classes.root,
          showingResults ? classes.show : classes.hide
        )}
      >
        <Flex
          className={cnj(
            classes.content,
            isJobs && classes.topGutter,
            contentClassName
          )}
        >
          {children({
            isLoading: isLoadingOverall,
            emptyMessage,
            onSelectHandler,
            filteredOption: isLocation ? filteredLocations : filteredOption,
            inputValue: value || '',
            dataRecents: isLocation ? dataRecentLocations : dataRecents,
            dataSuggestions: isLocation ? locationSuggestions : dataSuggestions,
            isLocation,
            show,
            handleClickOutside,
          })}
        </Flex>
        {showingResults && (
          <Layover {...{ closeOutside, isOpenRightPanel, wrapperRef }} />
        )}
      </Flex>
    </Flex>
  );
};

export default forwardRef(Input);

function Layover({ closeOutside, isOpenRightPanel, wrapperRef }: any) {
  useClickOutside(wrapperRef, {
    onClickOutside: closeOutside,
  });
  return (
    <Flex
      className={cnj(classes.layover, isOpenRightPanel && classes.layoverRight)}
      onClick={closeOutside}
    />
  );
}
