import React from 'react';
import useMedia from '@lobox/uikit/utils/useMedia';
import { useGlobalDispatch, useLogout } from '@lobox/utils';

import type { FC } from 'react';
import ProfilePanelDesktop from './Desktop';
import ProfilePanelMobile from './Mobile';

const ProfilePanel: FC = () => {
  const logoutHandler = useLogout();
  const { isMoreThanTablet } = useMedia();
  const globalDispatch = useGlobalDispatch();

  const handleLogout = () => {
    logoutHandler();
    closeProfilePanel();
  };

  const closeProfilePanel = () => {
    globalDispatch({
      type: 'SET_SHOW_PROFILE_PANEL',
      payload: false,
    });
  };

  if (isMoreThanTablet) {
    return (
      <ProfilePanelDesktop
        handleLogout={handleLogout}
        closeProfilePanel={closeProfilePanel}
      />
    );
  }

  return (
    <ProfilePanelMobile
      handleLogout={handleLogout}
      closeProfilePanel={closeProfilePanel}
    />
  );
};

export default ProfilePanel;
