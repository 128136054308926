import React from 'react';
import Flex from '@lobox/uikit/Flex';
import SimpleImageCropper from '@lobox/uikit/SimpleImageCropper';
import EasyCropper from '@lobox/uikit/EasyCropper';
import Skeleton from '@lobox/uikit/Skeleton';
import cnj from '@lobox/uikit/utils/cnj';
import debounce from 'lodash/debounce';
import classes from './MainCarouselItem.component.module.scss';

type Ratio = '0:0' | '1:1' | '4:5' | '16:9';
type Position = { x: number; y: number };
type Size = { width: number; height: number };

type Props = {
  file: any;
  firstItemSize: Size;
  cropperSize: Size;
  hiddenCropperSize: Size;
  wrapperRatio: Ratio;
  position: Position;
  zoom: number;
  backgroundColor?: string;
  isPreparing?: boolean;
  onPositionChange: (point: Position) => void;
  onImageChange: (evt?: Event) => void;
  setHiddenCroppersRef: (ref: React.RefObject<HTMLElement>) => void;
  setVideoRef: (ref: React.RefObject<HTMLVideoElement>) => void;
};

function MainCarouselItem({
  file,
  firstItemSize,
  cropperSize,
  hiddenCropperSize,
  wrapperRatio,
  position,
  zoom,
  backgroundColor,
  isPreparing,
  onPositionChange,
  onImageChange,
  setHiddenCroppersRef,
  setVideoRef,
}: Props): JSX.Element {
  const [bgColor, setBgColor] = React.useState(backgroundColor);

  const hasBackgroundImage =
    (wrapperRatio === '0:0' && firstItemSize.width < firstItemSize.height) ||
    wrapperRatio === '4:5';

  const bgColorDebounce = React.useCallback(
    debounce(
      (color) => setBgColor(() => (color ? `${color}c8` : undefined)),
      500
    ),
    []
  );

  React.useEffect(() => {
    bgColorDebounce(backgroundColor);
  }, [backgroundColor, bgColorDebounce]);

  return (
    <Flex
      className={classes.carouselItemWrapper}
      style={{
        backgroundColor: bgColor,
      }}
    >
      {isPreparing && <Skeleton className={classes.skeleton} />}
      {file?.type === 'image' && (
        <>
          {hasBackgroundImage && (
            <SimpleImageCropper
              image={file.file}
              width={cropperSize.width}
              height={cropperSize.height}
              className={classes.backgroundImage}
              scale={zoom || 1}
              position={position || { x: 0.5, y: 0.5 }}
              border={0}
            />
          )}
          <SimpleImageCropper
            image={file.file}
            width={cropperSize.width}
            height={cropperSize.height}
            className={cnj(classes.cropper, classes.grabCursor)}
            scale={zoom || 1}
            border={0}
            position={position || { x: 0.5, y: 0.5 }}
            onPositionChange={onPositionChange}
            onImageChange={onImageChange}
          />
          {/*
              hidden copper acts like a trick
              letting us to prepare image for api
            */}
          <SimpleImageCropper
            ref={setHiddenCroppersRef}
            image={file.file}
            width={hiddenCropperSize.width}
            height={hiddenCropperSize.height}
            scale={zoom || 1}
            border={0}
            position={position || { x: 0.5, y: 0.5 }}
            onImageReady={onImageChange}
            style={{ display: 'none' }}
          />
        </>
      )}
      {file?.type === 'video' && (
        <EasyCropper
          video={file.url}
          showGrid={false}
          zoomWithScroll={false}
          zoom={zoom || 1}
          crop={position || { x: 0, y: 0 }}
          cropSize={{
            width: cropperSize.width,
            height: cropperSize.height,
          }}
          classes={{
            cropAreaClassName: cnj(classes.videoCropArea, classes.grabCursor),
            mediaClassName: classes.videoMedia,
          }}
          onCropChange={onPositionChange}
          setVideoRef={setVideoRef}
        />
      )}
    </Flex>
  );
}

export default MainCarouselItem;
