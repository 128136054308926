import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Button from '@lobox/uikit/Button';
import { useTranslation } from '@lobox/utils';
import urls from '@shared/constants/urls';
import { useParams } from '@lobox/utils';
import classes from './NotLoggedInActions.module.scss';

const NotLoggedInUserActions = () => {
  const { t } = useTranslation();
  const { username } = useParams<any>();
  const onClickSignup = () => {
    window.location.href = urls.signUp;
  };

  const onClickLogin = () => {
    window.location.href = `${urls.login}?redirect=/${username}`;
  };

  return (
    <Flex flexDir="row" className={classes.actionsContainer}>
      <Button
        onClick={onClickLogin}
        schema="ghost"
        label={t('login_cap')}
        className={classes.btn}
        labelColor="brand"
        labelFont="bold"
      />
      <Button
        onClick={onClickSignup}
        schema="primary-blue"
        label={t('signup_cap')}
      />
    </Flex>
  );
};

export default NotLoggedInUserActions;
