import React from 'react';
import Button from '@lobox/uikit/Button';
import IconButton from '@lobox/uikit/Button/IconButton';
import SimpleImageCropper from '@lobox/uikit/SimpleImageCropper';
import EasyCropper from '@lobox/uikit/EasyCropper';
import cnj from '@lobox/uikit/utils/cnj';
import max from 'lodash/max';
import classes from './BottomCarouselItem.component.module.scss';

const cropperSize = {
  width: 80,
  height: 80,
};

type Props = {
  file: any;
  isActive: boolean;
  onClickOnRemove: (evt?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClick: (evt?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const onCropChangePlaceholder = (): null => null;

function BottomCarouselItem({
  file,
  isActive,
  onClickOnRemove,
  onClick,
}: Props): JSX.Element {
  const [zoom, setZoom] = React.useState(0);

  const updateZoom = (ref: React.MutableRefObject<HTMLVideoElement>) => {
    ref.current.loop = true;
    ref.current.playsInline = true;

    const videoWidth = ref.current?.offsetWidth;
    const videoHeight = ref.current?.offsetHeight;
    const maxSide = max([videoWidth, videoHeight]);

    setZoom(() =>
      max([maxSide / (videoWidth || 1), maxSide / (videoHeight || 1)])
    );
  };

  return (
    <Button
      className={cnj(classes.thumbnailButton, {
        [classes.thumbnailButtonSelected]: isActive,
      })}
      onClick={onClick}
      schema="transparent"
    >
      <IconButton
        colorSchema="primary"
        name="trash"
        type="far"
        size="sm"
        className={classes.trash}
        onClick={onClickOnRemove}
      />
      {file?.type === 'image' && (
        <SimpleImageCropper
          image={file.file}
          width={cropperSize.width}
          height={cropperSize.height}
          position={{ x: 0.5, y: 0.5 }}
          border={0}
          className={cnj(classes.defaultCursor, {
            [classes.diactivatedCropper]: !isActive,
          })}
        />
      )}
      {file?.type === 'video' && (
        <EasyCropper
          video={file.url}
          showGrid={false}
          zoom={zoom}
          crop={{ x: 0, y: 0 }}
          aspect={1}
          cropSize={{ width: 80, height: 80 }}
          setVideoRef={updateZoom}
          classes={{
            cropAreaClassName: classes.defaultCursor,
            mediaClassName: cnj(classes.videoMedia, {
              [classes.diactivatedCropper]: !isActive,
            }),
          }}
          onCropChange={onCropChangePlaceholder}
        />
      )}
    </Button>
  );
}

export default BottomCarouselItem;
