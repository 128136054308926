import classes from './index.module.scss';

import { isString } from 'lodash';
import React from 'react';
import { useTranslation } from '@lobox/utils';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import IconButton from '../Button/IconButton';
import Flex from '../Flex';

type StyleProps = {
  root?: string;
  radioButton?: string;
  textContainer?: string;
  label?: string;
  helperText?: string;
};
interface RadioButtonProps {
  value?: any;
  style?: any;
  label?: any;
  helperText?: any;
  error?: any;
  onChange?: any;
  font?: any;
  styles?: Partial<StyleProps>;
  labelProps?: any;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  style,
  label,
  helperText,
  error,
  onChange,
  font,
  styles,
  labelProps = {},
}) => {
  const { t } = useTranslation();

  const onChangeHandler = () => onChange?.(!value);

  return (
    <Flex className={cnj(classes.checkBoxRoot, style, styles?.root)}>
      <IconButton
        className={cnj(
          classes.icon,
          value && classes.selectedIcon,
          styles?.radioButton,
          error && classes.errorBoundary
        )}
        name={value ? 'dot-circle' : 'circle'}
        type="far"
        onClick={onChangeHandler}
        colorSchema={value ? 'tertiary-transparent' : 'transparent'}
        size="sm20"
      />
      {label && isString(label) && (
        <Flex
          className={cnj(
            classes.textContainer,
            helperText && classes.marginTop,
            styles?.textContainer
          )}
        >
          <Typography
            color="thirdText"
            size={15}
            ml={-2}
            font={font}
            className={classes?.label}
            {...labelProps}
          >
            {t(label)}
          </Typography>
          {helperText && (
            <Typography
              color="thirdText"
              size={15}
              className={classes?.helperText}
            >
              {helperText}
            </Typography>
          )}
        </Flex>
      )}
      {label && !isString(label) && label}
    </Flex>
  );
};

export default RadioButton;
