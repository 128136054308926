import {
  APP_ENTITIES,
  convertBlobToFile,
  imageUrlToBlobAndBase64,
} from '@lobox/utils';
import type { IPost } from '@lobox/utils';

export const makeUpdatePostObject = async (post: IPost): Promise<any> => {
  const { location, body, id, tags, medias } = post;
  const selectedLocation = location
    ? {
        title: location.title,
        // vicinity: locationDetail,
        position: {
          lat: location.lat,
          lon: location.lon,
        },
      }
    : null;

  const postBody = body;
  const selectedUsers = makeTags(tags);
  const files = await makeFileFromMedia(medias);
  return {
    updatePostId: id,
    selectedLocation,
    postBody,
    selectedUsers,
    files,
  };
};

const makeTags = (tags: any[]) => {
  return !tags?.length
    ? undefined
    : tags.map((x: any) => {
        const username =
          x.userType === APP_ENTITIES.page
            ? x.pageInfo.username
            : x.profileInfo.username;
        const fullName =
          x.userType === APP_ENTITIES.person
            ? `${x.profileInfo.name} ${x.profileInfo.surname}`
            : x.pageInfo.title;

        return {
          id:
            x.userType === APP_ENTITIES.page
              ? x.pageInfo.id
              : x.profileInfo.userId,
          croppedImageUrl:
            x.userType === APP_ENTITIES.page
              ? x.pageInfo.croppedImageUrl
              : x.profileInfo.croppedImageUrl,
          fullName,
          userType: x.userType,
          type: x.userType,
          username,
          usernameAtSign: `@${username}`,
        };
      });
};

const makeFileFromMedia = (medias: any[]): Promise<any[]> | [] => {
  if (!medias?.length) return [];
  const data = medias.map(async (file) => ({
    type: file.type.toLowerCase(),
    id: file.id,
    url: file.url,
    file: convertBlobToFile((await imageUrlToBlobAndBase64(file.url)).blob),
    isUploading: false,
    isUploaded: true,
    isError: false,
    res: {
      link: file.url,
    },
  }));

  return Promise.all(data);
};

export default { makeUpdatePostObject };
