import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import ListItem from '@lobox/uikit/ListItem';
import Link from '@lobox/uikit/Link';
import type ColorsKeys from '@lobox/uikit/ColorsKeys';
import classes from './LeftNavigationItem.component.module.scss';

interface LeftPanelProps {
  narrow?: boolean;
  autoWide?: boolean;
  label?: string;
  link: string;
  isActive?: boolean;
  leftSvg?: React.ReactNode;
  leftIcon?: string;
  leftColor?: ColorsKeys;
  onClick?: (...args: any[]) => any;
  rightAction?: React.ReactNode;
}

const LeftNavigationItem: React.FC<LeftPanelProps> = ({
  narrow,
  autoWide,
  label,
  link = '',
  leftSvg,
  isActive,
  rightAction,
  onClick,
}) => (
  <Link to={link} className={classes.root} onClick={onClick}>
    <ListItem
      hover
      autoWide={autoWide}
      narrow={narrow}
      hasNarrowLabel={narrow}
      leftSvg={leftSvg}
      label={label}
      labelSize={narrow ? 10 : 16}
      className={cnj(
        classes.item,
        isActive && classes.itemActive,
        narrow && classes.itemNarrow
      )}
      labelColor={isActive ? 'brand' : 'smoke_coal'}
      hoverColor={isActive ? 'brand' : 'smoke_coal'}
      hoverBgColor={isActive ? 'brand_10' : 'hoverPrimary'}
      labelClassName={cnj(classes.label, narrow && classes.labelNarrow)}
      labelsContainerClassName={classes.labelsContainer}
      labelFont="500"
      rightSvg={rightAction}
    />
  </Link>
);

export default LeftNavigationItem;
