import React, { useState } from 'react';
import { useGlobalDispatch, useGlobalState, useHistory } from '@lobox/utils';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Media from '@lobox/uikit/Media';
import Tooltip from '@lobox/uikit/Tooltip';
import { generateBadgeNumber, mainRoutes, useTranslation } from '@lobox/utils';
import useGetUnSeenNotificationsCount from '@shared/hooks/api-hook/useGetUnSeenNotificationsCount';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import EventModalsProvider from '@shared/contexts/eventModals.provider';
import type { IconButtonProps } from '@lobox/uikit/Button/IconButton';
import type { FC } from 'react';
import { TOP_BAR_NOTIFICATION_BUTTON } from '@shared/constants/enums';
import classes from './NotificationButton.module.scss';
import NotificationModal from './NotificationModal';

interface NotificationsProps {
  className?: string;
  onClick?: VoidFunction;
}

const Notifications: FC<NotificationsProps> = (props) => {
  const { className, onClick } = props;
  const globalDispatch = useGlobalDispatch();
  const isOpenNotificationModal = useGlobalState('isOpenNotificationModal');
  const { t } = useTranslation();
  const { data } = useGetUnSeenNotificationsCount();
  const canSeeNotificationScreen = useHasPermission([
    SCOPES.canSeeNotificationScreen,
  ]);
  const history = useHistory();
  const commonProps: IconButtonProps = {
    badgeNumber: canSeeNotificationScreen
      ? generateBadgeNumber(data?.unOpenedCount)
      : undefined,
    size: 'md20',
    name: isOpenNotificationModal ? 'bell' : 'bell-light',
    type: isOpenNotificationModal ? 'fas' : 'far',
    iconProps: {
      size: 22,
    },
    colorSchema: 'secondary2',
  };

  const setIsOpenNotificationModal = (payload: boolean) =>
    globalDispatch({
      type: 'SET_IS_OPEN_NOTIFICATION_PANEL',
      payload,
    });

  const toggleModal = (_state?: boolean) => {
    setIsOpenNotificationModal(!isOpenNotificationModal);
  };

  const closeModal = (e) => {
    const notifButtonElement = document?.getElementById(
      TOP_BAR_NOTIFICATION_BUTTON
    );

    if (notifButtonElement?.contains(e?.target)) return;
    setIsOpenNotificationModal(false);
  };

  const onClickHandler = () => {
    onClick?.();
    history.push(mainRoutes.notifications);
  };

  return canSeeNotificationScreen ? (
    <EventModalsProvider>
      <Media greaterThan="tablet">
        <Flex className={cnj(classes.notification, className)}>
          <Tooltip
            disabled={isOpenNotificationModal}
            trigger={
              <IconButton
                {...commonProps}
                onClick={toggleModal}
                colorSchema={
                  isOpenNotificationModal ? 'tertiary' : 'secondary2'
                }
                htmlId={TOP_BAR_NOTIFICATION_BUTTON}
              />
            }
          >
            {t('notifications')}
          </Tooltip>
        </Flex>
      </Media>
      <Media lessThan="midDesktop">
        <IconButton
          {...commonProps}
          onClick={onClickHandler}
          className={cnj(classes.topNotification, className)}
        />
      </Media>
      {isOpenNotificationModal && (
        <NotificationModal handleCloseModal={closeModal} />
      )}
    </EventModalsProvider>
  ) : (
    <IconButton {...commonProps} className={classes.notification} />
  );
};

export default Notifications;
