'use client';

import React from 'react';
import AppLayout from '@shared/components/layouts/AppLayout';
import { MediaContextProvider } from '@lobox/uikit/utils/media';
import { SearchProvider } from '@shared/contexts/search/search.provider';
import dynamic from 'next/dynamic';

const InstsallAppPopUp = dynamic(
  () => import('@shared/components/molecules/InstallPopUp')
);
const ToastContainer = dynamic(() => import('@lobox/uikit/ToastContainer'));

export default function RootLayout({ children }: React.PropsWithChildren) {
  return (
    <MediaContextProvider>
      <SearchProvider>
        <AppLayout>{children}</AppLayout>
      </SearchProvider>
      <InstsallAppPopUp />
      <ToastContainer />
    </MediaContextProvider>
  );
}
