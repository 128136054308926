import classes from './ShareEntityTabbedModal.component.module.scss';

import React, { useState } from 'react';
import {
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
} from '@lobox/utils';
import { ModalDialog, ModalHeaderSimple } from '@shared/components/molecules';
import Tabs from '@shared/components/containers/Tabs';
import type { ShareEntityTab } from '@lobox/utils/types/share';
import type { ComponentProps } from 'react';
import { ShareEntityCopyLink } from './tabs/ShareEntityCopyLink/ShareEntityCopyLink.component';
import type { ShareEntityModalProps } from './tabs/ShareEntityViaMessage/ShareEntityViaMessage.component';
import { ShareEntityViaMessage } from './tabs/ShareEntityViaMessage/ShareEntityViaMessage.component';
import CreatePostModal from '../CreatePostModal/CreatePostModal';
import type { CreateAttachmentPostProps } from '../CreatePost/components/Attachment/Attachment.component';
import ShareEntityViaEmail from './tabs/ShareEntityViaEmail/ShareEntityViaEmail.component';
import { shareLabels } from '@shared/constants/schedules';

const shareGeneralLabels: Partial<
  Record<CreateAttachmentPostProps['entityType'], string>
> = {
  job: 'share_job',
  availability: 'share_availability',
  post: 'share_post',
  resume: 'share_resume',
  page: 'share_page',
  profile: 'share_profile',
};

const ShareEntityTabbedModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const [activeTab, setActiveTab] = useState('');
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const { currentTab: createPostCurrentTab } =
    useGlobalState('createPostModal');

  const closeHandler = () => {
    dispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: { isOpen: false },
    });
    dispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        selectedPostForEdit: undefined,
        isOpenModal: false,
        attachment: undefined,
        currentTab: 'main',
      },
    });
  };

  const { entityData, tabs: entityTabs } = useGlobalState(
    'shareEntityTabbedModal'
  );

  const entityType = entityData?.attachment
    ?.type as CreateAttachmentPostProps['entityType'];

  const modalTitle = t(
    shareGeneralLabels[entityType]
      ? shareGeneralLabels[entityType]
      : shareLabels[entityType?.toUpperCase()]
  );

  const tabs: ComponentProps<typeof Tabs>['tabs'] = [
    {
      path: 'share_via_post' as ShareEntityTab,
      title: t('via_post'),
      content: () => (
        <CreatePostModal
          post={entityData?.attachment}
          hasHeader={createPostCurrentTab !== 'main'}
          isOpen
          isCreation
        />
      ),
    },
    {
      path: 'share_via_message' as ShareEntityTab,
      title: t('via_message'),
      content: () => (
        <ShareEntityViaMessage
          entityData={entityData?.attachment?.data}
          entity={
            entityData?.attachment?.type.toLowerCase() as ShareEntityModalProps['entity']
          }
          setIsHeaderVisible={setIsHeaderVisible}
          onClose={() => {
            dispatch({
              type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
              payload: {
                isOpen: false,
              },
            });
          }}
        />
      ),
    },
    {
      path: 'share_via_email' as ShareEntityTab,
      title: t('via_email'),
      content: () => <ShareEntityViaEmail entityData={entityData} />,
    },
    {
      path: 'copy_link' as ShareEntityTab,
      title: t('copy_link'),
      content: () => <ShareEntityCopyLink entityData={entityData} />,
    },
  ]
    .filter((item) => entityTabs.includes(item?.path))
    .filter(Boolean);

  return (
    <ModalDialog
      modalClassName={!isHeaderVisible && classes.hidden}
      onBack={closeHandler}
      onClose={closeHandler}
      isOpen
    >
      <ModalHeaderSimple
        title={modalTitle}
        titleProps={{
          isWordWrap: true,
          isTruncated: true,
          lineNumber: 1,
          className: classes.title,
        }}
        backButtonProps={{ onClick: closeHandler }}
        closeButtonProps={{ onClick: closeHandler }}
      />
      <Tabs
        onChangeTab={setActiveTab}
        styles={{
          tabsWrap: classes.tabsWrap,
          tabsRoot: classes.tabsRoot,
          content: classes.content,
          linksWrap: classes.linksWrap,
        }}
        activePath={activeTab || tabs?.[0]?.path}
        tabs={tabs}
      />
    </ModalDialog>
  );
};

export default ShareEntityTabbedModal;
