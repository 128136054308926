import classes from './GlobalsSearchInput.sectionTitle.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';

export interface Props {
  className?: string;
  onClick?: () => void;
  text: string;
}

const SectionTitle: React.FC<Props> = ({ className, onClick, text }) => {
  const { t } = useTranslation();

  return (
    <Flex className={cnj(classes.clearAllRoot, className)}>
      <Flex className={classes.innerContainer}>
        <Typography
          font="400"
          size={16}
          height={20}
          color="colorIconForth2"
          mr="auto"
        >
          {text}
        </Typography>
        {/* {!!onClick && (
          <BaseButton onClick={onClick}>
            <Typography font="400" size={14} height={20} color="brand">
              {t('clear_all')}
            </Typography>
          </BaseButton>
        )} */}
      </Flex>
    </Flex>
  );
};

export default SectionTitle;
