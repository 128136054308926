import React from 'react';
import { motion } from 'framer-motion';
import cnj from '../utils/cnj';
import classes from './Drawer.component.module.scss';

interface DrawerProps extends React.PropsWithChildren {
  onBackDropClick?: (e: any) => void;
  backdropClassName?: string;
  contentClassName?: string;
  sliderDir?: 'left' | 'right';
}

const Drawer: React.FC<DrawerProps> = ({
  children,
  onBackDropClick = () => undefined,
  backdropClassName,
  contentClassName,
  sliderDir = 'right',
}) => {
  const isLeft = sliderDir === 'left';

  const backdropVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  const contentVariants = {
    visible: { x: 0 },
    hidden: { x: isLeft ? '-100%' : '100%' },
  };

  return (
    <>
      <motion.div
        onClick={onBackDropClick}
        initial="hidden"
        animate="visible"
        variants={backdropVariants}
        className={cnj(classes.backdrop, backdropClassName)}
        transition={{ duration: 0.25 }}
      />
      <motion.div
        initial="hidden"
        animate="visible"
        variants={contentVariants}
        className={cnj(
          classes.drawerContent,
          isLeft && classes.isLeftDrawerContent,
          contentClassName
        )}
        transition={{ duration: 0.25 }}
      >
        {children}
      </motion.div>
    </>
  );
};

export default Drawer;
