import React from 'react';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Skeleton from '@lobox/uikit/Skeleton';
import classes from './ClosedRightPanelItems.loading.module.scss';

const data = Array.from({ length: 20 }, (_, i) => i);

const ClosedRightPanelItemsLoading: React.FC = () => {
  const canSeeMessagesScreen = true;

  return (
    <Flex className={classes.closedRightPanelItemsLoadingRoot}>
      {canSeeMessagesScreen && (
        <IconButton
          colorSchema="secondary"
          name="chevron-left"
          type="far"
          className={classes.toggleButton}
        />
      )}
      {canSeeMessagesScreen
        ? data.map((item) => (
            <Skeleton key={`${item}`} className={classes.item} />
          ))
        : null}
    </Flex>
  );
};

export default ClosedRightPanelItemsLoading;
